import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";

import AppTopbar from "./components/AppTopbar";
import LoginView from "./views/LoginView";
import HomeView from "./components/HomeView";
import useToken from "./views/TokenView";
import TricketView from "./components/tricket/TricketView";
import RegisterView from "./views/RegisterView";
import ProfileView from "./components/ProfileView";
import RubberView from "./components/service/rubber/RubberView";
import ExpressView from "./components/express/ExpressView";
import WalletView from "./components/wallet/WalletView";
import HistoryWallet from "./components/wallet/HistoryWallet";
import CommisstionView from "./components/commission/CommissionView";
import OrderTricket from "./components/order/OrderTricket";
import OrderExpress from "./components/order/OrderExpress";
import TemwalletView from "./components/temwallet/TemwalletView";
import InputWallet from "./components/temwallet/InputWallet";
import CheckOutWallet from "./components/temwallet/CheckOutWallet";
import OrderService from "./components/order/OrderService";
import AccountView from "./components/service/account/AccountView";
import WebsiteView from "./components/service/website/WebsiteView";
import MarketingView from "./components/service/marketing/MarketingView";
import ActView from "./components/service/act/ActView";
import ArtworkView from "./components/service/artwork/ArtworkView";
import AppPremiumView from "./components/service/apppremium/ApppremiumView";
import OrderAppPremium from "./components/order/OrderApppremium";
import LoginHistory from "./components/login/LoginHistory";
import RegisterRefView from "./views/RegisterRefView";
import ForgetPassword from "./views/ForgetPassword";
import TopupView from "./components/counter/topup/TopupView";
import OrderTopup from "./components/order/OrderTopup";
import BoxExpress from "./components/express/BoxExpress";
import BusView from "./components/easybook/bus/busview";
import BusPayment from "./components/easybook/bus/Payment";

import POSView from "./components/pos/shop/POSView";
import CartProduct from "./components/pos/shop/CartProduct";
import OrderPreorder from "./components/pos/shop/OrderProduct";
import ImportProduct from "./components/pos/shop/ImportProduct";
import ProductShop from "./components/pos/shop/ProductShop";
import OrderPOS from "./components/order/OrderPOS";
import ClosePOSShop from "./components/pos/shop/ClosePOSShop";
import InvoicePOSShop from "./components/pos/shop/InvoicePOSShop";
import TermGameView from "./components/service/game/GameView";

export default function App() {

  const { token, setToken } = useToken()

  if (!token) {
    // return <LoginView setToken={setToken} />
    return (
      <Router>
        <Routes>
          <Route path="/" element={<LoginView setToken={setToken} />} />
          <Route path="/register" element={<RegisterView />} />
          <Route path="/register/:token" element={<RegisterRefView />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
        </Routes>
      </Router>
    )
  }

  return (
    <div>
      <AppTopbar />
      <Router>
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/pos" element={<POSView />} />
          <Route path="/cart/pos" element={<CartProduct />} />
          <Route path="/order/pos" element={<OrderPreorder />} />
          <Route path="/import/product" element={<ImportProduct />} />
          <Route path="/product/shop" element={<ProductShop />} />
          <Route path="/pos/order" element={<OrderPOS />} />
          <Route path="/pos/close/shop" element={<ClosePOSShop />} />
          <Route path="/pos/invoice" element={<InvoicePOSShop />} />
          <Route path="/login/history" element={<LoginHistory />} />
          <Route path="/home" element={<HomeView />} />
          <Route path="/profile" element={<ProfileView />} />
          <Route path="/tricket" element={<TricketView />} />
          <Route path="/tricket/order" element={<OrderTricket />} />
          <Route path="/rubber" element={<RubberView />} />
          <Route path="/artwork" element={<ArtworkView />} />
          <Route path="/service/order" element={<OrderService />} />
          <Route path="/account" element={<AccountView />} />
          <Route path="/website" element={<WebsiteView />} />
          <Route path="/marketing" element={<MarketingView />} />
          <Route path="/act" element={<ActView />} />
          <Route path="/express" element={<ExpressView />} />
          <Route path="/express/order" element={<OrderExpress />} />
          <Route path="/express/box" element={<BoxExpress />} />
          <Route path="/wallet" element={<WalletView />} />
          <Route path="/history/wallet" element={<HistoryWallet />} />
          <Route path="/commission" element={<CommisstionView />} />
          <Route path="/temtopup" element={<TopupView />} />
          <Route path="/temtopup/order" element={<OrderTopup />} />
          {/* <Route path="/temwallet" element={<TemwalletView />} /> */}
          {/* <Route path="/temwallet/:id/input" element={<InputWallet />} /> */}
          {/* <Route path="/temwallet/checkout/:code/:tel/:price" element={<CheckOutWallet />} /> */}
          <Route path="/termgame" element={<TermGameView />} />
          <Route path="/apppremium" element={<AppPremiumView />} />
          <Route path="/apppremium/order" element={<OrderAppPremium />} />
          {/* easybook */}
          <Route path="/bus" element={<BusView />} />
          <Route path="/payment" element={<BusPayment />} />
        </Routes>
      </Router>
      {/* <AppFooterbar /> */}
    </div>
  )
}