import React from "react";
import "./history.css";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chip } from 'primereact/chip';
import { Tag } from 'primereact/tag';
import { Image } from 'primereact/image';

async function getMe() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/me`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getShop(shop_id) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getWalletMember(packageId) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/wallet/member/${packageId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getWalletShop(packageId) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/wallet/shop/${packageId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getWalletHistroyMember(packageId) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/wallet/history/member/${packageId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getWalletHistroyShop(packageId) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/wallet/history/shop/${packageId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getIncomeHisroty(packageId) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/wallet/income/shop/${packageId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

function getColorStatus(status) {
    if (status === 'รอตรวจสอบ') {
        return "#e5c33b";
    } else if (status === 'รายการสำเร็จ') {
        return "#339900";
    } else {
        return "#f43030";
    }
};

function datetimeFormat(date) {
    return dayjs(date).format("DD/MM/YYYY เวลา HH:mm:ss น.");
};

function numberFormat(number) {
    return number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export default function HistoryWallet() {
    const [orderTopup, setOrderTopup] = React.useState([]);
    const [wallethistory, setWaleltHistory] = React.useState([]);
    const [income, setIncome] = React.useState([]);

    React.useState(async () => {
        const response = await getMe();
        if (response.logout === true) {
            localStorage.clear();
            window.location.href = "/";
        }
        if (response.level === 'member') {
            const wallet_topup = await getWalletMember(response.data._id);
            setOrderTopup(wallet_topup.data)
            const wallet_history = await getWalletHistroyMember(response.data._id);
            setWaleltHistory(wallet_history.data.reverse());
        } else if (response.level === 'employee') {
            if (response.data.employee_position !== 'manager') {
                Swal.fire({
                    title: "แจ้งเตือน?",
                    icon: "warn",
                    text: 'ผู้ใช้งานดังกล่าวไม่สามารถใช้งานระบบนี้ได้',
                    showCancelButton: false,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "ยืนยัน",
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = "/";
                    }
                })
            } else {
                const shop = await getShop(response.data.employee_shop_id);
                if (shop.data.shop_status === false) {
                    Swal.fire({
                        title: 'แจ้งเตือน!',
                        text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else {
                    const wallet_topup = await getWalletShop(shop.data._id);
                    setOrderTopup(wallet_topup.data.reverse())
                    const wallet_history = await getWalletHistroyShop(shop.data._id);
                    setWaleltHistory(wallet_history.data.reverse());
                    const income_history = await getIncomeHisroty(shop.data._id);
                    setIncome(income_history.data.reverse());
                }
            }
        }
    });

    const statusColor = (item) => {
        switch (item) {
            case 'เงินออก': return 'danger';
            case 'เงินเข้า': return 'success';
            default: return 'warn';
        }
    };

    const typeTemplate = (item) => {
        return <Tag value={item.type} rounded severity={statusColor(item.type)} />
    };

    const timeTemplate = (item) => {
        return datetimeFormat(item.timestamp);
    };

    const AmountBefore = (item) => {
        return `${numberFormat(item.before)} บาท`
    };
    const AmountTemplate = (item) => {
        return `${numberFormat(item.amount)} บาท`
    };
    const AmountAfter = (item) => {
        return `${numberFormat(item.after)} บาท`
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box>
                        <Grid container className={"container"}>
                            <div className="title"><h1>ประวัติการเงิน</h1></div>
                            <Box sx={{ width: '100%' }}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item className="history-model">
                                        <TabView className="history-field">
                                            <TabPanel header="ประวัติการเติมเงิน">
                                                <DataTable value={orderTopup} stripedRows paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50]} className="history-datatable">
                                                    <Column header="เลขที่ทำรายการ" body={(rowData) => (
                                                        <div>
                                                            <Chip label={rowData.invoice} className="chip" />
                                                        </div>
                                                    )}></Column>
                                                    <Column field="amount" header="จำนวน"></Column>
                                                    <Column field="charge" header="ค่าธรรมเนียม"></Column>
                                                    <Column header="หลักฐานการโอน" body={(rowData) => (
                                                        <div>
                                                            <Image src={`${process.env.REACT_APP_SHOP}/wallet/image/${rowData.detail}`} alt="" width="100" preview />
                                                        </div>
                                                    )}></Column>
                                                    <Column header="สถานะ" body={(rowData) => (
                                                        <div>
                                                            <Chip label={rowData.status} className="chip" style={{ backgroundColor: getColorStatus(rowData.status), color: 'white' }} />
                                                        </div>
                                                    )}></Column>
                                                </DataTable>
                                            </TabPanel>
                                            <TabPanel header="เงินเข้า-เงินออก">
                                                <DataTable value={wallethistory} stripedRows paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50]} className="history-datatable">
                                                    <Column header="รายการ" body={typeTemplate}></Column>
                                                    <Column field="name" header="รายละเอียด"></Column>
                                                    <Column header="ยอดเงินก่อนทำรายการ" body={AmountBefore}></Column>
                                                    <Column header="จำนวน" body={AmountTemplate}></Column>
                                                    <Column header="ยอดเงินคงเหลือ" body={AmountAfter}></Column>
                                                    <Column header="วันที่" body={timeTemplate}></Column>
                                                </DataTable>
                                            </TabPanel>
                                            <TabPanel header="รายได้">
                                                <DataTable value={income} stripedRows paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50]} className="history-datatable">
                                                    <Column header="รายการ" body={typeTemplate}></Column>
                                                    <Column field="name" header="รายละเอียด"></Column>
                                                    <Column header="ยอดเงินก่อนทำรายการ" body={AmountBefore}></Column>
                                                    <Column header="จำนวน" body={AmountTemplate}></Column>
                                                    <Column header="ยอดเงินคงเหลือ" body={AmountAfter}></Column>
                                                    <Column header="วันที่" body={timeTemplate}></Column>
                                                </DataTable>
                                            </TabPanel>
                                        </TabView>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )

}