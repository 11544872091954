import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { useReactToPrint } from "react-to-print";

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from 'primereact/inputtextarea';
import { ButtonGroup } from "primereact/buttongroup";
import { Card } from "primereact/card";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Sidebar } from 'primereact/sidebar';
import { Divider } from 'primereact/divider';

import LoadingDialog from "../../../views/LoadingView";
import ReceiptArtwork from "../artwork/ReceiptArtwork";

async function getMe() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/me`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json());
};

async function getShop(shop_id) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json());
};

async function getCategoryArt() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/service/artwork/category`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json());
};

async function getProductRubber(packageId) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/service/artwork/cate/${packageId}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json());
};

async function getPriceRubber(packageId) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/service/artwork/price/product/${packageId}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json());
};

async function getImageArtwork(packageData) {
    const token = JSON.parse(localStorage.getItem('token'));
    const data = fetch(`${process.env.REACT_APP_SHOP}/service/artwork/image/${packageData}`, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    });
    console.log(data);
};

async function callcheckout(packageData) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/service/artwork/order`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
        body: JSON.stringify(packageData),
    }).then(data => data.json());
};

async function getProvince() {
    return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json');
};

async function getDistrict() {
    return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json');
};

async function getSubDistrict() {
    return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_tambon.json');
};

async function GetMember(reftel) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/member/check/${reftel}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

function numberFormat(number) {
    return number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: '#ECD400',
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Item3 = styled(Paper)(({ theme }) => ({
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 2,
    fontFamily: 'Kanit'
}));

export default function RubberView() {
    const [isLoading, setisLoading] = useState(false);
    const [position, setPosition] = useState("");
    const [member, setMember] = useState({});
    const [employee, setEmployee] = useState({});
    const [shop, setShop] = useState({});

    const [products, setProducts] = useState([]);
    const [price, setPrice] = useState([]);
    const [rating, setRating] = useState(null);
    const [dialogPrice, setDialogPrice] = useState(false);
    const [dialogDetail, setDialogDetail] = useState(false);

    // Customer
    const [item_province, setItemProvince] = useState([]);
    const [item_district, setItemDistrict] = useState([]);
    const [item_subdistrict, setItemSubDistrict] = useState([]);
    const [province, setProvince] = useState('');
    const [district, setDistrict] = useState('');
    const [subdistrict, setSubdistrict] = useState('');
    const [postcode, setPostcode] = useState('');
    const [address, setAddress] = useState('');
    const [reftel, setReftel] = useState('');
    const [refteldisable, setReftelDisable] = useState(false);
    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [iden, setIden] = useState('');
    const [line, setLine] = useState('');

    // Product
    const [product_detail, setProductDetail] = useState(null);
    const [product_price, setProductPrice] = useState(null);
    const [detail, setDetail] = useState('');
    const [amount, setAmount] = useState(1);
    const [dialogUpdate, setDialogUpdate] = useState(false);
    const [updateAmount, setUpdateAmount] = useState(0);
    const [updateDescription, setUpdateDescription] = useState("");
    const [updateDetail, setUpdateDetail] = useState({});

    // Order
    const [order_rubber, setOrderRubber] = useState([]);
    const [order_details, setOrderDetail] = useState([]);
    // Platform
    const [dialogOrderPlatform, setDialogOrderPlatform] = useState(false);

    // ชำระเงิน
    const [dialogDiscount, setDialogDiscount] = useState(false);
    const [dialogCheckOut, setDialogCheckout] = useState(false);
    const [paymenttype, setPaymentType] = useState('');
    const [moneyreceive, setMoneyReceive] = useState(0);
    const [disabledMoneyReceive, setDisabledMoneyReceive] = useState(false);
    const [change, setChange] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [resp, setResp] = useState({});

    const [dialogReceipt, setDialogReceipt] = useState(false);
    const componentReceipt = useRef();
    const headlerPrintReceipt = useReactToPrint({
        content: () => componentReceipt.current,
    });

    useState(async () => {
        const response = await getMe();
        if (response.logout === true) {
            localStorage.clear();
            window.location.href = "/";
        }
        if (response.level === 'member') {
            const category = await getCategoryArt();
            const provinces = await getProvince();
            const categorys = category.data.find(
                (el) => el.name === "ตรายาง (Rubber Stamp)"
            );
            const rubber = await getProductRubber(categorys._id);
            setPosition('One Stop Platform');
            setMember(response.data);
            setProducts(rubber.data);
            setItemProvince(provinces.data);
        } else if (response.level === 'employee') {
            const shop = await getShop(response.data.employee_shop_id);
            if (shop.data.shop_status === false) {
                Swal.fire({
                    title: 'แจ้งเตือน!',
                    text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                if (shop.data.shop_type === 'One Stop Shop') {
                    Swal.fire({
                        title: "แจ้งเตือน!",
                        text: "ร้านค้าของท่าน ยังไม่เปิดให้ใช้งานระบบดังกล่าว!",
                        icon: "warning",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "ยืนยัน",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/";
                        }
                    })
                } else {
                    setPosition(shop.data.shop_type)
                    setEmployee(response.data);
                    setShop(shop.data);
                    const provinces = await getProvince();
                    setItemProvince(provinces.data);
                    const category = await getCategoryArt();
                    const categorys = category.data.find(
                        (el) => el.name === "ตรายาง (Rubber Stamp)"
                    );
                    if (categorys) {
                        const rubber = await getProductRubber(categorys._id);
                        setProducts(rubber.data);
                    }
                }
            }
        }
    });

    const handleDistrict = async (e) => {
        e.preventDefault();
        const response = await getDistrict();
        // console.log(response.data)
        const filtered = response.data;
        const districts = filtered.filter(el => el.province_id === e.value.id);
        setProvince(e.value);
        setItemDistrict(districts);
    };

    const handleSubDistrict = async (e) => {
        e.preventDefault();
        const response = await getSubDistrict();
        const filtered = response.data;
        const subdistricts = filtered.filter(el => el.amphure_id === e.value.id);
        setDistrict(e.value);
        setItemSubDistrict(subdistricts);
    };

    const handlePostcode = (e) => {
        e.preventDefault();
        setSubdistrict(e.value);
        setPostcode(e.value.zip_code);
    };

    const countryOptionTemplate = (option) => {
        return (
            <div>
                {option.name_th}
            </div>
        );
    };

    const ImageBodyTemplate = (item) => {
        return <img src={`${process.env.REACT_APP_SHOP}/service/artwork/image/${item}`} alt="" width={'100%'} />
    };

    const headleCheckPrice = async (e, item) => {
        e.preventDefault();
        const price = await getPriceRubber(item._id);
        if (price.status === true) {
            setProductDetail(item);
            setPrice(price.data);
            setDialogPrice(true);
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'ระบบขัดข้อง หรือระบบมีปัญหา กรุณาติดต่อแอดมินเพื่อทำรายการ',
                icon: 'error',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
    };

    const onHideDialogPrice = () => {
        setDialogPrice(false);
    };

    const headleRelectPrice = async (e, item) => {
        e.preventDefault();
        setProductPrice(item)
        setDialogDetail(true)
    };

    const onHideDialogDetail = () => {
        setDialogDetail(false);
    };

    const headleAddOrder = () => {
        const price = product_price.price * amount;
        const freight = product_price.freight * amount;
        const total = price + freight;
        const order_detail = {
            packageid: product_detail._id,
            priceid: product_price._id,
            packagedetail: detail,
            quantity: amount,
        };
        const order = {
            id: product_detail._id,
            priceid: product_price._id,
            name: product_detail.name,
            type: product_detail.detail,
            price: product_price.price,
            freight: product_price.freight,
            amount: amount,
            detail: detail,
            total_price: price,
            total_freight: freight,
            total_net: total,
        };
        setOrderDetail([...order_details, order_detail]);
        setOrderRubber([...order_rubber, order]);
        ClearData();
    };

    const headlerCloseData = () => {
        ClearData();
    };

    const updateProduct = (value) => e => {
        setDialogUpdate(true)
        setUpdateAmount(value.amount);
        setUpdateDescription(value.detail);
        setUpdateDetail(value);
    };

    const confirmUpdate = () => {
        let update_order_detail;
        let update_detail;

        let price = 0;
        let freight = 0;
        let total = 0;
        if (updateAmount > 5) {
            const value = updateAmount / 5;
            const result = Math.trunc(value);
            price = updateDetail.price * updateAmount;
            freight = updateDetail.freight + (result * 10);
            total = price + freight;
        } else {
            price = updateDetail.price * updateAmount;
            freight = updateDetail.freight;
            total = price + freight;
        }
        update_order_detail = {
            packageid: updateDetail.id,
            priceid: updateDetail.priceid,
            packagedetail: updateDescription,
            quantity: updateAmount,
        };
        update_detail = {
            ...updateDetail,
            amount: updateAmount,
            detail: updateDescription,
            total_price: price,
            total_freight: freight,
            total_net: total,
        };
        const position = order_rubber.findIndex((el) => el.id === updateDetail.id);
        const positions = order_details.findIndex((el) => el.packageid === updateDetail.id);
        order_rubber.splice(position, 1, update_detail);
        order_details.splice(positions, 1, update_order_detail);
        setOrderRubber([...order_rubber]);
        setOrderDetail([...order_details]);
        setDialogUpdate(false);
    };

    const onHideDialogUpdate = () => {
        setDialogUpdate(false);
    };

    const deleteProduct = (value) => e => {
        const position = order_rubber.findIndex((el) => el.id === value.id);
        const positions = order_details.findIndex((el) => el.packageid === value.id);
        order_rubber.splice(position, 1);
        order_details.splice(positions, 1);
        setOrderRubber([...order_rubber]);
        setOrderDetail([...order_details]);
        if (order_rubber.length === 0) {
            setDiscount(0)
        }
    };

    function ClearData() {
        setProductDetail(null);
        setProductPrice(null);
        setDialogPrice(false);
        setDialogDetail(false);
        setDetail('');
        setAmount(1);
    };

    function ClearDataCus() {
        setName('');
        setAddress('');
        setProvince('');
        setSubdistrict('');
        setDistrict('');
        setPostcode('');
        setTel('');
        setIden('');
        setLine('');
        setReftel('');
    };

    const heandleDialogCart = async () => {
        setDialogOrderPlatform(true);
    };

    // Check Out
    const calculateTotal = () => {
        return order_rubber.reduce((total, item) => total + item.total_net, 0);
    };

    const headleCheckReftel = async () => {
        if (reftel === "") {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณากรอกทศกัฐ์แฟมมิลี่',
                icon: 'warning',
                showConfirmButton: false,
                timer: 1500,
            });
            return false;
        } else {
            const response = await GetMember(reftel);
            if (response.status) {
                Swal.fire({
                    title: "ทศกัณฐ์แฟมมิลี่?",
                    icon: "success",
                    text: `เบอร์โทรศัพท์ ${response.data.tel} คุณ ${response.data.fristname} ${response.data.lastname}`,
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "ยืนยัน",
                    cancelButtonText: "ยกเลิก",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setReftelDisable(true);
                    }
                })
            } else {
                Swal.fire({
                    title: 'ทศกัณฐ์แฟมมิลี่!',
                    text: 'ไม่พบข้อมูลทศกัณฐ์แฟมมิลี่',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 3000,
                });
                return false;
            }
        }
    };

    const headlerClearReftel = async () => {
        Swal.fire({
            title: "เปลี่ยนทศกัณฐ์แฟมมิลี่?",
            icon: "warning",
            text: `คุณต้องการเปลี่ยนทศกัณฐ์แฟมมิลี่ ใช่หรือไม่?`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
        }).then((result) => {
            if (result.isConfirmed) {
                setReftelDisable(false);
                setReftel('');
            }
        })
    };

    const headlerCheckOut = () => {
        if (order_rubber.length === 0) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'ไม่มีรายการให้ชำระเงิน กรุณาเลือกสินค้าเพื่อทำรายการ',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        if (name === '' ||
            tel === '' ||
            iden === '' ||
            line === '' ||
            address === '' ||
            province === '' ||
            subdistrict === '' ||
            district === '' ||
            postcode === ''
        ) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณากรอกข้อมูลลูกค้าให้ครบถ้วน',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        if (reftel === '' || refteldisable === false) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณากรอกและยืนยัน ทศกัณฐ์แฟมมิลี่',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        if (shop.shop_wallet < calculateTotal()) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'ยอดเงินในระบบไม่เพียงต่อการชำระเงิน',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        setDialogCheckout(true);
    };

    const headlerPaymentType = (e, value) => {
        if (value === 'เงินสด') {
            setPaymentType(value);
            setMoneyReceive(0);
            setDisabledMoneyReceive(false);
        } else {
            const receive = calculateTotal() - discount;
            setPaymentType(value);
            setDisabledMoneyReceive(true);
            setMoneyReceive(receive);
        }
    };

    const onHideDialogCheckout = () => {
        setDialogCheckout(false);
    };

    const headleDiscount = () => {
        if (calculateTotal() === 0) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'ยอดรวมรวมไม่เพียงพอต่อการกรอกส่วนลด',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        } else {
            setDialogDiscount(true);
        }
    };

    const headleConfirmDiscount = () => {
        if (discount >= calculateTotal()) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'ส่วนลดมากกว่ายอดที่ต้องชำระ ไม่สามารถทำรายการได้',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            setDialogDiscount(false);
            setDiscount(0);
            return false;
        } else {
            setDialogDiscount(false);
        }
    };

    const onHideDialogDiscount = () => {
        setDiscount(0);
        setDialogDiscount(false);
    };

    const headleConfirmCheckout = async () => {
        if (moneyreceive < (calculateTotal() - discount)) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'จำนวนเงินที่กรอก ไม่เพียงพอต่อการชำระเงิน',
                icon: 'warning',
                showConfirmButton: false,
                timer: 2000,
            });
            return false;
        }
        const changes = moneyreceive - (calculateTotal() - discount);
        setChange(changes);
        const data = {
            maker_id: employee._id,
            shop_id: shop._id,
            customer_name: name,
            customer_tel: tel,
            customer_address: `${address}, ${subdistrict.name_th}, ${district.name_th}, ${province.name_th}, ${postcode}`,
            customer_iden: iden,
            customer_line: line,
            product_detail: order_details,
            shop_type: position,
            paymenttype: paymenttype,
            moneyreceive: moneyreceive,
            change: changes,
            discount: discount,
            platform: reftel,
            employee: employee._id,
        };
        setisLoading(true);
        setDialogCheckout(false);
        const response = await callcheckout(data);
        if (response.status === true) {
            setResp(response.data);
            setisLoading(false);
            setDialogReceipt(true);
            setMoneyReceive(0);
            ClearDataCus();
        } else {
            setisLoading(false);
            setDialogCheckout(true);
        }
    };

    const heandleCheckOutPlatform = async () => {
        if (order_rubber.length === 0) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'ไม่มีรายการให้ชำระเงิน กรุณาเลือกสินค้าเพื่อทำรายการ',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        setName(member.fristname);
        setTel(member.tel);
        setIden(member.iden.number);
        setLine('ไม่มี');
        setAddress(member.address);
        setSubdistrict(member.subdistrict);
        setDistrict(member.district);
        setProvince(member.province);
        setPostcode(member.postcode);
        setReftel(member.tel);
        if (member.wallet < calculateTotal()) {
            setDialogOrderPlatform(false)
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'ยอดเงินในระบบไม่เพียงต่อการชำระเงิน',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        const data = {
            maker_id: member._id,
            customer_name: member.fristname,
            customer_tel: member.tel,
            customer_address: `${member.address}, ${member.subdistrict}, ${member.district}, ${member.province}, ${member.postcode}`,
            customer_iden: member.iden.number,
            customer_line: 'ไม่มี',
            product_detail: order_details,
            shop_type: position,
            paymenttype: 'เงินโอน',
            moneyreceive: calculateTotal(),
            platform: member.tel,
            employee: member._id,
        };
        setisLoading(true);
        const resq = await callcheckout(data);
        if (resq.status === true) {
            setDialogOrderPlatform(false);
            setisLoading(false);
            Swal.fire({
                title: "ทำรายการสำเร็จ",
                icon: "success",
                text: `คุณได้ทำรายการสั่งซื้อ ใบเสร็จเลขที่ ${resq.data.invoice} สำเร็จ`,
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ทำรายการต่อ",
                cancelButtonText: "ตรวจสอบใบสั่งซื้อ",
            }).then((result) => {
                if (result.isConfirmed) {
                    ClearDataCus();
                    ClearData();
                    setOrderDetail([]);
                    setOrderRubber([]);
                    setReftelDisable(false);
                } else if (result.isDenied) {
                }
            })
        } else {
            setisLoading(false);
        }
    };

    const heandleResetOrder = (e) => {
        window.location.reload();
        // setDiscount(0);
        // setReftel("");
        // setOrderRubber([]);
        // setOrderDetail([]);
        // setReftelDisable(false);
        // setDialogReceipt(false);
    };

    const heandleResetPage = (e) => {
        window.location.href = "/";
    };

    return (
        <div>
            {position === 'One Stop Service' && (
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box>
                                <Grid container className={"container"}>
                                    <div className="title" ><h1>ตรายาง (Rubber Stamp)</h1></div>
                                    <Box sx={{ width: '100%' }}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={5}>
                                                <Grid className="form">
                                                    <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ข้อมูลลูกค้า</Item>
                                                    <Item2 sx={{ width: '100%', height: '100%', padding: '1rem', paddingTop: '1.5rem', borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
                                                        <Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                            <Item3 sx={{ padding: 0 }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <InputText className="custom-InputText" id="username" value={name} onChange={(e) => setName(e.target.value)} />
                                                                    <label htmlFor="username">ชื่อ-นามสกุล</label>
                                                                </FloatLabel>
                                                            </Item3>
                                                            <Item3 sx={{ padding: 0 }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <InputText className="custom-InputText" id="tel" value={tel} onChange={(e) => setTel(e.target.value)} />
                                                                    <label htmlFor="tel">เบอร์โทร</label>
                                                                </FloatLabel>
                                                            </Item3>
                                                        </Stack>
                                                        <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                            <Item3 sx={{ padding: 0 }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <InputText className="custom-InputText" id="iden" value={iden} onChange={(e) => setIden(e.target.value)} />
                                                                    <label htmlFor="iden">เลขประจำตัวประชาชน <small><strong>หมายเหตุ : </strong>เพื่อใช้ในการออกใบกำกับภาษี</small></label>
                                                                </FloatLabel>
                                                            </Item3>
                                                            <Item3 sx={{ padding: 0 }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <InputText className="custom-InputText" id="line" value={line} onChange={(e) => setLine(e.target.value)} />
                                                                    <label htmlFor="line">Line ID</label>
                                                                </FloatLabel>
                                                            </Item3>
                                                        </Stack>
                                                        <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                            <Item3 sx={{ padding: 0 }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <InputText className="custom-InputText" id="address" value={address} onChange={(e) => setAddress(e.target.value)} />
                                                                    <label htmlFor="address">อยู่จัดส่ง (ถ้ามี)<small><strong>หมายเหตุ : </strong> กรณีสินค้ามีการจัดส่งจะต้อง กรอกที่อยู่จัดส่งให้เรียบร้อย</small></label>
                                                                </FloatLabel>
                                                            </Item3>
                                                        </Stack>
                                                        <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                            <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <Dropdown id="province" value={province} onChange={(e) => handleDistrict(e)} filter options={item_province} optionLabel="name_th" itemTemplate={countryOptionTemplate} className="custom-InputText" />
                                                                    <label htmlFor="province">จังหวัด</label>
                                                                </FloatLabel>
                                                            </Item3>
                                                            <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <Dropdown id="district" value={district} onChange={(e) => handleSubDistrict(e)} options={item_district} optionLabel="name_th" itemTemplate={countryOptionTemplate} className="custom-InputText" />
                                                                    <label htmlFor="district">อำเภอ</label>
                                                                </FloatLabel>
                                                            </Item3>
                                                        </Stack>
                                                        <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                            <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <Dropdown id="subdistrict" value={subdistrict} onChange={(e) => handlePostcode(e)} options={item_subdistrict} optionLabel="name_th" className="custom-InputText" />
                                                                    <label htmlFor="subdistrict">ตำบล</label>
                                                                </FloatLabel>
                                                            </Item3>
                                                            <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <InputText className="custom-InputText" id="zipCode" value={postcode} readOnly />
                                                                    <label htmlFor="zipCode">รหัสไปรษณีย์</label>
                                                                </FloatLabel>
                                                            </Item3>
                                                        </Stack>
                                                    </Item2>
                                                </Grid>
                                                <Grid className="form">
                                                    <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>รายการสินค้า</Item>
                                                    <Item2 sx={{ width: '100%', height: '100%', padding: '1rem', paddingTop: '1.5rem', borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
                                                        <ScrollPanel className="scroll">
                                                            <Grid container spacing={1} sx={{ padding: 0 }} columnSpacing={{ xs: 3 }}>
                                                                {products.slice().map((item, index) => (
                                                                    <Grid item xs={4} sx={{ width: '50%', height: '100%' }} key={index}>
                                                                        <Grid className='step2item' onClick={(e) => headleCheckPrice(e, item)}>
                                                                            <Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', height: '100%' }}>
                                                                                {item.image ? (
                                                                                    ImageBodyTemplate(item.image)
                                                                                    // <img src={item.image} alt="" style={{ width: '100%' }} />
                                                                                ) : (
                                                                                    <img src={require('../../../assets/logo.png')} alt="" style={{ width: '100%' }} />
                                                                                )}
                                                                            </Item3>
                                                                            <Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#000000', fontSize: '18px' }}>
                                                                                ชื่อ : {item.name}
                                                                            </Item3>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </ScrollPanel>
                                                    </Item2>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Grid className="form">
                                                    <Grid container>
                                                        <Grid sx={{ width: '50%', paddingRight: 1 }}>
                                                            <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ยอดรวมสุทธิ</Item>
                                                            <Item2 sx={{ width: '100%', fontSize: '400%', color: '#ffff', borderTopRightRadius: '0', borderTopLeftRadius: '0', textShadow: '3px 2px 4px #000000' }}>{calculateTotal() - discount}</Item2>
                                                        </Grid>
                                                        <Grid sx={{ width: '50%', paddingRight: 1 }}>
                                                            <div className='button-family-2'>
                                                                <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.7rem' }}>
                                                                    <Item3 sx={{ padding: 0, backgroundColor: 'transparent', boxShadow: 'none' }}>
                                                                        <FloatLabel>
                                                                            <InputText className="custom-InputText" id="reftel" value={reftel} onChange={(e) => setReftel(e.target.value)} disabled={refteldisable} />
                                                                            <label htmlFor="reftel">ทศกัณฐ์แฟมมิลี่</label>
                                                                        </FloatLabel>
                                                                    </Item3>
                                                                    <Item3 sx={{ padding: 0 }}>
                                                                        {!refteldisable && (
                                                                            <Button className="custom-button" label="ทศกัณฐ์แฟมมิลี่" onClick={headleCheckReftel} />
                                                                        )}
                                                                        {refteldisable && (
                                                                            <Button className="custom-button" label="เปลี่ยน" onClick={headlerClearReftel} />
                                                                        )}
                                                                    </Item3>
                                                                </Stack>
                                                                <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.5rem', width: '100%' }}>
                                                                    <Item3 sx={{ padding: 0 }}>
                                                                        <Button className="custom-button" label="หน้าจอสำหรับลูกค้า" icon="pi pi-user" />
                                                                    </Item3>
                                                                </Stack>
                                                                <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                                                    <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                        <Button className="custom-button-2" label="ส่วนลดในบิล" onClick={headleDiscount} />
                                                                    </Item3>
                                                                    <Item3 sx={{ padding: 0, width: '40%' }}>
                                                                        <Button className="custom-button-2" label="ชำระเงิน" icon="pi pi-check" onClick={headlerCheckOut} />
                                                                    </Item3>
                                                                </Stack>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid className="form">
                                                    <Grid container>
                                                        <Grid sx={{ width: '100%', paddingRight: 1 }}>
                                                            <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ทั้งหมด : {order_rubber.length} รายการ</Item>
                                                            <Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
                                                                <DataTable value={order_rubber} style={{ fontFamily: 'Kanit' }}>
                                                                    <Column header="รายการ" body={(rowData) => (
                                                                        <div>
                                                                            <b>{rowData.name}</b>
                                                                            <br />
                                                                            {rowData.detail}
                                                                        </div>
                                                                    )}></Column>
                                                                    <Column field="amount" header="จำนวน"></Column>
                                                                    <Column field="total_price" header="ราคารวม"></Column>
                                                                    <Column field="total_freight" header="ค่าส่งรวม"></Column>
                                                                    <Column field="total_net" header="ยอดสุทธิ"></Column>
                                                                    <Column body={(rowData) => (
                                                                        <div>
                                                                            <Button icon="pi pi-plus" className="p-button-rounded p-button-outlined" style={{ color: 'green' }}
                                                                                onClick={updateProduct(rowData)} />
                                                                            <Button icon="pi pi-trash" className="p-button-rounded p-button-outlined"
                                                                                style={{ color: 'red', marginLeft: '0.5rem' }} onClick={deleteProduct(rowData)} />
                                                                        </div>
                                                                    )}></Column>
                                                                </DataTable>
                                                            </Item2>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {position === 'One Stop Platform' && (
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box>
                                <Grid container className={"container"}>
                                    <div className="title" ><h1>ตรายาง (Rubber Stamp)</h1></div>
                                    <Box sx={{ width: '100%' }}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                            <Grid item xs={12}>
                                                <Grid className="form">
                                                    <Button style={{ width: '100%' }} icon="pi pi-shopping-cart" label="ตะกร้าสินค้า" onClick={heandleDialogCart} />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid className="form">
                                                    <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>รายการสินค้า</Item>
                                                    <Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
                                                        <ScrollPanel className="scroll">
                                                            <Grid container spacing={1} sx={{ padding: 0 }} columnSpacing={{ xs: 3 }}>
                                                                {products.slice().map((item, index) => (
                                                                    <Grid item className="product" key={index}>
                                                                        <Grid className='step2item' onClick={(e) => headleCheckPrice(e, item)}>
                                                                            <Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', height: '100%' }}>
                                                                                {item.image ? (
                                                                                    <img src={item.image} alt="img" style={{ width: '50%' }} />
                                                                                ) : (
                                                                                    <img src={require('../../../assets/logo.png')} alt="img" style={{ width: '50%' }} />
                                                                                )}
                                                                            </Item3>
                                                                            <Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#000000', fontSize: '18px' }}>
                                                                                ชื่อ : {item.name}
                                                                            </Item3>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </ScrollPanel>
                                                    </Item2>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {/* Dialog ราคาสินค้า */}
            <Dialog header="ราคาสินค้า" visible={dialogPrice} style={{ width: '60vw', fontFamily: 'Kanit' }} onHide={() => onHideDialogPrice(false)}
                breakpoints={{ '960px': '75vw', '641px': '100vw', '430px': '80vw' }} >
                {price.length === 0 ? (
                    <div>ไม่พบราคาสินค้า</div>
                ) : (
                    <Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
                        {price.map((item, index) => (
                            <Grid item xs={4} key={index} className='product-grid' sx={{ padding: '.5rem', backgroundColor: 'transparent' }}
                                onClick={(e) => headleRelectPrice(e, item)}>
                                <Item3 className='product-item' sx={{ backgroundColor: '#ECD400', color: 'black', cursor: 'pointer' }}>
                                    ราคา : {item.price} บาท
                                </Item3>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Dialog>

            {/* Dialog Detail */}
            <Dialog header="รายละเอียด" visible={dialogDetail} style={{ width: '40vw', fontFamily: 'Kanit' }}
                onHide={() => onHideDialogDetail(false)} breakpoints={{ '1680px': '50vw', '641px': '100vw', '430px': '80vw' }} >
                {product_price && (
                    <div style={{ marginBottom: '1rem' }}>
                        <b>ชื่อสินค้า : {product_detail.name}</b><br></br>
                        <div></div><br></br>
                        ราคา : {product_price.price} บาท / {product_price.unit} ชิ้น
                        <div></div><br></br>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <label htmlFor="sqm-price" className="font-bold block mb-2">จำนวน : &emsp;</label>
                                <InputNumber inputId="sqm-price" value={amount} onValueChange={(e) => setAmount(e.value)}
                                    mode="decimal" showButtons min={1} />
                            </Grid>
                        </Grid>
                        <div></div><br></br>
                        <b>กรอกรายละเอียดเพิ่มเติม</b>
                        <div></div><br></br>
                        <div>
                            <InputTextarea value={detail} onChange={(e) => setDetail(e.target.value)} rows={5} cols={70} style={{ width: '100%' }} />
                        </div>
                    </div>
                )}
                <div style={{ flex: '1' }}></div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
                    <ButtonGroup>
                        <Button label="เพิ่มลงตะกล้า" icon="pi pi-check" className='add-cart' onClick={headleAddOrder} />
                        <Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={headlerCloseData} />
                    </ButtonGroup>
                </div>
            </Dialog>

            {/* Dialog Update Product */}
            <Dialog header="แก้ไขสินค้า" visible={dialogUpdate} style={{ width: '60vw', fontFamily: 'Kanit' }}
                onHide={() => onHideDialogUpdate(false)} breakpoints={{ '1680px': '50vw', '641px': '100vw', '430px': '80vw' }} >
                <div style={{ marginBottom: '1rem' }}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <label htmlFor="sqm-price" className="font-bold block mb-2">จำนวน : &emsp;</label>
                            <InputNumber inputId="sqm-price" value={updateAmount} onValueChange={(e) => setUpdateAmount(e.value)}
                                mode="decimal" showButtons min={1} />
                            {/* <label htmlFor="sqm-price" className="font-bold block mb-2">&emsp; ชิ้น</label> */}
                        </Grid>
                    </Grid>
                    <div></div><br></br>
                    <b>แก้ไขรายละเอียดเพิ่มเติม</b>
                    <div></div><br></br>
                    <div>
                        <InputTextarea value={updateDescription} onChange={(e) => setUpdateDescription(e.target.value)} rows={5} cols={70} style={{ width: '100%' }} />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
                    <ButtonGroup>
                        <Button label="แก้ไขตะกร้า" icon="pi pi-check" className='add-cart' onClick={() => confirmUpdate()} />
                        <Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={() => setDialogUpdate(false)} />
                    </ButtonGroup>
                </div>
            </Dialog>

            {/* Dialog Product Platform */}
            <Sidebar visible={dialogOrderPlatform} position="right" className="order-platform"
                onHide={() => { if (!dialogOrderPlatform) return; setDialogOrderPlatform(false); }}>
                <Grid container className={"container"}>
                    <div className="title" ><h1>ตะกร้าสินค้า</h1></div>
                    <Box sx={{ width: '100%', padding: '1rem' }}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            {order_rubber.map((item, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={7}>
                                        <div style={{ marginBottom: '1rem' }}>
                                            <b>ชื่อสินค้า : {item.name}</b><br />
                                            {item.detail}<br />
                                            ราคาสินค้า : ฿{item.total_price}<br />
                                            ค่าขนส่ง : ฿{item.total_freight}<br />
                                            ยอดสินค้า : ฿{item.total_net}<br />
                                        </div>
                                    </Grid>
                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
                                        <div style={{ marginBottom: '1rem' }}>
                                            <b>x {item.amount}</b><br />
                                        </div>
                                    </Grid>
                                    <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', alignItems: 'center' }}>
                                        <div style={{ marginBottom: '1rem' }}>
                                            <Button icon="pi pi-plus" className="p-button-rounded p-button-outlined" style={{ color: 'green' }}
                                                onClick={updateProduct(item)} />
                                            <Button icon="pi pi-trash" className="p-button-rounded p-button-outlined"
                                                style={{ color: 'red', marginLeft: '0.5rem' }} onClick={deleteProduct(item)} />
                                        </div>
                                    </Grid>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </Grid>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6} style={{ textAlign: 'left' }}>
                                <div style={{ marginBottom: '1rem', width: '100%' }}>
                                    <b style={{ fontSize: '18px', }}>
                                        ยอดสุทธิ :
                                    </b>
                                </div>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <b style={{ fontSize: '18px' }}>
                                    ฿{calculateTotal()}
                                </b>
                            </Grid>
                            <Divider />
                        </Grid>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <Button label="ชำระเงิน" style={{ width: '100%' }} className="button" onClick={heandleCheckOutPlatform} />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Sidebar>

            {/* Dialog Discount */}
            <Dialog header="ส่วนลด" visible={dialogDiscount} style={{ width: '30vw', fontFamily: 'Kanit' }}
                onHide={() => { if (!dialogDiscount) return; setDialogDiscount(false); }}
                breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
                <Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
                    <Grid item xs={12} style={{ marginTop: '1rem' }}>
                        <FloatLabel className="custom-InputText">
                            <InputNumber className="custom-InputText" id="discount" value={discount} onChange={(e) => setDiscount(e.value)} />
                            <label htmlFor="moneyreceive">ส่วนลดในบิล</label>
                        </FloatLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
                            <ButtonGroup>
                                <Button label="ยืนยัน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmDiscount} />
                                <Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={onHideDialogDiscount} />
                            </ButtonGroup>
                        </div>
                    </Grid>
                </Grid>
            </Dialog>

            {/* Dialog Checkout */}
            <Dialog header="ชำระเงิน" visible={dialogCheckOut} style={{ width: '30vw', fontFamily: 'Kanit' }} onHide={() => onHideDialogCheckout(false)}
                breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
                <Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
                    <Grid item xs={12}>
                        <Dropdown value={paymenttype} onChange={(e) => headlerPaymentType(e, e.value)} options={['เงินสด', 'เงินโอน', 'บัตรเครดิต', 'อิ่นๆ']}
                            placeholder="เลือกประเภทการชำระ" className="custom-InputText" />
                    </Grid>
                    <Grid item xs={12}>
                        <FloatLabel className="custom-InputText">
                            <InputNumber className="custom-InputText" id="moneyreceive" value={moneyreceive} onChange={(e) => setMoneyReceive(e.value)}
                                disabled={disabledMoneyReceive} />
                            <label htmlFor="moneyreceive">กรอกเงินที่รับมา</label>
                        </FloatLabel>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
                            <ButtonGroup>
                                <Button label="ชำระเงิน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmCheckout} />
                                <Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={onHideDialogCheckout} />
                            </ButtonGroup>
                        </div>
                    </Grid>
                </Grid>
            </Dialog>

            {/* Dialog Receipt */}
            <Dialog header="เงินทอน" visible={dialogReceipt} style={{ width: '60vw', fontFamily: 'Kanit' }}
                onHide={() => { if (!dialogReceipt) return; setDialogReceipt(false); }}
                breakpoints={{ '2000px': '30vw', '1680px': '40vw', '960px': '60vw', '641px': '70vw', '430px': '80vw' }} closable={false} >
                <Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
                    <Grid item xs={12}>
                        <Card className="border-1 shadow-none border-gray-400" style={{ padding: '0' }}>
                            <h1>
                                {numberFormat(change == null ? '' : change)}
                            </h1>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Button label="ใบเสร็จรับเงิน" style={{ width: '100%' }} onClick={headlerPrintReceipt} />
                            {/* <ReceiptExpress /> */}
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <Button label="ทำรายรายต่อ" style={{ width: '100%' }} onClick={heandleResetOrder} />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <Button label="หน้าหลัก" style={{ width: '100%' }} onClick={heandleResetPage} />
                        </div>
                    </Grid>
                    <div style={{ display: 'none' }}>
                        <div ref={componentReceipt} style={{ fontSize: '10px', textAlign: 'center', padding: '0', fontFamily: 'Kanit' }}>
                            <ReceiptArtwork data={resp} />
                        </div>
                    </div>
                </Grid>
            </Dialog>

            <LoadingDialog isLoading={isLoading} />
        </div>
    )
}