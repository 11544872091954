import React, { useState } from "react";

import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { styled } from '@mui/material/styles';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chip } from "primereact/chip";
import { ButtonGroup } from 'primereact/buttongroup';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import Barcode from 'react-barcode';
import html2canvas from "html2canvas";
import LoadingDialog from "../../../views/LoadingView";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getProductShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/product/shop/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getProductTG() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/product/tossagun`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

export default function ProductShop() {
	const [isLoading, setisLoading] = useState(false);
	const [position, setPosition] = useState({});
	const [member, setMember] = useState({});
	const [employee, setEmployee] = useState({});
	const [shop, setShop] = useState({});

	// Product
	const [product_shop, setProductShop] = useState([]);

	// Barcode
	const [dialogBarcode, setDialogBarcode] = useState(false);
	const [barcode, setBarcide] = useState("");
	const [name, setName] = useState("");
	const [price, setPrice] = useState("");


	useState(async () => {
		setisLoading(true);
		const response = await getMe();
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			Swal.fire({
				title: "แจ้งเตือน!",
				text: "ผู้ใช้งานดังกล่าว ไม่สามารถใช้งานระบบนี้ได้!",
				icon: "warning",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "ยืนยัน",
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = "/";
				}
			})
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				setisLoading(false);
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				setisLoading(false);
				setPosition(shop.data.shop_type);
				// Product
				const product1 = await getProductShop(shop.data._id);
				const product2 = await getProductTG();
				if (product2.status) {
					let value = null;
					let product = [];
					product1.data.forEach(el => {
						value = product2.data.find((e) => e._id === el.productShop_tossagun_id);
						if (value) {
							product.push({ ...el, product_ref: value });
						}
					});
					setProductShop(product);
				}
			}
		}
	});

	const headleBarcode = (value) => e => {
		setName(value.product_ref.productTG_name);
		setPrice(value.product_ref.productTG_price.price);
		setDialogBarcode(true);
		setBarcide(value.productShop_barcode);
	};

	const dowloadBarcode = async (e) => {
		let image = "";

		const option = {
			allowTaint: true,
			useCORS: true,
			imageTimeout: 3000,
			scale: 2
		};

		await html2canvas(document.querySelector("#capture"), option).then(function (
			canvas
		) {
			image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
			//console.log("data:image/png;" + image)
		});
		let a = document.createElement("a");
		a.href = image;
		a.download = `รหัสสินค้า ${barcode}.png`;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"order-container"}>
								<div className="title" ><h1>สินค้าในสต๊อกทั้งหมด</h1></div>
								<Box sx={{ width: '100%' }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item className="order-model">
											<DataTable value={product_shop} paginator rows={5} style={{ fontFamily: 'Kanit' }}>
												<Column selectionMode="multiple"></Column>
												<Column header="รหัสสินค้า" body={(item) => (
													<div>
														<Chip label={item.productShop_barcode} style={{
															fontFamily: 'Kanit', paddingLeft: '1rem',
															paddingRight: '1rem',
														}} />
													</div>
												)}></Column>
												<Column header="ชื่อสินค้า" body={(item) => (
													<div>
														{item.product_ref.productTG_name}
													</div>
												)}></Column>
												<Column header="ราคาสินค้า (บาท)" body={(item) => (
													<div>
														{item.product_ref.productTG_price.price}
													</div>
												)}></Column>
												<Column header="สต๊อกคงเหลือ (ชิ้น)" body={(item) => (
													<div>
														{item.productShop_stock}
													</div>
												)}></Column>
												<Column header="ตัวเลือก" body={(item) => (
													<div>
														<Button icon="pi pi-image" onClick={headleBarcode(item)} />
													</div>
												)}></Column>
											</DataTable>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>

			{/* Dialog Barcode */}
			<Dialog header="บาร์โค้ด" visible={dialogBarcode} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogBarcode) return; setDialogBarcode(false); }}
				breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
				<div id="capture" style={{ padding: '0.1cm', width: '6.2cm' }}>
					<div className="page">
						<div className="g-block">
							<div className="g-col-12" style={{ backgroundColor: 'indigo', padding: '10px', marginBottom: '5px' }}>
								<p style={{ color: 'white', fontWeight: 'bold', margin: '0px', padding: '0px' }}>
									{name}
								</p>
							</div>
							<div id="show-image" className="g-col-6" style={{ padding: '10px 0px 10px 10px' }}>
								<Barcode value={barcode} width={1} height={40} fontSize={14} />
							</div>
							<div className="g-col-6" style={{ padding: '0px 10px 0px 0px', marginBottom: '0px' }}>
								<p style={{ textAlign: 'right', margin: '0px', padding: '0px' }}><small>ราคา (บาท)</small></p>
								<h2 style={{ textAlign: 'right', margin: '0px', padding: '0px' }}>
									{price}<small>.-</small></h2>
							</div>
						</div>
					</div>
				</div>
				<Grid item xs={12}>
					<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }} className='grup-btn-media-dialog'>
						<ButtonGroup>
							<Button label="ดาวน์โหลด" icon="pi pi-download" className='btn-checkout' onClick={dowloadBarcode} />
							<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' />
						</ButtonGroup>
					</div>
				</Grid>
			</Dialog >

			<LoadingDialog isLoading={isLoading} />
		</div >
	)
};