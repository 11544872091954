
import Link from '@mui/material/Link';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import Swal from 'sweetalert2';
import "../styles/topbar.css";

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dialog } from "primereact/dialog";
import { PanelMenu } from 'primereact/panelmenu';

async function getMe() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/me`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getShop(shop_id) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getToken() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/register/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

function numberFormat(number) {
    return number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

function checkPosition(position) {
    if (position === 'One Stop Shop') {
        return true;
    } else {
        return false;
    }
};

function checkPositionExp(position) {
    if (position !== 'One Stop Platform') {
        return true;
    } else {
        return false;
    }
};

export default function AppTopbar() {
    const [sideBar, setSideBar] = useState(true);
    const [menuBar, setMenuBar] = useState(false);
    const [visible, setVisible] = useState(false);

    const [member, setMember] = useState("");
    const [wallet, setWallet] = useState(0);
    const [employee, setEmployee] = useState({});
    const [shop, setShop] = useState({});
    const [position, setPosition] = useState('');
    const [positionshop, setPositionShop] = useState('');

    const [path, setPath] = useState(window.location.origin);

    const [linkRef, setLinkRef] = useState('');
    const [dialogQRCode, setDialogQRCode] = useState(false);

    const item_menu = [
        {
            label: 'หน้าแรก',
            icon: 'pi pi-home',
            url: '/',
        },
        {
            label: 'รายได้',
            icon: 'pi pi-dollar',
            url: '/commission',
        },
        {
            label: 'โปรไฟล์',
            icon: 'pi pi-user',
            url: '/profile',
        },
        {
            label: 'กระเป๋าเงินอิเล็กทรอนิกส์',
            icon: 'pi pi-wallet',
            items: [
                {
                    label: 'เติมเงิน',
                    icon: 'pi pi-wallet',
                    url: '/wallet',
                },
                {
                    label: 'ประวัติการเงิน',
                    icon: 'pi pi-wallet',
                    url: '/history/wallet',
                }
            ]
        },
        {
            label: 'ระบบ POS',
            icon: 'pi pi-shopping-bag',
            visible: checkPosition(positionshop),
            items: [
                {
                    label: 'สั่งสินค้า',
                    icon: 'pi pi-shopping-cart',
                    url: '/cart/pos',
                },
                {
                    label: 'รายการสั่งสินค้า',
                    icon: 'pi pi-file',
                    url: '/order/pos',
                },
                {
                    label: 'นำเข้าสินค้า',
                    icon: 'pi pi-download',
                    url: '/import/product',
                },
                {
                    label: 'สินค้าทั้งหมด',
                    icon: 'pi pi-box',
                    url: '/product/shop',
                },
                {
                    label: 'ประวัติการขายสินค้า',
                    icon: 'pi pi-file',
                    url: '/pos/order',
                },
                {
                    label: 'ปิดการขายประจำวัน',
                    icon: 'pi pi-times',
                    url: '/pos/close/shop',
                },
                {
                    label: 'ใบแจ้งหนี้',
                    icon: 'pi pi-file',
                    url: '/pos/invoice',
                },
            ]
        },
        {
            label: 'ประวัติรายการขนส่ง',
            icon: 'pi pi-truck',
            visible: checkPositionExp(positionshop),
            items: [
                {
                    label: 'สินค้ารายการขนส่ง',
                    icon: 'pi pi-box',
                    url: '/express/box',
                },
                {
                    label: 'ประวัติการขนส่ง',
                    icon: 'pi pi-truck',
                    url: '/express/order',
                }
            ]
        },
        {
            label: 'ประวัติรายการเซอร์วิส',
            icon: 'pi pi-inbox',
            items: [
                {
                    label: 'ประวัติการเติมเงินมือถือ',
                    icon: 'pi pi-phone',
                    url: '/temtopup/order',
                },
                {
                    label: 'ประวัติการสั่งซื้อแอพพรีเมี่ยม',
                    icon: 'pi pi-sparkles',
                    url: '/apppremium/order',
                },
                {
                    label: 'ประวัติสั่งเซอร์วิส',
                    icon: 'pi pi-inbox',
                    url: '/service/order',
                },
            ]
        },
        {
            label: 'ประวัติการใช้งาน',
            icon: 'pi pi-map-marker',
            url: '/login/history',
        },
    ];

    useState(async () => {
        // console.log(window.innerWidth)
        if (window.innerWidth <= 600) {
            setSideBar(false);
            setMenuBar(true);
        } else {
            setSideBar(true);
            setMenuBar(false);
        }

        const response = await getMe();
        if (response.logout === true) {
            localStorage.clear();
            window.location.href = "/";
        }
        if (response.level === 'member') {
            setMember(response.data);
            setWallet(response.data.wallet);
            setPositionShop('One Stop Platform')
        } else if (response.level === 'employee') {
            const shop = await getShop(response.data.employee_shop_id);
            if (shop.data.shop_status === false) {
                Swal.fire({
                    title: 'แจ้งเตือน!',
                    text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 1500,
                });
                return false;
            } else {
                setPositionShop(shop.data.shop_type);
                setEmployee(response.data)
                setShop(shop.data)
                setWallet(shop.data.shop_wallet)
                setPosition(response.data.employee_position)
            }
        }
    })

    const toggleSidebar = () => {
        setSideBar(!sideBar);
    };

    const logout = async (e) => {
        e.preventDefault();
        setVisible(false);
        Swal.fire({
            title: "คุณต้องการออกจากระบบ?",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.clear();
                window.location.href = "/";
            }
        })
    };

    const heandleCopyLinkRef = async (e) => {
        e.preventDefault();
        const token = await getToken();
        if (token.status) {
            const link = `${path}/register/${token.token}`
            await navigator.clipboard.writeText(link);
            Swal.fire({
                title: 'สำเร็จ',
                text: 'ทำการคัดลอกลิ้งค์ผู้แนะนำสำเร็จ',
                icon: 'success',
                showConfirmButton: false,
                timer: 3000,
            });
        }
    };

    const heandleScanQRCode = async (e) => {
        e.preventDefault();
        const token = await getToken();
        if (token.status) {
            const link = `${path}/register/${token.token}`;
            setLinkRef(link);
            setDialogQRCode(true);
        }
    };

    const header = (
        <div className='pt-4'>
            {positionshop === 'One Stop Platform' && (
                <div>
                    {member.fristname} {member.lastname}
                    <br />
                    รหัสสมาชิก : {member.card_number}
                </div>
            )}
            {positionshop !== 'One Stop Platform' && (
                <div>
                    {shop.shop_name_main}
                    < br />
                    สาขา : {shop.shop_name_second} ({shop.shop_number})
                </div>
            )}
        </div>
    );

    return (
        <div>
            {menuBar &&
                <div className="menubar">
                    <Link href="/">
                        <img alt="" src={require('../assets/logo2.png')} className="logo1" />
                    </Link>
                    <div className="toggle-btn" onClick={toggleSidebar}>
                        ☰
                    </div>
                </div>
            }
            <div className={`sidebar overflow-y-auto  ${sideBar ? 'open' : ''}`}>
                <div className='sidebar-head'>
                    <img alt="" src={require('../assets/logo2.png')} className="logo2" />
                    <div>
                        <Card header={header} title={`฿${numberFormat(wallet)}`} subTitle={`ยอดเงินคงเหลิอ`} className='text-center' style={{ fontFamily: 'Kanit' }}>
                        </Card>
                    </div>
                </div>
                <div>
                    <PanelMenu model={item_menu} className="w-full mt-2 " style={{ fontFamily: 'Kanit' }} />
                </div>
                {member && (
                    <div>
                        <div>
                            <Button label="ลิ้งค์ผู้แนะนำ" severity="help" onClick={heandleCopyLinkRef} className="w-full" />
                        </div>
                        <div>
                            <Button label="สแกน QR Code ผู้แนะนำ" severity="help" onClick={heandleScanQRCode} className="w-full" />
                        </div>
                    </div>
                )}
                <div>
                    <Button label="ติดต่อพนักงาน" severity="info" className="w-full" />
                </div>
                <div>
                    <Button label="ออกจากระบบ" severity="danger" className="w-full" onClick={logout} />
                </div>
            </div>

            <Dialog visible={dialogQRCode} onHide={() => { if (!dialogQRCode) return; setDialogQRCode(false); }} >
                <div>
                    <QRCode value={linkRef} />
                </div>
            </Dialog>
        </div>
    )
}   