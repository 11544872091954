import React, { useState, useRef } from "react";
import axios from "axios";
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { useReactToPrint } from "react-to-print";

import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { ButtonGroup } from 'primereact/buttongroup';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Message } from 'primereact/message';
import { Image } from "primereact/image";
import { Card } from "primereact/card";
import { ScrollPanel } from 'primereact/scrollpanel';
import { TabView, TabPanel } from 'primereact/tabview';

import LoadingDialog from "../../../views/LoadingView";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json());
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json());
};

async function getCategoryAct() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/service/act/category`, {
		method: 'get',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json());
};

async function getProductAct(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/service/act/cate/${packageId}`, {
		method: 'get',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json());
};

async function GetMember(reftel) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/member/check/${reftel}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function callcheckout(packageData) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/service/act/order`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json());
};

async function getOrderRef(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/order/service/orderref/${packageId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json());
};

async function getProvince() {
	return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json');
};

async function getDistrict() {
	return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json');
};

async function getSubDistrict() {
	return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_tambon.json');
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Item2 = styled(Paper)(({ theme }) => ({
	backgroundColor: '#ECD400',
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Item3 = styled(Paper)(({ theme }) => ({
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	flexGrow: 2,
}));

export default function ActView() {
	const [isLoading, setisLoading] = useState(false);
	const [position, setPosition] = useState({});
	const [member, setMember] = useState({});
	const [employee, setEmployee] = useState({});
	const [shop, setShop] = useState({});

	// Product
	const [category, setCategory] = useState([]);
	const [dialogProduct, setDialogProduct] = useState(false);
	const [product_one, setProductOne] = useState([]);
	const [product_two, setProductTwo] = useState([]);
	const [product, setProduct] = useState([]);

	// Customer
	const [item_province, setItemProvince] = useState([]);
	const [item_district, setItemDistrict] = useState([]);
	const [item_subdistrict, setItemSubDistrict] = useState([]);

	const [province, setProvince] = useState('');
	const [district, setDistrict] = useState('');
	const [subdistrict, setSubdistrict] = useState('');
	const [postcode, setPostcode] = useState('');
	const [address, setAddress] = useState('');
	const [name, setName] = useState('');
	const [tel, setTel] = useState('');
	const [iden, setIden] = useState('');
	const [line, setLine] = useState('');

	// ทศกัณฐ์แฟมมิลี
	const [reftel, setReftel] = useState('');
	const [refteldisable, setReftelDisable] = useState(false);

	// detail
	const [dialogDetail, setDialogDetail] = useState(false);
	const [detail, setDetail] = useState("");
	const [editDetail, setEditDetail] = useState("");
	const [description, setDescription] = useState("");
	const [amount, setAmount] = useState(1);
	const [imagePreview1, setImagePreview1] = useState(''); //เล่มเขียว
	const [imagePreview2, setImagePreview2] = useState(''); //สำเนา ปชช
	const [image1, setImage1] = useState(''); //เล่มเขียว
	const [image2, setImage2] = useState(''); //สำเนา ปชช

	// Order
	const [order_act, setOrderAct] = useState([]);
	const [order_details, setOrderDetail] = useState([]);
	const [dialogImage, setDialogImage] = useState(false);

	// ชำระเงิน
	const [dialogDiscount, setDialogDiscount] = useState(false);
	const [dialogCheckOut, setDialogCheckout] = useState(false);
	const [paymenttype, setPaymentType] = useState('');
	const [moneyreceive, setMoneyReceive] = useState(0);
	const [disabledMoneyReceive, setDisabledMoneyReceive] = useState(false);
	const [change, setChange] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [resp, setResp] = useState({});

	const [dialogReceipt, setDialogReceipt] = useState(false);
	const componentReceipt = useRef();
	const headlerPrintReceipt = useReactToPrint({
		content: () => componentReceipt.current,
	});

	useState(async () => {
		setisLoading(true);
		const response = await getMe();
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			setPosition('One Stop Platform')
			setMember(response.data)
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				setisLoading(false);
				setPosition('One Stop Service')
				setEmployee(response.data);
				setShop(shop.data);
				const provinces = await getProvince();
				setItemProvince(provinces.data);
				// Product
				const cate = await getCategoryAct();
				setCategory(cate.data);
			}
		}
	});

	const handleDistrict = async (e) => {
		e.preventDefault();
		const response = await getDistrict();
		// console.log(response.data)
		const filtered = response.data;
		const districts = filtered.filter(el => el.province_id === e.value.id);
		setProvince(e.value);
		setItemDistrict(districts);
	};
	const handleSubDistrict = async (e) => {
		e.preventDefault();
		const response = await getSubDistrict();
		const filtered = response.data;
		const subdistricts = filtered.filter(el => el.amphure_id === e.value.id);
		setDistrict(e.value);
		setItemSubDistrict(subdistricts);
	};
	const handlePostcode = (e) => {
		e.preventDefault();
		setSubdistrict(e.value);
		setPostcode(e.value.zip_code);
	};
	const countryOptionTemplate = (option) => {
		return (
			<div>
				{option.name_th}
			</div>
		);
	};

	const ImageBodyTemplate = (item) => {
		return <img src={`${process.env.REACT_APP_SHOP}/service/act/image/${item}`} alt={item} width={'100%'} />
	};

	const headleGetProduct = async (e, item) => {
		setDialogProduct(true)
		const resp = await getProductAct(item._id);
		setProduct(resp.data);
		const productone = resp.data.filter((el) => el.type === 'ส่วนบุคคล');
		const producttwo = resp.data.filter((el) => el.type === 'ส่วนรับจ้าง');
		setProductOne(productone);
		setProductTwo(producttwo);
	};

	const headleCheckDetail = async (e, item) => {
		setDetail(item);
		setDialogDetail(true);
	};

	const handleAddOrder = () => {
		const total = detail.price * amount;
		const freight = detail.freight * amount;
		const net = total + freight;
		const order = {
			id: detail._id,
			name: detail.name,
			price: detail.price,
			amount: amount,
			freight: detail.freight,
			total_price: total,
			total_freight: freight,
			net: net,
			image1: '',
			image2: '',
			description: description,
		};
		const order_detail = {
			packageid: detail._id,
			packagedetail: description,
			quantity: amount,
		};
		setOrderAct([...order_act, order]);
		setOrderDetail([...order_details, order_detail]);
		setDialogDetail(false);
		setDialogProduct(false);
		setDescription('');
	};

	const headleUploadImage1 = async (e) => {
		const file = e.target.files[0];
		if (file) {
			const img_size = file.size;
			if (img_size > 500000) {
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'รูปภาพดังกล่าวมีขนาดใหญ่เกินไป',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
				return false
			}
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.addEventListener('load', (event) => {
				setImagePreview1(event.target.result);
			});
			setImage1(file)
		}
	};

	const headleUploadImage2 = async (e) => {
		const file = e.target.files[0];
		if (file) {
			const img_size = file.size;
			if (img_size > 500000) {
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'รูปภาพดังกล่าวมีขนาดใหญ่เกินไป',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
				return false
			}
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.addEventListener('load', (event) => {
				setImagePreview2(event.target.result);
			});
			setImage2(file);
		}
	};

	const deleteProduct = (value) => e => {
		const position = order_act.findIndex((el) => el.id === value.id);
		const positions = order_details.findIndex((el) => el.packageid === value.id);
		order_act.splice(position, 1);
		order_details.splice(positions, 1);
		setOrderAct([...order_act]);
		setOrderDetail([...order_details]);
		setImage1({});
		if (order_act.length === 0) {
			setDiscount(0)
		}
	};

	// Check Out
	const calculateTotal = () => {
		return order_act.reduce((total, item) => total + item.net, 0);
	};

	const headleCheckReftel = async () => {
		if (reftel === "") {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกทศกัฐ์แฟมมิลี่',
				icon: 'warning',
				showConfirmButton: false,
				timer: 1500,
			});
			return false;
		} else {
			const response = await GetMember(reftel);
			if (response.status) {
				Swal.fire({
					title: "ทศกัณฐ์แฟมมิลี่?",
					icon: "success",
					text: `เบอร์โทรศัพท์ ${response.data.tel} คุณ ${response.data.fristname} ${response.data.lastname}`,
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "ยืนยัน",
					cancelButtonText: "ยกเลิก",
				}).then((result) => {
					if (result.isConfirmed) {
						setReftelDisable(true);
					}
				})
			} else {
				Swal.fire({
					title: 'ทศกัณฐ์แฟมมิลี่!',
					text: 'ไม่พบข้อมูลทศกัณฐ์แฟมมิลี่',
					icon: 'error',
					showConfirmButton: false,
					timer: 3000,
				});
				return false;
			}
		}
	};

	const headlerClearReftel = async () => {
		Swal.fire({
			title: "เปลี่ยนทศกัณฐ์แฟมมิลี่?",
			icon: "warning",
			text: `คุณต้องการเปลี่ยนทศกัณฐ์แฟมมิลี่ ใช่หรือไม่?`,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "ยืนยัน",
			cancelButtonText: "ยกเลิก",
		}).then((result) => {
			if (result.isConfirmed) {
				setReftelDisable(false);
				setReftel('');
			}
		})
	};

	const heandleCheckOut = async () => {
		if (order_act.length === 0) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ไม่มีรายการให้ชำระเงิน กรุณาเลือกสินค้าเพื่อทำรายการ',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (name === '' ||
			tel === '' ||
			iden === '' ||
			line === '' ||
			address === '' ||
			province === '' ||
			subdistrict === '' ||
			district === '' ||
			postcode === ''
		) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกข้อมูลลูกค้าให้ครบถ้วน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (reftel === '' || refteldisable === false) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกและยืนยัน ทศกัณฐ์แฟมมิลี่',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (shop.shop_wallet < calculateTotal()) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ยอดเงินในระบบไม่เพียงต่อการชำระเงิน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		setDialogCheckout(true);
	};

	const headlerPaymentType = (e, value) => {
		if (value === 'เงินสด') {
			setPaymentType(value);
			setMoneyReceive(0);
			setDisabledMoneyReceive(false);
		} else {
			const receive = calculateTotal() - discount;
			setPaymentType(value);
			setDisabledMoneyReceive(true);
			setMoneyReceive(receive);
		}
	};

	const onHideDialogDiscount = () => {
		setDiscount(0);
		setDialogDiscount(false);
	};

	const headleConfirmDiscount = () => {
		if (discount >= calculateTotal()) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ส่วนลดมากกว่ายอดที่ต้องชำระ ไม่สามารถทำรายการได้',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			setDialogDiscount(false);
			setDiscount(0);
			return false;
		} else {
			setDialogDiscount(false);
		}
	};

	const headleDiscount = () => {
		if (calculateTotal() === 0) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ยอดรวมรวมไม่เพียงพอต่อการกรอกส่วนลด',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		} else {
			setDialogDiscount(true);
		}
	};

	const onHideDialogCheckout = () => {
		setDialogCheckout(false);
	};

	const headleConfirmCheckout = async () => {
		if (moneyreceive < (calculateTotal() - discount)) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'จำนวนเงินที่กรอก ไม่เพียงพอต่อการชำระเงิน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		const changes = moneyreceive - (calculateTotal() - discount);
		setChange(changes);
		const data = {
			maker_id: employee._id,
			shop_id: shop._id,
			customer_name: name,
			customer_tel: tel,
			customer_address: `${address}, ${subdistrict.name_th}, ${district.name_th}, ${province.name_th}, ${postcode}`,
			customer_iden: iden,
			customer_line: line,
			product_detail: order_details,
			shop_type: position,
			paymenttype: paymenttype,
			moneyreceive: moneyreceive,
			change: changes,
			discount: discount,
			platform: reftel,
			employee: employee._id,
		};
		setisLoading(true);
		setDialogCheckout(false);
		const response = await callcheckout(data);
		if (response.status === true) {
			setResp(response.data);
			setisLoading(false);
			setDialogImage(true);
			// setDialogReceipt(true);
			setMoneyReceive(0);
			setDiscount(0);
			ClearDataCus();
		} else {
			setisLoading(false);
			setDialogCheckout(true);
		}
	};

	const headleUploadImage = async (e) => {
		if (image1 === '' && image2 === '') {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณาแนบรูปภาพให้ครบถ้วน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false
		}
		setisLoading(true);
		setDialogImage(false);

		const detailRef = await getOrderRef(resp.invoice);

		const formData1 = new FormData();
		const formData2 = new FormData();
		formData1.append('image', image1);
		formData2.append('image', image2);
		let status = resp.status;
		status.push({
			name: 'รอดำเนินการ',
			timestamp: dayjs(Date.now()).format(""),
		})
		const data = {
			status: status
		};
		const token = JSON.parse(localStorage.getItem('token'));

		const resp1 = await axios.put(`${process.env.REACT_APP_SHOP}/order/service/orderref/book/${detailRef.data._id}`, formData1, {
			headers: {
				'auth-token': `Bearer ${token}`
			}
		});
		const resp2 = await axios.put(`${process.env.REACT_APP_SHOP}/order/service/orderref/iden/${detailRef.data._id}`, formData2, {
			headers: {
				'auth-token': `Bearer ${token}`
			}
		});
		const resp3 = await axios.put(`${process.env.REACT_APP_SHOP}/order/service/${resp._id}`, data, {
			headers: {
				'auth-token': `Bearer ${token}`
			}
		});
		if (resp1.status === 201 && resp2.status === 201 && resp3.status === 201) {
			setisLoading(false);
			clearImage();
			setDialogReceipt(true);
		} else {
			setisLoading(false);
			setDialogImage(true);
			Swal.fire({
				title: 'มีบางอย่างผิดพลาด!',
				text: 'เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ',
				icon: 'error',
				showConfirmButton: false,
				timer: 3000,
			});
		}
	};

	const headleCancelImage = (e) => {
		setDialogImage(false);
		setDialogReceipt(true);
	};

	function clearImage() {
		setImage1('');
		setImagePreview1('');
		setImage2('');
		setImagePreview2('');
	};

	function ClearDataCus() {
		setName('');
		setAddress('');
		setProvince('');
		setSubdistrict('');
		setDistrict('');
		setPostcode('');
		setTel('');
		setIden('');
		setLine('');
		setReftel('');
	};

	const heandleResetOrder = (e) => {
		setReftel("");
		setOrderAct([]);
		setOrderDetail([]);
		clearImage();
		ClearDataCus();
		setReftelDisable(false);
		setDialogReceipt(false);
	};

	const heandleResetPage = (e) => {
		window.location.href = "/";
	};

	return (
		<div>
			{position === 'One Stop Service' && (
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Box>
								<Grid container className={"container"}>
									<div className="title" ><h1>รายการต่อ พ.ร.บ.</h1></div>
									<Box sx={{ width: '100%' }}>
										<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
											<Grid item xs={5}>
												<Grid className="form">
													<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>รายการสินค้า</Item>
													<Item2 sx={{ width: '100%', height: '100%', padding: '1rem', paddingTop: '1.5rem', borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
														<ScrollPanel className="scroll-premium">
															<Grid container spacing={1} sx={{ padding: 0 }} columnSpacing={{ xs: 3 }}>
																{order_act.length !== 0 && (
																	<React.Fragment>
																		<Message severity="warn" text="ท่านมีรายการค้างชำระอยู่ กรุณาชำระรายการดังกล่าว เพื่อทำรายการต่อ" style={{ width: '100%', fontFamily: 'Kanit', marginLeft: '2rem' }} />
																	</React.Fragment>
																)}
																{order_act.length === 0 && (
																	<React.Fragment>
																		{category.slice().map((item, index) => (
																			<Grid item xs={4} sx={{ width: '50%', height: '100%' }} key={index}>
																				<Grid className='step2item' onClick={(e) => headleGetProduct(e, item)}>
																					<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', height: '100%' }}>
																						{item.image ? (
																							ImageBodyTemplate(item.image)
																						) : (
																							<img src={require('../../../assets/logo.png')} alt="img" style={{ width: '100%' }} />
																						)}
																					</Item3>
																					<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#000000', fontSize: '18px' }}>
																						<p>{item.name}</p>
																					</Item3>
																				</Grid>
																			</Grid>
																		))}
																	</React.Fragment>
																)}
															</Grid>
														</ScrollPanel>
													</Item2>
												</Grid>
											</Grid>
											<Grid item xs={7}>
												<Grid className="form">
													<Grid container>
														<Grid sx={{ width: '50%', paddingRight: 1 }}>
															<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ยอดรวมสุทธิ</Item>
															<Item2 sx={{ width: '100%', fontSize: '400%', color: '#ffff', borderTopRightRadius: '0', borderTopLeftRadius: '0', textShadow: '3px 2px 4px #000000' }}>{numberFormat(calculateTotal() - discount)}</Item2>
														</Grid>
														<Grid sx={{ width: '50%', paddingRight: 1 }}>
															<div>
																<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.7rem' }}>
																	<Item3 sx={{ padding: 0, backgroundColor: 'transparent', boxShadow: 'none' }}>
																		<FloatLabel>
																			<InputText className="custom-InputText" id="reftel" value={reftel} onChange={(e) => setReftel(e.target.value)}
																				disabled={refteldisable} />
																			<label htmlFor="reftel">ทศกัณฐ์แฟมมิลี่</label>
																		</FloatLabel>
																	</Item3>
																	<Item3 sx={{ padding: 0 }}>
																		{!refteldisable && (
																			<Button className="custom-button" label="ทศกัณฐ์แฟมมิลี่" onClick={headleCheckReftel} />
																		)}
																		{refteldisable && (
																			<Button className="custom-button" label="เปลี่ยน" onClick={headlerClearReftel} />
																		)}
																	</Item3>
																</Stack>
																<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.5rem', width: '100%' }}>
																	<Item3 sx={{ padding: 0 }}>
																		<Button className="custom-button" label="หน้าจอสำหรับลูกค้า" icon="pi pi-user" />
																	</Item3>
																</Stack>
																<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap">
																	<Item3 sx={{ padding: 0, width: '40%' }}>
																		<Button className="custom-button-2" label="ส่วนลดในบิล" onClick={headleDiscount} />
																	</Item3>
																	<Item3 sx={{ padding: 0, width: '40%', height: '100%' }}>
																		<Button className="custom-button-2" label="ชำระเงิน" icon="pi pi-check" onClick={heandleCheckOut} />
																	</Item3>
																</Stack>
															</div>
														</Grid>
													</Grid>
												</Grid>
												<Grid className="form">
													<Grid container>
														<Grid sx={{ width: '100%', paddingRight: 1 }}>
															<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ทั้งหมด : {order_act.length} รายการ</Item>
															<Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
																<DataTable value={order_act} style={{ fontFamily: 'Kanit' }}>
																	<Column header="รายการ" body={(rowData) => (
																		<div>
																			<b>{rowData.name}</b>
																			<br />
																			{rowData.type}
																		</div>
																	)}></Column>
																	<Column header="ราคา" body={(rowData) => (
																		<div>
																			{numberFormat(rowData.price)}
																		</div>
																	)}>
																	</Column>
																	<Column header="ค่าธรรมเนียม" body={(rowData) => (
																		<div>
																			{numberFormat(rowData.freight)}
																		</div>
																	)}>
																	</Column>
																	<Column field="amount" header="จำนวน"></Column>
																	<Column header="ราคารวม" body={(rowData) => (
																		<div>
																			{numberFormat(rowData.net)}
																		</div>
																	)}></Column>
																	<Column body={(rowData) => (
																		<div>
																			{/* <Button label="แนบรูป" onClick={updateImage(rowData)} /> */}
																			<Button icon="pi pi-trash" className="p-button-rounded p-button-outlined"
																				style={{ color: 'red', marginLeft: '0.5rem' }} onClick={deleteProduct(rowData)} />
																		</div>
																	)}></Column>
																</DataTable>
															</Item2>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Box>
						</Grid>
					</Grid >
				</Box >
			)}

			{/* Dialog Product */}
			<Dialog header="รายการสินค้า" visible={dialogProduct} style={{ width: '60vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogProduct) return; setDialogProduct(false); }} breakpoints={{ '1680px': '50vw', '641px': '100vw', '430px': '80vw' }} >
				{product.length === 0 ? (
					<div>ไม่พบราคาสินค้า</div>
				) : (
					<Grid container spacing={2} sx={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
						<div className="w-full" >
							<TabView style={{ fontFamily: 'Kanit', paddingTop: '0.5rem' }}>
								<TabPanel header="ส่วนบุคคล" className="grid">
									{product_one.map((item, index) => (
										<Grid item xs={4} key={index} sx={{ padding: '.5rem', backgroundColor: 'transparent', width: '100%', height: '100%' }} >
											<Grid className='step2item' onClick={(e) => headleCheckDetail(e, item)}>
												<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', height: '100%' }}>
													{item.image ? (
														ImageBodyTemplate(item.image)
													) : (
														<img src={require('../../../assets/logo.png')} alt="img" style={{ width: '100%' }} />
													)}
												</Item3>
												<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#000000', fontSize: '16px' }}>
													ชื่อ : {item.name}
													<br />
													({item.type})
													<br />
													ราคา : {item.price} บาท
												</Item3>
											</Grid>
										</Grid>
									))}
								</TabPanel>
								<TabPanel header="ส่วนรับจ้าง" className="grid">
									{product_two.map((item, index) => (
										<Grid item xs={4} key={index} sx={{ padding: '.5rem', backgroundColor: 'transparent', width: '100%', height: '100%' }} >
											<Grid className='step2item' onClick={(e) => headleCheckDetail(e, item)}>
												<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', height: '100%' }}>
													{item.image ? (
														ImageBodyTemplate(item.image)
													) : (
														<img src={require('../../../assets/logo.png')} alt="img" style={{ width: '100%' }} />
													)}
												</Item3>
												<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#000000', fontSize: '16px' }}>
													ชื่อ : {item.name}
													<br />
													({item.type})
													<br />
													ราคา : {item.price} บาท
												</Item3>
											</Grid>
										</Grid>
									))}
								</TabPanel>
							</TabView>
						</div>
					</Grid>
				)}
			</Dialog>

			{/* Dialog Detail */}
			<Dialog header="รายละเอียด" visible={dialogDetail} style={{ width: '60vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogDetail) return; setDialogDetail(false); }} breakpoints={{ '1680px': '50vw', '641px': '100vw', '430px': '80vw' }} >
				{detail && (
					<div style={{ marginBottom: '1rem' }}>
						<b>ชื่อสินค้า : {detail.name}</b><br></br>
						ประเภทบริการ : {detail.type}
						<br />
						<br />
						ราคาสุทธิ : {numberFormat(detail.price == null ? '' : detail.price)} บาท
						<br />
						ค่าธรรมเนียม : {numberFormat(detail.freight == null ? '' : detail.freight)} บาท
						<div></div><br></br>
						รายละเอียด : {detail.detail}
						<div></div><br></br>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
							<Grid item xs={12}>
								<label htmlFor="sqm-price" className="font-bold block mb-2">จำนวน : &emsp;</label>
								<InputNumber inputId="sqm-price" value={amount} onValueChange={(e) => setAmount(e.value)} mode="decimal" showButtons min={1} disabled />
							</Grid>
						</Grid>
						<div></div><br></br>
						<p className="font-bold">กรอกรายละเอียดลูกค้า</p>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1 }}>
							<Grid item xs={6}>
								<FloatLabel className="custom-InputText">
									<InputText className="custom-InputText" id="name" value={name} onChange={(e) => setName(e.target.value)} />
									<label htmlFor="name">ชื่อ-นามสกุล</label>
								</FloatLabel>
							</Grid>
							<Grid item xs={6}>
								<FloatLabel className="custom-InputText">
									<InputText className="custom-InputText" id="tel" value={tel} onChange={(e) => setTel(e.target.value)} />
									<label htmlFor="tel">เบอร์โทร</label>
								</FloatLabel>
							</Grid>
							<Grid item xs={6}>
								<FloatLabel className="custom-InputText">
									<InputText className="custom-InputText" id="tel" value={iden} onChange={(e) => setIden(e.target.value)} />
									<label htmlFor="tel">เลขบัตรประจำตัวประชาชน</label>
								</FloatLabel>
							</Grid>
							<Grid item xs={6}>
								<FloatLabel className="custom-InputText">
									<InputText className="custom-InputText" id="tel" value={iden} onChange={(e) => setIden(e.target.value)} />
									<label htmlFor="tel">ที่อยู่</label>
								</FloatLabel>
							</Grid>
							<Grid item xs={6}>
								<FloatLabel className="custom-InputText">
									<Dropdown value={province} id="province" onChange={handleDistrict} options={item_province} optionLabel='name_th' placeholder='จังหวัด' filter style={{ width: '100%', textAlign: 'left' }} />
									{/* <InputText className="custom-InputText" id="tel" value={iden} onChange={(e) => setIden(e.target.value)} /> */}
									<label htmlFor="province">จังหวัด</label>
								</FloatLabel>
							</Grid>
							<Grid item xs={6}>
								<FloatLabel className="custom-InputText">
									<Dropdown value={district} id="district" onChange={handleSubDistrict} options={item_district} optionLabel='name_th' placeholder='อำเภอ' filter style={{ width: '100%', textAlign: 'left' }} />
									{/* <InputText className="custom-InputText" id="tel" value={iden} onChange={(e) => setIden(e.target.value)} /> */}
									<label htmlFor="district">อำเภอ</label>
								</FloatLabel>
							</Grid>
							<Grid item xs={6}>
								<FloatLabel className="custom-InputText">
									<Dropdown value={subdistrict} id="subdistrict" onChange={handlePostcode} options={item_subdistrict} optionLabel='name_th' placeholder='ตำบล' filter style={{ width: '100%', textAlign: 'left' }} />
									{/* <InputText className="custom-InputText" id="tel" value={iden} onChange={(e) => setIden(e.target.value)} /> */}
									<label htmlFor="subdistrict">ตำบล</label>
								</FloatLabel>
							</Grid>
							<Grid item xs={6}>
								<FloatLabel className="custom-InputText">
									<InputText className="custom-InputText" id="postcode" value={postcode} />
									<label htmlFor="postcode">รหัสไปรษณีย์</label>
								</FloatLabel>
							</Grid>
						</Grid>
						<div></div><br></br>
						<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
							<Grid item xs={6}>
								<div className="image-package">
									{image1 === '' && (
										<label className="file-input-label1">
											<span>แนบรูป สมุดเล่มเขียว</span>
											<input type="file" className="input-image" onChange={headleUploadImage1} />
										</label>
									)}
									{image1 !== '' && (
										<div style={{ textAlign: 'center', width: '100%' }}>
											<Image src={imagePreview1} alt="img" width="100%" height="200" preview />
											<br />
											<Button label="เปลี่ยนรูป" style={{ width: '50%' }} onClick={() => setImage1('')} />
										</div>
									)}
								</div>
							</Grid>
							<Grid item xs={6}>
								<div className="image-package">
									{image2 === '' && (
										<label className="file-input-label1">
											<span>แนบรูป สำเนาบัตรประจำตัวประชาชน</span>
											<input type="file" className="input-image" onChange={headleUploadImage2} />
										</label>
									)}
									{image2 !== '' && (
										<div style={{ textAlign: 'center', width: '100%' }}>
											<Image src={imagePreview2} alt="img" width="100%" height="200" preview />
											<br />
											<Button label="เปลี่ยนรูป" style={{ width: '50%' }} onClick={() => setImage2('')} />
										</div>
										// <img src={image2} alt="img" style={{ width: '200px', height: '200px' }} />
									)}
								</div>
							</Grid>
						</Grid>
						<div></div><br></br>
						<div>
							<strong>
								กรอกรายละเอียดเพิ่มเติม
							</strong>
							<br/>
							<small>
								เช่น ป่ายทะเบียน ยีห้อ
							</small>
						</div>
						{/* <p className="font-bold">กรอกรายละเอียดเพิ่มเติม</p> */}
						{/* <small>เช่น ป่ายทะเบียน ยีห้อ</small> */}
						<div>
							<InputTextarea value={description} onChange={(e) => setDescription(e.target.value)} rows={5} cols={70} className="w-full" />
						</div>
					</div>
				)}
				<div style={{ flex: '1' }}></div>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
					<ButtonGroup>
						<Button label="เพิ่มลงตะกล้า" icon="pi pi-check" className='add-cart' onClick={handleAddOrder} />
						<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={() => setDialogDetail(false)} />
					</ButtonGroup>
				</div>
			</Dialog>

			{/* Dialog Image */}
			<Dialog header="แนบหลักฐาน" visible={dialogImage} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogImage) return; setDialogImage(false); }}
				breakpoints={{ '1680px': '50vw', '641px': '100vw', '430px': '80vw' }} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={6}>
						<div className="image-package">
							{image1 === '' && (
								<label className="file-input-label1">
									<span>แนบรูป สมุดเล่มเขียว</span>
									<input type="file" className="input-image" onChange={headleUploadImage1} />
								</label>
							)}
							{image1 !== '' && (
								<div style={{ textAlign: 'center', width: '100%' }}>
									<Image src={imagePreview1} alt="img" width="100%" height="200" preview />
									<Button label="เปลี่ยนรูป" style={{ width: '50%' }} onClick={() => setImage1('')} />
								</div>
							)}
						</div>
					</Grid>
					<Grid item xs={6}>
						<div className="image-package">
							{image2 === '' && (
								<label className="file-input-label1">
									<span>แนบรูป สำเนาบัตรประจำตัวประชาชน</span>
									<input type="file" className="input-image" onChange={headleUploadImage2} />
								</label>
							)}
							{image2 !== '' && (
								<div style={{ textAlign: 'center', width: '100%' }}>
									<Image src={imagePreview2} alt="img" width="100%" height="200" preview />
									<Button label="เปลี่ยนรูป" style={{ width: '50%' }} onClick={() => setImage2('')} />
								</div>
								// <img src={image2} alt="img" style={{ width: '200px', height: '200px' }} />
							)}
						</div>
					</Grid>
				</Grid>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }} className='grup-btn-media-dialog'>
					<ButtonGroup>
						<Button label="ยืนยัน" icon="pi pi-check" className='add-cart' onClick={headleUploadImage} />
						<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={headleCancelImage} />
					</ButtonGroup>
				</div>
			</Dialog>

			{/* Dialog Discount */}
			<Dialog header="ส่วนลด" visible={dialogDiscount} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogDiscount) return; setDialogDiscount(false); }}
				breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ marginTop: '1rem' }}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="discount" value={discount} onChange={(e) => setDiscount(e.value)} />
							<label htmlFor="moneyreceive">ส่วนลดในบิล</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="ยืนยัน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmDiscount} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={onHideDialogDiscount} />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>

			{/* Dialog Checkout */}
			<Dialog header="ชำระเงิน" visible={dialogCheckOut} style={{ width: '60vw', fontFamily: 'Kanit' }} onHide={() => onHideDialogCheckout(false)}
				breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12}>
						<Dropdown value={paymenttype} onChange={(e) => headlerPaymentType(e, e.value)} options={['เงินสด', 'เงินโอน', 'บัตรเครดิต', 'อิ่นๆ']}
							placeholder="เลือกประเภทการชำระ" className="custom-InputText" />
					</Grid>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="moneyreceive" value={moneyreceive} onChange={(e) => setMoneyReceive(e.value)}
								disabled={disabledMoneyReceive} />
							<label htmlFor="moneyreceive">กรอกเงินที่รับมา</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="ชำระเงิน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmCheckout} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={onHideDialogCheckout} />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>

			{/* Dialog Receipt */}
			<Dialog header="เงินทอน" visible={dialogReceipt} style={{ width: '60vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogReceipt) return; setDialogReceipt(false); }}
				breakpoints={{ '2000px': '30vw', '1680px': '40vw', '960px': '60vw', '641px': '70vw', '430px': '80vw' }} closable={false} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12}>
						<Card className="border-1 shadow-none border-gray-400" style={{ padding: '0' }}>
							<h1>
								{numberFormat(change == null ? '' : change)}
							</h1>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<div>
							<Button label="ใบเสร็จรับเงิน" style={{ width: '100%' }} onClick={headlerPrintReceipt} />
							{/* <ReceiptExpress /> */}
						</div>
					</Grid>
					<Grid item xs={6}>
						<div>
							<Button label="ทำรายรายต่อ" style={{ width: '100%' }} onClick={heandleResetOrder} />
						</div>
					</Grid>
					<Grid item xs={6}>
						<div>
							<Button label="หน้าหลัก" style={{ width: '100%' }} onClick={heandleResetPage} />
						</div>
					</Grid>
					{/* <div style={{ display: 'none' }}> */}
					{/* <div ref={componentReceipt} style={{ fontSize: '10px', textAlign: 'center', padding: '0', fontFamily: 'Kanit' }}> */}
					{/* <ReceiptArtwork data={resp} /> */}
					{/* </div> */}
					{/* </div> */}
				</Grid>
			</Dialog>

			<LoadingDialog isLoading={isLoading} />
		</div >
	)
}