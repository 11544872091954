import Box from "@mui/material/Box";
import { useState, useRef } from 'react';
import Swal from "sweetalert2";

import Grid from "@mui/material/Grid";

import { Autocomplete, TextField } from "@mui/material";

import { Dialog } from "primereact/dialog";
import LoadingDialog from "../../../views/LoadingView";
import "./ListBus.css";

import { getBusLogo } from "./BusLogo";
import { getCompanyDisplayName } from "./CompanyNames";
// import "./tailwind.css";
import "primeflex/primeflex.css";

async function getMe() {
  const token = JSON.parse(localStorage.getItem("token"));
  return fetch(`${process.env.REACT_APP_SHOP}/me`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": `Bearer ${token}`,
    },
  }).then((data) => data.json());
}

async function getShop(shop_id) {
  const token = JSON.parse(localStorage.getItem("token"));
  return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": `Bearer ${token}`,
    },
  }).then((data) => data.json());
}
// ดึงข้อมูล จังหวัด
async function getProvince() {
  return fetch(`${process.env.REACT_APP_SHOP}/easybook/bus/province`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}
// ดึงข้อมูลสถานนีขนส่ง
async function getSubplaces() {
  return fetch(`${process.env.REACT_APP_SHOP}/easybook/bus/subplaces`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

async function getBoxExpress(shop_id) {
  const token = JSON.parse(localStorage.getItem("token"));
  return fetch(`${process.env.REACT_APP_SHOP}/express/box/shop/${shop_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": `Bearer ${token}`,
    },
  }).then((data) => data.json());
}

async function addBoxExpress(packageData) {
  const token = JSON.parse(localStorage.getItem("token"));
  return fetch(`${process.env.REACT_APP_SHOP}/express/box`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "auth-token": `Bearer ${token}`,
    },
    body: JSON.stringify(packageData),
  }).then((data) => data.json());
}

async function updateBoxExpress(packageData, packageId) {
  const token = JSON.parse(localStorage.getItem("token"));
  return fetch(`${process.env.REACT_APP_SHOP}/express/box/${packageId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "auth-token": `Bearer ${token}`,
    },
    body: JSON.stringify(packageData),
  }).then((data) => data.json());
}

async function deleteBoxExpress(packageId) {
  const token = JSON.parse(localStorage.getItem("token"));
  return fetch(`${process.env.REACT_APP_SHOP}/express/box/${packageId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "auth-token": `Bearer ${token}`,
    },
  }).then((data) => data.json());
}

export default function BoxExpress() {
  const [isLoading, setisLoading] = useState(false);
  const [employee, setEmployee] = useState("");
  const [shop, setShop] = useState("");

  // product
  const [itemProduct, setItemProduct] = useState([]);
  const [dialogAdd, setDialogAdd] = useState(false);
  const [productName, setProductName] = useState("");
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [length, setLength] = useState(null);
  const [cost, setCost] = useState(null);
  const [price, setPrice] = useState(null);

  // Detail
  const [dialogUpdate, setDialogUpdate] = useState(false);
  const [id, setId] = useState("");
  const [updateProductName, setUpdateProductName] = useState("");
  const [updateWidth, setUpdateWidth] = useState(null);
  const [updateHeight, setUpdateHeight] = useState(null);
  const [updateLength, setUpdateLength] = useState(null);
  const [updateCost, setUpdateCost] = useState(null);
  const [updatePrice, setUpdatePrice] = useState(null);

  // ข้อมูลจังหวัด
  const [allprovince, setallprovince] = useState([]);
  // ข้อมูลสถานี
  const [allsubplaces, setallsubplaces] = useState([]);
  //จาก
  // จังหวัด
  const [fromprovince, setfromProvince] = useState([]);
  const [selectedfromprovince, setselectedfromprovince] = useState(null);
  // สถานี
  const [fromsubplaces, setfromsubplaces] = useState([]);
  const [selectedfromsubplaces, setselectedfromsubplaces] = useState(null);
  // ถึง
  // จังหวัด
  const [toprovince, settoProvince] = useState([]);
  const [selectedtoprovince, setselectedtoprovince] = useState(null);
  // สถานี
  const [tosubplaces, settosubplaces] = useState([]);
  const [selectedtosubplaces, setselectedtosubplaces] = useState(null);

  //วันที่ออกเดินทาง
  const [Fromdate, setFromDate] = useState(""); //วันที่ออก
  //วันที่กลับ
  const [Todate, setToDate] = useState(""); //วันที่กลับ
  //จำนวนคน
  const [people, setPeople] = useState(""); //จำนวนคน

  // เที่ยวรถ
  const [trip, setTrip] = useState([]); //เที่ยวรถ
  const tripParamsRef = useRef(null);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);

  // เลือกที่นั่ง
  const [dialogSeatVisible, setDialogSeatVisible] = useState(false);
  const [seatData, setSeatData] = useState(null);

  const [selectedSeats, setSelectedSeats] = useState([]);


  useState(async () => {
    const response = await getMe();
    setisLoading(true);
    if (response.logout === true) {
      localStorage.clear();
      window.location.href = "/";
    }
    if (response.level === "member") {
      setisLoading(false);
      Swal.fire({
        title: "แจ้งเตือน!",
        text: "ผู้ใช้งานดังกล่าว ไม่สามารถใช้งานระบบนี้ได้!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ยืนยัน",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/";
        }
      });
    } else if (response.level === "employee") {
      const shop = await getShop(response.data.employee_shop_id);
      if (shop.data.shop_status === false) {
        setisLoading(false);
        Swal.fire({
          title: "แจ้งเตือน!",
          text: "ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน",
          icon: "warning",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        setisLoading(false);
        setEmployee(response.data);
        setShop(shop.data);
        const province = await getProvince();
        setallprovince(province.data);
        //จาก
        setfromProvince(province.data);
        //ถึง
        settoProvince(province.data);
        const subplaces = await getSubplaces();
        setallsubplaces(subplaces.data);

        // const box = await getBoxExpress(shop.data._id);
        // setItemProduct(box.data);
      }
    }
  });

  // จาก
  const handleChangeFromProvince = (value) => {
    if (value !== null) {
      setselectedfromprovince(value);
      setselectedfromsubplaces(null);
      // กรองข้อมูล subplaces ที่ตรงกับ PlaceId ของจังหวัดที่เลือก
      const filteredSubplaces = allsubplaces.filter(
        (subplace) => subplace.PlaceId === value.PlaceId
      );
      setfromsubplaces(filteredSubplaces);
    }
  };

  // ถึง
  const handleChangeToProvince = (value) => {
    if (value !== null) {
      setselectedtoprovince(value);
      setselectedtosubplaces(null);
      // กรองข้อมูล subplaces ที่ตรงกับ PlaceId ของจังหวัดที่เลือก
      const filteredSubplaces = allsubplaces.filter(
        (subplace) => subplace.PlaceId === value.PlaceId
      );
      settosubplaces(filteredSubplaces);
    }
  };

  // ดึง api เที่ยวรถ
  const getTrip = async () => {
    try {
      if (!selectedfromprovince) {
        Swal.fire({
          title: "แจ้งเตือน!",
          text: "กรุณาเลือกจังหวัดต้นทางก่อน!",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      if (!selectedtoprovince) {
        Swal.fire({
          title: "แจ้งเตือน!",
          text: "กรุณาเลือกจังหวัดปลายทางก่อน",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      if (!Fromdate) {
        Swal.fire({
          title: "แจ้งเตือน!",
          text: "กรุณาเลือกวันที่ออกเดินทาง",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      if (!people) {
        Swal.fire({
          title: "แจ้งเตือน!",
          text: "กรุณากรอกจำนวนผู้โดยสาร",
          icon: "warning",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      const data = {
        FromPlaceId: selectedfromprovince?.PlaceId,
        FromSubPlaceId: selectedfromsubplaces?.SubPlaceId || null,
        ToPlaceId: selectedtoprovince?.PlaceId,
        ToSubPlaceId: selectedtosubplaces?.SubPlaceId || null,
        DepartureStartDate: Fromdate,
        DepartureEndDate: Todate || null,
        Pax: people,
        Currency: "THB",
      };

      const resp = await fetch(
        `${process.env.REACT_APP_SHOP}/easybook/bus/checktrip`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!resp.ok) {
        throw new Error("Failed to fetch data");
      }

      const result = await resp.json();
      console.log("Response:", result);

      const subplacesResp = await fetch(
        `${process.env.REACT_APP_SHOP}/easybook/bus/subplaces`
      );
      const subplacesData = await subplacesResp.json();

      const tripsWithSubPlaceNames = result.data.map((trip) => {
        // Find matching SubPlaceId in subplacesData for FromSubPlaceId
        const foundFromSubPlace = subplacesData.data.find(
          (sp) => sp.SubPlaceId === trip.FromSubPlaceId
        );
        const foundToSubPlace = subplacesData.data.find(
          (sp) => sp.SubPlaceId === trip.ToSubPlaceId
        );
        const foundFromPlaceName = subplacesData.data.find(
          (sp) => sp.SubPlaceId === trip.FromSubPlaceId
        );
        const foundToPlaceName = subplacesData.data.find(
          (sp) => sp.SubPlaceId === trip.ToSubPlaceId
        );

        const foundFromLatitude = subplacesData.data.find(
          (sp) => sp.SubPlaceId === trip.FromSubPlaceId
        );

        // ฟังก์ชันสำหรับแปลงชื่อสถานี
        const convertStationName = (name) => {
          return name === "สถานีขนส่งผู้โดยสาร จ.เชียงใหม่ (กรมขนส่ง)"
            ? "สถานีขนส่งผู้โดยสารจังหวัดเชียงใหม่ แห่งที่ 3 (อาเขต)"
            : name;
        };
        return {
          ...trip,
          FromSubPlaceName: convertStationName(
            foundFromSubPlace
              ? foundFromSubPlace.SubPlaceName
              : "Unknown SubPlace"
          ),
          ToSubPlaceName: convertStationName(
            foundToSubPlace ? foundToSubPlace.SubPlaceName : "Unknown SubPlace"
          ),
          FromPlaceName: foundFromPlaceName
            ? foundFromPlaceName.PlaceName
            : "Unknown SubPlace",
          ToPlaceName: foundToPlaceName
            ? foundToPlaceName.PlaceName
            : "Unknown SubPlace",
          FromLatitude: foundFromLatitude
            ? foundFromLatitude.Latitude
            : "Unknown SubPlace",
          FromLongitude: foundFromLatitude
            ? foundFromLatitude.Longitude
            : "Unknown SubPlace",
        };
      });

      setTrip(tripsWithSubPlaceNames);
    } catch (error) {
      console.error("Error fetching trip data:", error);
    }
  };
  const formatTripDuration = (duration) => {
    if (!duration || typeof duration !== "string") {
      return "";
    }

    // ใช้ Regular Expression ในการแยกชั่วโมงและนาทีจาก duration
    const match = duration.match(/(\d+)[hH](\d+)m?/);

    if (!match) {
      return "";
    }

    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);

    const totalHours = hours + Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const remainingHours = totalHours % 24;
    const days = Math.floor(totalHours / 24);

    // ถ้าระยะเวลาเกิน 24 ชั่วโมง
    if (totalHours >= 24) {
      return `${days} วัน ${remainingHours}h${remainingMinutes}m`;
    }

    // ถ้าระยะเวลาไม่เกิน 24 ชั่วโมง
    return `${totalHours}h${remainingMinutes}m`;
  };

  const translateCoachType = (coachType) => {
    switch (coachType) {
      case "Van transfer":
        return "รถตู้";
      case "single-decker-bus":
        return "รถบัสชั้นเดียว";
      case "double-decker-bus":
        return "รถบัสสองชั้น";
      case "Express Bus":
        return "รถบัสแบบด่วน";
      case "Bus VIP 24 seat":
        return "รถบัส VIP 24 ที่นั่ง";
      default:
        return coachType;
    }
  };
  const formatTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const calculateArrivalTime = (departureDate, duration) => {
    if (!departureDate || !duration) {
      return "";
    }

    const departure = new Date(departureDate);
    if (isNaN(departure.getTime())) {
      return "";
    }

    let hours = 0;
    let minutes = 0;

    // รองรับรูปแบบ "14h10m"
    const durationMatch = duration.match(/(\d+)h(?:(\d+)m)?/);
    if (durationMatch) {
      hours = parseInt(durationMatch[1], 10) || 0;
      minutes = parseInt(durationMatch[2], 10) || 0;
    } else {
      return "";
    }

    const arrivalTime = new Date(
      departure.getTime() + (hours * 60 + minutes) * 60000
    );

    return formatTime(arrivalTime);
  };
  const calculateArrivalDate = (departureDate, duration) => {
    if (!departureDate || !duration) {
      return "";
    }

    const departure = new Date(departureDate);
    if (isNaN(departure.getTime())) {
      return "";
    }

    let hours = 0;
    let minutes = 0;

    // รองรับรูปแบบ "14h10m"
    const durationMatch = duration.match(/(\d+)h(?:(\d+)m)?/);
    if (durationMatch) {
      hours = parseInt(durationMatch[1], 10) || 0;
      minutes = parseInt(durationMatch[2], 10) || 0;
    } else {
      return "";
    }

    const arrivalTime = new Date(
      departure.getTime() + (hours * 60 + minutes) * 60000
    );

    return formatDate(arrivalTime);
  };

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };


  const handleSeatSelection = async (tripKey) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_SHOP}/easybook/bus/checkseat`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    TripKey: tripKey,
                    Currency: "THB",
                }),
            }
        );

        if (!response.ok) {
            throw new Error("Failed to fetch seat data");
        }

        const result = await response.json();
        console.log("Seat Data:", result);

        if (result.status && result.data) {
            const { Status, Code, Message } = result.data;

            if (Status === 0 && Code === -1005) {
                // Show SweetAlert2 notification for no seats available
                Swal.fire({
                    icon: 'warning',
                    title: 'ไม่มีที่นั่งเหลือ',
                    text: Message,
                }).then(() => {
                    // Remove trip from the list after closing the alert
                    getTrip();
                });
                return; // Exit the function early
            }

            const selectedTrip = trip.find((t) => t.TripKey === tripKey);
            console.log("Selected Trip:", selectedTrip);

            setSelectedTrip(selectedTrip);
            setSeatData(result.data);
            setDialogSeatVisible(true);
        } else {
            // Show SweetAlert2 notification for other API errors
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: result.data.Message || 'An error occurred while fetching seat data.',
            });
        }
    } catch (error) {
        console.error("Error fetching seat data:", error);
        // Show SweetAlert2 notification for fetch errors
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.message || 'An error occurred while fetching seat data.',
        });
    }
};

// Function to remove trip from the list
const removeTripFromList = (tripKey) => {
    setTrip((prevTrips) =>
        prevTrips.filter((t) => t.TripKey !== tripKey)
    );
};







const toggleSeatSelection = (seatNumber) => {
  const isSelected = selectedSeats.includes(seatNumber);
  
  if (isSelected) {
      setSelectedSeats((prevSeats) => prevSeats.filter((seat) => seat !== seatNumber));
  } else {
      if (selectedSeats.length < people) {
          setSelectedSeats((prevSeats) => [...prevSeats, seatNumber]);
      } else {
          Swal.fire({
              icon: 'warning',
              title: 'ไม่สามารถเลือกที่นั่งได้',
              text: `คุณสามารถเลือกที่นั่งได้สูงสุด ${people} ที่นั่ง`,
          });
      }
  }
};

  
  
  const handleProceed = async () => {

    if (selectedSeats.length < people) {
      Swal.fire({
          icon: 'warning',
          title: 'ไม่สามารถดำเนินการต่อได้',
          text: `คุณต้องเลือกที่นั่ง ${people} ที่นั่ง`,
      });
      return;
  }
  
    const payload = {
      Currency: "THB",
      DepartureTripKey: selectedTrip.TripKey,
      DepartAdultPax: selectedTrip.AdultPax || 1, // ใช้ค่าเริ่มต้นถ้าไม่มีข้อมูล
      DepartChildPax: selectedTrip.ChildPax || 0, // ใช้ค่าเริ่มต้นถ้าไม่มีข้อมูล
      DepartFromSubSubPlaceId: selectedTrip.FromSubPlaceId,
      DepartToSubSubPlaceId: selectedTrip.ToSubPlaceId,
      SelectedDepartureSeats: selectedSeats,
    };
  
    console.log("Payload before sending:", payload);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_SHOP}/easybook/bus/checkprice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log("Response data:", data);

      sessionStorage.setItem('totalPassengers', people);
      sessionStorage.setItem('paymentDetails', JSON.stringify(data));
      sessionStorage.setItem('selectedTrip', JSON.stringify(selectedTrip)); 
      sessionStorage.setItem('selectedSeats', JSON.stringify(selectedSeats)); 


      window.open('/payment', '_blank');
    } catch (error) {
      console.error("Error while proceeding to payment:", error);
    }
  };
  


  return (
    <div>
      {/* โชว์ข้อมูลจังหวัดมาเป็นกอง json */}

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <Grid container className="order-container">
                <div className="title">
                  <h1>จองตั๋วรถบัส</h1>
                </div>
                <Grid
                  container
                  rowSpacing={1}
                  sx={{ backgroundColor: "white", padding: 2, margin: 2 }}
                >
                  <Grid container spacing={2}>
                    {/* แถวที่ 1 */}
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        disablePortal
                        options={fromprovince}
                        getOptionLabel={(option) => option.PlaceName}
                        value={selectedfromprovince}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            handleChangeFromProvince(newValue);
                          } else {
                            setselectedfromprovince(null);
                            setfromsubplaces([]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="เลือกจังหวัด(จาก)" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        disablePortal
                        options={fromsubplaces}
                        getOptionLabel={(option) => option?.SubPlaceName}
                        value={selectedfromsubplaces}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setselectedfromsubplaces(newValue);
                          } else {
                            setselectedfromsubplaces(null);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="เลือกสถานี(จาก)" />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        disablePortal
                        options={toprovince}
                        getOptionLabel={(option) => option?.PlaceName}
                        value={selectedtoprovince}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            handleChangeToProvince(newValue);
                          } else {
                            setselectedtoprovince(null);
                            settosubplaces([]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="เลือกจังหวัด(ถึง)" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        disablePortal
                        options={tosubplaces}
                        getOptionLabel={(option) => option?.SubPlaceName}
                        value={selectedtosubplaces}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setselectedtosubplaces(newValue);
                          } else {
                            setselectedtosubplaces(null);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="เลือกสถานี(ถึง)" />
                        )}
                      />
                    </Grid>

                    {/* แถวที่ 2 */}
                    <Grid item xs={12} sm={6} md={3}>
                      {/* datepicker ตัวแปร Fromdate */}
                      <TextField
                        fullWidth
                        label="วันที่ออกเดินทาง"
                        type="date"
                        // defaultValue="2022-01-01"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={Fromdate}
                        onChange={(event) => setFromDate(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      {/* datepicker */}
                      <TextField
                        fullWidth
                        label="วันที่กลับ"
                        type="date"
                        // defaultValue="2022-01-01"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={Todate}
                        onChange={(event) => setToDate(event.target.value)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      {/* ใช้ textField จำนวนคน และ ให้กรอกแต่จำนวนเต็ม */}
                      <TextField
                        fullWidth
                        label="จำนวนคน"
                        type="number"
                        value={people}
                        onChange={(event) =>
                          setPeople(parseInt(event.target.value))
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      {/* ปุ่ม ค้นหา รถโดยสาร */}

                      <button
  onClick={getTrip}
  className="p-button p-component w-full flex justify-content-center align-items-center"
>
  <span className="p-button-icon p-c">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      className="mr-2"
      viewBox="0 0 26 26"
    >
      <path
        fill="currentColor"
        d="M10 .188A9.812 9.812 0 0 0 .187 10A9.812 9.812 0 0 0 10 19.813c2.29 0 4.393-.811 6.063-2.125l.875.875a1.845 1.845 0 0 0 .343 2.156l4.594 4.625c.713.714 1.88.714 2.594 0l.875-.875a1.84 1.84 0 0 0 0-2.594l-4.625-4.594a1.824 1.824 0 0 0-2.157-.312l-.875-.875A9.812 9.812 0 0 0 10 .188zM10 2a8 8 0 1 1 0 16a8 8 0 0 1 0-16zM4.937 7.469a5.446 5.446 0 0 0-.812 2.875a5.46 5.46 0 0 0 5.469 5.469a5.516 5.516 0 0 0 3.156-1a7.166 7.166 0 0 1-.75.03a7.045 7.045 0 0 1-7.063-7.062c0-.104-.005-.208 0-.312z"
      />
    </svg>
  </span>
  ค้นหา รถโดยสาร
</button>

                    </Grid>
                  </Grid>
                </Grid>
                {/* <Box sx={{ width: '100%', height: 'auto' }}>
									<pre>{JSON.stringify(trip, null, 2)}</pre>

								</Box> */}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <div className="surface-card p-4 shadow-2 border-round">

{trip.length > 0 ? (
    trip.map((trip, index) => (
        <div key={index} className="mb-4 p-3 surface-100 border-round">
            <div className="flex justify-content-between align-items-center mb-3">
                <div className="flex align-items-center">
                    <img src={getBusLogo(trip.CompanyName)} className=" bg-cover bg-center border-round w-6rem h-6rem mr-2" />
                    <div>
                        <span className="font-bold block">{getCompanyDisplayName(trip.CompanyName)}</span>
                        <span className="text-sm text-color-secondary">{trip.CompanyName} ({trip.CoachType})</span>
                    </div>
                </div>
                <div className="text-right">
                    <span className="text-2xl font-bold text-primary">฿{trip.AdultPrice.toLocaleString()}</span>
                    <div className="text-sm text-color-secondary">ที่นั่งว่าง: {trip.SeatAvailable}</div>
                </div>
            </div>
            <div className="flex align-items-start mb-3">
                <div className="flex flex-column align-items-center mr-3 justify-content-center" style={{ minHeight: '6rem' }}>
                    <h1 className="text-black text-base text-center font-bold m-0">{formatTripDuration(trip.TripDuration)}</h1>
                </div>
                <div className="flex flex-column align-items-center mr-3">
                    <i className="pi pi-circle-fill text-gray-500 text-xl"></i>
                    <div className="h-3rem border-left-2 border-primary"></div>
                    <i className="pi pi-circle-fill text-primary text-xl"></i>
                </div>
                <div className="flex-1">
                    <div className="mb-2">
                        <p className="text-xs text-gray-800 m-0">{trip.FromPlaceName}</p>
                        <p className="m-0"><span className="font-bold">{formatTime(trip.DepartureDate)} &nbsp;&nbsp;</span> {trip.FromSubPlaceName}</p>
                    </div>
                    <div>
                        <p className="text-xs text-gray-800 m-0">{trip.ToPlaceName} </p>
                        <p className="m-0"><span className="font-bold"> {calculateArrivalTime(trip.DepartureDate, trip.TripDuration)} &nbsp;&nbsp;</span> {trip.ToSubPlaceName}</p>
                    </div>
                </div>
            </div>
            <div className="flex justify-content-end align-items-center">
                <button
                    className="p-button p-button-text mr-2"
                    onClick={() => {
                        setSelectedTrip(trip);
                        setDialogVisible(true);
                    }}
                >
                    ดูรายละเอียด
                </button>
                <button  onClick={() => handleSeatSelection(trip.TripKey)} className="p-button p-button-rounded">เลือกที่นั่ง</button>
            </div>
        </div>
    ))
) : (
    <div className="p-3 surface-100 border-round">
        <p className="text-center text-gray-600">ไม่มีรายการ</p>
    </div>
)}
</div>

       
<Dialog
header="รายละเอียดเที่ยวรถ"
visible={dialogVisible}
style={{ width: "90vw", maxWidth: "900px" }}
breakpoints={{ "960px": "75vw", "641px": "100vw" }}
onHide={() => {
  if (!dialogVisible) return;
  setDialogVisible(false);
}}
className="p-fluid"
>
{selectedTrip && (
<div className="grid">
              <div className="col-12 md:col-6 lg:col-6">
                <div className="col-12 ">
                  <div
                    className="border-round-xl border py-3 px-3 shadow-2"
                    style={{
                      backgroundColor: "#FEFFD2",
                      color: "#272829",
                      borderColor: "#ECD400",
                    }}
                  >
                    <div className="flex align-items-center w-full justify-content-between border-bottom-1 ">
                      <div className="flex items-center">
                        <button
                          className="border-round-xs m-2 border  px-3 py-1 text-sm font-semibold"
                          style={{
                            backgroundColor: "#C07F00",
                            color: "#FCFFB2",
                            borderColor: "#ECD400",
                          }}
                        >
                          ข้อมูลการเดินทางออก
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-column md:flex-row justify-content-between align-items-start md:align-items-center border-bottom-1 py-2">
                      <div className="flex items-center mb-2 md:mb-0">
                        <span className="font-medium">Date</span>
                      </div>
                      <div>
                        <span className="font-medium">
                          {formatDate(selectedTrip.DepartureDate)}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-column md:flex-row justify-content-between align-items-start md:align-items-center border-bottom-1 py-2">
                      <div className="flex items-center mb-2 md:mb-0">
                        <span className="font-medium">Time</span>
                      </div>
                      <div>
                        <span className="font-medium">
                          {formatTime(selectedTrip.DepartureDate)}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-column md:flex-row justify-content-between align-items-start md:align-items-center border-bottom-1 py-2">
                      <div className="flex items-center mb-2 md:mb-0">
                        <span className="font-medium">Location</span>
                      </div>
                      <div>
                        <span className="font-medium">
                          {selectedTrip.FromSubPlaceName}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-column border-bottom-1 py-2">
                      <div className="flex items-center mb-1">
                        <span className="font-medium">Address</span>
                      </div>
                      <div className="pl-2">
                        <span className="font-medium text-sm">
                          {selectedTrip.PickupAddress}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 ">
                  <div
                    className="border-round-xl border py-3 px-3 shadow-2"
                    style={{
                      backgroundColor: "#FEFFD2",
                      color: "#272829",
                      borderColor: "#ECD400",
                    }}
                  >
                    <div className="flex align-items-center w-full justify-content-between border-bottom-1 ">
                      <div className="flex items-center">
                        <button
                          className="border-round-xs m-2 border  px-3 py-1 text-sm font-semibold"
                          style={{
                            backgroundColor: "#C07F00",
                            color: "#FCFFB2",
                            borderColor: "#ECD400",
                          }}
                        >
                          ข้อมูลการเดินทางมาถึง
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-column md:flex-row justify-content-between align-items-start md:align-items-center border-bottom-1 py-2">
                      <div className="flex items-center mb-2 md:mb-0">
                        <span className="font-medium">Date</span>
                      </div>
                      <div>
                        <span className="font-medium">
                          {calculateArrivalDate(
                            selectedTrip.DepartureDate,
                            selectedTrip.TripDuration
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-column md:flex-row justify-content-between align-items-start md:align-items-center border-bottom-1 py-2">
                      <div className="flex items-center mb-2 md:mb-0">
                        <span className="font-medium">Time</span>
                      </div>
                      <div>
                        <span className="font-medium">
                          {calculateArrivalTime(
                            selectedTrip.DepartureDate,
                            selectedTrip.TripDuration
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-column md:flex-row justify-content-between align-items-start md:align-items-center border-bottom-1 py-2">
                      <div className="flex items-center mb-2 md:mb-0">
                        <span className="font-medium">Location</span>
                      </div>
                      <div>
                        <span className="font-medium">
                          {selectedTrip.ToSubPlaceName}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-column border-bottom-1 py-2">
                      <div className="flex items-center mb-1">
                        <span className="font-medium">Address</span>
                      </div>
                      <div className="pl-2">
                        <span className="font-medium text-sm">
                          {selectedTrip.DropOffAddress}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 md:col-6 lg:col-6">
                <div className="col-12">
                  <div
                    className="border-round-xl border py-3 px-3 shadow-2 "
                    style={{
                      backgroundColor: "#FEFFD2",
                      color: "#272829",
                      borderColor: "#ECD400",
                    }}
                  >
                    <div className="flex align-items-center w-full justify-content-between border-bottom-1 ">
                      <div className="flex items-center">
                        <button
                          className="border-round-xs m-2 border  px-3 py-1 text-sm font-semibold"
                          style={{
                            backgroundColor: "#C07F00",
                            color: "#FCFFB2",
                            borderColor: "#ECD400",
                          }}
                        >
                          Coach & Fare Info
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-column md:flex-row justify-content-between align-items-start md:align-items-center border-bottom-1 py-2">
                      <div className="flex items-center mb-2 md:mb-0">
                        <span className="font-medium">ประเภทรถ </span>
                      </div>
                      <div>
                        <span className="font-medium">
                          {" "}
                          {translateCoachType(selectedTrip.CoachType)}
                        </span>
                      </div>
                    </div>
                    <div className="flex flex-column md:flex-row justify-content-between align-items-start md:align-items-center border-bottom-1 py-2">
                      <div className="flex items-center mb-2 md:mb-0">
                        <span className="font-medium">ราคาตั๋ว</span>
                      </div>
                      <div>
                        <span className="font-medium">
                          ผู้ใหญ่: {selectedTrip.AdultPrice}
                        </span>{" "}
                        <br />
                        <span className="font-medium">
                          เด็ก: {selectedTrip.ChildPrice}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="border-round-xl border py-3 px-3 shadow-2"
                    style={{
                      backgroundColor: "#FEFFD2",
                      color: "#272829",
                      borderColor: "#ECD400",
                    }}
                  >
                    <div className="flex align-items-center w-full justify-content-between border-bottom-1 ">
                      <div className="flex items-center">
                        <button
                          className="border-round-xs m-2 border  px-3 py-1 text-sm font-semibold"
                          style={{
                            backgroundColor: "#C07F00",
                            color: "#FCFFB2",
                            borderColor: "#ECD400",
                          }}
                        >
                          Depart Location Map
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-column md:flex-row justify-content-center align-items-start md:align-items-center border-bottom-1 py-2">
                      <div className="w-full">
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${selectedTrip.FromLatitude},${selectedTrip.FromLongitude}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="w-full"
                        >
                          <button className="p-button p-component w-full">
                            <span className="p-button-label">ดูแผนที่</span>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          )}
          </Dialog>
      </Box>

      <Dialog
        header="เลือกที่นั่ง"
        visible={dialogSeatVisible}
        style={{ width: "50vw" }}
        onHide={() => setDialogSeatVisible(false)}
       
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        footer={
          <div className="flex justify-content-end">
            <button
           onClick={handleProceed} 
              className="p-button p-component p-button-primary"
            >
              ดำเนินการต่อ
            </button>
          </div>
        }
      >
        {seatData ? (
          <div>
            <div className="flex justify-content-center py-2 gap-4 text-sm text-color-secondary">
              <div className="inline-flex align-items-center">
                <div className="text-center cursor-pointer p-3 mr-2 border-round-lg border-2 bg-white border-primary-400 text-black hover:bg-primary-50"></div>
                <p>ยังมีจำหน่ายอยู่</p>
              </div>
              <div className="inline-flex align-items-center">
                <div className="text-center cursor-pointer p-3 mr-2 border-round-lg border-2 bg-custom-gradient border-custom-color text-custom-white"></div>
                <p>ไม่ว่าง</p>
              </div>
            </div>
            <div className="mb-2">
        <h4>ที่นั่งที่เลือก: {selectedSeats.join(', ') || "ไม่มีที่นั่งเลือก"}</h4>
      </div>
            <div className="flex">
              {/* แสดงข้อมูลชั้นล่างทางซ้าย */}
              {seatData.BusSeatPlan.Decks.some(deck => deck.DeckName === "lower") && (

              <div className="col-12 sm:col-12 md:col-6 pr-4">
                <p> - ชั้นล่าง:</p>

                <div className="flex justify-content-end align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 14 14"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M2.315 5.25a5.002 5.002 0 0 1 9.37 0zm-.309 1.5a5.001 5.001 0 0 0 4.244 5.194V9.143L4.156 6.75zm5.744 5.194a5.001 5.001 0 0 0 4.244-5.194h-2.15L7.75 9.143zM7 0a7 7 0 1 0 0 14A7 7 0 0 0 7 0"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <button className="w-full text-sm mt-2 bg-custom-yellow  text-white py-1  border-round-md">
                  ด้านหน้า
                </button>

                <div className="mt-2 px-4 p-4 border-round-md border-1 border-dashed border-300 transition-colors transition-duration-100 ease-in-out hover:border-900">
                  {seatData.BusSeatPlan.Decks.map((deck, deckIndex) => {
                    if (deck.DeckName === "lower") {
						const maxColumns = Math.max(...deck.Seats.map(seat => seat.X));

                      return (
                        <div key={deckIndex}>
                          <div
                            className="grid "
                            style={{
                              display: "grid",
							  gridTemplateColumns: `repeat(${maxColumns}, 1fr)`,
                              gridGap: "0.5rem",
                            }}
                          >
                            {deck.Seats.map((seat, seatIndex) => (
                              <div
                                key={seatIndex}
                                onClick={seat.IsAvailable ? () => toggleSeatSelection(seat.SeatNumber) : null}

                                className={`text-center cursor-pointer p-2 gap-8 border-round-lg border-2	 ${
                                  seat.IsAvailable
                                  ? selectedSeats.includes(seat.SeatNumber)
                                    ? "bg-red-500 text-white"
                                    : "bg-white border-primary-400 text-black hover:bg-primary-50"
                                    : "bg-custom-gradient border-custom-color text-custom-white cursor-not-allowed"
                                    }`}
                                style={{
									gridColumnStart: seat.X,
									gridColumnEnd: `span 1`,
									gridRowStart: seat.Y,
                                }}
                              >
                                {seat.SeatNumber}
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
                <button className="w-full mt-4 text-sm bg-custom-yellow  text-white py-1 border-round-md">
                  ด้านหลัง
                </button>
                
              </div>
 )}
              {/* แสดงข้อมูลชั้นบนทางขวา */}
              {seatData.BusSeatPlan.Decks.some(deck => deck.DeckName === "upper") && (

              <div className="col-12 sm:col-12 md:col-6 pl-4">
                <p>- ชั้นบน:</p>
                <div className="flex justify-content-end align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 14 14"
                  >
                    <path
                      fill="currentColor"
                      fillRule="evenodd"
                      d="M2.315 5.25a5.002 5.002 0 0 1 9.37 0zm-.309 1.5a5.001 5.001 0 0 0 4.244 5.194V9.143L4.156 6.75zm5.744 5.194a5.001 5.001 0 0 0 4.244-5.194h-2.15L7.75 9.143zM7 0a7 7 0 1 0 0 14A7 7 0 0 0 7 0"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <button className="w-full text-sm mt-2 bg-custom-yellow  text-white py-1 border-round-md">
                  ด้านหน้า
                </button>
                <div className="mt-2 px-4 p-4 border-round-md border-1 border-dashed border-300 transition-colors transition-duration-100 ease-in-out hover:border-900">
                  {seatData.BusSeatPlan.Decks.map((deck, deckIndex) => {
                    if (deck.DeckName === "upper") {
						const maxColumns = Math.max(...deck.Seats.map(seat => seat.X));

                      return (
                        <div key={deckIndex}>
                          <div
                            className="grid "
                            style={{
                              display: "grid",
							  gridTemplateColumns: `repeat(${maxColumns}, 1fr)`,
                              gridGap: "0.5rem",
                            }}
                          >
                            {deck.Seats.map((seat, seatIndex) => (
                              <div
                                key={seatIndex}
                                onClick={seat.IsAvailable ? () => toggleSeatSelection(seat.SeatNumber) : null}
                                className={`text-center cursor-pointer p-2 gap-8 border-round-lg border-2 ${
                                  seat.IsAvailable
                                    ? selectedSeats.includes(seat.SeatNumber)
                                      ? "bg-red-500 text-white"
                                      : "bg-white border-primary-400 text-black hover:bg-primary-50"
                                      : "bg-custom-gradient border-custom-color text-custom-white cursor-not-allowed"
                                      }`}
                                style={{
									gridColumnStart: seat.X,
									gridColumnEnd: `span 1`,
									gridRowStart: seat.Y,
                                }}
                              >
                                {seat.SeatNumber}
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
                <button className="w-full mt-4 text-sm bg-custom-yellow  text-white py-1 border-round-md">
                  ด้านหลัง
                </button>
              </div>
              )}
            </div>
            <p className="text-red-500">*ที่นั่งเด็กชาร์จเป็นอัตราเดียวกับผู้ใหญ่
            เด็กนั่งกับผู้ปกครองไม่เสียค่าบริการ</p>

          </div>
        ) : (
          <p>กำลังโหลดข้อมูลที่นั่ง...</p>
        )}
      </Dialog>

      <LoadingDialog isLoading={isLoading} />
    </div>
  );
}
