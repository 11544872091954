import React, { useState, useRef } from "react";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { useReactToPrint } from "react-to-print";

import { styled } from '@mui/material/styles';
import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { ButtonGroup } from 'primereact/buttongroup';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chip } from "primereact/chip";
import { Dropdown } from 'primereact/dropdown';
import { Card } from "primereact/card";

import LoadingDialog from "../../../views/LoadingView";
import ReceiptPOS from "./ReceitPOS";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getProductShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/product/shop/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getProductTG() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/product/tossagun`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getInvoiceShop(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/invoice/shop/shop/${packageId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function GetMember(reftel) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/member/check/${reftel}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function callcheckout(packageData) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/preorder/shop`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json());
};

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Item2 = styled(Paper)(({ theme }) => ({
	backgroundColor: '#ECD400',
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Item3 = styled(Paper)(({ theme }) => ({
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	flexGrow: 2,
}));

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};


export default function POSView() {
	const [isLoading, setisLoading] = useState(false);
	const [position, setPosition] = useState({});
	const [member, setMember] = useState({});
	const [employee, setEmployee] = useState({});
	const [shop, setShop] = useState({});

	// Product
	const [product_shop, setProductShop] = useState([]);
	const [barcode, setBarcode] = useState('');

	// ทศกัณฐ์แฟมมิลี
	const [reftel, setReftel] = useState('');
	const [refteldisable, setReftelDisable] = useState(false);

	// Order
	const [order, setOrder] = useState([]);

	// ชำระเงิน
	const [dialogDiscount, setDialogDiscount] = useState(false);
	const [dialogCheckOut, setDialogCheckout] = useState(false);
	const [paymenttype, setPaymentType] = useState('');
	const [moneyreceive, setMoneyReceive] = useState(0);
	const [disabledMoneyReceive, setDisabledMoneyReceive] = useState(false);
	const [change, setChange] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [resp, setResp] = useState({});

	const [dialogReceipt, setDialogReceipt] = useState(false);
	const componentReceipt = useRef();
	const headlerPrintReceipt = useReactToPrint({
		content: () => componentReceipt.current,
	});

	useState(async () => {
		setisLoading(true);
		const response = await getMe();
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			Swal.fire({
				title: "แจ้งเตือน!",
				text: "ผู้ใช้งานดังกล่าว ไม่สามารถใช้งานระบบนี้ได้!",
				icon: "warning",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "ยืนยัน",
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = "/";
				}
			})
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				setisLoading(false);
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				// Invoice 
				const resp_invoice = await getInvoiceShop(shop.data._id);
				const invoice = resp_invoice.data.filter(
					(el) => el.invoice_status === 'ค้างชำระ' ||
						el.invoice_status === 'รอตรวจสอบ'
				);
				if (invoice.length >= 2) {
					Swal.fire({
						title: "บล็อคการขายสินค้า!",
						text: "เนื่องจากร้านนี้มีใบแจ้งหนี้ค้างชำระอยู่ จึงไม่สามารถให้บริการขายหน้าร้านได้!",
						icon: "warning",
						showCancelButton: true,
						confirmButtonColor: "#3085d6",
						confirmButtonText: "ชำระใบแจ้งหนี้",
						cancelButtonColor: "#FFCC00",
						cancelButtonText: "หน้าแรก",
					}).then((result) => {
						if (result.isConfirmed) {
							window.location.href = "/pos/invoice";
						} else {
							window.location.href = "/";
						}
					})
				} else {
					setisLoading(false);
					setEmployee(response.data);
					setShop(shop.data);
					setPosition(shop.data.shop_type);
					// Product
					const product1 = await getProductShop(shop.data._id);
					const product2 = await getProductTG();
					if (product2.status) {
						let value = null;
						let product = [];
						product1.data.forEach(el => {
							value = product2.data.find((e) => e._id === el.productShop_tossagun_id);
							if (value) {
								product.push({ ...el, product_ref: value });
							}
						});
						setProductShop(product);
					}
				}

			}
		}
	});

	const ImageBodyTemplate = (item) => {
		return <img src={`${process.env.REACT_APP_SHOP}/product/tossagun/image/${item}`} alt="" width={'100%'} />
	};

	const amountProductById = (item) => {
		const amount = order.find((el) => el._id === item);
		if (amount) {
			return amount.amount;
		} else {
			return 0;
		}
	};

	const headleGetProduct = async (e, item) => {
		const product = product_shop.find((el) => el._id === item._id);
		const timenow = dayjs(Date.now()).format('HH:mm:ss');

		//ตรวจสอบเวลาจำหน่ายเครื่องดื่มแอลกอฮอร์
		if (product.productTG_alcohol_statustrue) {
			const start1 = dayjs().set('hour', 0).set('minute', 0).set('second', 0).format('HH:mm:ss')
			const end1 = dayjs().set('hour', 11).set('minute', 0).set('second', 0).format('HH:mm:ss')
			const start2 = dayjs().set('hour', 14).set('minute', 0).set('second', 0).format('HH:mm:ss')
			const end2 = dayjs().set('hour', 17).set('minute', 0).set('second', 0).format('HH:mm:ss')
			if (((timenow > start1) && (timenow < end1)) || ((timenow > start2) && (timenow < end2))) {
				//ในเวลา
				Swal.fire({
					title: 'นอกเวลาจำหน่าย!',
					text: 'ไม่สามารถขายสินค้าประเภทแอลกอฮอลได้ เนื่องจากอยู่นอกเวลาจำหน่าย 11.00-14.00 น. และ 17.00-24.00 น.',
					icon: 'error',
					showConfirmButton: false,
					timer: 2000,
				});
				return false;
			}
		}

		// const in_stock = product_shop.find((el) => el._id === item._id).productShop_stock;
		// const amount_order = await amountProductById(item._id);

		// if (in_stock < amount_order + 1) {
		// Swal.fire({
		// title: 'ผิดพลาด!',
		// text: 'เนื่องจากสินค้าในสต๊อกไม่พอจำหน่าย',
		// icon: 'error',
		// showConfirmButton: false,
		// timer: 2000,
		// });
		// return false;
		// }

		const new_order = order.find((el) => el._id === item._id);
		if (new_order) {
			const position = order.findIndex(
				(el) => el._id === item._id
			);
			const new_data = {
				...new_order,
				amount: new_order.amount + 1,
				total: new_order.total + item.product_ref.productTG_price.price,
			};
			order.splice(position, 1, new_data);
			setOrder([...order]);
		} else {
			const new_data = {
				...item,
				amount: 1,
				total: item.product_ref.productTG_price.price * 1,
			};
			setOrder([...order, new_data]);
		}

		Swal.fire({
			title: 'สำเร็จ!',
			text: 'เพิ่มสินค้าสำเร็จ',
			icon: 'success',
			showConfirmButton: false,
			timer: 300,
		});
	};

	const headleScanBarcode = (e) => {
		e.preventDefault();
		if (barcode === '') {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกรหัสสินค้าหรือรหัสบาร์โค้ด',
				icon: 'warning',
				showConfirmButton: false,
				timer: 1500,
			});
			return false;
		}
		const product = product_shop.find((el) => el.productShop_barcode === barcode);
		if (product) {
			headleGetProduct(e, product)
			setBarcode('');
		} else {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ไม่พบรหัสสินค้ารายการนี้',
				icon: 'warning',
				showConfirmButton: false,
				timer: 1500,
			});
			return false;
		}
	};

	const deleteProduct = (value) => e => {
		const position = order.findIndex((el) => el._id === value._id);
		order.splice(position, 1);
		setOrder([...order]);
		if (order.length === 0) {
			setDiscount(0)
		}
	};

	// Check Out 
	const calculateTotal = () => {
		return order.reduce((total, item) => total + item.total, 0);;
	};

	const headleCheckReftel = async () => {
		if (reftel === "") {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกทศกัฐ์แฟมมิลี่',
				icon: 'warning',
				showConfirmButton: false,
				timer: 1500,
			});
			return false;
		} else {
			const response = await GetMember(reftel);
			if (response.status) {
				Swal.fire({
					title: "ทศกัณฐ์แฟมมิลี่?",
					icon: "success",
					text: `เบอร์โทรศัพท์ ${response.data.tel} คุณ ${response.data.fristname} ${response.data.lastname}`,
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "ยืนยัน",
					cancelButtonText: "ยกเลิก",
				}).then((result) => {
					if (result.isConfirmed) {
						setReftelDisable(true);
					}
				})
			} else {
				Swal.fire({
					title: 'ทศกัณฐ์แฟมมิลี่!',
					text: 'ไม่พบข้อมูลทศกัณฐ์แฟมมิลี่',
					icon: 'error',
					showConfirmButton: false,
					timer: 3000,
				});
				return false;
			}
		}
	};

	const headlerClearReftel = async () => {
		Swal.fire({
			title: "เปลี่ยนทศกัณฐ์แฟมมิลี่?",
			icon: "warning",
			text: `คุณต้องการเปลี่ยนทศกัณฐ์แฟมมิลี่ ใช่หรือไม่?`,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "ยืนยัน",
			cancelButtonText: "ยกเลิก",
		}).then((result) => {
			if (result.isConfirmed) {
				setReftelDisable(false);
				setReftel('');
			}
		})
	};

	const headleDiscount = () => {
		if (calculateTotal() === 0) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ยอดรวมรวมไม่เพียงพอต่อการกรอกส่วนลด',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		} else {
			setDialogDiscount(true);
		}
	};

	const headleConfirmDiscount = () => {
		if (discount >= calculateTotal()) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ส่วนลดมากกว่ายอดที่ต้องชำระ ไม่สามารถทำรายการได้',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			setDialogDiscount(false);
			setDiscount(0);
			return false;
		} else {
			setDialogDiscount(false);
		}
	};

	const onHideDialogDiscount = () => {
		setDiscount(0);
		setDialogDiscount(false);
	};

	const heandleCheckOut = async () => {
		if (order.length === 0) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ไม่มีรายการให้ชำระเงิน กรุณาเลือกสินค้าเพื่อทำรายการ',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (reftel === '' || refteldisable === false) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกและยืนยัน ทศกัณฐ์แฟมมิลี่',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		setDialogCheckout(true);
	};

	const headlerPaymentType = (e, value) => {
		if (value === 'เงินสด') {
			setPaymentType(value);
			setMoneyReceive(0);
			setDisabledMoneyReceive(false);
		} else {
			const receive = calculateTotal() - discount;
			setPaymentType(value);
			setDisabledMoneyReceive(true);
			setMoneyReceive(receive);
		}
	};

	const onHideDialogCheckout = () => {
		setDialogCheckout(false);
	};

	const headleConfirmCheckout = async () => {
		if (moneyreceive < (calculateTotal() - discount)) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'จำนวนเงินที่กรอก ไม่เพียงพอต่อการชำระเงิน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 2000,
			});
			setDialogCheckout(false);
			return false;
		}
		const changes = moneyreceive - (calculateTotal() - discount);
		setChange(changes);
		setDialogCheckout(false);
		Swal.fire({
			title: "ยืนยันชำระเงิน?",
			icon: "question",
			text: "รับเงินเรียบร้อย ยืนยันการชำระเงิน และบันทึกข้อมูล",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "ยืนยัน",
			cancelButtonText: "ยกเลิก",
		}).then(async (result) => {
			if (result.isConfirmed) {
				const data = {
					poshop_shop_id: shop._id,
					poshop_detail: order,
					poshop_platform: reftel,
					poshop_paymenttype: paymenttype,
					poshop_discount: discount,
					poshop_moneyreceive: moneyreceive,
					poshop_change: changes,
					poshop_employee: employee._id,
				};
				setisLoading(true);
				const response = await callcheckout(data);
				if (response.status === true) {
					setResp(response.poshop);
					setisLoading(false);
					setDialogReceipt(true);
					setMoneyReceive(0);
					setDiscount(0);
				} else {
					setisLoading(false);
					setDialogCheckout(true);
				}
			} else {
				setDialogCheckout(true);
			}
		})
	};

	const heandleResetOrder = (e) => {
		setDiscount(0);
		setReftel("");
		setOrder([]);
		setReftelDisable(false);
		setDialogReceipt(false);
	};

	const heandleResetPage = (e) => {
		window.location.href = "/";
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"container"}>
								<div className="title" ><h1>ขายสินค้า (POS)</h1></div>
								<Box sx={{ width: '100%' }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item xs={5}>
											<Grid className="form">
												<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>
													<Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
														<Item3 sx={{ padding: 0 }}>
															<FloatLabel className="custom-InputText">
																<form onSubmit={headleScanBarcode}>
																	<InputText className="custom-InputText" id="barcode" placeholder="สแกนบาร์โค้ดในช่องนี้"
																		value={barcode} onChange={(e) => setBarcode(e.target.value)} />
																</form>
															</FloatLabel>
														</Item3>
													</Stack>
												</Item>
												<Item2 sx={{ width: '100%', height: '100%', padding: '1rem', paddingTop: '1.5rem', borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
													<ScrollPanel className="scroll">
														<Grid container spacing={1} sx={{ padding: 0 }} columnSpacing={{ xs: 3 }}>
															{product_shop.slice().map((item, index) => (
																<Grid item xs={4} sx={{ width: '50%', height: '100%' }} key={index}>
																	<Grid className='step2item' onClick={(e) => headleGetProduct(e, item)}>
																		<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', height: '100%' }}>
																			{/* {ImageBodyTemplate(item.productTG_image)} */}
																			{item.product_ref.productTG_image ? (
																				ImageBodyTemplate(item.product_ref.productTG_image)
																			) : (
																				<img src={require('../../../assets/logo.png')} alt="" style={{ width: '100%' }} />
																			)}
																		</Item3>
																		<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#000000', fontSize: '18px' }} style={{ fontFamily: 'Kanit' }}>
																			<b>{item.product_ref.productTG_name}</b>
																			<br />
																			ราคา : {item.product_ref.productTG_price.price} บาท
																			{/* <br /> */}
																			{/* <Chip label={`มี ${item.productShop_stock} ชิ้น`} style={{ fontFamily: 'Kanit', fontSize: '12px' }} /> */}
																		</Item3>
																	</Grid>
																</Grid>
															))}
														</Grid>
													</ScrollPanel>
												</Item2>
											</Grid>
										</Grid>
										<Grid item xs={7}>
											<Grid className="form">
												<Grid container>
													<Grid sx={{ width: '50%', paddingRight: 1 }}>
														<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ยอดรวมสุทธิ</Item>
														<Item2 sx={{ width: '100%', fontSize: '400%', color: '#ffff', borderTopRightRadius: '0', borderTopLeftRadius: '0', textShadow: '3px 2px 4px #000000' }}>{calculateTotal() - discount}</Item2>
													</Grid>
													<Grid sx={{ width: '50%', paddingRight: 1 }}>
														<div className='button-family-2'>
															<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.7rem' }}>
																<Item3 sx={{ padding: 0, backgroundColor: 'transparent', boxShadow: 'none' }}>
																	<FloatLabel>
																		<InputText className="custom-InputText" id="reftel" value={reftel} onChange={(e) => setReftel(e.target.value)} disabled={refteldisable} />
																		<label htmlFor="reftel">ทศกัณฐ์แฟมมิลี่</label>
																	</FloatLabel>
																</Item3>
																<Item3 sx={{ padding: 0 }}>
																	{!refteldisable && (
																		<Button className="custom-button" label="ทศกัณฐ์แฟมมิลี่" onClick={headleCheckReftel} />
																	)}
																	{refteldisable && (
																		<Button className="custom-button" label="เปลี่ยน" onClick={headlerClearReftel} />
																	)}
																</Item3>
															</Stack>
															<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.5rem', width: '100%' }}>
																<Item3 sx={{ padding: 0 }}>
																	<Button className="custom-button" label="หน้าจอสำหรับลูกค้า" icon="pi pi-user" />
																</Item3>
															</Stack>
															<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap">
																<Item3 sx={{ padding: 0, width: '40%' }}>
																	<Button className="custom-button-2" label="ส่วนลดในบิล" onClick={headleDiscount} />
																</Item3>
																<Item3 sx={{ padding: 0, width: '40%' }}>
																	<Button className="custom-button-2" label="ชำระเงิน" icon="pi pi-check" onClick={heandleCheckOut} />
																</Item3>
																{/* <Item3 sx={{ padding: 0, width: '100%', height: '100%' }}> */}
																{/* <Button className="custom-button-2" label="ชำระเงิน" icon="pi pi-check" onClick={heandleCheckOut} /> */}
																{/* </Item3> */}
															</Stack>
														</div>
													</Grid>
												</Grid>
											</Grid>
											<Grid className="form">
												<Grid container>
													<Grid sx={{ width: '100%', paddingRight: 1 }}>
														<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ทั้งหมด : {order.length} รายการ</Item>
														<Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
															<DataTable value={order} style={{ fontFamily: 'Kanit' }}>
																<Column header="ชื่อสินค้า" body={(e) => (
																	<div>
																		{e.product_ref.productTG_name}
																	</div>
																)}></Column>
																<Column header="ราคา/หน่วย" body={(e) => (
																	<div>
																		{numberFormat(e.product_ref.productTG_price.price == null ? '' : e.product_ref.productTG_price.price)}
																	</div>
																)}></Column>
																<Column header="จำนวน" field="amount">
																</Column>
																<Column header="รวม" body={(e) => (
																	<div>
																		{numberFormat(e.total == null ? '' : e.total)}
																	</div>
																)}></Column>
																<Column header="ตัวเลือก" body={(e) => (
																	<div>
																		<Button icon="pi pi-trash" className="p-button-rounded p-button-outlined"
																			style={{ color: 'red', marginLeft: '0.5rem' }} onClick={deleteProduct(e)} />
																	</div>
																)}>
																</Column>
															</DataTable>
														</Item2>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>

			{/* Dialog Discount */}
			<Dialog header="ส่วนลด" visible={dialogDiscount} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogDiscount) return; setDialogDiscount(false); }}
				breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ marginTop: '1rem' }}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="discount" value={discount} onChange={(e) => setDiscount(e.value)} />
							<label htmlFor="moneyreceive">ส่วนลดในบิล</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="ยืนยัน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmDiscount} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={onHideDialogDiscount} />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>

			{/* Dialog Checkout */}
			<Dialog header="ชำระเงิน" visible={dialogCheckOut} style={{ width: '30vw', fontFamily: 'Kanit' }} onHide={() => onHideDialogCheckout(false)}
				breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ marginTop: '1rem' }}>
						<FloatLabel className="custom-InputText">
							<Dropdown value={paymenttype} onChange={(e) => headlerPaymentType(e, e.value)} options={['เงินสด', 'เงินโอน', 'บัตรเครดิต', 'อิ่นๆ']}
								className="custom-InputText" />
							<label htmlFor="moneyreceive">เลือกประเภทการชำระ</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12} style={{ marginTop: '1rem' }}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="moneyreceive" value={moneyreceive} onChange={(e) => setMoneyReceive(e.value)}
								disabled={disabledMoneyReceive} />
							<label htmlFor="moneyreceive">กรอกเงินที่รับมา</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="ชำระเงิน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmCheckout} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={onHideDialogCheckout} />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>

			{/* Dialog Receipt */}
			<Dialog header="เงินทอน" visible={dialogReceipt} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogReceipt) return; setDialogReceipt(false); }}
				breakpoints={{ '1680px': '30vw', '960px': '60vw', '641px': '70vw', '430px': '80vw' }} closable={false} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12}>
						<Card className="border-1 shadow-none border-gray-400" style={{ padding: '0' }}>
							<h1>
								{numberFormat(change == null ? '' : change)}
							</h1>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<div>
							<Button label="ใบเสร็จรับเงิน" style={{ width: '100%' }} onClick={headlerPrintReceipt} />
							{/* <ReceiptExpress /> */}
						</div>
					</Grid>
					<Grid item xs={6}>
						<div>
							<Button label="ทำรายรายต่อ" style={{ width: '100%' }} onClick={heandleResetOrder} />
						</div>
					</Grid>
					<Grid item xs={6}>
						<div>
							<Button label="หน้าหลัก" style={{ width: '100%' }} onClick={heandleResetPage} />
						</div>
					</Grid>
					<div style={{ display: 'none' }}>
						<div ref={componentReceipt} style={{ fontSize: '10px', textAlign: 'center', padding: '0', fontFamily: 'Kanit' }}>
							{/* <ReceiptArtwork data={resp} /> */}
							<ReceiptPOS data={resp} />
						</div>
					</div>
				</Grid>
			</Dialog>

			<LoadingDialog isLoading={isLoading} />
		</div>
	)
}