import React, { useState, useRef } from "react";
import axios from "axios";

import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { styled } from '@mui/material/styles';

import { useReactToPrint } from "react-to-print";

import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { ButtonGroup } from 'primereact/buttongroup';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Card } from "primereact/card";

import LoadingDialog from "../../../views/LoadingView";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json());
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json());
};

async function getProductGame() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/service/game`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json());
};

async function GetMember(reftel) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/member/check/${reftel}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Item2 = styled(Paper)(({ theme }) => ({
	backgroundColor: '#ECD400',
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Item3 = styled(Paper)(({ theme }) => ({
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	flexGrow: 2,
}));

export default function TermGameView() {
	const [isLoading, setisLoading] = useState(false);
	const [position, setPosition] = useState({});
	const [member, setMember] = useState({});
	const [employee, setEmployee] = useState({});
	const [shop, setShop] = useState({});

	// Product
	const [img, setImg] = useState("https://peamsub24hr.online/")
	const [product, setProduct] = useState([]);

	// Customer
	const [name, setName] = useState('');
	const [tel, setTel] = useState('');
	const [uid, setUID] = useState('');

	// ทศกัณฐ์แฟมมิลี
	const [reftel, setReftel] = useState('');
	const [refteldisable, setReftelDisable] = useState(false);

	// detail
	const [dialogDetail, setDialogDetail] = useState(false);
	const [detail, setDetail] = useState({});
	const [amount, setAmount] = useState(1);

	// Order
	const [order_termgame, setOrderTermgame] = useState([]);

	// ชำระเงิน
	const [dialogDiscount, setDialogDiscount] = useState(false);
	const [dialogCheckOut, setDialogCheckout] = useState(false);
	const [paymenttype, setPaymentType] = useState('');
	const [moneyreceive, setMoneyReceive] = useState(0);
	const [disabledMoneyReceive, setDisabledMoneyReceive] = useState(false);
	const [change, setChange] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [resp, setResp] = useState({});

	const [dialogReceipt, setDialogReceipt] = useState(false);

	const componentReceipt = useRef();
	const headlerPrintReceipt = useReactToPrint({
		content: () => componentReceipt.current,
	});

	useState(async () => {
		const response = await getMe();
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setPosition('One Stop Platform')
			setMember(response.data)
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				setPosition('One Stop Service')
				setEmployee(response.data);
				setShop(shop.data);
				// Product
				const product = await getProductGame();
				setProduct(product.data);
			}
		}
	});

	const headleGetDetail = async (e, item) => {
		setDetail(item);
		setDialogDetail(true);
	};

	const onHideDialogDetail = () => {
		setDialogDetail(false);
	};

	const headleAddOrder = () => {
		if (name === "" ||
			tel === "" ||
			uid === ""
		) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 1500,
			});
			return false;
		}
		const order = {
			...detail,
			type_code: Number(detail.type_code),
			price: Number(detail.price),
			discount: Number(detail.price),
			amount: 1,
			cus_name: name,
			cus_tel: tel,
			uid: uid,
		};
		setOrderTermgame([...order_termgame, order]);
		setDialogDetail(false);
		clearDataCus();
		console.log(order)
	};

	// Check Out
	const calculateTotal = () => {
		return order_termgame.reduce((total, item) => total + item.type_code, 0);
	};

	const headleCheckReftel = async () => {
		if (reftel === "") {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกทศกัฐ์แฟมมิลี่',
				icon: 'warning',
				showConfirmButton: false,
				timer: 1500,
			});
			return false;
		} else {
			const response = await GetMember(reftel);
			if (response.status) {
				Swal.fire({
					title: "ทศกัณฐ์แฟมมิลี่?",
					icon: "success",
					text: `เบอร์โทรศัพท์ ${response.data.tel} คุณ ${response.data.fristname} ${response.data.lastname}`,
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "ยืนยัน",
					cancelButtonText: "ยกเลิก",
				}).then((result) => {
					if (result.isConfirmed) {
						setReftelDisable(true);
					}
				})
			} else {
				Swal.fire({
					title: 'ทศกัณฐ์แฟมมิลี่!',
					text: 'ไม่พบข้อมูลทศกัณฐ์แฟมมิลี่',
					icon: 'error',
					showConfirmButton: false,
					timer: 3000,
				});
				return false;
			}
		}
	};
	
	const headlerClearReftel = async () => {
		Swal.fire({
			title: "เปลี่ยนทศกัณฐ์แฟมมิลี่?",
			icon: "warning",
			text: `คุณต้องการเปลี่ยนทศกัณฐ์แฟมมิลี่ ใช่หรือไม่?`,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "ยืนยัน",
			cancelButtonText: "ยกเลิก",
		}).then((result) => {
			if (result.isConfirmed) {
				setReftelDisable(false);
				setReftel('');
			}
		})
	};

	const headleDiscount = () => {
		if (calculateTotal() === 0) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ยอดรวมรวมไม่เพียงพอต่อการกรอกส่วนลด',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		} else {
			setDialogDiscount(true);
		}
	};
	const headleConfirmDiscount = () => {
		if (discount >= calculateTotal()) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ส่วนลดมากกว่ายอดที่ต้องชำระ ไม่สามารถทำรายการได้',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			setDialogDiscount(false);
			setDiscount(0);
			return false;
		} else {
			setDialogDiscount(false);
		}
	};

	const onHideDialogDiscount = () => {
		setDiscount(0);
		setDialogDiscount(false);
	};

	const heandleCheckOut = async () => {
		if (order_termgame.length === 0) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ไม่มีรายการให้ชำระเงิน กรุณาเลือกสินค้าเพื่อทำรายการ',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
	};

	const headlerPaymentType = (e, value) => {
		if (value === 'เงินสด') {
			setPaymentType(value);
			setMoneyReceive(0);
			setDisabledMoneyReceive(false);
		} else {
			setPaymentType(value);
			setDisabledMoneyReceive(true);
			setMoneyReceive(calculateTotal());
		}
	};
	const onHideDialogCheckout = () => {
		setDialogCheckout(false);
	};

	const headleConfirmCheckout = async () => {
		if (moneyreceive < (calculateTotal() - discount)) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'จำนวนเงินที่กรอก ไม่เพียงพอต่อการชำระเงิน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
	};

	function clearDataCus() {
		setName("");
		setTel("");
		setUID("");
	};

	return (
		<div>
			{position !== 'One Stop Platform' && (
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Box>
								<Grid container className={"container"}>
									<div className="title" ><h1>เติมเกมออนไลน์ (TermGame)</h1></div>
									<Box sx={{ width: '100%' }}>
										<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
											<Grid item xs={5}>
												<Grid className="form">
													<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>รายการสินค้า</Item>
													<Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
														<ScrollPanel className="scroll-premium">
															<Grid container spacing={1} sx={{ padding: 0 }} columnSpacing={{ xs: 3 }}>
																{product.slice().map((item, index) => (
																	<Grid item xs={4} sx={{ width: '50%', height: '100%' }} key={index}>
																		<Grid className='step2item' onClick={(e) => headleGetDetail(e, item)}>
																			<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', height: '100%' }}>
																				<img src={img + item.img} alt={item.c_type} style={{ width: '70%' }} />
																			</Item3>
																			<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#000000', fontSize: '18px' }}>
																				<b>{item.name}</b>
																				<br />
																				ราคา : {item.type_code} บาท
																			</Item3>
																		</Grid>
																	</Grid>
																))}
															</Grid>
														</ScrollPanel>
													</Item2>
												</Grid>
											</Grid>
											<Grid item xs={7}>
												<Grid className="form">
													<Grid container>
														<Grid sx={{ width: '50%', paddingRight: 1 }}>
															<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ยอดรวมสุทธิ</Item>
															<Item2 sx={{
																width: '100%', fontSize: '400%', color: '#ffff', borderTopRightRadius: '0',
																borderTopLeftRadius: '0', textShadow: '3px 2px 4px #000000'
															}}>{(calculateTotal() - discount)}</Item2>
														</Grid>
														<Grid sx={{ width: '50%', paddingRight: 1 }}>
															<div>
																<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.5rem', width: '100%' }}>
																	<Item3 sx={{ padding: 0 }}>
																		<FloatLabel>
																			<InputText className="w-full" id="reftel" value={reftel} onChange={(e) => setReftel(e.target.value)}
																				disabled={refteldisable} />
																			<label htmlFor="reftel">ทศกัณฐ์แฟมมิลี่</label>
																		</FloatLabel>
																	</Item3>
																	<Item3 sx={{ padding: 0 }}>
																		{!refteldisable && (
																			<Button className="custom-button" label="ทศกัณฐ์แฟมมิลี่" onClick={headleCheckReftel} />
																		)}
																		{refteldisable && (
																			<Button className="custom-button" label="เปลี่ยน" onClick={headlerClearReftel} />
																		)}
																	</Item3>
																</Stack>
																<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.5rem', width: '100%' }}>
																	<Item3 sx={{ padding: 0 }}>
																		<Button className="custom-button" label="หน้าจอสำหรับลูกค้า" icon="pi pi-user" />
																	</Item3>
																</Stack>
																<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap">
																	<Item3 sx={{ padding: 0, width: '40%' }}>
																		<Button className="custom-button-2" label="ส่วนลดในบิล" onClick={headleDiscount} />
																	</Item3>
																	<Item3 sx={{ padding: 0, width: '40%' }}>
																		<Button className="custom-button-2" label="ชำระเงิน" icon="pi pi-check" onClick={heandleCheckOut} />
																	</Item3>
																</Stack>
															</div>
														</Grid>
													</Grid>
												</Grid>
												<Grid className="form">
													<Grid container>
														<Grid sx={{ width: '100%', paddingRight: 1 }}>
															<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ทั้งหมด : {order_termgame.length} รายการ</Item>
															<Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
																<DataTable value={order_termgame} style={{ fontFamily: 'Kanit' }}>
																	<Column style={{ width: '15%' }} body={(rowData) => (
																		<img src={img + rowData.img} alt={rowData.name} style={{ width: '100%' }} />
																	)}></Column>
																	<Column header="ผู้ทำรายการ" style={{ width: '25%' }} body={(rowData) => (
																		<div>
																			{rowData.cus_name} - {rowData.cus_tel}
																			<br />
																			({rowData.uid})
																		</div>
																	)}></Column>
																	<Column header="รายละเอียด" style={{ width: '25%' }} body={(rowData) => (
																		<div dangerouslySetInnerHTML={{ __html: rowData.info }}></div>
																	)}></Column>
																	<Column field="type_code" header="ราคา" style={{ width: '15%' }}></Column>
																	<Column field="amount" header="จำนวน"></Column>
																	<Column style={{ width: '20%' }} body={(rowData) => (
																		<div>
																			<Button icon="pi pi-trash" className="p-button-rounded p-button-outlined"
																				style={{ color: 'red' }} />
																		</div>
																	)}></Column>
																</DataTable>
															</Item2>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Box>
			)}

			{/* Dialog Detail */}
			<Dialog header="รายละเอียด" visible={dialogDetail} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => onHideDialogDetail(false)} breakpoints={{ '1680px': '50vw', '641px': '100vw', '430px': '80vw' }} >
				{detail && (
					<div style={{ marginBottom: '1rem' }}>
						<b>ชื่อสินค้า : {detail.name}</b><br></br>
						ประเภท : {detail.category}
						<br /><br />
						ราคาสุทธิ : {numberFormat(detail.type_code == null ? '' : detail.type_code)} บาท
						{/* รายละเอียด : {detail.info} */}
						<div></div><br></br>
						<div dangerouslySetInnerHTML={{ __html: detail.info }}></div>
						<div></div><br></br>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
							<Grid item xs={6}>
								<FloatLabel className="custom-InputText">
									<InputText className="custom-InputText" id="username" value={name} onChange={(e) => setName(e.target.value)} />
									<label htmlFor="username">ชื่อ-นามสกุล</label>
								</FloatLabel>
							</Grid>
							<Grid item xs={6}>
								<FloatLabel className="custom-InputText">
									<InputText className="custom-InputText" id="tel" value={tel} onChange={(e) => setTel(e.target.value)} />
									<label htmlFor="tel">เบอร์โทร</label>
								</FloatLabel>
							</Grid>
							<Grid item xs={12}>
								<FloatLabel className="custom-InputText">
									<InputText className="custom-InputText" id="line" value={uid} onChange={(e) => setUID(e.target.value)} />
									<label htmlFor="line">UID ของเกม</label>
								</FloatLabel>
							</Grid>
						</Grid>
						<div></div><br></br>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
							<Grid item xs={12}>
								<label htmlFor="sqm-price" className="font-bold block mb-2">จำนวน : &emsp;</label>
								<InputNumber inputId="sqm-price" value={amount} onValueChange={(e) => setAmount(e.value)}
									mode="decimal" showButtons min={1} disabled />
							</Grid>
						</Grid>
					</div>
				)}
				<div style={{ flex: '1' }}></div>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
					<ButtonGroup>
						<Button label="เพิ่มลงตะกล้า" icon="pi pi-check" className='add-cart' onClick={headleAddOrder} />
						<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={() => setDialogDetail(false)} />
					</ButtonGroup>
				</div>
			</Dialog>

			{/* Dialog Discount */}
			<Dialog header="ส่วนลด" visible={dialogDiscount} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogDiscount) return; setDialogDiscount(false); }}
				breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ marginTop: '1rem' }}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="discount" value={discount} onChange={(e) => setDiscount(e.value)} />
							<label htmlFor="moneyreceive">ส่วนลดในบิล</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="ยืนยัน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmDiscount} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={onHideDialogDiscount} />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>

			{/* Dialog Checkout */}
			<Dialog header="ชำระเงิน" visible={dialogCheckOut} style={{ width: '30vw', fontFamily: 'Kanit' }} onHide={() => onHideDialogCheckout(false)}
				breakpoints={{ '1680px': '30vw', '641px': '60vw', '430px': '80vw' }} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12}>
						<Dropdown value={paymenttype} onChange={(e) => headlerPaymentType(e, e.value)} options={['เงินสด', 'เงินโอน', 'บัตรเครดิต', 'อิ่นๆ']}
							placeholder="เลือกประเภทการชำระ" className="custom-InputText" />
					</Grid>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="moneyreceive" value={moneyreceive} onChange={(e) => setMoneyReceive(e.value)}
								disabled={disabledMoneyReceive} />
							<label htmlFor="moneyreceive">กรอกเงินที่รับมา</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="ชำระเงิน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmCheckout} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={onHideDialogCheckout} />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>
		</div>
	)
};