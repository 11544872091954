import React, { useState } from "react";
import "./order.css";
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Panel } from 'primereact/panel';

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getOrderAoc(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/order/service/aoc/${packageId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getFlightBooking(packageData) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/aoc/get/flight/booking`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json())
};

function datetimeFormat(date) {
	return dayjs(date).format("DD/MM/YYYY เวลา HH:mm:ss น.");
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export default function OrderTricket() {
	const [position, setPosition] = useState("");
	const [member, setMember] = useState({});

	const [visibleDetail, setVisibleDetail] = useState(false);
	const [detail, setDetail] = useState("");

	const [order, setOrderAoc] = useState([]);

	useState(async () => {
		const response = await getMe();
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setPosition('One Stop Platform')
			setMember(response.data)
			const order_aoc = await getOrderAoc(response.data._id);
			setOrderAoc(order_aoc.data);
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
			}
		}
	});

	const totalTemplate = (item) => {
		return `${numberFormat(item.total)} บาท`;
	};

	const statusTemplate = (item) => {
		const name = item.status[item.status.length - 1].name;
		return <Chip label={name} style={{ backgroundColor: statusColor(name), color: 'white', fontFamily: 'Kanit' }} />
	};

	const statusColor = (item) => {
		switch (item) {
			case 'รอดำเนินการออกตั๋ว': return '#9400D3';
			default: return 'danger';
		}
	}

	const timeTemplate = (item) => {
		return datetimeFormat(item.timestamp);
	};

	const headleDetail = (value) => async e => {
		setVisibleDetail(true);
		console.log(value)
		setDetail(value);
		const data = {
			bookingKeyReference: value.transaction_id
		};
		const resq = await getFlightBooking(data);
		console.log(resq)
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"order-container"}>
								<div className="title" ><h1>รายการจองตั๋วเครื่องบิน (Order Tricket)</h1></div>
								<Box sx={{ width: '100%' }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item className="order-model">
											<DataTable value={order} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100]} style={{ fontFamily: 'Kanit' }}>
												<Column header="เลขที่ใบเสร็จ" body={(rowData) => (
													<div>
														<Chip label={rowData.invoice} style={{ fontFamily: 'Kanit' }} />
													</div>
												)}></Column>
												<Column field="transaction_id" header="เลขอ้างอิง"></Column>
												<Column header="ยอดทำรายการ" body={totalTemplate}></Column>
												<Column header="สถานะ" body={statusTemplate}></Column>
												<Column header="วันที่" body={timeTemplate}></Column>
												<Column header="ตัวเลือก" body={(rowData) => (
													<div className="p-d-flex p-jc-center">
														<Button outlined icon="pi pi-align-center" severity="help"
															label="รายละเอียด" style={{ fontFamily: 'Kanit' }} onClick={headleDetail(rowData)} />
													</div>
												)}>
												</Column>
											</DataTable>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<Sidebar visible={visibleDetail} position="right" onHide={() => setVisibleDetail(false)} className="order-visible">
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ fontFamily: 'Kanit' }}>
						<h2>เลขที่ใบเสร็จ : {detail.invoice}</h2>
						วันเวลาที่สั่ง : {datetimeFormat(detail.timestamp)}
						<div></div><br></br>
					</Grid>
				</Grid>
			</Sidebar>
		</div>
	)
}