import React, { useState } from "react";
import Swal from 'sweetalert2';
import axios from "axios";
import '../styles/login.css';
import Link from '@mui/material/Link';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import LoadingDialog from "./LoadingView";

async function loginUser(credentials) {
    return fetch(`${process.env.REACT_APP_SHOP}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }).then(data => data.json())
};


export default function LoginView({ setToken }) {
    const [isLoading, setisLoading] = useState(false);

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null);

    const onButtonEnter = async e => {
        if (e.key === 'Enter') {
            if (username === '') {
                Swal.fire({
                    title: 'แจ้งเตือน',
                    icon: 'warning',
                    text: 'กรุณากรอกยูสเซอร์เนม',
                    showConfirmButton: false,
                    timer: 3000,
                });
                return false;
            }
            if (password === '') {
                Swal.fire({
                    title: 'แจ้งเตือน',
                    icon: 'warning',
                    text: 'กรุณากรอกรหัสผ่าน',
                    showConfirmButton: false,
                    timer: 3000,
                });
                return false;
            }
            e.preventDefault();
            setisLoading(true);
            const security = await axios.get("https://api.ipify.org/?format=json");
            const response = await loginUser({
                username,
                password,
                ip_address: security.data.ip,
            });
            if (response.status === true) {
                if (response.level === 'member') {
                    Swal.fire({
                        title: 'เข้าสู่ระบบสำเร็จ',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000,
                    }).then(() => {
                        setisLoading(false);
                        setToken(response.token);
                        localStorage.setItem('position', response.result.position)
                    })
                } else if (response.level === 'employee') {
                    if (!response.result.status) {
                        setisLoading(false);
                        Swal.fire({
                            title: 'เข้าสู่ระบบไม่สำเร็จ',
                            icon: 'error',
                            text: 'คุณไม่สามารถเข้าใช้งานได้ กรุณาติดต่อเจ้าของร้าน',
                            showConfirmButton: false,
                            timer: 3000,
                        });
                        return false;
                    }
                    axios.get(`${process.env.REACT_APP_SHOP}/shop/${response.result.shop_id}`, {
                        headers: {
                            'auth-token': `Bearer ${response.token}`
                        }
                    }).then((res) => {
                        setisLoading(false);
                        Swal.fire({
                            title: 'เข้าสู่ระบบสำเร็จ',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 3000,
                        }).then(() => {
                            setToken(response.token);
                        })
                    })
                } else {
                    setisLoading(false);
                    Swal.fire({
                        title: 'เข้าสู่ระบบไม่สำเร็จ',
                        icon: 'error',
                        text: 'คุณไม่สามารถเข้าใช้งานได้',
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    return false;
                }
            } else {
                setisLoading(false);
                Swal.fire({
                    title: 'เข้าสู่ระบบไม่สำเร็จ',
                    icon: 'error',
                    text: `เข้าสู่ระบบไม่สำเร็จ ${response.message}`,
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        }
    }

    const onButtonClick = async e => {
        if (username === '') {
            Swal.fire({
                title: 'แจ้งเตือน',
                icon: 'warning',
                text: 'กรุณากรอกยูสเซอร์เนม',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        if (password === '') {
            Swal.fire({
                title: 'แจ้งเตือน',
                icon: 'warning',
                text: 'กรุณากรอกรหัสผ่าน',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        e.preventDefault();
        setisLoading(true);
        const security = await axios.get("https://api.ipify.org/?format=json");
        const response = await loginUser({
            username,
            password,
            ip_address: security.data.ip,
        });
        if (response.status === true) {
            if (response.level === 'member') {
                Swal.fire({
                    title: 'เข้าสู่ระบบสำเร็จ',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000,
                }).then(() => {
                    setisLoading(false);
                    setToken(response.token);
                    localStorage.setItem('position', response.result.position)
                })
            } else if (response.level === 'employee') {
                if (!response.result.status) {
                    setisLoading(false);
                    Swal.fire({
                        title: 'เข้าสู่ระบบไม่สำเร็จ',
                        icon: 'error',
                        text: 'คุณไม่สามารถเข้าใช้งานได้ กรุณาติดต่อเจ้าของร้าน',
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    return false;
                }
                axios.get(`${process.env.REACT_APP_SHOP}/shop/${response.result.shop_id}`, {
                    headers: {
                        'auth-token': `Bearer ${response.token}`
                    }
                }).then((res) => {
                    setisLoading(false);
                    Swal.fire({
                        title: 'เข้าสู่ระบบสำเร็จ',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000,
                    }).then(() => {
                        setToken(response.token);
                    })
                })
            }
        } else {
            setisLoading(false);
            Swal.fire({
                title: 'เข้าสู่ระบบไม่สำเร็จ',
                icon: 'error',
                text: `เข้าสู่ระบบไม่สำเร็จ ${response.message}`,
                showConfirmButton: false,
                timer: 3000,
            });
        }
    };

    return (
        <div>
            <div className={'containerlogin'}>
                <div className={'logo'}>
                    <div>
                        <Image src={require('../assets/logo.png')} alt="logo" width="200" />
                    </div>
                </div>
                <br />
                <div className={'containerinput'}>
                    <div className={'inputtext'}>
                        <FloatLabel>
                            <InputText id="username" value={username} type="text" onChange={(e) => setUsername(e.target.value)} onKeyUp={onButtonEnter} />
                            <label htmlFor="username">Username</label>
                        </FloatLabel>
                    </div>
                    <div className={'inputtext'}>
                        <FloatLabel>
                            <InputText id="password" value={password} type="password" onChange={(e) => setPassword(e.target.value)} onKeyUp={onButtonEnter} />
                            <label htmlFor="password">Password</label>
                        </FloatLabel>
                        <FloatLabel>
                            <Link href="/forgetpassword" color="inherit">
                                <p style={{ textAlign: 'right' }}>ลืมรหัสผ่าน?</p>
                            </Link>
                        </FloatLabel>
                    </div>
                </div>
                <div>
                    <Button label="เข้าสู่ระบบ" onClick={onButtonClick} />
                </div>
                <br />
                <div>
                    <Link href="/register">
                        <p>สมัครสมาชิก</p>
                    </Link>
                    {/* <Button label="เข้าสู่ระบบ" onClick={onButtonClick} /> */}
                </div>
            </div>

            <LoadingDialog isLoading={isLoading} />
        </div>
    )
}