import React, { useState } from "react";
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from "axios";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Dialog } from "primereact/dialog";
import { Image } from 'primereact/image';
import { FileUpload } from 'primereact/fileupload';
import { ButtonGroup } from "primereact/buttongroup";
import { Sidebar } from "primereact/sidebar";
import { Card } from 'primereact/card';

import LoadingDialog from "../../../views/LoadingView";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getEmployee(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/employee/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getInvoiceShop(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/invoice/shop/shop/${packageId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

function datetimeFormat(date) {
	return dayjs(date).format("DD/MM/YYYY เวลา hh:mm:ss น.");
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

function calTotal(item) {
	const total = item.reduce((sum, d) => sum + d.summary_cost, 0);
	return total;
};

function colorStatus(status) {
	switch (status) {
		case 'ชำระสำเร็จ':
			return 'green';
		case 'รอตรวจสอบ':
			return 'purple';
		case 'ค้างชำระ':
			return 'orange';
		default:
			return 'red';
	}
};

export default function InvoicePOSShop() {
	const [isLoading, setisLoading] = useState(false);
	const [employee, setEmployee] = useState([]);

	const [shop, setShop] = useState("");
	const [me, setMe] = useState("");
	const [emp, setEmp] = useState("");

	const [order_invoice, setOrderInvoice] = useState([]);
	const [orders_invoice, setOrdersInvoice] = useState([]);

	const [detail, setDetail] = useState("");
	const [visibleDetail, setVisibleDetail] = useState(false);

	// Upload Image
	const [dialogUpload, setDialogUpload] = useState(false);
	const [image_preview, setImagePreview] = useState("https://cdn-icons-png.flaticon.com/512/126/126477.png");
	const [image_upload, setImageUpload] = useState(null);

	useState(async () => {
		const response = await getMe();
		setisLoading(true);
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			Swal.fire({
				title: "แจ้งเตือน!",
				text: "ผู้ใช้งานดังกล่าว ไม่สามารถใช้งานระบบนี้ได้!",
				icon: "warning",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "ยืนยัน",
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = "/";
				}
			})
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				setisLoading(false);
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				setisLoading(false);
				setMe(response.data);
				setEmp(response.data);
				setShop(shop.data);

				// พนักงานทั้งหมดภายในร้าน
				const emp = await getEmployee(shop.data._id);
				setEmployee(emp.data);

				const resp = await getInvoiceShop(shop.data._id);
				setOrderInvoice(resp.data.reverse());
				setOrdersInvoice(resp.data);
			}
		}
	});

	const headleDetail = (value) => async e => {
		setDetail(value);
		setVisibleDetail(true);
	};

	const headlePayment = (value) => async e => {
		setDetail(value);
		setDialogUpload(true);
	};

	const headleUploadImage = async (event) => {
		setImagePreview(event.files[0].objectURL);
		setImageUpload(event.files[0])
	};

	const removeImage = e => {
		setImagePreview("https://cdn-icons-png.flaticon.com/512/126/126477.png");
		setImageUpload(null);
	};

	const confirmUpload = e => {
		if (image_upload === null) {
			setDialogUpload(false);
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณาแนบรูปภาพหลักฐานการชำระเงิน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		setDialogUpload(false);
		Swal.fire({
			title: "แจ้งชำระเงินต้นทุน?",
			icon: "question",
			text: `ยืนยันการแจ้งชำระเงินต้นทุนตามใบแจ้งหนี้เลขที่ ${detail.invoice_ref} ? `,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "ยืนยัน",
			cancelButtonText: "ยกเลิก",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setisLoading(true);

				const formData = new FormData();
				formData.append('invoice_image', image_upload);
				formData.append('invoice_status', 'รอตรวจสอบ');

				const token = JSON.parse(localStorage.getItem('token'));
				const res = await axios.put(`${process.env.REACT_APP_SHOP}/invoice/shop/${detail._id}`, formData, {
					headers: {
						'auth-token': `Bearer ${token}`
					}
				});
				if (res.data.status) {
					setisLoading(false);
					Swal.fire({
						title: 'สำเร็จ!',
						text: 'แจ้งชำระเงินต้นทุนเรียบร้อยแล้ว รอการตรวจสอบจากส่วนกลาง',
						icon: 'success',
						showConfirmButton: false,
						timer: 3000,
					});
					const resp = await getInvoiceShop(shop._id);
					setOrderInvoice(resp.data.reverse());
					setOrdersInvoice(resp.data);
				} else {
					setisLoading(false);
					setDialogUpload(true);
					Swal.fire({
						title: 'แจ้งเตือน!',
						text: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
						icon: 'error',
						showConfirmButton: false,
						timer: 3000,
					});
					return false;
				}
			}
		})
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"order-container"}>
								<div className="title" >
									<h1>ใบแจ้งหนี้</h1>
									<p><em>ใบแจ้งหนี้ปิดงานร้านค้าประจำวัน</em></p>
								</div>
							</Grid>
							<Box sx={{ width: '100%', height: 'auto' }}>
								<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
									<Grid item className="order-model" style={{ marginTop: '2rem' }}>
										<DataTable value={order_invoice} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100]} style={{ fontFamily: 'Kanit' }}>
											<Column header="เลขที่ใบแจ้งหนี้" style={{ width: '20%' }} body={(item) => (
												<Chip label={item.invoice_ref} style={{
													fontFamily: 'Kanit',
													paddingLeft: '1rem', paddingRight: '1rem',
												}} />
											)}></Column>
											<Column header="รายการ" body={(item) => (
												<div>
													{item.invoice_detail.length}
												</div>
											)}></Column>
											<Column header="ยอดชำระ" body={(item) => (
												<div>
													{calTotal(item.invoice_detail)}
												</div>
											)}></Column>
											<Column header="วันที่ออกใบแจ้งหนี้" body={(item) => (
												<div>
													{datetimeFormat(item.invoice_timestamp)}
												</div>
											)}></Column>
											<Column header="สถานะ" body={(item) => (
												<Chip label={item.invoice_status} style={{
													fontFamily: 'Kanit',
													paddingLeft: '1rem',
													paddingRight: '1rem',
													color: 'white',
													backgroundColor: colorStatus(item.invoice_status)
												}} />
											)}></Column>
											<Column header="เพิ่มเติม" body={(item) => (
												<div>
													{item.invoice_status === 'ค้างชำระ' && (
														<Button icon="pi pi-upload" label="แจ้งชำระเงิน"
															style={{ fontFamily: 'Kanit', color: 'white' }} onClick={headlePayment(item)} />
													)}
													{item.invoice_status !== 'ค้างชำระ' && (
														<Button icon="pi pi-list"
															style={{ fontFamily: 'Kanit', color: 'white' }} onClick={headleDetail(item)} />
													)}
												</div>
											)}></Column>
										</DataTable>
									</Grid>
								</Grid>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<Sidebar visible={visibleDetail} position="right" onHide={() => setVisibleDetail(false)} className="order-visible">
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ fontFamily: 'Kanit' }}>
						<h2>รายละเอียดใบแจ้งหนี้</h2>
						เลขที่ : {detail.invoice_ref}
					</Grid>
					<Grid item xs={4}>
						<Card style={{ backgroundColor: 'red' }}>
						</Card>
					</Grid>
					<Grid item xs={4}>
						<Card style={{ backgroundColor: 'green' }}>

						</Card>
					</Grid>
					<Grid item xs={4}>
						<Card style={{ backgroundColor: 'blue' }}>

						</Card>
					</Grid>
					<Grid item xs={12}>
						<DataTable value={detail.invoice_detail} style={{ fontFamily: 'Kanit' }}>
							<Column header="#" style={{ width: '10%' }} body={(item, { rowIndex }) => (
								<div>
									{rowIndex + 1}
								</div>
							)}>
							</Column>
							<Column header="รายการ" style={{ width: '30%' }} body={(item) => (
								<div className="p-d-flex p-jc-center">
									{item.name}
								</div>
							)}></Column>
							<Column header="ต้นทุน/หน่วย" style={{ width: '20%' }} body={(item) => (
								<div>
									{numberFormat(item.cost)}
								</div>
							)}></Column>
							<Column header="จำนวน" style={{ width: '20%' }} body={(item) => (
								<div>
									{item.amount}
								</div>
							)}></Column>
							<Column header="รวม" style={{ width: '20%' }} body={(item) => (
								<div>
									{numberFormat(item.summary_cost)}
								</div>
							)}></Column>
						</DataTable>
					</Grid>
				</Grid>
			</Sidebar>

			{/* Dialog Receipt */}
			<Dialog header="อัพโหลดรูปหลักฐานการชำระเงิน" visible={dialogUpload} style={{ width: '40vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogUpload) return; setDialogUpload(false); }}
				breakpoints={{ '1680px': '40vw', '960px': '60vw', '641px': '70vw', '430px': '80vw' }} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
						<Image src={image_preview} alt="" width="150" preview />
					</Grid>
					<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
						{image_upload === null && (
							<FileUpload mode="basic" customUpload accept="image/*" auto={true} chooseLabel="เลือกรูปภาพ"
								uploadHandler={headleUploadImage} />
						)}
						{image_upload !== null && (
							<Button label="เปลี่ยนรูป" icon="pi pi-trash" onClick={removeImage} />
						)}
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="ยืนยัน" icon="pi pi-check" className='btn-checkout' onClick={confirmUpload} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={() => { setDialogUpload(false); removeImage(); }} />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>

			<LoadingDialog isLoading={isLoading} />
		</div>
	)
};