import React, { useState } from "react";
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from "primereact/inputtext";
import { ButtonGroup } from "primereact/buttongroup";
import LoadingDialog from "../../views/LoadingView";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getBoxExpress(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/express/box/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function addBoxExpress(packageData) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/express/box`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json())
};

async function updateBoxExpress(packageData, packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/express/box/${packageId}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json())
};

async function deleteBoxExpress(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/express/box/${packageId}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

export default function BoxExpress() {
	const [isLoading, setisLoading] = useState(false);
	const [employee, setEmployee] = useState('');
	const [shop, setShop] = useState('');

	// product
	const [itemProduct, setItemProduct] = useState([]);
	const [dialogAdd, setDialogAdd] = useState(false);
	const [productName, setProductName] = useState('');
	const [width, setWidth] = useState(null);
	const [height, setHeight] = useState(null);
	const [length, setLength] = useState(null);
	const [cost, setCost] = useState(null);
	const [price, setPrice] = useState(null);

	// Detail
	const [dialogUpdate, setDialogUpdate] = useState(false);
	const [id, setId] = useState('');
	const [updateProductName, setUpdateProductName] = useState('');
	const [updateWidth, setUpdateWidth] = useState(null);
	const [updateHeight, setUpdateHeight] = useState(null);
	const [updateLength, setUpdateLength] = useState(null);
	const [updateCost, setUpdateCost] = useState(null);
	const [updatePrice, setUpdatePrice] = useState(null);

	useState(async () => {
		const response = await getMe();
		setisLoading(true);
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			Swal.fire({
				title: "แจ้งเตือน!",
				text: "ผู้ใช้งานดังกล่าว ไม่สามารถใช้งานระบบนี้ได้!",
				icon: "warning",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "ยืนยัน",
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = "/";
				}
			})
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				setisLoading(false);
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				setisLoading(false);
				setEmployee(response.data);
				setShop(shop.data);
				const box = await getBoxExpress(shop.data._id);
				setItemProduct(box.data);
			}
		}
	});

	const headleAddProduct = () => {
		setDialogAdd(true);
	};

	const headleConfirmAdd = async e => {
		const data = {
			shop_id: employee.employee_shop_id,
			name: productName,
			width: width,
			length: length,
			height: height,
			cost: cost,
			price: price,
			employee: employee._id,
		};
		setisLoading(true)
		setDialogAdd(false)
		const resp = await addBoxExpress(data)
		if (resp.status) {
			Swal.fire({
				title: 'สำเร็จ!',
				text: 'เพิ่มรายการสินค้าสำเร็จ',
				icon: 'success',
				showConfirmButton: false,
				timer: 3000,
			});
			setisLoading(false)
			clearData()
			setDialogAdd(false)
		} else {
			setDialogAdd(true)
		}
	};

	const headleDetail = (value) => e => {
		setId(value._id);
		setUpdateProductName(value.name);
		setUpdateWidth(value.width);
		setUpdateLength(value.length);
		setUpdateHeight(value.height);
		setUpdateCost(value.cost);
		setUpdatePrice(value.price);
		setDialogUpdate(true);
	};

	const confirmUpdate = async e => {
		const data = {
			name: updateProductName,
			width: updateWidth,
			length: updateLength,
			height: updateHeight,
			cost: updateCost,
			price: updatePrice,
		};
		setisLoading(true)
		setDialogUpdate(false)
		const resp = await updateBoxExpress(data, id);
		if (resp.status) {
			Swal.fire({
				title: 'สำเร็จ!',
				text: 'อัพเดทรายการสินค้าสำเร็จ',
				icon: 'success',
				showConfirmButton: false,
				timer: 3000,
			});
			setisLoading(false)
			setDialogUpdate(false)
		} else {
			setDialogUpdate(true)
		}
	};

	const deleteProduct = (value) => e => {
		if (employee.employee_position !== 'manager') {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ผู้ใช้งานดังกล่าว ไม่สามารถลบรายการนี้ได้!',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;

		} else {
			Swal.fire({
				title: "แจ้งเตือน!",
				text: "คุณต้องการลบรายการสินค้านี้!",
				icon: "question",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "ยืนยัน",
				cancelButtonText: "ยกเลิก",
			}).then(async (result) => {
				if (result.isConfirmed) {
					const resp = await deleteBoxExpress(value._id);
					if (resp.status) {
						Swal.fire({
							title: 'สำเร็จ!',
							text: 'คุณทำการลบสินค้าดังกล่าวสำเร็จ!',
							icon: 'success',
							showConfirmButton: false,
							timer: 3000,
						});
					}
				}
			})
		}
	};

	function clearData() {
		setProductName('');
		setWidth(null);
		setLength(null);
		setHeight(null);
		setCost(null);
		setPrice(null);
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className="order-container">
								<div className="title" ><h1>จัดการสินค้าและบริการสำหรับระบบขนส่ง</h1></div>
								<Box sx={{ width: '100%', height: 'auto' }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item className="order-model">
											<Button label="เพิ่มสินค้า" icon="pi pi-plus" onClick={headleAddProduct} />
											<DataTable value={itemProduct} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100]}
												style={{ fontFamily: 'Kanit', paddingTop: '1rem' }}>
												<Column field="name" header="ชื่อรายการ" />
												<Column field="width" header="กว้าง" />
												<Column field="length" header="ยาว" />
												<Column field="height" header="สูง" />
												<Column field="cost" header="ต้นทุน" />
												<Column field="price" header="ราคาขาย" />
												<Column header="ตัวเลือก" body={(rowData) => (
													<div>
														<Button icon="pi pi-align-justify" className="p-button-rounded p-button-outlined"
															onClick={headleDetail(rowData)} />
														<Button icon="pi pi-trash" className="p-button-rounded p-button-outlined"
															style={{ color: 'red', marginLeft: '0.5rem' }} onClick={deleteProduct(rowData)} />
													</div>
												)} />
											</DataTable>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>

			{/* Dialog AddProduct */}
			<Dialog header="เพิ่มสินค้า" visible={dialogAdd} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogAdd) return; setDialogAdd(false); }}
				breakpoints={{ '1680px': '30vw', '641px': '70vw', '430px': '80vw' }} closable={false} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputText className="custom-InputText" id="productName" value={productName} onChange={(e) => setProductName(e.target.value)} />
							<label htmlFor="productName">ชื่อสินค้า</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="width" value={width} onChange={(e) => setWidth(e.value)} />
							<label htmlFor="width">กว้าง</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="length" value={length} onChange={(e) => setLength(e.value)} />
							<label htmlFor="length">ยาว</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="height" value={height} onChange={(e) => setHeight(e.value)} />
							<label htmlFor="height">สูง</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="cost" value={cost} onChange={(e) => setCost(e.value)} />
							<label htmlFor="cost">ต้นทุน</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="price" value={price} onChange={(e) => setPrice(e.value)} />
							<label htmlFor="price">ราคาขาย</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="เพิ่มสินค้า" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmAdd} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>

			{/* Dialog UpdateProduct */}
			<Dialog header="อัพเดทสินค้า" visible={dialogUpdate} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogUpdate) return; setDialogUpdate(false); }}
				breakpoints={{ '1680px': '30vw', '641px': '70vw', '430px': '80vw' }} closable={false} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputText className="custom-InputText" id="updateProductName" value={updateProductName} onChange={(e) => setUpdateProductName(e.target.value)} />
							<label htmlFor="updateProductName">ชื่อสินค้า</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="updateWidth" value={updateWidth} onChange={(e) => setUpdateWidth(e.value)} />
							<label htmlFor="updateWidth">กว้าง</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="updateLength" value={updateLength} onChange={(e) => setUpdateLength(e.value)} />
							<label htmlFor="updateLength">ยาว</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="updateHeight" value={updateHeight} onChange={(e) => setUpdateHeight(e.value)} />
							<label htmlFor="updateHeight">สูง</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="updateCost" value={updateCost} onChange={(e) => setUpdateCost(e.value)} />
							<label htmlFor="updateCost">ต้นทุน</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="updatePrice" value={updatePrice} onChange={(e) => setUpdatePrice(e.value)} />
							<label htmlFor="updatePrice">ราคาขาย</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="เพิ่มสินค้า" icon="pi pi-check" className='btn-checkout' onClick={confirmUpdate} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={(e) => setDialogUpdate(false)} />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>

			<LoadingDialog isLoading={isLoading} />
		</div>
	)
}