// companyDisplayNames.js

const companyDisplayNames = {
  'Bangkok Buslines': 'บางกอกบัสไลน์',
  'Thai Sriram Transport Limited Partnership': 'ไทยศรีรามขนส่ง',
  'Budsarakam Tour': 'บุษราคัม ทัวร์',
  'Dee Travels Hatyai': 'ดี ทราเวล หาดใหญ่ (Dee Travels Hatyai)',
  'Piyachai Pattana': 'ปิยะชัยพัฒนา',
  'Sri Trang Tour': 'ศรีตรัง ทัวร์',
  'Piyaprasert Tour': 'ปิยะประเสริฐ ทัวร์',
  'Phitsanulok Yanyon': 'พิษณุโลกยานยนต์',
  'Prayat Tour': 'ประหยัด ทัวร์',
  'Lotus Phiboon Tour': 'โลตัสพิบูล ทัวร์',
  'Jirattakarn Khemarat': 'จิรัฐกาล เขมราฐ รถบัส',
  'Phu Kradueng Tour': 'ภูกระดึง ทัวร์',
  'Khemmarat Rungreang Tour': 'เขมราฐ รุ่งเรือง ทัวร์',
  'Sombat Tour': 'สมบัติทัวร์',
  'Kantharalak': 'กันทรลักษ์ทัวร์',
  'Wattanasakorn Tour': 'วัฒนาสาคร ทัวร์',
  'Montanatip': 'มณธนาธิป ทัวร์',
  'Krungsiam Tour': 'กรุงสยามทัวร์',
  'Sawasdee Esan': 'สวัสดีอีสาน',
  'Sawasdee Surin': 'สวัสดีสุรินทร์',
  'Rungprasert Tour': 'รุ่งประเสริฐ ทัวร์',
  'Sunbus': 'ซันบัส',
  'Rayong Tour': 'ระยองทัวร์',
  'Thaisanguan Tour': 'ไทยสงวนทัวร์ ',
  'Bangsaphantour': 'บางสะพานทัวร์ ',
  'Air Chaiyaphum': 'แอร์ชัยภูมิ ',
  'Suwannatee Khon Song': 'สุวรรณนที ทัวร์ ',
  'Chumphae Tour': 'ชุมแพทัวร์ ',
  'Chaiyaphum Tour': 'ชัยภูมิทัวร์ ',
  'Sasanan Tour': 'ศศนันท์ ทัวร์ ',

};

export const getCompanyDisplayName = (companyName) => companyDisplayNames[companyName] || companyName;