import "primeflex/primeflex.css";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";

import React, { useEffect, useState } from "react";
import countryCodesData from "./CountryCodes.json";
import nationalities from "./nationalities.json";
import "./Payment.css";

const PaymentPage = () => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [selectedSeats, setSelectedSeats] = useState(null);

  const [selectedPrefix, setSelectedPrefix] = useState(null);

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);

  const [selectedNationality, setSelectedNationality] = useState(null);
  const [totalPassengers, setTotalPassengers] = useState(0);
  const [adultCount, setAdultCount] = useState(0);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const passengers = sessionStorage.getItem("totalPassengers");
    setTotalPassengers(parseInt(passengers) || 0);

    const storedPaymentDetails = sessionStorage.getItem("paymentDetails");
    if (storedPaymentDetails) {
      setPaymentDetails(JSON.parse(storedPaymentDetails).data);
    } else {
      console.error("No payment details found in local storage");
    }
    const storedSelectedTrip = sessionStorage.getItem("selectedTrip");
    if (storedSelectedTrip) {
      setSelectedTrip(JSON.parse(storedSelectedTrip)); // ตั้งค่า selectedTrip
    } else {
      console.error("No selected trip found in local storage");
    }
    const storedSelectedSeats = sessionStorage.getItem("selectedSeats");
    if (storedSelectedSeats) {
      setSelectedSeats(JSON.parse(storedSelectedSeats)); // ตั้งค่า selectedSeats
    }
    // ใช้ข้อมูลจาก JSON โดยตรง
    const formattedCountryCodes = countryCodesData.map((info) => ({
      name: info.name,
      code: info.isoCode, // ใช้ isoCode
      dialCode: info.dialCode,
      flag: info.flag, // ใช้ URL ของธงชาติ
    }));

    setCountryCodes(formattedCountryCodes);
    setSelectedCountry(formattedCountryCodes[0]); // ตั้งค่าเริ่มต้นเป็นประเทศแรก
  }, []);

  if (!paymentDetails || !selectedTrip || !selectedSeats) {
    return <div>Loading...</div>;
  }
  const priceBreakDowns = paymentDetails.PriceBreakDowns;

  // ดึงค่า AdminFee และ DepartTicketFare จาก priceBreakDowns
  const adminFee = priceBreakDowns.find(item => item.BreakDownType === 'AdminFee')?.Amount || 0;
  const departTicketFare = priceBreakDowns.find(item => item.BreakDownType === 'DepartTicketFare')?.Amount || 0;

  // ค่าโดยสารการเดินทางขากลับ (สมมติว่า BreakDownType คือ 'ReturnTicketFare')
  const returnTicketFare = priceBreakDowns.find(item => item.BreakDownType === 'ReturnTicketFare')?.Amount || 0;

  const prefixes = [
    { label: "Mr.", value: "Mr" },
    { label: "Mrs.", value: "Mrs" },
    { label: "Miss", value: "Miss" },
  ];

  const handlePrefixChange = (e) => {
    setSelectedPrefix(e.value);
  };
  const nationalityOptions = nationalities.map((item) => ({
    label: item.nationality,
    value: item.nationality,
  }));

  const handleNationalityChange = (e) => {
    setSelectedNationality(e.value);
    // ทำอย่างอื่นตามที่ต้องการ เช่น อัปเดตฟอร์มหรือส่งข้อมูลไปยังคอมโพเนนต์แม่
  };
  const adultOptions = Array.from({ length: totalPassengers + 1 }, (_, i) => ({
    label: i.toString(),
    value: i,
  }));

  const childCount = totalPassengers - adultCount;

  return (
    <div className="">
      <div className="min-vh-100 bg-gray-50 py-2">
        <div className="px-5">
   
          <div className="mb-2">
            <h1 className="text-3xl md:text-5xl font-bold text-gray-800">
            ละเอียดของผู้รับตั๋วและชำระเงิน
            </h1>
          </div>
        
        </div>

        <div className="surface-card border-round border-1 border-gray-200 p-5 text-gray-800">
          <div className="w-full">
            <div className="-mx-3 md:flex items-start">



              <div className="col-12 md:col-6 px-3">
              <div
                  className="bg-white overflow-hidden shadow-3 mb-4"
                  style={{
                    borderRadius: "0.5rem",
                  }}
                >
                  <div
                    className="p-1 "
                    style={{ backgroundColor: "#F4CE14" }}
                  ></div>
                  <div className="  space-y-2 text-sm  p-4 mb-4 ">
                    <span
                      className=" text-base font-medium"
                      style={{
                        textTransform: "uppercase",
                        color: "#B80000",
                      }}
                    >
                     ข้อมูลผู้รับตั๋ว
                    </span>
                    <div>
                      <div className="mt-3">
                        <label className="text-gray-800 font-semibold text-sm mb-2 ml-1">
                          คำนำหน้า
                        </label>
                        <div>
                          <Dropdown
                            value={selectedPrefix}
                            options={prefixes}
                            onChange={handlePrefixChange}
                            placeholder="เลือกคำนำหน้า"
                            className="w-full custom-input  mb-1"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="text-gray-800 font-semibold text-sm mb-2 ml-1">
                          ชื่อ
                        </label>
                        <div>
                          <input
                            className="w-full custom-input px-3 py-2 mb-1 text-sm"
                            placeholder="กรอกชื่อ-นามสกุล"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="text-gray-800 font-semibold text-sm mb-2 ml-1">
                          ที่อยู่อีเมลล์
                        </label>
                        <div>
                          <input
                            className="w-full custom-input px-3 py-2 mb-1 text-sm"
                            placeholder="กรอกที่อยู่อีเมลล์"
                            type="email"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="text-gray-800 font-semibold text-sm mb-2 ml-1">
                          เบอร์โทร
                        </label>
                        <div className="flex">
                          <Dropdown
                            value={selectedCountry}
                            options={countryCodes}
                            onChange={(e) => setSelectedCountry(e.value)}
                            optionLabel="name"
                            placeholder="เลือกรหัสประเทศ"
                            filter
                            className="w-2/5 mr-2"
                            itemTemplate={(option) => (
                              <div className="flex align-items-center">
                                <img
                                  src={option.flag}
                                  alt={option.name}
                                  style={{ width: "24px", height: "24px" }}
                                />{" "}
                                <span className="ml-2">
                                  {option.name} {option.dialCode}
                                </span>
                              </div>
                            )}
                            valueTemplate={(option) => (
                              <div className="flex align-items-center">
                                <img
                                  src={option.flag}
                                  alt={option.name}
                                  style={{ width: "24px", height: "24px" }}
                                />
                                <span className="ml-2">{option.dialCode}</span>
                              </div>
                            )}
                          />
                          <input
                            placeholder="กรอกเบอร์โทรศัพท์"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            className="w-full custom-input px-3 py-2 mb-1 text-sm"
                          />
                        </div>
                        <p className="text-sm">
                          กรุณาใส่หมายเลขโทรศัพท์ที่ถูกต้องเพราะหมายเลขนี้จะถูกใช้สำหรับพนักงานของเราเพื่อติดต่อคุณในกรณีฉุกเฉินหากมีการเปลี่ยนแปลงเวลาของรถบัสที่คุณเลือก
                        </p>
                      </div>

                      <div className="mb-3">
                        <label className="text-gray-800 font-semibold text-sm mb-2 ml-1">
                          สัญชาติ
                        </label>
                        <div>
                          <Dropdown
                            value={selectedNationality}
                            options={nationalityOptions}
                            onChange={handleNationalityChange}
                            placeholder="เลือกสัญชาติ"
                            className="w-full custom-input mb-1"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="text-gray-800 font-semibold text-sm mb-2 ml-1">
                          จำนวนผู้โดยสารในการเดินทางขาไป
                        </label>
                        <div>
                          <div className="flex gap-2">
                            <div className="w-full">
                              <label className="text-gray-800 font-semibold text-sm mb-2 ml-1">
                                ผู้ใหญ่
                              </label>

                              <Dropdown
                                value={adultCount}
                                options={adultOptions}
                                onChange={(e) => setAdultCount(e.value)}
                                placeholder="เลือกจำนวน"
                                className="w-full custom-input mb-1"
                              />
                            </div>
                            <div className="w-full">
                              <label className="text-gray-800 font-semibold text-sm mb-2 ml-1">
                                เด็ก
                              </label>

                              <span
                                className="text-sm px-4 py-2 border-round"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "var(--green-50)",
                                  color: "var(--green-600)",
                                  border: "1px solid var(--green-500)",
                                }}
                              >
                                {childCount}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>





                <div
                  className="bg-white overflow-hidden shadow-3 "
                  style={{
                    borderRadius: "0.5rem",
                  }}
                >
                  <div
                    className="p-1 "
                    style={{ backgroundColor: "#F4CE14" }}
                  ></div>
                  <div className="  space-y-2 text-sm  p-4 mb-4 ">
                    <span
                      className=" text-base font-medium"
                      style={{
                        textTransform: "uppercase",
                        color: "#B80000",
                      }}
                    >
                      Refund Protection
                    </span>
                    <div className="p-2 bg-white flex flex-column sm:flex-row align-items-center border-b mb-2 border-gray-200 rounded-lg">
                      <div
                        className="mr-0 sm:mr-5 inline-flex align-items-center justify-content-center flex-shrink-0 mb-3 sm:mb-0"
                        style={{ color: "#B31312" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="58"
                          height="58"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M12.005 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.96 9.96 0 0 1-6.383-2.302l-.244-.209l.901-1.902a8 8 0 1 0-2.27-5.837l-.004.25h2.5l-2.706 5.716A9.954 9.954 0 0 1 2.005 12c0-5.523 4.477-10 10-10Zm1 4v2h2.5v2h-5.5a.5.5 0 0 0-.09.992l.09.008h4a2.5 2.5 0 0 1 0 5h-1v2h-2v-2h-2.5v-2h5.5a.5.5 0 0 0 .09-.992l-.09-.008h-4a2.5 2.5 0 1 1 0-5h1V6h2Z"
                          />
                        </svg>
                      </div>
                      <div className="flex-grow sm:text-left text-center mt-0">
                        <p className="leading-relaxed text-sm">
                          Book our 100% Refund Protection and enjoy a worry-free
                          trip! You’ll get a 100% refund on your booking up to
                          24 hours before departure, just in case your plans
                          change unexpectedly. T&C apply.
                        </p>
                      </div>
                    </div>

                    <div className="flex justify-content-end align-align-align-items-center">
                      <div className="flex  pr-2 h-full items-center">
                        <Checkbox
                          onChange={(e) => setChecked(e.checked)}
                          checked={checked}
                        ></Checkbox>

                        <span className="ml-3 font-medium">
                          Upgrade to Refund Protection at THB 47.52/seat
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

              
              </div>

              <div className="col-12 md:col-6 px-3">
                <div
                  className="bg-white overflow-hidden shadow-3 mb-6 "
                  style={{
                    borderRadius: "0.5rem",
                  }}
                >
                  <div
                    className="p-1 "
                    style={{ backgroundColor: "#F4CE14" }}
                  ></div>
                  <div className="  space-y-2 text-sm  p-4 mb-4 ">
                    <span
                      className=" text-base font-medium"
                      style={{
                        textTransform: "uppercase",
                        color: "#B80000",
                      }}
                    >
                      ข้อมูลการเดินทางออก
                    </span>
                    <div className="pt-2 text-sm flex flex-column">
                      <span className="font-semibold text-sm">
                        วันที่ออกเดินทาง:
                      </span>
                      <span className="  text-sm">
                        {selectedTrip.DepartureDate}
                      </span>
                    </div>
                    <div className="pt-2 text-sm flex flex-column">
                      <span className="font-semibold text-sm">ออกจาก:</span>
                      <span className="font-thin  text-sm">
                        {selectedTrip.FromSubPlaceName},{" "}
                        {selectedTrip.FromPlaceName}
                      </span>
                    </div>
                    <div className="pt-2 text-sm flex flex-column">
                      <span className="font-semibold text-sm">
                        Ticket Surcharge:
                      </span>
                      <span className="font-thin  text-sm">
                        {selectedTrip.ToSubPlaceName},{" "}
                        {selectedTrip.ToPlaceName}
                      </span>
                    </div>
                    <div className="pt-2 text-sm flex flex-column">
                      <span className="font-semibold text-sm">
                        รถบัสโดยสาร:
                      </span>
                      <span className="font-thin  text-sm">
                        {selectedTrip.CompanyName}
                      </span>
                    </div>
                    <div className="pt-2 text-sm flex flex-column">
                      <span className="font-semibold text-sm">
                        หมายเลขที่นั่ง:
                      </span>
                      <span className="font-thin text-sm">
                        {selectedSeats && selectedSeats.length > 0
                          ? selectedSeats.map((seat) => seat).join(", ")
                          : "ไม่มีที่นั่งที่เลือก"}
                      </span>
                    </div>
                    <div className="pt-2 text-sm flex flex-column">
                      <span className="font-semibold text-sm">
                      ราคาตั๋วต่อหน่วย:
                      </span>
                      <div className="pt-2 text-sm flex flex-row">
                      <span className="font-semibold text-sm mr-2">
                      เด็ก:
                      </span>
                      <span className="font-thin text-sm">
                      {paymentDetails.Currency} &nbsp;
                      {paymentDetails.DepartTripInfo.SingleChildPrice}
                      </span>
                    </div>
                    <div className="pt-2 text-sm flex flex-row">
                      <span className="font-semibold text-sm mr-2">
                      ผู้ใหญ่:
                      </span>
                      <span className="font-thin text-sm">
                      {paymentDetails.Currency} &nbsp;
                      {paymentDetails.DepartTripInfo.SingleAdultPrice}
                      </span>
                    </div>
                    </div>
                    
                  </div>
                </div>

                <div
                  className="bg-white overflow-hidden shadow-3 mb-6 "
                  style={{
                    borderRadius: "0.5rem",
                  }}
                >
                  <div
                    className="p-1 "
                    style={{ backgroundColor: "#F4CE14" }}
                  ></div>
                  <div className="  space-y-2 text-sm  p-4 mb-4 ">
                    <span
                      className=" text-base font-medium"
                      style={{
                        textTransform: "uppercase",
                        color: "#B80000",
                      }}
                    >
                      ข้อมูลการชำระเงิน

                    </span>
                    <div className="px-4 border-b">
                <div className="flex justify-content-between py-2 text-gray-800">
                    <span>ค่าโดยสารการเดินทางขาไป</span>
                    <span className="font-semibold text-pink-500"> {departTicketFare} บาท</span>
                </div>
                <div className="flex justify-content-between py-2 text-gray-800">
                    <span>ค่าโดยสารการเดินทางขากลับ</span>
                    <span className="font-semibold text-pink-500">{returnTicketFare} บาท</span>
                </div>
                <div className="flex justify-content-between py-2 text-gray-800">
                    <span>ค่าธรรมเนียมผู้ดูแลระบบ</span>
                    <span className="font-semibold text-pink-500">{adminFee} บาท</span>
                </div>
                <div className="flex justify-content-between py-2 text-gray-800">
                    <span>ภาษีมูลค่าเพิ่ม</span>
                    <span className="font-semibold text-pink-500">0.00 บาท</span>
                </div>
            </div>
            
            <div className="mb-2  pb-2 border-bottom-1	 border-gray-200	">

                        <div className=" flex align-items-center	 justify-content-end">
                            <div className=" px-2 ">
                                
                                    <input className="custom-input px-3 py-2 mb-1 text-sm"
                                     placeholder="รหัสส่วนลด" type="text"/>
                            
                            </div>
                            <div className="px-2">
                                <button className=" w-full text-sm  mx-auto border border-transparent bg-gray-400 hover:bg-gray-500 focus:bg-gray-500 text-white 
                                border-round px-5 py-2 font-semibold">APPLY</button>
                            </div>
                        </div>

                    </div>

            <div className="font-semibold text-xl px-4 flex justify-content-between py-2 text-gray-900">
                <span>ทั้งหมด</span>
                <span>{paymentDetails.TotalAmount} บาท</span>
            </div>
        
                  
                  
                  </div>
                </div>


                <div>
                  <button className="button border-2 "
                   style={{
                    borderRadius: "0.5rem",
                  }}>
                     ชำระเงิน
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default PaymentPage;
