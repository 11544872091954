import React from "react";
import axios from 'axios';
import Swal from 'sweetalert2';
import '../styles/register.css';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import { useParams } from 'react-router-dom';

import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from "primereact/checkbox";
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';

import LoadingDialog from "./LoadingView";

import { decodeToken } from "react-jwt";

const prefixs = [
	{ name: 'นาย', code: 'นาย' },
	{ name: 'นาง', code: 'นาง' },
	{ name: 'นางสาว', code: 'นางสาว' },
];

async function getProvince() {
	return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json');
};

async function getDistrict() {
	return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json');
};

async function getSubDistrict() {
	return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_tambon.json');
};

async function getMember(tel) {
	return fetch(`${process.env.REACT_APP_SHOP}/member/check/${tel}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	}).then(data => data.json());
};

async function getCheckOTP(packageData) {
	return fetch(`${process.env.REACT_APP_SHOP}/member/check/otp`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json());
};

async function getOTP(tel) {
	const packageData = {
		phone: tel
	};
	return fetch(`${process.env.REACT_APP_SHOP}/member/verify`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json());
};

async function registerMember(packageData) {
	return fetch(`${process.env.REACT_APP_SHOP}/member/create`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json());
};

async function getContractPDPA() {
	return fetch(`${process.env.REACT_APP_SHOP}/contract/PDPA`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	}).then(data => data.json());
};

export default function RegisterRefView() {
	const { token } = useParams();
	const [isLoading, setisLoading] = React.useState(false);

	const [contractPDPA, setContractPDPA] = React.useState({});
	const [contractCondition, setContractCondition] = React.useState({});

	// const [promise, setPromise] = React.useState(false);
	const [dialogContractPDPA, setDialogContractPDPA] = React.useState(false);
	const [dialogContractCondition, setDialogContractCondition] = React.useState(false);
	// const [dialogContract, setDialogContract] = React.useState(false);

	const [item_province, setItemProvince] = React.useState([]);
	const [item_district, setItemDistrict] = React.useState([]);
	const [item_subdistrict, setItemSubDistrict] = React.useState([]);
	const [item_provincec, setItemProvinceC] = React.useState([]);
	const [item_districtc, setItemDistrictC] = React.useState([]);
	const [item_subdistrictc, setItemSubDistrictC] = React.useState([]);
	const [disabledRef, setDisabledRef] = React.useState(false);
	const [disabled, setDisabled] = React.useState(false);

	// ผู้แนะนำ
	const [reftel, setRefTel] = React.useState('');
	const [refname, setRefName] = React.useState('');
	// ข้อมูลสมาชิก
	const [prefix, setPrefix] = React.useState('');
	const [fristname, setFristname] = React.useState('');
	const [lastname, setLastname] = React.useState('');
	const [tel, setTel] = React.useState('');
	const [otp, setOTP] = React.useState('');
	const [tokenotp, setTokenOTP] = React.useState('');
	const [checkOTP, setCheckOTP] = React.useState(false);
	const [confrimeOTP, setConfrimeOTP] = React.useState(false);
	const [password, setPassword] = React.useState('');
	const [confirmpass, setComfirmpass] = React.useState('');
	const [address, setAddress] = React.useState('');
	const [province, setProvince] = React.useState('');
	const [district, setDistrict] = React.useState('');
	const [subdistrict, setSubdistrict] = React.useState('');
	const [postcode, setPostcode] = React.useState('');
	const [addressc, setAddressC] = React.useState('');
	const [provincec, setProvinceC] = React.useState('');
	const [districtc, setDistrictC] = React.useState('');
	const [subdistrictc, setSubdistrictC] = React.useState('');
	const [postcodec, setPostcodeC] = React.useState('');

	// สัญญา
	const [promiseCondition, setPromiseCondition] = React.useState(false);
	const [promisePDPA, setPromisePDPA] = React.useState(false);

	const [checkAddOne, setCheckAddOne] = React.useState(false);
	const [checkAddTwo, setCheckAddTwo] = React.useState(false);

	React.useState(async () => {
		const resqPDPA = await getContractPDPA();
		setContractPDPA(resqPDPA.content);
		setContractCondition(resqPDPA.content);
		const provinces = await getProvince();
		setItemProvince(provinces.data);
		setItemProvinceC(provinces.data);
		setCheckAddTwo(true);

		const myDecodedToken = decodeToken(token);
		headleRef(myDecodedToken.tel);
	});

	const handleDistrict = async (e) => {
		e.preventDefault();
		const response = await getDistrict();
		const districts = response.data.filter(el => el.province_id === e.value.id);
		setProvince(e.value);
		setItemDistrict(districts);
	};

	const handleDistrictC = async (e) => {
		e.preventDefault();
		const response = await getDistrict();
		const districts = response.data.filter(el => el.province_id === e.value.id);
		setProvinceC(e.value);
		setItemDistrictC(districts);
	};

	const handleSubDistrict = async (e) => {
		e.preventDefault();
		const response = await getSubDistrict();
		const subdistricts = response.data.filter(el => el.amphure_id === e.value.id);
		setDistrict(e.value);
		setItemSubDistrict(subdistricts);
	};

	const handleSubDistrictC = async (e) => {
		e.preventDefault();
		const response = await getSubDistrict();
		const subdistricts = response.data.filter(el => el.amphure_id === e.value.id);
		setDistrictC(e.value);
		setItemSubDistrictC(subdistricts);
	};

	const handlePostcode = (e) => {
		e.preventDefault();
		setSubdistrict(e.value);
		setPostcode(e.value.zip_code);
	};

	const handlePostcodeC = (e) => {
		e.preventDefault();
		setSubdistrictC(e.value);
		setPostcodeC(e.value.zip_code);
	};

	const headleRef = async (value) => {
		setRefTel(value);
		const cleaned = ('' + value).replace(/\D/g, '');
		if (cleaned === '') {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณากรอกเบอร์โทรศัพท์ผู้แนะนำ 10 หลัก`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (cleaned.length === 10) {
			const check_tel = await getMember(cleaned);
			if (check_tel.status === false) {
				Swal.fire({
					title: 'แจ้งเตือน',
					icon: 'warning',
					text: check_tel.message,
					showConfirmButton: false,
					timer: 3000,
				});
				return false;
			} else if (check_tel.status === true) {
				const name = `${check_tel.data.fristname} ${check_tel.data.lastname}`;
				Swal.fire({
					icon: 'warning',
					title: 'ผู้แนะนำ',
					text: `ยืนยันผู้แนะนำ ${name} ?`,
					showCancelButton: true,
					confirmButtonText: 'ตกลง',
					cancelButtonText: 'ยกเลิก'
				}).then(async (res) => {
					if (res.isConfirmed) {
						setRefName(name);
						setDisabledRef(true);
					} else {
						setRefTel('');
					}
				});
			}
		} else {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `เบอร์โทรศัพท์ผู้แนะนำจะต้องเป็น 10 หลักเท่านั้น`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
	};

	const headleClearRef = async e => {
		e.preventDefault();
		Swal.fire({
			icon: 'warning',
			title: 'เปลี่ยนผู้แนะนำ',
			text: `ยืนยันเปลี่ยนผู้แนะนำ ${refname} ?`,
			showCancelButton: true,
			confirmButtonText: 'ตกลง',
			cancelButtonText: 'ยกเลิก'
		}).then(async (res) => {
			if (res.isConfirmed) {
				setRefName('');
				setRefTel('');
				setDisabledRef(false);
			}
		});
	};

	const headleOTP = async e => {
		e.preventDefault();
		const cleaned = ('' + tel).replace(/\D/g, '');
		if (cleaned === '') {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณากรอกเบอร์โทรศัพท์ 10 หลัก`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (cleaned.length === 10) {
			const check_tel = await getMember(cleaned);
			if (check_tel.status === true) {
				Swal.fire({
					title: 'แจ้งเตือน',
					icon: 'warning',
					text: `เบอร์นี้ได้มีการสมัครสมาชิกเรียบร้อยแล้ว`,
					showConfirmButton: false,
					timer: 3000,
				});
				return false;
			} else {
				Swal.fire({
					icon: 'warning',
					title: 'ยืนยันรับ OTP',
					text: `ยืนยันเบอร์โทร ${cleaned} ใช้ในการลงทะเบียน?`,
					showCancelButton: true,
					confirmButtonText: 'ตกลง',
					cancelButtonText: 'ยกเลิก'
				}).then(async (res) => {
					if (res.isConfirmed) {
						const otp = await getOTP(cleaned);
						if (otp.status === true) {
							setTokenOTP(otp.result.token);
							setCheckOTP(true);
							Swal.fire({
								title: 'สำเร็จ',
								icon: 'success',
								text: `ส่งรหัส OTP ไปที่เบอร์โทรศัพท์ของคุณเรียบร้อย`,
								showConfirmButton: false,
								timer: 3000,
							});
						} else {
							Swal.fire({
								title: 'Oops...',
								icon: 'error',
								text: `มีบางอย่างผิดพลาด`,
								confirmButtonText: 'ตกลง',
								timer: 3000,
							});
						}
					}
				});
			}
		} else {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `เบอร์โทรศัพท์จะต้องเป็น 9 หรือ 10 หลักเท่านั้น`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
	};

	const headleCheckOTP = async e => {
		e.preventDefault();
		if (otp === '') {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณากรอกรหัส OTP ที่ได้รับ`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (otp.length !== 6) {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณากรอกรหัส OTP ให้ครบ 6 หลัก`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		const packageData = {
			otp_code: otp,
			token: tokenotp
		};
		const check_otp = await getCheckOTP(packageData);
		if (check_otp.status === true) {
			Swal.fire({
				title: 'สำเร็จ',
				icon: 'success',
				text: `ยืนยันการตรวจสอบ OTP สำเร็จ`,
				showConfirmButton: false,
				timer: 3000,
			});
			setDisabled(true);
			setConfrimeOTP(true);
		} else if (check_otp.status === false) {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'error',
				text: `รหัส OTP ถูกใช้งานแล้ว`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	};

	const headleCheckAddOne = (e) => {
		if (
			address === '' ||
			subdistrict === '' ||
			district === '' ||
			province === '' ||
			postcode === ''
		) {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณากรอกข้อมูลที่อยู่ตามบัตรประชนให้ครบถ้วน`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		setCheckAddOne(e)
		setCheckAddTwo(!e)
		setAddressC(address);
		setSubdistrictC(subdistrict);
		setDistrictC(district);
		setProvinceC(province);
		setPostcodeC(postcode);
	};

	const headleCheckAddTwo = async (e) => {
		if (
			address === '' ||
			subdistrict === '' ||
			district === '' ||
			province === '' ||
			postcode === ''
		) {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณากรอกข้อมูลที่อยู่ตามบัตรประชนให้ครบถ้วน`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		setCheckAddOne(!e)
		setCheckAddTwo(e)
		const provinces = await getProvince();
		setItemProvinceC(provinces.data);
	};

	const headleSubmit = async e => {
		e.preventDefault();
		if (
			reftel === '' ||
			prefix === '' ||
			fristname === '' ||
			lastname === '' ||
			tel === '' ||
			password === '' ||
			confirmpass === '' ||
			address === '' ||
			subdistrict === '' ||
			district === '' ||
			province === '' ||
			postcode === '' ||
			addressc === '' ||
			subdistrictc === '' ||
			districtc === '' ||
			provincec === '' ||
			postcodec === ''
		) {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณากรอกข้อมูลให้ครบถ้วน`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (password !== confirmpass) {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `รหัสผ่านไม่ตรงกัน`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (promisePDPA !== true && promiseCondition !== true) {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณาอ่านและยินยอมข้อสัญญา`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (confrimeOTP !== true) {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณาตรวจสอบเบอร์โทรศัพท์และยืนยันตัวตนด้วย OTP`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		const data = {
			ref_tel: reftel,
			prefix: prefix,
			fristname: fristname,
			lastname: lastname,
			tel: tel,
			password: password,
			address: address,
			subdistrict: subdistrict.name_th,
			district: district.name_th,
			province: province.name_th,
			postcode: `${postcode}`,
			current_address: {
				address: addressc,
				subdistrict: subdistrictc.name_th,
				district: districtc.name_th,
				province: provincec.name_th,
				postcode: `${postcodec}`,
			},
		};
		setisLoading(true);
		const response = await registerMember(data);
		if (response.status === true) {
			setisLoading(false);
			Swal.fire({
				title: 'สำเร็จ',
				icon: 'success',
				text: `สมัครสมาชิกสำเร็จ`,
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				window.location.href = "/";
			});
		} else {
			setisLoading(false);
			Swal.fire({
				title: 'ไม่สำเร็จ',
				icon: 'error',
				text: `สมัครสมาชิกไม่สำเร็จ กรุณาตรวจสอบ`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	};

	const heandlePromiseOne = (e) => {
		setPromiseCondition(e.checked)
		setDialogContractCondition(false);
	};

	const heandlePromiseTwo = (e) => {
		setPromisePDPA(e.checked)
		setDialogContractPDPA(false);
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"regis-container"}>
								<Box className={"regis-model"}>
									<React.Fragment>
										<Box sx={{ flexGrow: 1 }} className={"regis-form"}>
											<Grid item xs={12}>
												<Grid container sx={{ justifyContent: 'center' }}>
													<Grid item>
														<h1>สมัครสมาชิก</h1>
														<p>มีบัญชีผู้ใช้งานแล้ว? <Link href="/">เข้าสู่ระบบ</Link></p>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container className={"register-input-tel"}>
													<Grid item className={"input-tel-re"}>
														<FloatLabel>
															{/* <input type="tel" id="reftel" value={reftel} onChange={handleSetReftel} placeholder="999-9999999" disabled={disabled} className="regis-input-text" /> */}
															<InputText id="reftel" value={reftel} className={"regis-input-text"} onChange={(e) => setRefTel(e.target.value)} disabled={disabledRef} />
															<label htmlFor="reftel">เบอร์โทรศัพท์ผู้แนะนำ</label>
														</FloatLabel>
													</Grid>
													<Grid item className={"input-text"}>
														<FloatLabel>
															<InputText id="ref_name" value={refname} className={"regis-input-text"} disabled />
															<label htmlFor="ref_name">ผู้แนะนำ</label>
														</FloatLabel>
													</Grid>
													<Grid item className={"button-otp"}>
														{!refname && (
															<Button label="ตรวจสอบ" onClick={headleRef} className={"regis-button-otp"} />
														)}
														{refname && (
															<Button label="เปลี่ยน" onClick={headleClearRef} className={"regis-button-otp"} />
														)}
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container className={"register-input-name"}>
													<Grid item className={"input-prefix"}>
														<Dropdown value={prefix} options={prefixs} id="prefix" placeholder="คำนำหน้า" className={"regis-input-text"}
															onChange={(e) => setPrefix(e.value)} optionLabel="name" optionValue="code" />
													</Grid>
													<Grid item className={"input-text"}>
														<FloatLabel>
															<InputText id="fristname" value={fristname} className={"regis-input-text"}
																onChange={(e) => setFristname(e.target.value)} />
															<label htmlFor="fristname">ชื่อ</label>
														</FloatLabel>
													</Grid>
													<Grid item className={"input-text"}>
														<FloatLabel>
															<InputText id="lastname" value={lastname} className={"regis-input-text"}
																onChange={(e) => setLastname(e.target.value)} />
															<label htmlFor="lastname">นามสกุล</label>
														</FloatLabel>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container className={"register-input-tel"}>
													<Grid item className={"input-tel-re"}>
														<FloatLabel>
															<InputText id="tel" value={tel} className={"regis-input-text"} onChange={(e) => setTel(e.target.value)} disabled={disabled} />
															<label htmlFor="tel">เบอร์โทรศัพท์</label>
														</FloatLabel>
													</Grid>
													<Grid item className={"input-text"}>
														<FloatLabel>
															<InputText id="otp" value={otp} className={"regis-input-text"} onChange={(e) => setOTP(e.target.value)} disabled={disabled} />
															<label htmlFor="otp">OTP</label>
														</FloatLabel>
													</Grid>
													<Grid item className={"button-otp"}>
														{checkOTP === false && (
															<Button label="OTP" onClick={headleOTP} className={"regis-button-otp"} />
														)}
														{checkOTP === true && (
															<Button label="ตรวจสอบ" onClick={headleCheckOTP} className={"regis-button-otp"} />
														)}
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container className={"register-input"}>
													<Grid item className={"input-text"}>
														<FloatLabel>
															<InputText id="password" type="password" value={password} className={"regis-input-text"}
																onChange={(e) => setPassword(e.target.value)} />
															<label htmlFor="password">รหัสผ่าน</label>
														</FloatLabel>
													</Grid>
													<Grid item className={"input-text"}>
														<FloatLabel>
															<InputText id="confirmpass" type="password" value={confirmpass} className={"regis-input-text"}
																onChange={(e) => setComfirmpass(e.target.value)} />
															<label htmlFor="confirmpass">ยืนยันรหัสผ่าน</label>
														</FloatLabel>
													</Grid>
												</Grid>
											</Grid>
											<Divider className={"divider"} textAlign="left">
												<Chip label="ที่อยู่ตามบัตรประชาชน" style={{ fontFamily: 'Kanit' }} />
											</Divider>
											<Grid item xs={12}>
												<Grid container className={"register-input"}>
													<Grid item className={"input-text"}>
														<FloatLabel>
															<InputText id="address" value={address} className={"regis-input-text"}
																onChange={(e) => setAddress(e.target.value)} />
															<label htmlFor="address">ที่อยู่</label>
														</FloatLabel>
													</Grid>
													<Grid item className={"input-text"}>
														<Dropdown value={province} options={item_province} id="province" placeholder="จังหวัด" className={"regis-input-text"}
															onChange={handleDistrict} optionLabel="name_th" filter />
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container className={"register-input"}>
													<Grid item className={"input-text"}>
														<Dropdown value={district} options={item_district} id="district" placeholder="อำเภอ" className={"regis-input-text"}
															onChange={handleSubDistrict} optionLabel="name_th" filter />
													</Grid>
													<Grid item className={"input-text"}>
														<Dropdown value={subdistrict} options={item_subdistrict} id="subdistrict" placeholder="ตำบล" className={"regis-input-text"}
															onChange={handlePostcode} optionLabel="name_th" filter />
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container className={"register-input"}>
													<Grid item className={"input-text"}>
														<FloatLabel>
															<InputText id="postcode" value={postcode} className={"regis-input-text"} />
															<label htmlFor="postcode">รหัสไปรษณีย์</label>
														</FloatLabel>
													</Grid>
												</Grid>
											</Grid>
											<Divider className={"divider"} textAlign="left">
												<Chip label="ที่อยู่ปัจจุบัน" style={{ fontFamily: 'Kanit' }} />
											</Divider>
											<Grid item xs={12}>
												<Grid container className={"register-input-address"}>
													<Grid item className={"check-text"}>
														<Checkbox
															// label="ที่อยู่ใหม่"
															checked={checkAddTwo}
															onChange={e => headleCheckAddTwo(e.checked)}
														/>
														<label>&nbsp;&nbsp;ที่อยู่ใหม่</label>
													</Grid>
													<Grid item className={"check-text"}>
														<Checkbox
															// label="ที่อยู่ตามบัตรประชาชน"
															checked={checkAddOne}
															onChange={e => headleCheckAddOne(e.checked)}
														/>
														<label>&nbsp;&nbsp;ที่อยู่ตามบัตรประชาชน</label>
													</Grid>
												</Grid>
											</Grid>
											{checkAddTwo && (
												<React.Fragment>
													<Grid item xs={12}>
														<Grid container className={"register-input"}>
															<Grid item className={"input-text"}>
																<FloatLabel>
																	<InputText id="addressc" value={addressc} className={"regis-input-text"}
																		onChange={(e) => setAddressC(e.target.value)} />
																	<label htmlFor="addressc">ที่อยู่</label>
																</FloatLabel>
															</Grid>
															<Grid item className={"input-text"}>
																<Dropdown value={provincec} options={item_provincec} id="provincec" placeholder="จังหวัด" className={"regis-input-text"}
																	onChange={(e) => handleDistrictC(e, e.value)} optionLabel="name_th" />
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={12}>
														<Grid container className={"register-input"}>
															<Grid item className={"input-text"}>
																<Dropdown value={districtc} options={item_districtc} id="districtc" placeholder="อำเภอ" className={"regis-input-text"}
																	onChange={(e) => handleSubDistrictC(e, e.value)} optionLabel="name_th" />
															</Grid>
															<Grid item className={"input-text"}>
																<Dropdown value={subdistrictc} options={item_subdistrictc} id="subdistrictc" placeholder="ตำบล" className={"regis-input-text"}
																	onChange={(e) => handlePostcodeC(e, e.value)} optionLabel="name_th" />
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={12}>
														<Grid container className={"register-input"}>
															<Grid item className={"input-text"}>
																<FloatLabel>
																	<InputText id="postcodec" value={postcodec} className={"regis-input-text"} />
																	<label htmlFor="postcodec">รหัสไปรษณีย์</label>
																</FloatLabel>
															</Grid>
														</Grid>
													</Grid>
												</React.Fragment>
											)}
											<Grid item xs={12}>
												<Grid container className={"register-input-pomise"}>
													<Grid item className={"check-text"}>
														{/* <Checkbox onChange={e => setPromise(e.checked)} checked={promise}></Checkbox> */}
														<label><Link onClick={(e) => setDialogContractCondition(true)}>ข้อตกลงการใช้บริการ</Link> และ <Link onClick={(e) => setDialogContractPDPA(true)}>นโยบายความเป็นส่วนตัว</Link></label>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container className={"register-input-submit"}>
													<Button label="สมัครสมาชิก" onClick={headleSubmit} />
												</Grid>
											</Grid>
										</Box>
									</React.Fragment>
								</Box>
							</Grid>
						</Box>
					</Grid >
				</Grid >
			</Box >

			{/* Dialog Checkout */}
			<Sidebar visible={dialogContractCondition} style={{ fontFamily: 'Kanit', width: '100%' }} showCloseIcon={false}>
				<div dangerouslySetInnerHTML={{ __html: contractCondition }} />
				<div style={{ textAlign: 'center' }}>
					<Checkbox onChange={heandlePromiseOne} checked={promiseCondition} />&nbsp;&nbsp; ยอมรับเงื่อนไขสัญญาข้างต้น และเซ็นสัญญาอิเล็กทรอนิกส์ถือว่าสัญญาฉบับนี้สมบูรณ์แบบและถูกต้องตามกฎหมาย
				</div>
			</Sidebar>

			{/* Dialog Checkout */}
			<Sidebar visible={dialogContractPDPA} style={{ fontFamily: 'Kanit', width: '100%' }} showCloseIcon={false}>
				<div dangerouslySetInnerHTML={{ __html: contractPDPA }} />
				<div style={{ textAlign: 'center' }}>
					<Checkbox onChange={heandlePromiseTwo} checked={promisePDPA} />&nbsp;&nbsp; ยอมรับเงื่อนไขสัญญาข้างต้น และเซ็นสัญญาอิเล็กทรอนิกส์ถือว่าสัญญาฉบับนี้สมบูรณ์แบบและถูกต้องตามกฎหมาย
				</div>
			</Sidebar>

			<LoadingDialog isLoading={isLoading} />
		</div>
	)
}