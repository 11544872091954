import React, { useState, useRef } from "react";
import dayjs from "dayjs";
import LoadingDialog from "../../views/LoadingView";

import { useReactToPrint } from "react-to-print";

import { Button } from 'primereact/button';

async function getMember(tel) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/member/check/${tel}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

function datetimeFormat(date) {
	return dayjs(date).format("DD/MM/YYYY เวลา HH:mm:ss น.");
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

async function getEmployee() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/employee`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

export default function ReceiptExpress({ data }) {
	const [isLoading, setisLoading] = useState(false);

	const [employee, setEmployee] = useState({});
	const [member, setMember] = useState({});
	const [order_detail, setOrderDetail] = useState([]);


	useState(async () => {
		setisLoading(true);
		const mem = await getMember(data.platform);
		const emp = await getEmployee();
		if (mem.status === true && emp.status === true) {
			setisLoading(false);
			// setEmployee(emp.data);
			setMember(mem.data);
			setOrderDetail(data.product);

			const emp_data = emp.data.find(
				(el) => el._id === data.employee
			);
			if (emp_data) {
				setEmployee(emp_data);
			}
		}
	});

	return (
		<div>
			<div>
				<div>
					<img src={require('../../assets/logoreceipt.png')} alt="img" style={{ width: '50%', paddingTop: '1rem' }} />
					<p>
						บริษัท ทศกัณฐ์ ดิจิตอลนิวเจนเนอเรชั่น จำกัด <br />(สำนักงานใหญ่)<br />
						103 หมู่ที่ 4 ถนนรอบเมืองเชียงใหม่ ตำบลสุเทพ อำเภอเมือง จังหวัดเชียงใหม่ 50200 <br />
						เลขประจำตัวผู้เสียภาษี 0505567007065 <br />
						โทรศัพท์ 058-083288 Line ID : @tossagun
					</p>
					<p>
						ต้นฉบับใบเสร็จ / ใบกำกับภาษี <br />
						Original Receipt / Tax Invoice
					</p>
					<p style={{ textAlign: 'right' }}>
						ใบเสร็จเลขที่ : {data.invoice}<br />
						วันที่ : {datetimeFormat(data.timestamp)}<br />
						ผู้รับเงิน : {employee.employee_firstname}
					</p>
				</div>
				<hr />
				<div style={{ textAlign: 'left' }}>
					ชื่อและที่อยู่ผู้ซื้อ/ใช้บริการ
					<br />
					คุณ {member.fristname} {member.lastname}
					<br />
					สมาชิก Number {member.card_number}
					<br />
					ที่อยู่ {member.address} {member.subdistrict} {member.district} {member.province} {member.postcode}
					<br />
					{/* เลขประจำตัวผู้เสียภาษี {member.iden.number} */}
					{/* <br /> */}
					เบอร์โทรศัพท์ : {member.tel}
				</div>
				<hr />
				<div>
					<table style={{ width: '100%' }}>
						<tbody>
							<tr>
								<th style={{ textAlign: 'right' }}>ราคา</th>
							</tr>
						</tbody>
					</table>
					<table style={{ width: '100%' }}>
						<tbody>
							{order_detail.map((item, index) => (
								<React.Fragment key={index}>
									{(item.type === 'parcel' || item.type === 'พัสดุ') && (
										<tr>
											<td style={{ width: '5%' }}>x1</td>
											<td style={{ textAlign: 'left', paddingLeft: '0.5rem', width: '50%' }}>
												({item.courier_code})
												<br />
												Tracking : {item.courier_tracking_code}
												<br />
												ผู้รับ : {item.to.name}
												<br />
												รหัสไปรษณีย์ : {item.to.postcode}
												<br />
												(COD : {item.cod_amount} บาท)
												<br />
												(ขนาด : {item.parcel.width} * {item.parcel.length} * {item.parcel.height} * {item.parcel.weight})
											</td>
											<td style={{ textAlign: 'right', width: '10%' }}>{numberFormat(item.price == null ? '' : item.price)}</td>
										</tr>
									)}
								</React.Fragment>
							))}
							{order_detail.map((item, index) => (
								<React.Fragment key={index}>
									{(item.type === 'product' || item.type === 'กล่อง') && (
										<tr>
											<td style={{ width: '5%' }}>x{item.amount == null ? '' : item.amount}</td>
											<td style={{ textAlign: 'left', paddingLeft: '0.5rem', width: '50%' }}>
												{item.name}
												<br />
												(กว้าง {item.width} ซม. * ยาว {item.length} ซม. * สูง {item.height} ซม.)
											</td>
											<td style={{ textAlign: 'right', width: '10%' }}>{numberFormat(item.price == null ? '' : item.price)}</td>
										</tr>
									)}
								</React.Fragment>
							))}
						</tbody>
					</table>
					<hr />
					<table style={{ width: '100%' }}>
						<tbody>
							<tr>
								<td style={{ textAlign: 'left' }}>ค่าธรรมเนียม COD :</td>
								<td style={{ textAlign: 'right' }}>{numberFormat(data.total_cod_charge == null ? '' : data.total_cod_charge)}</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'left' }}>COD VAT 7% :</td>
								<td style={{ textAlign: 'right' }}>{numberFormat(data.total_cod_vat == null ? '' : data.total_cod_vat)}</td>
							</tr>
						</tbody>
					</table>
					<hr />
					<table style={{ width: '100%' }}>
						<tbody>
							<tr style={{ fontWeight: 'bold' }}>
								<td style={{ textAlign: 'left' }}>ยอดสุทธิ :</td>
								<td style={{ textAlign: 'right' }}>{numberFormat(data.total == null ? '' : data.total)}</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'left' }}>{data.payment_type} :</td>
								<td style={{ textAlign: 'right' }}>{numberFormat(data.moneyreceive == null ? '' : data.moneyreceive)}</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'left' }}>เงินทอน :</td>
								<td style={{ textAlign: 'right' }}>{numberFormat(data.change == null ? '' : data.change)}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<hr />
				<div>
					<p style={{ textAlign: 'left' }}>
						หมายเหตุ : Remark
					</p>
					<p style={{ textAlign: 'left' }}>
					</p>
					<table style={{ width: '100%' }}>
						<tbody>
							<tr>
								<td style={{ textAlign: 'left' }}>
									สมาชิกสะสมคะแนน
									<br />
									Member Number : {member.card_number}
									<br />
									คะแนนสะสมคงเหลือ
								</td>
								<td style={{ textAlign: 'right' }}>{member.happy_point == null ? '' : member.happy_point}P</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'left' }}>
									{/* <small> */}
									คะแนนที่ได้รับครั้งนี้
									<br />
									Reward Point
									{/* </small> */}
								</td>
								<td style={{ textAlign: 'right' }}>{data.total - data.total_box}P</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'left' }}>
									{/* <small> */}
									เงินคืนในครั้งนี้
									<br />
									Cash Back In Bill
									{/* </small> */}
								</td>
								<td style={{ textAlign: 'right' }}>{numberFormat(data.total_platform == null ? '' : data.total_platform)}</td>
							</tr>
						</tbody>
					</table>
					<p>
						<b>***ขอขอบพระคุณที่ใช้บริการ***</b><br />
						ทุกการซื้อ/บริการ ... คือการแบ่งปัน<br />
						THANK TOU
					</p>
					{/* <p style={{ textAlign: 'left' }}> */}
					{/* Attention : Contact help desk <br /> */}
					{/* หากมีข้อมูลสอบถาม/พบปัญหา สามารถสอบถามแนะนำ <br /> */}
					{/* Call center สอบถามการให้บริการได้ที่ <br /> */}
					{/* โทรศัพท์ 052-083288 <br /> */}
					{/* Line OA : @tossagun */}
					{/* </p> */}
				</div>
			</div>

			<LoadingDialog isLoading={isLoading} />
		</div>
	)
}