import React, { useState } from "react";
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Button } from 'primereact/button';
import LoadingDialog from "./LoadingView";

async function getMember(tel) {
	return fetch(`${process.env.REACT_APP_SHOP}/member/check/${tel}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	}).then(data => data.json());
};

async function getOTP(tel) {
	const packageData = {
		phone: tel
	};
	return fetch(`${process.env.REACT_APP_SHOP}/member/verify`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json());
};

async function getCheckOTP(packageData) {
	return fetch(`${process.env.REACT_APP_SHOP}/member/check/otp`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json());
};

async function getForgetPassword(packageData) {
	return fetch(`${process.env.REACT_APP_SHOP}/member/forgetpassword`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json());
};

export default function ForgetPassword() {
	const [isLoading, setisLoading] = useState(false);

	const [tel, setTel] = React.useState('');
	const [otp, setOTP] = React.useState('');
	const [tokenotp, setTokenOTP] = React.useState('');
	const [checkOTP, setCheckOTP] = React.useState(false);
	const [confrimeOTP, setConfrimeOTP] = React.useState(false);
	const [disabled, setDisabled] = React.useState(false);

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfrimPassword] = useState('');

	const headleOTP = async e => {
		e.preventDefault();
		const cleaned = ('' + tel).replace(/\D/g, '');
		if (cleaned === '') {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณากรอกเบอร์โทรศัพท์ 10 หลัก`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (cleaned.length === 10) {
			const check_tel = await getMember(cleaned);
			if (check_tel.status !== true) {
				Swal.fire({
					title: 'แจ้งเตือน',
					icon: 'warning',
					text: `เบอร์นี้ไม่ได้มีการสมัครเป็นสมาชิก`,
					showConfirmButton: false,
					timer: 3000,
				});
				return false;
			} else {
				Swal.fire({
					icon: 'warning',
					title: 'ยืนยันรับ OTP',
					text: `ยืนยันเบอร์โทร ${cleaned} ใช้ในรีเซ็ตรหัสผ่าน?`,
					showCancelButton: true,
					confirmButtonText: 'ตกลง',
					cancelButtonText: 'ยกเลิก'
				}).then(async (res) => {
					if (res.isConfirmed) {
						const otp = await getOTP(cleaned);
						if (otp.status === true) {
							setTokenOTP(otp.result.token);
							setCheckOTP(true);
							Swal.fire({
								title: 'สำเร็จ',
								icon: 'success',
								text: `ส่งรหัส OTP ไปที่เบอร์โทรศัพท์ของคุณเรียบร้อย`,
								showConfirmButton: false,
								timer: 3000,
							});
						} else {
							Swal.fire({
								title: 'Oops...',
								icon: 'error',
								text: `มีบางอย่างผิดพลาด`,
								confirmButtonText: 'ตกลง',
								timer: 3000,
							});
						}
					}
				});
			}
		} else {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `เบอร์โทรศัพท์จะต้องเป็น 10 หลักเท่านั้น`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
	};

	const headleCheckOTP = async e => {
		e.preventDefault();
		if (otp === '') {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณากรอกรหัส OTP ที่ได้รับ`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (otp.length !== 6) {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณากรอกรหัส OTP ให้ครบ 6 หลัก`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		const packageData = {
			otp_code: otp,
			token: tokenotp
		};
		const check_otp = await getCheckOTP(packageData);
		if (check_otp.status === true) {
			Swal.fire({
				title: 'สำเร็จ',
				icon: 'success',
				text: `ยืนยันการตรวจสอบ OTP สำเร็จ`,
				showConfirmButton: false,
				timer: 3000,
			});
			setDisabled(true);
			setConfrimeOTP(true);
		} else if (check_otp.status === false) {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'error',
				text: `รหัส OTP ถูกใช้งานแล้ว`,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	};

	const heandleResetPassword = async (e) => {
		if (password === '' ||
			confirmPassword === ''
		) {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณากรอกข้อมูลให้ครบถ้วน`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (password !== confirmPassword) {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `รหัสผ่านไม่ตรงกัน`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (confrimeOTP !== true) {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: `กรุณาตรวจสอบเบอร์โทรศัพท์และยืนยันตัวตนด้วย OTP`,
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		const data = {
			tel: tel,
			password: password
		};
		setisLoading(true);
		const resq = await getForgetPassword(data);
		if (resq.status) {
			setisLoading(false);
			Swal.fire({
				title: "สำเร็จ",
				icon: "success",
				text: "ทำการรีเซ็ตรหัสผ่านสำเร็จ",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				// cancelButtonColor: "#d33",
				confirmButtonText: "ยืนยัน",
				// cancelButtonText: "ยกเลิก",
			}).then((result) => {
				if (result.isConfirmed) {
					localStorage.clear();
					window.location.href = "/";
				}
			})
		}
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"regis-container"}>
								<Box className={"regis-model"}>
									<Box sx={{ flexGrow: 1 }} className={"regis-form"}>
										<Grid item xs={12}>
											<Grid container sx={{ justifyContent: 'center', textAlign: 'center' }}>
												<Grid item>
													<h1>รีเซ็ตรหัสผ่าน</h1>
													<Link href="/">เข้าสู่ระบบ</Link>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container className={"register-input-tel"} style={{ marginTop: '2rem' }}>
												<Grid item className={"input-tel-re"}>
													<FloatLabel>
														<InputText id="tel" value={tel} className={"regis-input-text"} onChange={(e) => setTel(e.target.value)} disabled={disabled} />
														<label htmlFor="tel">เบอร์โทรศัพท์</label>
													</FloatLabel>
												</Grid>
												<Grid item className={"input-text"}>
													<FloatLabel>
														<InputText id="otp" value={otp} className={"regis-input-text"} onChange={(e) => setOTP(e.target.value)} disabled={disabled} />
														<label htmlFor="otp">OTP</label>
													</FloatLabel>
												</Grid>
												<Grid item className={"button-otp"}>
													{checkOTP === false && (
														<Button label="OTP" onClick={headleOTP} className={"regis-button-otp"} />
													)}
													{checkOTP === true && (
														<Button label="ตรวจสอบ" onClick={headleCheckOTP} className={"regis-button-otp"} />
													)}
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container className="register-input" >
												<Grid item className="input-text">
													<FloatLabel>
														<InputText id="password" type="password" value={password} className="regis-input-text"
															onChange={(e) => setPassword(e.target.value)} />
														<label htmlFor="password">รหัสผ่าน</label>
													</FloatLabel>
												</Grid>
												<Grid item className="input-text">
													<FloatLabel>
														<InputText id="confirmpass" type="password" value={confirmPassword} className="regis-input-text"
															onChange={(e) => setConfrimPassword(e.target.value)} />
														<label htmlFor="confirmpass">ยืนยันรหัสผ่าน</label>
													</FloatLabel>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container style={{ justifyContent: 'center' }} >
												<Button label="รีเซ็ตรหัสผ่าน" onClick={heandleResetPassword} />
											</Grid>
										</Grid>
									</Box>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<LoadingDialog isLoading={isLoading} />
		</div>
	)
}