import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import "../styles/profile.css";
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { Button } from 'primereact/button';
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { MeterGroup } from 'primereact/metergroup';

async function getMe() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/me`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getShop(shop_id) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
}

function numberFormat(number) {
    return number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

async function resetPassword(packageData) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/member/resetpassword`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
        body: JSON.stringify(packageData),
    }).then(data => data.json())
};

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: '#ECD400',
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Item3 = styled(Paper)(({ theme }) => ({
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 2,
    fontFamily: 'Kanit'
    // backgroundColor: 'red'
}));

export default function ProfileView() {
    const [member, setMember] = useState({});
    const [employee, setEmployee] = useState({});
    const [shops, setShop] = useState({});
    const [position, setPosition] = useState('');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfrimePassword] = useState('');

    useState(async () => {
        const response = await getMe();
        if (response.logout === true) {
            localStorage.clear();
            window.location.href = "/";
        }
        if (response.level === 'member') {
            setMember(response.data)
            setPosition('member')
        } else if (response.level === 'employee') {
            const shop = await getShop(response.data.employee_shop_id);
            if (shop.data.shop_status === false) {
                Swal.fire({
                    title: 'แจ้งเตือน!',
                    text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                setEmployee(response);
                setShop(shop.data);
                setPosition('employee');
            }
        }
    });

    const heandleResetPassword = async (e) => {
        if (password === '' ||
            confirmPassword === ''
        ) {
            Swal.fire({
                title: 'แจ้งเตือน',
                icon: 'warning',
                text: `กรุณากรอกข้อมูลให้ครบถ้วน`,
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        if (password !== confirmPassword) {
            Swal.fire({
                title: 'แจ้งเตือน',
                icon: 'warning',
                text: `รหัสผ่านไม่ตรงกัน`,
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        const data = {
            password: password
        };
        const resq = await resetPassword(data);
        if (resq.status) {
            Swal.fire({
                title: "สำเร็จ",
                icon: "success",
                text: "ทำการเปลี่ยนรหัสผ่านสำเร็จ กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: "#d33",
                confirmButtonText: "ยืนยัน",
                // cancelButtonText: "ยกเลิก",
            }).then((result) => {
                if (result.isConfirmed) {
                    localStorage.clear();
                    window.location.href = "/";
                }
            })
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ bgcolor: 'goldenrod' }}>
                        <Grid container className={"profile-container"}>
                            <Box className={"profile-model"}>
                                {position === 'member' && (
                                    <React.Fragment>
                                        <Box sx={{ flexGrow: 1 }} className={"profile-form"}>
                                            <Grid item xs={12}>
                                                <img src={require('../assets/mascot.png')} alt="img" className={'profile-img'} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item className={"profile-data"}>
                                                        <Stack spacing={{ xs: 4 }} direction="column" useFlexGap flexWrap="wrap">
                                                            <FloatLabel className="custom-InputText">
                                                                <InputText className="custom-InputText" id="card_number" value={member.card_number} disabled />
                                                                <label htmlFor="card_number">Card Number</label>
                                                            </FloatLabel>
                                                            <FloatLabel className="custom-InputText">
                                                                <InputText className="custom-InputText" id="fristname" value={member.fristname} disabled />
                                                                <label htmlFor="fristname">ชื่อ</label>
                                                            </FloatLabel>
                                                            <FloatLabel className="custom-InputText">
                                                                <InputText className="custom-InputText" id="lastname" value={member.lastname} disabled />
                                                                <label htmlFor="lastname">นามสกุล</label>
                                                            </FloatLabel>
                                                            <FloatLabel className="custom-InputText">
                                                                <InputText className="custom-InputText" id="tel" value={member.tel} disabled />
                                                                <label htmlFor="tel">เบอร์โทรศัพท์</label>
                                                            </FloatLabel>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item className={"profile-data"}>
                                                        <Stack spacing={{ xs: 4 }} direction="column" useFlexGap flexWrap="wrap">
                                                            {/* <div className="p-inputgroup flex-1"> */}
                                                            {/* <span className="p-inputgroup-addon"> */}
                                                            {/* <i className="pi pi-lock"></i> */}
                                                            {/* </span> */}
                                                            {/* <InputText placeholder="กรอกรหัสผ่านเดิม" className="custom-InputText" /> */}
                                                            {/* </div> */}
                                                            <div className="p-inputgroup flex-1">
                                                                <span className="p-inputgroup-addon">
                                                                    <i className="pi pi-key"></i>
                                                                </span>
                                                                <InputText type="password" value={password} placeholder="กรอกรหัสผ่านใหม่" className="custom-InputText"
                                                                    onChange={(e) => setPassword(e.target.value)} />
                                                            </div>
                                                            <div className="p-inputgroup flex-1">
                                                                <span className="p-inputgroup-addon">
                                                                    <i className="pi pi-check"></i>
                                                                </span>
                                                                <InputText type="password" value={confirmPassword} placeholder="กรอกรหัสผ่านยืนยัน" className="custom-InputText"
                                                                    onChange={(e) => setConfrimePassword(e.target.value)} />
                                                            </div>
                                                            <div className="p-inputgroup flex-1" style={{ justifyContent: 'center' }}>
                                                                <Button label="เปลี่ยนรหัสผ่าน" onClick={heandleResetPassword} />
                                                            </div>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item className={"profile-data"}>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </React.Fragment>
                                )}
                                {position === 'employee' && (
                                    <React.Fragment>
                                        <Box sx={{ flexGrow: 1 }} className={"profile-form"}>
                                            <Grid item xs={12}>
                                                <img src={require('../assets/mascot.png')} alt="img" className={'profile-img'} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item className={"profile-number"}>
                                                        {shops.shop_name_main} ({shops.shop_name_second})
                                                    </Grid>
                                                    <Grid item className={"profile-name"}>
                                                        Shop Number @{shops.shop_number}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </React.Fragment>
                                )}
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
};