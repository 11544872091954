import React, { useState, useRef } from "react";
import "./order.css";
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useReactToPrint } from "react-to-print";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Sidebar } from 'primereact/sidebar';
import { Divider } from 'primereact/divider';

import LoadingDialog from "../../views/LoadingView";
import ReceiptAppPremium from "../service/apppremium/ReceiptAppPremium";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getEmployee(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/employee/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getMember(tel) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/member/check/${tel}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

function datetimeFormat(date) {
	return dayjs(date).format("DD/MM/YYYY เวลา HH:mm:ss น.");
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

async function getOrderAppPremium(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/order/service/apppremium/${packageId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getOrderPurchaseId(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/order/service/apppremium/purchase/${packageId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

export default function OrderAppPremium() {
	const [isLoading, setisLoading] = useState(false);
	const [visibleDetail, setVisibleDetail] = useState(false);
	const [order, setOrderAoc] = useState([]);
	const [emp, setEmp] = useState("");
	const [employee, setEmployee] = useState([]);
	const [detail, setDetail] = useState("");
	const [order_detail, setOrderDetail] = useState([]);
	const [member, setMember] = useState({});

	const componentReceipt = useRef();
	const headlerPrintReceipt = useReactToPrint({
		content: () => componentReceipt.current,
	});

	React.useState(async () => {
		const response = await getMe();
		setisLoading(true);
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			Swal.fire({
				title: "แจ้งเตือน!",
				text: "ผู้ใช้งานดังกล่าว ไม่สามารถใช้งานระบบนี้ได้!",
				icon: "warning",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "ยืนยัน",
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = "/";
				}
			})
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				setisLoading(false);
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				setisLoading(false);
				const employee_list = await getEmployee(shop.data._id);
				const order_express = await getOrderAppPremium(shop.data._id);
				setOrderAoc(order_express.data.reverse())
				setEmployee(employee_list.data);
				setEmp(response.data)
			}
		}
	});

	const getNameEmployee = (item) => {
		const emp = employee.find(
			(el) => el._id === item
		);
		if (emp) {
			return <Chip label={emp.employee_firstname} style={{
				fontFamily: 'Kanit',
				paddingLeft: '1rem', paddingRight: '1rem',
				backgroundColor: '#FF0066',
				color: 'white',
			}} />;
		} else {
			return <Chip label='ไม่พบข้อมูลพนักงานดังกล่าว' style={{
				fontFamily: 'Kanit',
				paddingLeft: '1rem', paddingRight: '1rem',
				backgroundColor: '#FF0066',
				color: 'white',
			}} />;
		}
	};

	const getNameEmp = (item) => {
		const emp = employee.find(
			(el) => el._id === item
		);
		if (emp) {
			return emp.employee_name
		} else {
			return 'ไม่พบข้อมูลพนักงานในระบบ'
		}
	};

	const getLastStatus = (item) => {
		const status = item[item.length - 1].name;
		return <Chip label={status} style={{
			fontFamily: 'Kanit',
			paddingLeft: '1rem', paddingRight: '1rem',
			backgroundColor: getColorStatus(status),
			color: 'white'
		}} />
	};

	const getColorStatus = (item) => {
		switch (item) {
			case "รอชำระเงิน":
				return "#e5c33b";
			case "ชำระเงิน":
				return "#339900";
			default:
				return "#f43030";
		}
	};

	const getStatusColor = (item) => {
		switch (item) {
			case 'ซื้อสินค้าสำเร็จ':
				return 'bg-green-500';
			default:
				return 'bg-red-500';
		}
	};

	const headleDetail = (value) => async e => {
		setVisibleDetail(true);
		setDetail(value);
		setOrderDetail(value.product);
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"order-container"}>
								<div className="title" ><h1>ประวัติการสั่งซื้อ AppPremium</h1></div>
								<Box sx={{ width: '100%' }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item className="order-model">
											<DataTable value={order} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100]} style={{ fontFamily: 'Kanit' }}>
												<Column header="เลขที่ใบเสร็จ" body={(rowData) => (
													<Chip label={rowData.invoice} style={{
														fontFamily: 'Kanit',
														paddingLeft: '1rem', paddingRight: '1rem',
													}} />
												)}></Column>
												<Column header="เลขที่ใบสั่งซื้อ" body={(rowData) => (
													<Chip label={rowData.purchase_id} style={{
														fontFamily: 'Kanit',
														paddingLeft: '1rem', paddingRight: '1rem',
														backgroundColor: '#FFD700',
													}} />
												)} ></Column>
												<Column header="ยอดรวม (บาท)" body={(rowData) => (
													<div>
														{numberFormat(rowData.total)}
													</div>
												)}></Column>
												<Column header="การชำระเงิน" field="payment_type" body={(rowData) => (
													<Chip label={rowData.payment_type} style={{
														fontFamily: 'Kanit',
														paddingLeft: '1rem', paddingRight: '1rem',
													}} />
												)}></Column>
												<Column field="bill_status" header="สถานะ" body={(rowData) => (
													<div>
														{getLastStatus(rowData.status)}
													</div>
												)}></Column>
												<Column header="เจ้าหน้าที่" field="employee" body={(rowData) => (
													<div>
														{getNameEmployee(rowData.employee)}
													</div>
												)}></Column>
												<Column header="วันที่ทำรายการ" body={(rowData) => (
													<div>
														{datetimeFormat(rowData.timestamp)}
													</div>
												)}></Column>
												<Column header="ตัวเลือก" body={(rowData) => (
													<div>
														<Button outlined icon="pi pi-align-center" severity="help"
															label="รายละเอียด" style={{ fontFamily: 'Kanit' }} onClick={headleDetail(rowData)} />
														{emp.employee_position === 'manager' && (
															<Button outlined icon="pi pi-times" severity="danger"
																label="ยกเลิก" style={{ fontFamily: 'Kanit', marginLeft: '0.5rem' }} onClick={headleDetail(rowData)} />
														)}
													</div>
												)}></Column>
											</DataTable>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<Sidebar visible={visibleDetail} position="right" onHide={() => setVisibleDetail(false)} className="order-visible">
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ fontFamily: 'Kanit' }}>
						<h2>เลขที่ใบเสร็จ : {detail.invoice}</h2>
						<br />
						<Divider align="center">
							<Chip label="รายการสั่งซื้อ" style={{ paddingLeft: '1rem', paddingRight: '1rem', fontFamily: 'Kanit' }} />
						</Divider>
						<br />
						<DataTable value={order_detail} style={{ fontFamily: 'Kanit' }}>
							<Column body={(rowData) => (
								<img src={rowData.detail.img} alt={rowData.courier_code} width="80" />
							)} ></Column>
							<Column header="ประเภท" body={(rowData) => (
								<Chip label={rowData.detail.c_type} style={{
									paddingLeft: '1rem',
									paddingRight: '1rem',
									fontFamily: 'Kanit',
								}} />
							)} ></Column>
							<Column header="รายละเอียด" body={(rowData) => (
								<p>{rowData.detail.name} <br />
									<small><em>{rowData.detail.des}</em></small>
								</p>
							)} ></Column>
							<Column header="ราคา" field="total"></Column>
							<Column header="สถานะ" field="order_status" body={(rowData) => (
								<Chip label={rowData.order_status} className={`${getStatusColor(rowData.order_status)} text-white`} style={{ fontFamily: 'Kanit' }} />
							)} ></Column>
						</DataTable>
					</Grid>
					<Grid item xs={6} style={{ fontFamily: 'Kanit' }}>
						<Button label="พิมพ์ใบเสร็จ" icon="pi pi-print" onClick={headlerPrintReceipt} style={{ width: '70%', borderRadius: '0.5rem', fontFamily: 'Kanit' }} />
					</Grid>
				</Grid>

				<div style={{ display: 'none' }}>
					<div ref={componentReceipt} style={{ fontSize: '16px', textAlign: 'center', padding: '1rem', fontFamily: 'Kanit' }}>
						<ReceiptAppPremium data={detail} />
					</div>
				</div>
			</Sidebar>

			<LoadingDialog isLoading={isLoading} />
		</div>
	)
}