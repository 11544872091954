
import React, { useState, useRef } from "react";
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import axios from "axios";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';

import { useReactToPrint } from "react-to-print";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Panel } from 'primereact/panel';
import { Timeline } from 'primereact/timeline';
import { Image } from "primereact/image";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Divider } from 'primereact/divider';
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';

import LoadingDialog from "../../views/LoadingView";
import ReceiptPOS from "../pos/shop/ReceitPOS";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getEmployee(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/employee/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getOrderShop(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/preorder/shop/shop-id/${packageId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getProductShop(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/product/shop/shop/${packageId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

function datetimeFormatFull(date) {
	return dayjs(date).locale('th').format("DD/MM/YYYY เวลา hh:mm:ss น.");
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

function getLastStatus(item) {
	return item[item.length - 1].name;
};

const Item3 = styled(Paper)(({ theme }) => ({
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	flexGrow: 2,
	fontFamily: 'Kanit'
	// backgroundColor: 'red'
}));

export default function OrderPOS() {
	const [isLoading, setisLoading] = useState(false);
	const [employee, setEmployee] = useState([]);

	const [product_shop, setProductShop] = useState([]);

	const [visibleDetail, setVisibleDetail] = useState(false);
	const [order, setOrder] = useState([]);
	const [orders, setOrders] = useState([]);
	const [detail, setDetail] = useState("");
	const [detail_product, setDetailProduct] = useState([]);

	const [me, setMe] = useState("");
	const [emp, setEmp] = useState("");
	const [shops, setShop] = useState("");

	const [invoice, setInvoice] = useState("");

	const [dateStart, setDateStart] = useState(null);
	const [dateEnd, setDateEnd] = useState(null);

	const componentReceipt = useRef();
	const headlerPrintReceipt = useReactToPrint({
		content: () => componentReceipt.current,
	});

	useState(async () => {
		const response = await getMe();
		setisLoading(true);
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			Swal.fire({
				title: "แจ้งเตือน!",
				text: "ผู้ใช้งานดังกล่าว ไม่สามารถใช้งานระบบนี้ได้!",
				icon: "warning",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "ยืนยัน",
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = "/";
				}
			})
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				setisLoading(false);
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				setisLoading(false);
				setMe(response.data);
				setEmp(response.data);
				setShop(shop.data);
				const emp = await getEmployee(shop.data._id);
				setEmployee(emp.data);
				const resp_order = await getOrderShop(shop.data._id);
				// const resp_order = resp.data.filter(
				// (el) => el.poshop_cutoff === false
				// );
				setOrder(resp_order.data.reverse());
				setOrders(resp_order.data);
				const product = await getProductShop(shop.data._id);
				setProductShop(product.data);
			}
		}
	});

	const headleDetail = (value) => async e => {
		setVisibleDetail(true);
		setDetail(value);
		setDetailProduct(value.poshop_detail);
	};

	const cancelOrderPOS = (value) => async e => {
		if (me.employee_position !== 'manager') {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ผู้ใช้งานดังกล่าวไม่สามารถยกเลิกรายการได้',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (getLastStatus(value.poshop_status) !== 'ชำระเงิน') {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'รายการดังกล่าวไม่สามารถยกเลิกได้',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		Swal.fire({
			title: "แจ้งเตือน!",
			text: "ท่านต้องการยกเลิกรายการดังกล่าวใช่หรือไม่!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "ยืนยัน",
			cancelButtonText: "ยกเลิก",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setisLoading(true);
				const token = JSON.parse(localStorage.getItem('token'));
				value.poshop_detail.forEach(async (item) => {
					const product = product_shop.find((el) => el._id === item._id);
					await axios.put(`${process.env.REACT_APP_SHOP}/product/shop/${item._id}`, {
						productShop_stock: product.productShop_stock + item.amount,
					}, {
						headers: {
							'auth-token': `Bearer ${token}`
						}
					}).catch((err) => {
						Swal.fire({
							title: 'ผิดพลาด!',
							text: err.response.data.message,
							icon: 'error',
							showConfirmButton: false,
							timer: 3000,
						});
					})
				});
				await axios.put(`${process.env.REACT_APP_SHOP}/preorder/shop/${value._id}`, {
					poshop_identity: false,
					poshop_employee: emp._id,
				}, {
					headers: {
						'auth-token': `Bearer ${token}`
					}
				}).then(async () => {
					setisLoading(false);
					Swal.fire({
						title: 'ยกเลิกสำเร็จ!',
						text: 'ยกเลิกใบสั่งซื้อเรียบร้อยแล้ว',
						icon: 'success',
						showConfirmButton: false,
						timer: 3000,
					});
					const resp_order = await getOrderShop(shops._id);
					setOrder(resp_order.data.reverse());
					setOrders(resp_order.data);
				}).catch((err) => {
					setisLoading(false);
					Swal.fire({
						title: 'ผิดพลาด!',
						text: err.response.data.message,
						icon: 'error',
						showConfirmButton: false,
						timer: 3000,
					});
				})
			}
		})
	};

	const getNameEmployee = (item) => {
		const emp = employee.find(
			(el) => el._id === item
		);
		if (emp) {
			return <Chip label={emp.employee_firstname} style={{
				fontFamily: 'Kanit',
				paddingLeft: '1rem', paddingRight: '1rem',
				backgroundColor: '#FF0066',
				color: 'white',
			}} />;
		} else {
			return <Chip label='ไม่พบข้อมูลพนักงานดังกล่าว' style={{
				fontFamily: 'Kanit',
				paddingLeft: '1rem', paddingRight: '1rem',
				backgroundColor: '#FF0066',
				color: 'white',
			}} />;
		}
	};

	const getColorStatus = (item) => {
		switch (item) {
			case "รอชำระเงิน":
				return "#e5c33b";
			case "ชำระเงิน":
				return "#339900";
			default:
				return "#f43030";
		}
	};

	const footerGroup = (
		<ColumnGroup>
			<Row>
				<Column footer="ส่วนลด :" colSpan={3} footerStyle={{ textAlign: 'left' }} />
				<Column footer={numberFormat(detail.poshop_discount == null ? '' : detail.poshop_discount)} />
			</Row>
			<Row>
				<Column footer="ยอดสุทธิ :" colSpan={3} footerStyle={{ textAlign: 'left' }} />
				<Column footer={numberFormat(detail.poshop_total == null ? '' : detail.poshop_total)} />
			</Row>
		</ColumnGroup>
	);

	const searchReciept = async e => {
		if (e.key === 'Enter') {
			const resp = orders.filter((el) => el.poshop_invoice.toLowerCase().includes(invoice.toLowerCase()));
			setOrder(resp)
			if (invoice === '') {
				setOrder(orders)
			}
		}
	};

	const searchDate = async e => {
		if (dateStart === null || dateEnd === null) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณาเลือกช่วงวันที่ต้องการค้นหา',
				icon: 'warning',
				showConfirmButton: false,
				timer: 1500,
			});
			return false;
		}
		const resp = orders.filter((el) => dayjs(el.poshop_timestamp).format("DD/MM/YYYY") >= dayjs(dateStart).format("DD/MM/YYYY") &&
			dayjs(el.poshop_timestamp).format("DD/MM/YYYY") <= dayjs(dateEnd).format("DD/MM/YYYY"));
		setOrder(resp)
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"order-container"}>
								<div className="title" ><h1>ประวัติการขายสินค้า</h1></div>
								<Box sx={{ width: '100%', height: 'auto' }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
											<div style={{ width: '50%' }}>
												<Panel header="ค้นหา">
													<Divider align="center">
														<Chip label="เลขที่ใบเสร็จ" style={{
															fontFamily: 'Kanit',
															paddingLeft: '1rem',
															paddingRight: '1rem',
														}} />
													</Divider>
													<Grid item xs={12} style={{ marginTop: '1rem' }}>
														<FloatLabel className="custom-InputText">
															<InputText className="custom-InputText" id="invoice" value={invoice} onChange={(e) => setInvoice(e.target.value)} onKeyUp={searchReciept} />
															<label htmlFor="invoice">ค้นหาเลขที่ใบเสร็จ</label>
														</FloatLabel>
													</Grid>
													<Divider align="center">
														<Chip label="ตามช่วงวันที่" style={{
															fontFamily: 'Kanit',
															paddingLeft: '1rem',
															paddingRight: '1rem',
														}} />
													</Divider>
													<Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
														<Item3 sx={{ padding: 0 }}>
															<FloatLabel className="custom-InputText">
																<Calendar id="dateStart" value={dateStart} onChange={(e) => setDateStart(e.value)} showIcon style={{ width: '100%' }} dateFormat="dd/mm/yy" />
																<label htmlFor="dateStart" className="font-bold block mb-2">
																	วันที่เริ่มต้น
																</label>
															</FloatLabel>
														</Item3>
														<Item3 sx={{ padding: 0 }}>
															<FloatLabel className="custom-InputText">
																<Calendar id="buttondisplay" value={dateEnd} onChange={(e) => setDateEnd(e.value)} showIcon style={{ width: '100%' }} dateFormat="dd/mm/yy" />
																<label htmlFor="buttondisplay" className="font-bold block mb-2">
																	วันที่สิ้นสุด
																</label>
															</FloatLabel>
														</Item3>
													</Stack>
													<Grid item xs={12} style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
														<Button label="ค้นหา" icon="pi pi-fw pi-search" onClick={searchDate} />
													</Grid>
												</Panel>
											</div>
										</Grid>
									</Grid>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item className="order-model" style={{ marginTop: '2rem' }}>
											<DataTable value={order} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100]} style={{ fontFamily: 'Kanit' }}>
												<Column header="เลขที่ใบเสร็จ" body={(item) => (
													<Chip label={item.poshop_invoice} style={{
														fontFamily: 'Kanit',
														paddingLeft: '1rem', paddingRight: '1rem',
													}} />
												)}></Column>
												<Column header="รายการ" body={(item) => (
													<div>
														{item.poshop_detail.length}
													</div>
												)}></Column>
												<Column header="ยอดรวม" body={(item) => (
													<div>
														{numberFormat(item.poshop_total)}
													</div>
												)}></Column>
												<Column header="การชำระเงิน" body={(item) => (
													<Chip label={item.poshop_paymenttype} style={{
														fontFamily: 'Kanit',
														paddingLeft: '1rem', paddingRight: '1rem',
													}} />
												)}></Column>
												<Column header="เจ้าหน้าที่" body={(item) => (
													<div>
														{getNameEmployee(item.poshop_employee)}
													</div>
												)}></Column>
												<Column header="วันเวลา" body={(item) => (
													<div>
														{datetimeFormatFull(item.poshop_timestamp)}
													</div>
												)}></Column>
												<Column header="สถานะ" body={(item) => (
													<div>
														<Chip label={getLastStatus(item.poshop_status)} style={{
															fontFamily: 'Kanit',
															paddingLeft: '1rem',
															backgroundColor: getColorStatus(getLastStatus(item.poshop_status)),
															paddingRight: '1rem',
															color: 'white',
														}} />
													</div>
												)}></Column>
												<Column header="ตัวเลือก" body={(item) => (
													<div>
														<Button outlined icon="pi pi-align-center" severity="help"
															label="รายละเอียด" onClick={headleDetail(item)} />
														{(item.poshop_identity === true && item.poshop_cutoff === false) && (
															<Button label="ยกเลิกรายการ" severity="danger" style={{ marginLeft: '1rem' }} onClick={cancelOrderPOS(item)} />
														)}
													</div>
												)}></Column>
											</DataTable>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid >
			</Box >

			<Sidebar visible={visibleDetail} position="right" onHide={() => setVisibleDetail(false)} className="order-visible">
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ fontFamily: 'Kanit' }}>
						<h2>เลขที่ใบเสร็จ : {detail.poshop_invoice}</h2>
						<p>เวลาสั่งซื้อ : {datetimeFormatFull(detail.poshop_timestamp)}</p>
						<DataTable value={detail_product} style={{ fontFamily: 'Kanit' }} footerColumnGroup={footerGroup}>
							<Column header="รายการ" style={{ width: '50%' }} body={(item) => (
								<div>
									{item.product_ref.productTG_name}
								</div>
							)}></Column>
							<Column header="ราคา/หน่วย" body={(item) => (
								<div>
									{numberFormat(item.product_ref.productTG_price.price)}
								</div>
							)}></Column>
							<Column header="จำนวน" body={(item) => (
								<div>
									{item.amount}
								</div>
							)}></Column>
							<Column header="รวม" body={(item) => (
								<div>
									{numberFormat(item.total)}
								</div>
							)}></Column>
						</DataTable>
					</Grid>

					<Grid item xs={6} style={{ fontFamily: 'Kanit' }}>
						<Button label="พิมพ์ใบเสร็จ" className="button" icon="pi pi-print" onClick={headlerPrintReceipt} style={{ width: '70%' }} />
					</Grid>
				</Grid>

				<div style={{ display: 'none' }}>
					<div ref={componentReceipt} style={{ fontSize: '10px', textAlign: 'center', padding: '0rem', fontFamily: 'Kanit' }}>
						<ReceiptPOS data={detail} />
					</div>
				</div>
			</Sidebar>

			<LoadingDialog isLoading={isLoading} />
		</div >
	)
};
