import React, { useState } from "react";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { styled } from '@mui/material/styles';

import { ScrollPanel } from 'primereact/scrollpanel';
import { Chip } from "primereact/chip";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { ButtonGroup } from 'primereact/buttongroup';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from 'primereact/card';
import LoadingDialog from "../../../views/LoadingView";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getProduct() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/product/tossagun/by/credit`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getInvoiceTax(packageData) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/invoice-tax/tossagun/preorder`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json())
};

async function preorderTossagun(packageData) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/preorder/tossagun`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json())
};

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Item2 = styled(Paper)(({ theme }) => ({
	backgroundColor: '#ECD400',
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Item3 = styled(Paper)(({ theme }) => ({
	...theme.typography.body - 2,
	// padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	flexGrow: 2,
}));

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export default function CartProduct() {
	const [isLoading, setisLoading] = useState(false);
	const [position, setPosition] = useState({});
	const [member, setMember] = useState({});
	const [employee, setEmployee] = useState({});
	const [shop, setShop] = useState({});

	// Product Tossagun
	const [product, setProduct] = useState([]);
	const [dialogAmount, setDialogAmount] = useState(false);
	const [dialogUpdateAmount, setDialogUpdateAmount] = useState(false);
	const [amount, setAmount] = useState(1);
	const [updateAmount, setUpdateAmount] = useState(null);
	const [detail, setDetail] = useState('');
	const [updateDetail, setUpdateDetail] = useState('');

	// Order
	const [order, setOrder] = useState([]);

	useState(async () => {
		setisLoading(true);
		const response = await getMe();
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			Swal.fire({
				title: "แจ้งเตือน!",
				text: "ผู้ใช้งานดังกล่าว ไม่สามารถใช้งานระบบนี้ได้!",
				icon: "warning",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "ยืนยัน",
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = "/";
				}
			})
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				setisLoading(false);
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
				return false;
			} else {
				setisLoading(false);
				if (response.data.employee_position !== 'manager') {
					Swal.fire({
						title: 'แจ้งเตือน!',
						text: 'คุณไม่สามารถใช้งานระบบดังกล่าวได้',
						icon: 'warning',
						showConfirmButton: false,
						timer: 1500,
					});
					return false;
				}
				setPosition(shop.data.shop_type);
				setEmployee(response.data)
				setShop(shop.data);
				// Product
				const resp = await getProduct();
				setProduct(resp.data);
			}
		}
	});

	const ImageBodyTemplate = (item) => {
		return <img src={`${process.env.REACT_APP_SHOP}/product/tossagun/image/${item}`} alt="" width={'70%'} />
	};

	const headleGetProduct = async (e, item) => {
		setDetail(item);
		setDialogAmount(true);
	};

	const calculateTotal = () => {
		return order.reduce((total, item) => total + item.total, 0);
	};

	const headleConfirmAmount = () => {
		const total = detail.productTG_cost.cost_net * amount;
		const product = {
			...detail,
			amount: amount,
			total: Number(total.toFixed(2)),
		};

		const check_data = order.find((el) => el._id === product._id);
		if (check_data) {
			const position = order.findIndex(
				(el) => el._id === product._id
			);
			const new_item = {
				...check_data,
				total: check_data.total + product.total,
				amount: check_data.amount + product.amount,
			};
			order.splice(position, 1, new_item);
			setOrder([...order]);
		} else {
			setOrder([...order, product]);
		}

		Swal.fire({
			title: 'สำเร็จ',
			text: 'เพิ่มสินค้าในตะกร้าเรียบร้อยแล้ว',
			icon: 'success',
			showConfirmButton: false,
			timer: 1000,
		});
		setAmount(1);
		setDialogAmount(false);
	};

	const headleCancelAmount = () => {
		setDialogAmount(false);
		setAmount(1);
	};

	const headleUpdateProduct = (value) => e => {
		setUpdateDetail(value);
		setUpdateAmount(value.amount);
		setDialogUpdateAmount(true);
	};

	const headleDeleteProduct = (value) => e => {
		Swal.fire({
			title: "ลบสินค้า",
			icon: "warning",
			text: "คุณต้องการลบสินค้าดังกล่าวออกจากตะกร้าใช่หรือไม่?",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "ยืนยัน",
			cancelButtonText: "ยกเลิก",
		}).then((result) => {
			if (result.isConfirmed) {
				const position = order.findIndex((el) => el.id === value.id);
				order.splice(position, 1);
				setOrder([...order]);
			}
		})
	};

	const headleConfirmUpdateAmount = () => {

		const total = updateDetail.productTG_cost.cost_net * updateAmount;

		const new_data = {
			...updateDetail,
			amount: updateAmount,
			total: Number(total.toFixed(2))
		};
		const position = order.findIndex(
			(el) => el._id === updateDetail._id
		);
		order.splice(position, 1, new_data);
		setOrder([...order]);

		Swal.fire({
			title: 'สำเร็จ',
			text: 'อัพเดทสินค้าสำเร็จ',
			icon: 'success',
			showConfirmButton: false,
			timer: 1000,
		});

		setDialogUpdateAmount(false);
	};

	const headleSendOrder = () => {
		const total = order.reduce((sum, el) => (sum + el.total), 0);
		if (shop.shop_credit < total) {
			Swal.fire({
				title: 'แจ้งเตือน',
				text: 'ยอดเครดิตคงเหลือร้านค้าของท่านไม่เพียงพอ กรุณาตรวจสอบสินค้าภายในตะกร้า',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		Swal.fire({
			title: "ยืนยันการสั่งสินค้า",
			icon: "warning",
			// text: "คุณต้องการลบสินค้าดังกล่าวออกจากตะกร้าใช่หรือไม่?",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "ยืนยัน",
			cancelButtonText: "ยกเลิก",
		}).then(async (result) => {
			if (result.isConfirmed) {
				const data = {
					date: dayjs(Date.now()).format(),
				};
				const invoice = await getInvoiceTax(data);

				const item_order = {
					ponba_shop_id: shop._id,
					ponba_number: invoice.invoice_preorder_nba,
					ponba_detail: order,
					ponba_total: total,
					ponba_status: "รอตรวจสอบ",
					ponba_timestamp: [
						{
							name: "รอตรวจสอบ",
							timestamp: dayjs(Date.now()).format(),
						},
					],
					ponba_emp_shop: `${employee.employee_firstname} ${employee.employee_lastname} ${dayjs(Date.now()).format("DD/MM/YYYY เวลา HH:mm:ss น.")}`,
				};

				setisLoading(true);
				const resp = await preorderTossagun(item_order);

				if (resp.status) {
					setisLoading(false);
					Swal.fire({
						title: 'สำเร็จ',
						text: 'สั่งสินค้าสำเร็จกรุณารอแอดมินตรวจสอบและดำเนินการ',
						icon: 'success',
						showConfirmButton: false,
						timer: 3000,
					});
					setOrder([]);
				} else {
					Swal.fire({
						title: 'แจ้งเตือน',
						text: 'มีบางอย่างผิดพลาดกรุณาดำเนินการใหม่อีกครั้ง',
						icon: 'error',
						showConfirmButton: false,
						timer: 3000,
					});
				}
			}
		})
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"container"}>
								<div className="title" ><h1>สั่งซื้อสินค้าเข้าร้าน</h1></div>
								<Box sx={{ width: '100%' }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item xs={5}>
											<Grid className="form">
												<Grid container>
													<Grid sx={{ width: '100%', paddingRight: 1 }}>
														<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ตะกร้าของฉัน</Item>
														<Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
															<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
																<Grid item xs={4}>
																	<Card style={{ backgroundColor: 'red', color: 'white', fontFamily: 'Kanit' }}>
																		<p style={{ fontSize: '14px', padding: '0rem', margin: '0rem' }}>หักเครดิตสุทธิ/บาท</p>
																		<h2 style={{ padding: '0rem', margin: '0rem' }}>{numberFormat(calculateTotal())}</h2>
																	</Card>
																</Grid>
																<Grid item xs={4}>
																	<Card style={{ backgroundColor: 'green', color: 'white', fontFamily: 'Kanit' }}>
																		<p style={{ fontSize: '14px', padding: '0rem', margin: '0rem' }}>สินค้า/รายการ</p>
																		<h2 style={{ padding: '0rem', margin: '0rem' }}>{order.length}</h2>
																	</Card>
																</Grid>
																<Grid item xs={4}>
																	<Card style={{ backgroundColor: 'purple', color: 'white', fontFamily: 'Kanit' }}>
																		<p style={{ fontSize: '14px', padding: '0rem', margin: '0rem' }}>เครดิตที่มี/บาท</p>
																		<h2 style={{ padding: '0rem', margin: '0rem' }}>{numberFormat(shop.shop_credit == null ? '' : shop.shop_credit)}</h2>
																	</Card>
																</Grid>
															</Grid>
															<DataTable value={order} style={{ fontFamily: 'Kanit', marginTop: '0.5rem' }}>
																<Column header="รายการ" body={(rowData) => (
																	<div>
																		<b>{rowData.productTG_name}</b>
																	</div>
																)}></Column>
																<Column field="amount" header="จำนวน"></Column>
																<Column header="รวม" body={(rowData) => (
																	<div>
																		{rowData.total}
																	</div>
																)}></Column>
																<Column></Column>
																<Column header="ตัวเลือก" body={(rowData) => (
																	<div>
																		<Button icon="pi pi-plus" className="p-button-rounded p-button-outlined" style={{ color: 'green' }}
																			onClick={headleUpdateProduct(rowData)} />
																		<Button icon="pi pi-trash" className="p-button-rounded p-button-outlined"
																			style={{ color: 'red', marginLeft: '0.5rem' }} onClick={headleDeleteProduct(rowData)} />
																	</div>
																)}></Column>
															</DataTable>
															<Grid container spacing={1} sx={{ padding: 0 }} columnSpacing={{ xs: 3 }}>
																<Grid item sx={{ width: '100%', height: '100%' }}>
																	<Grid className='step2item'>
																		<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', height: '100%' }}>
																			<Button label="ส่งรายการสั่งซื้อ (Pre-Order)" icon="pi pi-send" style={{ backgroundColor: 'purple', color: 'white', marginTop: '1rem', width: '100%' }}
																				className="button" onClick={headleSendOrder} />
																		</Item3>
																	</Grid>
																</Grid>
															</Grid>
														</Item2>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={7}>
											<Grid className="form">
												<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>
													<Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
														<Item3 sx={{ padding: 0 }}>
															<FloatLabel className="custom-InputText">
																<InputText className="custom-InputText" id="barcode" placeholder="สแกนบาร์โค้ดในช่องนี้" />
															</FloatLabel>
														</Item3>
													</Stack>
												</Item>
												<Item2 sx={{ width: '100%', height: '100%', padding: '1rem', paddingTop: '1.5rem', borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
													<ScrollPanel style={{ height: '80vh' }}>
														<Grid container spacing={1} sx={{ padding: 0 }} columnSpacing={{ xs: 3 }}>
															{product.slice().map((item, index) => (
																<Grid item xs={4} sx={{ width: '50%', height: '100%' }} key={index}>
																	<Grid className='step2item'>
																		<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', height: '100%' }}>
																			{/* {ImageBodyTemplate(item.productTG_image)} */}
																			{item.productTG_image ? (
																				ImageBodyTemplate(item.productTG_image)
																			) : (
																				<img src={require('../../../assets/logo.png')} alt="" style={{ width: '70%' }} />
																			)}
																		</Item3>
																		<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#000000', fontSize: '18px' }} style={{ fontFamily: 'Kanit' }}>
																			{/* <b>{item.productTG_name}</b> */}
																			<p>
																				<strong>{item.productTG_name}</strong>
																				<br />
																				<small>บาร์โค้ด : {item.productTG_barcode}</small>
																			</p>
																			{/* <br /> */}
																			{/* ต้นทุน : {numberFormat(item.productTG_cost.cost_net)} บาท */}
																			{/* <br /> */}
																			{/* <Chip label={`มี ${item.productTG_stock} ชิ้น`} style={{ fontFamily: 'Kanit', fontSize: '12px' }} /> */}
																		</Item3>
																		<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#000000', fontSize: '12px' }} style={{ fontFamily: 'Kanit' }}>
																			<p>
																				กำไร/ชิ้น : {numberFormat(item.productTG_price.price - item.productTG_cost.cost_net)} บาท
																				<br />
																				ราคาหน้าร้าน : {numberFormat(item.productTG_price.price)} บาท
																				<br />
																				ต้นทุน : {numberFormat(item.productTG_cost.cost_net)} บาท
																			</p>
																			<Button label="รายละเอียด" style={{ backgroundColor: 'gray', color: 'white', margin: '0.5rem' }} className="button" />
																			<Button label="เพิ่มในตะกร้า" style={{ backgroundColor: 'orange', color: 'white', margin: '0.5rem' }} className="button" onClick={(e) => headleGetProduct(e, item)} />
																		</Item3>
																	</Grid>
																</Grid>
															))}
														</Grid>
													</ScrollPanel>
												</Item2>
											</Grid>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>

			{/* Dialog Amount */}
			<Dialog header="จำนวนสั่งซื้อ" visible={dialogAmount} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogAmount) return; setDialogAmount(false); }}
				breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ marginTop: '1rem' }}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="amount" value={amount} onChange={(e) => setAmount(e.value)} />
							<label htmlFor="amount">จำนวน</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="ยืนยัน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmAmount} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={headleCancelAmount} />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>

			{/* Dialog Amount */}
			<Dialog header="จำนวนสั่งซื้อ" visible={dialogUpdateAmount} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogUpdateAmount) return; setDialogUpdateAmount(false); }}
				breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ marginTop: '1rem' }}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="updateAmount" value={updateAmount} onChange={(e) => setUpdateAmount(e.value)} />
							<label htmlFor="updateAmount">จำนวน</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="ยืนยัน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmUpdateAmount} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={(e) => setDialogUpdateAmount(false)} />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>

			<LoadingDialog isLoading={isLoading} />
		</div>
	)
}