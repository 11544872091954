import React, { useState } from "react";
import axios from "axios";
import { styled } from '@mui/material/styles';

import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { ButtonGroup } from 'primereact/buttongroup';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Message } from 'primereact/message';

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json());
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json());
};

async function getWebsite() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/service/program`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json());
};

async function GetMember(reftel) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/member/check/${reftel}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function callcheckout(packageData) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/service/program/order`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json());
};

async function getProvince() {
	return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json');
};

async function getDistrict() {
	return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json');
};

async function getSubDistrict() {
	return await axios.get('https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_tambon.json');
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Item2 = styled(Paper)(({ theme }) => ({
	backgroundColor: '#ECD400',
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Item3 = styled(Paper)(({ theme }) => ({
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	flexGrow: 2,
}));

export default function WebsiteView() {
	const [loading, setLoading] = useState(false);
	const [position, setPosition] = useState({});
	const [member, setMember] = useState({});
	const [employee, setEmployee] = useState({});
	const [shop, setShop] = useState({});

	// Product
	const [product, setProduct] = useState([]);

	// Customer
	const [item_province, setItemProvince] = useState([]);
	const [item_district, setItemDistrict] = useState([]);
	const [item_subdistrict, setItemSubDistrict] = useState([]);

	const [province, setProvince] = useState('');
	const [district, setDistrict] = useState('');
	const [subdistrict, setSubdistrict] = useState('');
	const [postcode, setPostcode] = useState('');
	const [address, setAddress] = useState('');
	const [name, setName] = useState('');
	const [tel, setTel] = useState('');
	const [iden, setIden] = useState('');
	const [line, setLine] = useState('');

	// ทศกัณฐ์แฟมมิลี
	const [reftel, setReftel] = useState('');
	const [refteldisable, setReftelDisable] = useState(false);

	// detail
	const [dialogDetail, setDialogDetail] = useState(false);
	const [detail, setDetail] = useState({});
	const [description, setDescription] = useState("");
	const [amount, setAmount] = useState(1);

	// Order
	const [order_website, setOrderWebsite] = useState([]);
	const [order_details, setOrderDetail] = useState([]);

	// ชำระเงิน
	const [dialogCheckOut, setDialogCheckout] = useState(false);
	const [paymenttype, setPaymentType] = useState('');
	const [moneyreceive, setMoneyReceive] = useState(0);
	const [disabledMoneyReceive, setDisabledMoneyReceive] = useState(false);
	const [change, setChange] = useState(0);

	useState(async () => {
		const response = await getMe();
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setPosition('One Stop Platform')
			setMember(response.data)
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				setPosition('One Stop Service')
				setEmployee(response.data);
				setShop(shop.data);
				const provinces = await getProvince();
				setItemProvince(provinces.data);
				// Product
				const product = await getWebsite();
				setProduct(product.data);
			}
		}
	});

	const handleDistrict = async (e) => {
		e.preventDefault();
		const response = await getDistrict();
		// console.log(response.data)
		const filtered = response.data;
		const districts = filtered.filter(el => el.province_id === e.value.id);
		setProvince(e.value);
		setItemDistrict(districts);
	};
	const handleSubDistrict = async (e) => {
		e.preventDefault();
		const response = await getSubDistrict();
		const filtered = response.data;
		const subdistricts = filtered.filter(el => el.amphure_id === e.value.id);
		setDistrict(e.value);
		setItemSubDistrict(subdistricts);
	};
	const handlePostcode = (e) => {
		e.preventDefault();
		setSubdistrict(e.value);
		setPostcode(e.value.zip_code);
	};
	const countryOptionTemplate = (option) => {
		return (
			<div>
				{option.name_th}
			</div>
		);
	};

	const ImageBodyTemplate = (item) => {
		return <img src={`${process.env.REACT_APP_SHOP}/service/program/image/${item}`} alt={item} width={'70%'} />
	};

	const headleGetDetail = async (e, item) => {
		setDetail(item);
		setDialogDetail(true);
	};

	const onHideDialogDetail = () => {
		setDialogDetail(false);
	};

	const handleAddOrder = () => {
		const total = detail.price * amount;
		const order = {
			id: detail._id,
			name: detail.name,
			price: detail.price,
			amount: amount,
			total_price: total,
		};
		const order_detail = {
			packageid: detail._id,
			packagedetail: description,
			quantity: amount,
		};
		setOrderWebsite([...order_website, order]);
		setOrderDetail([...order_details, order_detail]);
		setDialogDetail(false);
	};

	// Check Out 
	const calculateTotal = () => {
		return order_website.reduce((total, item) => total + item.total_price, 0);
	};

	const headleCheckReftel = async () => {
		if (reftel === "") {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกทศกัฐ์แฟมมิลี่',
				icon: 'warning',
				showConfirmButton: false,
				timer: 1500,
			});
			return false;
		} else {
			const response = await GetMember(reftel);
			if (response.status) {
				Swal.fire({
					title: "ทศกัณฐ์แฟมมิลี่?",
					icon: "success",
					text: `เบอร์โทรศัพท์ ${response.data.tel} คุณ ${response.data.fristname} ${response.data.lastname}`,
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "ยืนยัน",
					cancelButtonText: "ยกเลิก",
				}).then((result) => {
					if (result.isConfirmed) {
						setReftelDisable(true);
					}
				})
			} else {
				Swal.fire({
					title: 'ทศกัณฐ์แฟมมิลี่!',
					text: 'ไม่พบข้อมูลทศกัณฐ์แฟมมิลี่',
					icon: 'error',
					showConfirmButton: false,
					timer: 3000,
				});
				return false;
			}
		}
	};

	const headlerClearReftel = async () => {
		Swal.fire({
			title: "เปลี่ยนทศกัณฐ์แฟมมิลี่?",
			icon: "warning",
			text: `คุณต้องการเปลี่ยนทศกัณฐ์แฟมมิลี่ ใช่หรือไม่?`,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "ยืนยัน",
			cancelButtonText: "ยกเลิก",
		}).then((result) => {
			if (result.isConfirmed) {
				setReftelDisable(false);
				setReftel('');
			}
		})
	};

	const heandleCheckOut = async () => {
		if (order_website.length === 0) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ไม่มีรายการให้ชำระเงิน กรุณาเลือกสินค้าเพื่อทำรายการ',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (name === '' ||
			tel === '' ||
			iden === '' ||
			line === '' ||
			address === '' ||
			province === '' ||
			subdistrict === '' ||
			district === '' ||
			postcode === ''
		) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกข้อมูลลูกค้าให้ครบถ้วน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (reftel === '' || refteldisable === false) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกและยืนยัน ทศกัณฐ์แฟมมิลี่',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (shop.shop_wallet < calculateTotal()) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ยอดเงินในระบบไม่เพียงต่อการชำระเงิน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		setDialogCheckout(true);
	};

	const headlerPaymentType = (e, value) => {
		if (value === 'เงินสด') {
			setPaymentType(value);
			setMoneyReceive(0);
			setDisabledMoneyReceive(false);
		} else {
			setPaymentType(value);
			setDisabledMoneyReceive(true);
			setMoneyReceive(calculateTotal());
		}
	};

	const onHideDialogCheckout = () => {
		setDialogCheckout(false);
	};

	const headleConfirmCheckout = async () => {
		if (moneyreceive < calculateTotal()) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'จำนวนเงินที่กรอก ไม่เพียงพอต่อการชำระเงิน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		const changes = moneyreceive - calculateTotal();
		setChange(changes);
		const data = {
			maker_id: employee._id,
			shop_id: shop._id,
			customer_name: name,
			customer_tel: tel,
			customer_address: `${address}, ${subdistrict.name_th}, ${district.name_th}, ${province.name_th}, ${postcode}`,
			customer_iden: iden,
			customer_line: line,
			product_detail: order_details,
			shop_type: position,
			paymenttype: paymenttype,
			moneyreceive: moneyreceive,
			change: changes,
			platform: reftel,
			employee: employee._id,
		};
		const resq = await callcheckout(data);
		if (resq.status === true) {
			setDialogCheckout(false);
			Swal.fire({
				title: "ทำรายการสำเร็จ",
				icon: "success",
				text: `คุณได้ทำรายการสั่งซื้อ ใบเสร็จเลขที่ ${resq.data.invoice} สำเร็จ`,
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "ทำรายการต่อ",
				cancelButtonText: "ตรวจสอบใบสั่งซื้อ",
			}).then((result) => {
				if (result.isConfirmed) {
					ClearDataCus();
					setOrderDetail([]);
					setOrderWebsite([]);
					setReftelDisable(false);
				} else if (result.isDenied) {
				}
			})
		}
	};

	function ClearDataCus() {
		setName('');
		setAddress('');
		setProvince('');
		setSubdistrict('');
		setDistrict('');
		setPostcode('');
		setTel('');
		setIden('');
		setLine('');
		setReftel('');
	};

	return (
		<div>
			{position === 'One Stop Service' && (
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Box>
								<Grid container className={"container"}>
									<div className="title" ><h1>ออกแบบและพัฒนาเว็บไซต์</h1></div>
									<Box sx={{ width: '100%' }}>
										<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
											<Grid item xs={5}>
												<Grid className="form">
													<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ข้อมูลลูกค้า</Item>
													<Item2 sx={{ width: '100%', height: '100%', padding: '1rem', paddingTop: '1.5rem', borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
														<Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
															<Item3 sx={{ padding: 0 }}>
																<FloatLabel className="custom-InputText">
																	<InputText className="custom-InputText" id="username" value={name} onChange={(e) => setName(e.target.value)} />
																	<label htmlFor="username">ชื่อ-นามสกุล</label>
																</FloatLabel>
															</Item3>
															<Item3 sx={{ padding: 0 }}>
																<FloatLabel className="custom-InputText">
																	<InputText className="custom-InputText" id="tel" value={tel} onChange={(e) => setTel(e.target.value)} />
																	<label htmlFor="tel">เบอร์โทร</label>
																</FloatLabel>
															</Item3>
														</Stack>
														<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
															<Item3 sx={{ padding: 0 }}>
																<FloatLabel className="custom-InputText">
																	<InputText className="custom-InputText" id="iden" value={iden} onChange={(e) => setIden(e.target.value)} />
																	<label htmlFor="iden">เลขประจำตัวประชาชน <small><strong>หมายเหตุ : </strong>เพื่อใช้ในการออกใบกำกับภาษี</small></label>
																</FloatLabel>
															</Item3>
															<Item3 sx={{ padding: 0 }}>
																<FloatLabel className="custom-InputText">
																	<InputText className="custom-InputText" id="line" value={line} onChange={(e) => setLine(e.target.value)} />
																	<label htmlFor="line">Line ID</label>
																</FloatLabel>
															</Item3>
														</Stack>
														<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
															<Item3 sx={{ padding: 0 }}>
																<FloatLabel className="custom-InputText">
																	<InputText className="custom-InputText" id="address" value={address} onChange={(e) => setAddress(e.target.value)} />
																	<label htmlFor="address">อยู่จัดส่ง (ถ้ามี)<small><strong>หมายเหตุ : </strong> กรณีสินค้ามีการจัดส่งจะต้อง กรอกที่อยู่จัดส่งให้เรียบร้อย</small></label>
																</FloatLabel>
															</Item3>
														</Stack>
														<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
															<Item3 sx={{ padding: 0, width: '40%' }}>
																<Dropdown value={province} onChange={(e) => handleDistrict(e)} filter options={item_province} optionLabel="name_th" placeholder="จังหวัด"
																	itemTemplate={countryOptionTemplate} className="custom-InputText" />
															</Item3>
															<Item3 sx={{ padding: 0, width: '40%' }}>
																<Dropdown value={district} onChange={(e) => handleSubDistrict(e)} options={item_district} optionLabel="name_th" placeholder="อำเภอ"
																	itemTemplate={countryOptionTemplate} className="custom-InputText" />
															</Item3>
														</Stack>
														<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
															<Item3 sx={{ padding: 0, width: '40%' }}>
																<Dropdown value={subdistrict} onChange={(e) => handlePostcode(e)} options={item_subdistrict} optionLabel="name_th" placeholder="ตำบล"
																	className="custom-InputText" />
															</Item3>
															<Item3 sx={{ padding: 0, width: '40%' }}>
																<FloatLabel className="custom-InputText">
																	<InputText className="custom-InputText" id="zipCode" value={postcode} readOnly />
																	<label htmlFor="zipCode">รหัสไปรษณีย์</label>
																</FloatLabel>
															</Item3>
														</Stack>
													</Item2>
												</Grid>
												<Grid className="form">
													<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>รายการสินค้า</Item>
													<Item2 sx={{ width: '100%', height: '100%', padding: '1rem', paddingTop: '1.5rem', borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
														<Grid container spacing={1} sx={{ padding: 0 }} columnSpacing={{ xs: 3 }}>
															{order_website.length !== 0 && (
																<React.Fragment>
																	<Message severity="warn" text="ท่านมีรายการค้างชำระอยู่ กรุณาชำระรายการดังกล่าว เพื่อทำรายการต่อ" style={{ width: '100%', fontFamily: 'Kanit' }} />
																</React.Fragment>
															)}
															{order_website.length === 0 && (
																<React.Fragment>
																	{product.slice().map((item, index) => (
																		<Grid item xs={4} sx={{ width: '50%', height: '100%' }} key={index}>
																			<Grid className='step2item' onClick={(e) => headleGetDetail(e, item)}>
																				<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', height: '100%' }}>
																					{item.image ? (
																						ImageBodyTemplate(item.image)
																					) : (
																						<img src={require('../../../assets/logo.png')} alt="img" style={{ width: '50%' }} />
																					)}
																				</Item3>
																				<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#000000', fontSize: '18px' }}>
																					ชื่อ : {item.name}
																				</Item3>
																			</Grid>
																		</Grid>
																	))}
																</React.Fragment>
															)}
														</Grid>
													</Item2>
												</Grid>
											</Grid>
											<Grid item xs={7}>
												<Grid className="form">
													<Grid container>
														<Grid sx={{ width: '50%', paddingRight: 1 }}>
															<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ยอดรวมสุทธิ</Item>
															<Item2 sx={{ width: '100%', fontSize: '400%', color: '#ffff', borderTopRightRadius: '0', borderTopLeftRadius: '0', textShadow: '3px 2px 4px #000000' }}>{calculateTotal()}</Item2>
														</Grid>
														<Grid sx={{ width: '50%', paddingRight: 1 }}>
															<div className='button-family-2'>
																<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.7rem' }}>
																	<Item3 sx={{ padding: 0, backgroundColor: 'transparent', boxShadow: 'none' }}>
																		<FloatLabel>
																			<InputText className="custom-InputText" id="reftel" value={reftel} onChange={(e) => setReftel(e.target.value)}
																				disabled={refteldisable} />
																			<label htmlFor="reftel">ทศกัณฐ์แฟมมิลี่</label>
																		</FloatLabel>
																	</Item3>
																	<Item3 sx={{ padding: 0 }}>
																		{!refteldisable && (
																			<Button className="custom-button" label="ทศกัณฐ์แฟมมิลี่" onClick={headleCheckReftel} />
																		)}
																		{refteldisable && (
																			<Button className="custom-button" label="เปลี่ยน" onClick={headlerClearReftel} />
																		)}
																	</Item3>
																</Stack>
																<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.5rem', width: '100%' }}>
																	<Item3 sx={{ padding: 0 }}>
																		<Button className="custom-button" label="หน้าจอสำหรับลูกค้า" icon="pi pi-user" />
																	</Item3>
																</Stack>
																<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap">
																	<Item3 sx={{ padding: 0, width: '100%', height: '100%' }}>
																		<Button className="custom-button-2" label="ชำระเงิน" icon="pi pi-check" onClick={heandleCheckOut} />
																	</Item3>
																</Stack>
															</div>
														</Grid>
													</Grid>
												</Grid>
												<Grid className="form">
													<Grid container>
														<Grid sx={{ width: '100%', paddingRight: 1 }}>
															<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ทั้งหมด : {order_website.length} รายการ</Item>
															<Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
																<DataTable value={order_website} style={{ fontFamily: 'Kanit' }}>
																	<Column field="name" header="รายการ"></Column>
																	<Column field="price" header="ราคา"></Column>
																	<Column field="amount" header="จำนวน"></Column>
																	<Column field="total_price" header="ราคารวม"></Column>
																</DataTable>
															</Item2>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Box>
			)}


			{/* Dialog Detail */}
			<Dialog header="รายละเอียด" visible={dialogDetail} style={{ width: '60vw', fontFamily: 'Kanit' }}
				onHide={() => onHideDialogDetail(false)} breakpoints={{ '1680px': '50vw', '641px': '100vw', '430px': '80vw' }} >
				{detail && (
					<div style={{ marginBottom: '1rem' }}>
						<b>ชื่อสินค้า : {detail.name}</b><br></br>
						<br />
						ราคา : {numberFormat(detail.net == null ? '' : detail.net)} บาท
						<br />
						ภาษี : {numberFormat(detail.vat == null ? '' : detail.vat)} บาท
						<br />
						ราคาสุทธิ : {numberFormat(detail.price == null ? '' : detail.price)} บาท
						<div></div><br></br>
						รายละเอียด : {detail.detail}
						<div></div><br></br>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
							<Grid item xs={12}>
								<label htmlFor="sqm-price" className="font-bold block mb-2">จำนวน : &emsp;</label>
								<InputNumber inputId="sqm-price" value={amount} onValueChange={(e) => setAmount(e.value)}
									mode="decimal" showButtons min={1} disabled />
								<label htmlFor="sqm-price" className="font-bold block mb-2">&emsp; ชิ้น</label>
							</Grid>
						</Grid>
						<div></div><br></br>
						<b>กรอกรายละเอียดเพิ่มเติม</b>
						<div></div><br></br>
						<div className="card">
							<InputTextarea value={description} onChange={(e) => setDescription(e.target.value)} rows={5} cols={70} />
						</div>
					</div>
				)}
				<div style={{ flex: '1' }}></div>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
					<ButtonGroup>
						<Button label="เพิ่มลงตะกล้า" icon="pi pi-check" className='add-cart' onClick={handleAddOrder} />
						<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={() => setDialogDetail(false)} />
					</ButtonGroup>
				</div>
			</Dialog>

			{/* Dialog Checkout */}
			<Dialog header="ชำระเงิน" visible={dialogCheckOut} style={{ width: '60vw', fontFamily: 'Kanit' }} onHide={() => onHideDialogCheckout(false)}
				breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12}>
						<Dropdown value={paymenttype} onChange={(e) => headlerPaymentType(e, e.value)} options={['เงินสด', 'เงินโอน', 'บัตรเครดิต', 'อิ่นๆ']}
							placeholder="เลือกประเภทการชำระ" className="custom-InputText" />
					</Grid>
					<Grid item xs={12}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="moneyreceive" value={moneyreceive} onChange={(e) => setMoneyReceive(e.value)}
								disabled={disabledMoneyReceive} />
							<label htmlFor="moneyreceive">กรอกเงินที่รับมา</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="ชำระเงิน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmCheckout} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={onHideDialogCheckout} />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>
		</div>
	)
}