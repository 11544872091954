import React, { useState, useRef } from "react";
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { useReactToPrint } from "react-to-print";

import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { ButtonGroup } from 'primereact/buttongroup';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Sidebar } from 'primereact/sidebar';
import { Divider } from 'primereact/divider';
import { Card } from "primereact/card";
import LoadingDialog from "../../../views/LoadingView";
import ReceiptTopup from "./ReceiptTopup";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json());
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json());
};

async function GetMember(reftel) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/member/check/${reftel}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function callcheckout(packageData) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/topup/service/booking`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json());
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Item2 = styled(Paper)(({ theme }) => ({
	backgroundColor: '#ECD400',
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const Item3 = styled(Paper)(({ theme }) => ({
	...theme.typography.body - 2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	flexGrow: 2,
	fontFamily: 'Kanit'
	// backgroundColor: 'red'
}));

export default function TopupView() {
	const [isLoading, setisLoading] = useState(false);
	const [position, setPosition] = useState({});
	const [member, setMember] = useState({});
	const [employee, setEmployee] = useState({});
	const [shop, setShop] = useState({});

	// Customer
	const [tel, setTel] = useState('');

	// Product
	const [product, setProduct] = useState([
		{
			topup_id: 501,
			topup_type: "Mobile Topup",
			topup_name: "1-2-Call (AIS)",
			logo: "/topup/AIS-Logo.jpg",
			name: "เติมเงินเอไอเอส-วันทูคอล",
			amounts: [
				{ name: '10', code: 10 },
				{ name: '20', code: 20 },
				{ name: '30', code: 30 },
				{ name: '40', code: 40 },
				{ name: '50', code: 50 },
				{ name: '60', code: 60 },
				{ name: '70', code: 70 },
				{ name: '80', code: 80 },
				{ name: '90', code: 90 },
				{ name: '100', code: 100 },
				{ name: '150', code: 150 },
				{ name: '200', code: 200 },
				{ name: '300', code: 300 },
				{ name: '400', code: 400 },
				{ name: '500', code: 500 },
				{ name: '800', code: 800 }
			],
		},
		{
			topup_id: 504,
			topup_type: "Mobile Topup",
			topup_name: "True Move (TRUE)",
			logo: "/topup/True-cash-Logo.png",
			name: "เติมเงินทรูมูฟ",
			amounts: [
				{ name: '10', code: 10 },
				{ name: '20', code: 20 },
				{ name: '30', code: 30 },
				{ name: '40', code: 40 },
				{ name: '50', code: 50 },
				{ name: '60', code: 60 },
				{ name: '100', code: 100 },
				{ name: '200', code: 200 },
				{ name: '300', code: 300 },
				{ name: '500', code: 500 },
				{ name: '800', code: 800 }
			],
		},
		{
			topup_id: 502,
			topup_type: "Mobile Topup",
			topup_name: "Happy (DTAC)",
			logo: "/topup/dtac-logo.jpg",
			name: "เติมเงินดีแทค",
			amounts: [
				{ name: '10', code: 10 },
				{ name: '20', code: 20 },
				{ name: '30', code: 30 },
				{ name: '40', code: 40 },
				{ name: '50', code: 50 },
				{ name: '60', code: 60 },
				{ name: '70', code: 70 },
				{ name: '80', code: 80 },
				{ name: '90', code: 90 },
				{ name: '100', code: 100 },
				{ name: '150', code: 150 },
				{ name: '200', code: 200 },
				{ name: '300', code: 300 },
				{ name: '400', code: 400 },
				{ name: '500', code: 500 },
				{ name: '800', code: 800 }
			],
		},
		{
			topup_id: 505,
			topup_type: "Mobile Topup",
			topup_name: "MY CAT",
			logo: "/topup/mycat-cash.jpg",
			name: "เติมเงินมายแคท",
			amounts: [
				{ name: '10', code: 10 },
				{ name: '20', code: 20 },
				{ name: '50', code: 50 },
				{ name: '100', code: 100 },
				{ name: '200', code: 200 },
				{ name: '300', code: 300 },
				{ name: '500', code: 500 }
			],
		},
		{
			topup_id: 529,
			topup_type: "WALLET",
			topup_name: "True Wallet",
			logo: "/topup/TrueWallet.png",
			name: "ทรูมันนี่วอลเล็ต",
			amounts: [
				{ name: '30', code: 30 },
				{ name: '40', code: 40 },
				{ name: '50', code: 50 },
				{ name: '90', code: 90 },
				{ name: '100', code: 100 },
				{ name: '150', code: 150 },
				{ name: '300', code: 300 },
				{ name: '500', code: 500 },
				{ name: '1000', code: 1000 }
			],
		},
		{
			topup_id: 701,
			topup_type: "Cash Card",
			topup_name: "True Money (E-PIN)",
			logo: "/topup/True-cash-Logo.png",
			name: "บัตรเงินสดทรูมันนี่",
			amounts: [
				{ name: '50', code: 50 },
				{ name: '90', code: 90 },
				{ name: '150', code: 150 },
				{ name: '300', code: 300 },
				{ name: '1000', code: 1000 }
			],
		},
		{
			topup_id: 737,
			topup_type: "Cash Card",
			topup_name: "12Call Cashcard (E-PIN)",
			logo: "/topup/AIS-Logo.jpg",
			name: "บัตรเงินสดวันทูคอล",
			amounts: [
				{ name: '50', code: 50 },
				{ name: '90', code: 90 },
				{ name: '150', code: 150 },
				{ name: '300', code: 300 },
				{ name: '1000', code: 1000 }
			],
		},
		{
			topup_id: 6035,
			topup_type: "WALLET",
			topup_name: "ShopeePay",
			logo: "/topup/shoppepay.jpg",
			name: "ช็อปปี้เพย์",
			amounts: [
				{ name: '20', code: 20 },
				{ name: '50', code: 50 },
				{ name: '100', code: 100 },
				{ name: '200', code: 200 },
				{ name: '300', code: 300 },
				{ name: '400', code: 400 },
				{ name: '500', code: 500 },
				{ name: '1000', code: 1000 }
			],
		},
		{
			topup_id: 6036,
			topup_type: "WALLET",
			topup_name: "Rabbit LINE Pay",
			logo: "/topup/rabbit.png",
			name: "แร็ปบิดไลน์เพย์",
			amounts: [
				{ name: '100', code: 100 },
				{ name: '150', code: 150 },
				{ name: '200', code: 200 },
				{ name: '300', code: 300 },
				{ name: '400', code: 400 },
				{ name: '500', code: 500 },
				{ name: '600', code: 600 },
				{ name: '800', code: 800 },
				{ name: '1000', code: 1000 }
			],
		},
		{
			topup_id: 901,
			topup_type: "SMS",
			topup_name: "Domestic SMS",
			logo: "/topup/sms.jpg",
			name: "เอสเอ็มเอส",
			amounts: [{ name: '1', code: 1 }],
		},
	]);

	// ทศกัณฐ์แฟมมิลี
	const [reftel, setReftel] = useState('');
	const [refteldisable, setReftelDisable] = useState(false);

	// Detail
	const [dialogDetail, setDialogDetail] = useState(false);
	const [detail, setDetail] = useState({});
	const [price, setPrice] = useState([]);
	const [amount, setAmount] = useState(null);

	// Order
	const [order, setOrder] = useState([]);

	// ชำระเงิน
	const [dialogDiscount, setDialogDiscount] = useState(false);
	const [dialogCheckOut, setDialogCheckout] = useState(false);
	const [paymenttype, setPaymentType] = useState('');
	const [moneyreceive, setMoneyReceive] = useState(0);
	const [disabledMoneyReceive, setDisabledMoneyReceive] = useState(false);
	const [change, setChange] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [resp, setResp] = useState({});

	const [dialogReceipt, setDialogReceipt] = useState(false);
	const componentReceipt = useRef();
	const headlerPrintReceipt = useReactToPrint({
		content: () => componentReceipt.current,
	});

	useState(async () => {
		setisLoading(true);
		const response = await getMe();
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			setPosition('One Stop Platform')
			setMember(response.data)
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				setisLoading(false);
				setPosition('One Stop Service')
				setEmployee(response.data);
				setShop(shop.data);
			}
		}
	});

	const headleGetProduct = (e, item) => {
		if (tel === '') {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกเบอร์โทรศัพท์ที่ต้องการทำรายการ',
				icon: 'warning',
				showConfirmButton: false,
				timer: 2000,
			});
			return false;
		}
		setDetail(item);
		setPrice(item.amounts)
		setDialogDetail(true);
	};

	const headleAddOrder = () => {
		if (amount === null) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณาเลือกจำนวนที่ต้องการทำรายการ',
				icon: 'warning',
				showConfirmButton: false,
				timer: 2000,
			});
			return false;
		}
		let fee = 0;
		let total = 0;
		if (detail.topup_type === 'WALLET') {
			if (amount.code < 100) {
				fee = 5;
			} else {
				fee = 7;
			}
			total = amount.code + fee;
		} else {
			fee = 0;
			total = amount.code + fee;
		}
		const order_topup = {
			amount: amount.code,
			branch: 'ADVWS',
			service_id: detail.topup_id,
			mobile: tel,
			name: detail.name,
			type: detail.topup_type,
			fee: fee,
			total: total,
		};
		setOrder([...order, order_topup]);
		setTel('');
		setDialogDetail(false);
		setPrice([]);
		setAmount(null);
	}

	const deleteProduct = (value) => e => {
		const position = order.find(item => item === value);
		order.splice(position, 1);
		setOrder([...order]);
		if (order.length === 0) {
			setDiscount(0);
		}
	};

	// Check Out 
	const calculateTotal = () => {
		return order.reduce((total, item) => total + item.total, 0);
	};

	const headleCheckReftel = async () => {
		if (reftel === "") {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกทศกัฐ์แฟมมิลี่',
				icon: 'warning',
				showConfirmButton: false,
				timer: 1500,
			});
			return false;
		} else {
			const response = await GetMember(reftel);
			if (response.status) {
				Swal.fire({
					title: "ทศกัณฐ์แฟมมิลี่?",
					icon: "success",
					text: `เบอร์โทรศัพท์ ${response.data.tel} คุณ ${response.data.fristname} ${response.data.lastname}`,
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "ยืนยัน",
					cancelButtonText: "ยกเลิก",
				}).then((result) => {
					if (result.isConfirmed) {
						setReftelDisable(true);
					}
				})
			} else {
				Swal.fire({
					title: 'ทศกัณฐ์แฟมมิลี่!',
					text: 'ไม่พบข้อมูลทศกัณฐ์แฟมมิลี่',
					icon: 'error',
					showConfirmButton: false,
					timer: 3000,
				});
				return false;
			}
		}
	};

	const headlerClearReftel = async () => {
		Swal.fire({
			title: "เปลี่ยนทศกัณฐ์แฟมมิลี่?",
			icon: "warning",
			text: `คุณต้องการเปลี่ยนทศกัณฐ์แฟมมิลี่ ใช่หรือไม่?`,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "ยืนยัน",
			cancelButtonText: "ยกเลิก",
		}).then((result) => {
			if (result.isConfirmed) {
				setReftelDisable(false);
				setReftel('');
			}
		})
	};

	const heandleCheckOut = async () => {
		if (order.length === 0) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ไม่มีรายการให้ชำระเงิน กรุณาเลือกสินค้าเพื่อทำรายการ',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (reftel === '' || refteldisable === false) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณากรอกและยืนยัน ทศกัณฐ์แฟมมิลี่',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		if (shop.shop_wallet < calculateTotal()) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ยอดเงินในระบบไม่เพียงต่อการชำระเงิน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}
		setDialogCheckout(true);
	};

	const headlerPaymentType = (e, value) => {
		if (value === 'เงินสด') {
			setPaymentType(value);
			setMoneyReceive(0);
			setDisabledMoneyReceive(false);
		} else {
			setPaymentType(value);
			setDisabledMoneyReceive(true);
			setMoneyReceive(calculateTotal());
		}
	};

	const headleDiscount = () => {
		if (calculateTotal() === 0) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ยอดรวมรวมไม่เพียงพอต่อการกรอกส่วนลด',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		} else {
			setDialogDiscount(true);
		}
	};

	const headleConfirmDiscount = () => {
		if (discount >= calculateTotal()) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ส่วนลดมากกว่ายอดที่ต้องชำระ ไม่สามารถทำรายการได้',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			setDialogDiscount(false);
			setDiscount(0);
			return false;
		} else {
			setDialogDiscount(false);
		}
	};

	const onHideDialogDiscount = () => {
		setDiscount(0);
		setDialogDiscount(false);
	};

	const onHideDialogCheckout = () => {
		setDialogCheckout(false);
	};

	const headleConfirmCheckout = async () => {
		if (moneyreceive < (calculateTotal() - discount)) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'จำนวนเงินที่กรอก ไม่เพียงพอต่อการชำระเงิน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 2000,
			});
			return false;
		}
		const changes = moneyreceive - (calculateTotal() - discount);
		setChange(changes);
		const data = {
			maker_id: employee._id,
			shop_id: shop._id,
			product_detail: order,
			paymenttype: paymenttype,
			moneyreceive: moneyreceive,
			change: changes,
			discount: discount,
			platform: reftel,
			employee: employee._id,
		};
		setisLoading(true);
		setDialogCheckout(false);
		const response = await callcheckout(data);
		if (response.status === true) {
			setResp(response.data);
			setisLoading(false);
			setDialogReceipt(true);
			setMoneyReceive(0);
		} else {
			setisLoading(false);
			setDialogCheckout(true);
		}
	};

	const heandleResetOrder = (e) => {
		setDiscount(0);
		setReftel("");
		setOrder([]);
		setReftelDisable(false);
		setDialogReceipt(false);
	};

	const heandleResetPage = (e) => {
		window.location.href = "/";
	};

	return (
		<div>
			{position === 'One Stop Service' && (
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Box>
								<Grid container className={"container"}>
									<div className="title" ><h1>เติมเงิน/เติมวอลเล็ต (Topup/Wallet)</h1></div>
									<Box sx={{ width: '100%' }}>
										<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
											<Grid item xs={5}>
												<Grid className="form">
													<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ข้อมูลลูกค้า</Item>
													<Item2 sx={{ width: '100%', height: '100%', padding: '1rem', paddingTop: '1.5rem', borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
														<Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
															<Item3 sx={{ padding: 0 }}>
																<FloatLabel className="custom-InputText">
																	<InputText className="custom-InputText" id="tel" value={tel} onChange={(e) => setTel(e.target.value)} />
																	<label htmlFor="tel">เบอร์โทร</label>
																</FloatLabel>
															</Item3>
														</Stack>
													</Item2>
												</Grid>
												<Grid className="form">
													<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>รายการสินค้า</Item>
													<Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
														<ScrollPanel className="scroll">
															<Grid container spacing={1} sx={{ padding: 0 }} columnSpacing={{ xs: 3 }}>
																{product.slice().map((item, index) => (
																	<Grid item xs={6} sx={{ width: '50%', height: '100%' }} key={index}>
																		<Grid className='step2item' onClick={(e) => headleGetProduct(e, item)}>
																			<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', width: '100%', height: '100%' }}>
																				<img className="item" src={item.logo} alt={item.service_id} style={{ width: '70%' }} />
																			</Item3>
																			<Item3 sx={{ backgroundColor: 'transparent', boxShadow: 'none', color: '#000000', fontSize: '18px' }}>
																				{item.name}
																			</Item3>
																		</Grid>
																	</Grid>
																))}
															</Grid>
														</ScrollPanel>
													</Item2>
												</Grid>
											</Grid>
											<Grid item xs={7}>
												<Grid className="form">
													<Grid container>
														<Grid sx={{ width: '50%', paddingRight: 1 }}>
															<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ยอดรวมสุทธิ</Item>
															<Item2 sx={{ width: '100%', fontSize: '400%', color: '#ffff', borderTopRightRadius: '0', borderTopLeftRadius: '0', textShadow: '3px 2px 4px #000000' }}>{calculateTotal() - discount}</Item2>
														</Grid>
														<Grid sx={{ width: '50%', paddingRight: 1 }}>
															<div className='button-family-2'>
																<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.7rem' }}>
																	<Item3 sx={{ padding: 0, backgroundColor: 'transparent', boxShadow: 'none' }}>
																		<FloatLabel>
																			<InputText className="custom-InputText" id="reftel" value={reftel} onChange={(e) => setReftel(e.target.value)} disabled={refteldisable} />
																			<label htmlFor="reftel">ทศกัณฐ์แฟมมิลี่</label>
																		</FloatLabel>
																	</Item3>
																	<Item3 sx={{ padding: 0 }}>
																		{!refteldisable && (
																			<Button className="custom-button" label="ทศกัณฐ์แฟมมิลี่" onClick={headleCheckReftel} />
																		)}
																		{refteldisable && (
																			<Button className="custom-button" label="เปลี่ยน" onClick={headlerClearReftel} />
																		)}
																	</Item3>
																</Stack>
																<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.5rem', width: '100%' }}>
																	<Item3 sx={{ padding: 0 }}>
																		<Button className="custom-button" label="หน้าจอสำหรับลูกค้า" icon="pi pi-user" />
																	</Item3>
																</Stack>
																<Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap">
																	<Item3 sx={{ padding: 0, width: '40%' }}>
																		<Button className="custom-button-2" label="ส่วนลดในบิล" onClick={headleDiscount} />
																	</Item3>
																	<Item3 sx={{ padding: 0, width: '40%' }}>
																		<Button className="custom-button-2" label="ชำระเงิน" icon="pi pi-check" onClick={heandleCheckOut} />
																	</Item3>
																</Stack>
															</div>
														</Grid>
													</Grid>
												</Grid>
												<Grid className="form">
													<Grid container>
														<Grid sx={{ width: '100%', paddingRight: 1 }}>
															<Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ทั้งหมด : {order.length} รายการ</Item>
															<Item2 sx={{ borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
																<DataTable value={order} style={{ fontFamily: 'Kanit' }}>
																	<Column header="รายการ" body={(rowData) => (
																		<div>
																			<b>{rowData.name}</b>
																			<br />
																			({rowData.type})
																		</div>
																	)}></Column>
																	<Column field="mobile" header="เบอร์โทรศัพท์"></Column>
																	<Column field="amount" header="ราคา"></Column>
																	<Column field="fee" header="ค่าธรรมเนียม"></Column>
																	<Column field="total" header="ยอดสุทธิ"></Column>
																	<Column body={(rowData) => (
																		<div>
																			<Button icon="pi pi-trash" className="p-button-rounded p-button-outlined"
																				style={{ color: 'red', marginLeft: '0.5rem' }} onClick={deleteProduct(rowData)} />
																		</div>
																	)}></Column>
																</DataTable>
															</Item2>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</Box>
						</Grid>
					</Grid>
				</Box>
			)}

			{/* Dialog Detail */}
			<Dialog header="รายละเอียด" visible={dialogDetail} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogDetail) return; setDialogDetail(false); }}
				breakpoints={{ '1680px': '50vw', '641px': '100vw', '430px': '80vw' }} >
				{detail && (
					<div style={{ marginBottom: '1rem' }}>
						<b>ชื่อสินค้า : {detail.name}</b><br></br>
						ประเภทการขาย: {detail.topup_type}
						<br />
						<div></div><br></br>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
							<Grid item xs={12} style={{ marginTop: '2rem' }}>
								<FloatLabel className="custom-InputText">
									<Dropdown value={amount} onChange={(e) => setAmount(e.value)} options={price} optionLabel="name"
										className="custom-InputText" />
									<label htmlFor="province">จำนวน</label>
								</FloatLabel>
							</Grid>
						</Grid>
					</div>
				)}
				<div style={{ flex: '1' }}></div>
				<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
					<ButtonGroup>
						<Button label="เพิ่มลงตะกล้า" icon="pi pi-check" className='add-cart' onClick={headleAddOrder} />
						<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={() => setDialogDetail(false)} />
					</ButtonGroup>
				</div>
			</Dialog>

			{/* Dialog Discount */}
			<Dialog header="ส่วนลด" visible={dialogDiscount} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogDiscount) return; setDialogDiscount(false); }}
				breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ marginTop: '1rem' }}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="discount" value={discount} onChange={(e) => setDiscount(e.value)} />
							<label htmlFor="moneyreceive">ส่วนลดในบิล</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="ยืนยัน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmDiscount} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={onHideDialogDiscount} />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>

			{/* Dialog Checkout */}
			<Dialog header="ชำระเงิน" visible={dialogCheckOut} style={{ width: '30vw', fontFamily: 'Kanit' }} onHide={() => onHideDialogCheckout(false)}
				breakpoints={{ '1680px': '30vw', '641px': '100vw', '430px': '80vw' }} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ marginTop: '1rem' }}>
						<FloatLabel className="custom-InputText">
							<Dropdown value={paymenttype} onChange={(e) => headlerPaymentType(e, e.value)} options={['เงินสด', 'เงินโอน', 'บัตรเครดิต', 'อิ่นๆ']}
								className="custom-InputText" />
							<label htmlFor="moneyreceive">เลือกประเภทการชำระ</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12} style={{ marginTop: '1rem' }}>
						<FloatLabel className="custom-InputText">
							<InputNumber className="custom-InputText" id="moneyreceive" value={moneyreceive} onChange={(e) => setMoneyReceive(e.value)}
								disabled={disabledMoneyReceive} />
							<label htmlFor="moneyreceive">กรอกเงินที่รับมา</label>
						</FloatLabel>
					</Grid>
					<Grid item xs={12}>
						<div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
							<ButtonGroup>
								<Button label="ชำระเงิน" icon="pi pi-check" className='btn-checkout' onClick={headleConfirmCheckout} />
								<Button label="ยกเลิก" icon="pi pi-times" className='btn-canecl' onClick={onHideDialogCheckout} />
							</ButtonGroup>
						</div>
					</Grid>
				</Grid>
			</Dialog>

			{/* Dialog Receipt */}
			<Dialog header="เงินทอน" visible={dialogReceipt} style={{ width: '30vw', fontFamily: 'Kanit' }}
				onHide={() => { if (!dialogReceipt) return; setDialogReceipt(false); }}
				breakpoints={{ '1680px': '30vw', '1680px': '40vw', '960px': '60vw', '641px': '70vw', '430px': '80vw' }} closable={false} >
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12}>
						<Card className="border-1 shadow-none border-gray-400" style={{ padding: '0' }}>
							<h1>
								{numberFormat(change == null ? '' : change)}
							</h1>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<div>
							<Button label="ใบเสร็จรับเงิน" style={{ width: '100%' }} onClick={headlerPrintReceipt} />
							{/* <ReceiptExpress /> */}
						</div>
					</Grid>
					<Grid item xs={6}>
						<div>
							<Button label="ทำรายรายต่อ" style={{ width: '100%' }} onClick={heandleResetOrder} />
						</div>
					</Grid>
					<Grid item xs={6}>
						<div>
							<Button label="หน้าหลัก" style={{ width: '100%' }} onClick={heandleResetPage} />
						</div>
					</Grid>
					<div style={{ display: 'none' }}>
						<div ref={componentReceipt} style={{ fontSize: '10px', textAlign: 'center', padding: '0', fontFamily: 'Kanit' }}>
							{/* <ReceiptArtwork data={resp} /> */}
							<ReceiptTopup data={resp} />
						</div>
					</div>
				</Grid>
			</Dialog>

			<LoadingDialog isLoading={isLoading} />
		</div>
	)
}