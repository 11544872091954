import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import React, { useState } from "react";
import Swal from 'sweetalert2';
import "./tricket.css";

import ConnectingAirportsRoundedIcon from '@mui/icons-material/ConnectingAirportsRounded';


import { Button } from 'primereact/button';
import { ButtonGroup } from 'primereact/buttongroup';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from "primereact/floatlabel";
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from "primereact/inputtext";
import { SelectButton } from 'primereact/selectbutton';

import dayjs from "dayjs";
import LoadingDialog from "../../views/LoadingView";


async function getMe() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/me`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json());
};

async function getShop(shop_id) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json());
};

async function GetMember(reftel) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/member/check/${reftel}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getTricket(packageDate) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/aoc/get/ticket`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
        body: JSON.stringify(packageDate),
    }).then(data => data.json())
};

async function getTricketPrice(packageDate) {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/aoc/get/price`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
        body: JSON.stringify(packageDate),
    }).then(data => data.json())
};




function numberFormat(number) {
    return number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

function datetimeFormat(date) {
    return dayjs(date).format("hh:mm");
};

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: '#ECD400',
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Item3 = styled(Paper)(({ theme }) => ({
    ...theme.typography.body - 2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexGrow: 2,
    fontFamily: 'Kanit'
    // backgroundColor: 'red'
}));

const options = [
    { name: 'เที่ยวเดียว', code: 'O' },
    { name: 'ไป-กลับ', code: 'R' }
];

const airport = [
    { name: 'เชียงใหม่', code: 'CNX' },
    { name: 'สุวรรณภูมิ', code: 'BKK' }
];

const svc = [
    { name: 'Economy', code: 'Y' },
    { name: 'Premium Economy', code: 'P' },
    { name: 'Bussiness', code: 'C' },
    { name: 'First class', code: 'F' },
];

const prefixes = [
    { label: 'Mr.', value: 'mr' },
    { label: 'Mrs.', value: 'mrs' },
    { label: 'Miss', value: 'miss' }
];

export default function TricketView() {
    const [isLoading, setisLoading] = useState(false);
    const [position, setPosition] = useState({});
    const [member, setMember] = useState({});
    const [employee, setEmployee] = useState({});
    const [shop, setShop] = useState({});

    // ค้นหาเที่ยวบิน
    const [tripType, setTripType] = useState("O");
    const [originCode, setOriginCode] = useState("");
    const [destinationCode, setDestinationCode] = useState("");
    const [departDate, setDepartDate] = useState("");
    const [returnDate, setRetrunDate] = useState("");

    const [adult, setAdult] = useState(1);
    const [child, setChild] = useState(0);
    const [infant, setInfant] = useState(0);

    const [svcClass, setSvcClass] = useState(svc[0].code);

    // สัญญา
    const [promise, setPromise] = useState(false);
    const [dialogContract, setDialogContract] = useState(false);

    // ทศกัณฐ์แฟมมิลี
    const [reftel, setReftel] = useState('');
    const [refteldisable, setReftelDisable] = useState(false);

    // detail
    const [dialogTricketOne, setDialogTricketOne] = useState(false);
    const [dialogTricketTwo, setDialogTricketTwo] = useState(false);
    const [tricket, setTricket] = useState([]);
    const [pgSearchOID, setpgSearchOID] = useState("");
    const [Flight, setFlight] = useState({});
    const [Filght2, setFlight2] = useState([]);
    const [FlightOne, setFlightOne] = useState("");
    const [FlightTwo, setFlightTwo] = useState("");
    const [detail, setDetail] = useState({}); //ที่เก็บข้อมูล adtpaxs , chapaxs
    const [dialogDetail, setDialogDetail] = useState(false);

    // ข้อมูลลูกค้า
    
    const [firstname, setFirstname] = useState('');
    const [middlename, setMiddlename] = useState('');
    const [lastname, setLastname] = useState('');
    const [telNo, setTelNo] = useState('');
    const [email, setEmail] = useState('');

    const [page, setPage] = useState(0);
    
    // passport Data
    const [passportData, setPassportData] = useState(false);
    const [passportNumber, setPassportNumber] = useState('');
    const [passportNationality, setPassportNationality] = useState('');
    const [passportExpiryDate, setPassportExpiryDate] = useState('');
    const [passportIssuingCountry, setPassportIssuingCountry] = useState('');
    const [passportIssuingDate, setPassportIssuingDate] = useState('');

    // Order
    const [order_tricket, setOrderTricket] = useState([]);

    // set state of button 
    const [currentIndex, setCurrentIndex] = useState(0);


    const handlePrefixChange = (value, index) => {
        const updatedAdtPaxs = [...detail.adtPaxs];
        updatedAdtPaxs[index].prefix = value;
        setDetail({ ...detail, adtPaxs: updatedAdtPaxs });
    };
    
    const handleFirstnameChange = (value, index) => {
        const updatedAdtPaxs = [...detail.adtPaxs];
        updatedAdtPaxs[index].firstname = value;
        setDetail({ ...detail, adtPaxs: updatedAdtPaxs });
    };
    
    const handleMiddlenameChange = (value, index) => {
        const updatedAdtPaxs = [...detail.adtPaxs];
        updatedAdtPaxs[index].middlename = value;
        setDetail({ ...detail, adtPaxs: updatedAdtPaxs });
    };
    
    const handleLastnameChange = (value, index) => {
        const updatedAdtPaxs = [...detail.adtPaxs];
        updatedAdtPaxs[index].lastname = value;
        setDetail({ ...detail, adtPaxs: updatedAdtPaxs });
    };
    
    const handleTelNoChange = (value, index) => {
        const updatedAdtPaxs = [...detail.adtPaxs];
        updatedAdtPaxs[index].telNo = value;
        setDetail({ ...detail, adtPaxs: updatedAdtPaxs });
    };
    
    const handleEmailChange = (value, index) => {
        const updatedAdtPaxs = [...detail.adtPaxs];
        updatedAdtPaxs[index].email = value;
        setDetail({ ...detail, adtPaxs: updatedAdtPaxs });
    };
    
    const handlePassportNumber = (value, index) => {
        const updatedAdtPaxs = [...detail.adtPaxs];
        updatedAdtPaxs[index].passportNumber = value
        setDetail({ ...detail, adtPaxs: updatedAdtPaxs});
    }

    const handlePassportNationality = (value, index) => {
        const updatedAdtPaxs = [...detail.adtPaxs];
        updatedAdtPaxs[index].passportNationality = value
        setDetail({ ...detail, adtPaxs: updatedAdtPaxs});
    }

    const handlePassportExpiryDate = (value, index) => {
        const updatedAdtPaxs = [...detail.adtPaxs];
        updatedAdtPaxs[index].passportExpiryDate = value
        setDetail({ ...detail, adtPaxs: updatedAdtPaxs});
    }

    const handlePassportIssuingCountry = (value, index) => {
        const updatedAdtPaxs = [...detail.adtPaxs];
        updatedAdtPaxs[index].passportIssuingCountry = value
        setDetail({ ...detail, adtPaxs: updatedAdtPaxs});
    }

    const handlePassportIssuingDate = (value, index) => {
        const updatedAdtPaxs = [...detail.adtPaxs];
        updatedAdtPaxs[index].passportIssuingDate = value
        setDetail({...detail, adtPaxs: updatedAdtPaxs});
    }

    useState(async () => {
        setisLoading(true);
        const response = await getMe();
        if (response.logout === true) {
            localStorage.clear();
            window.location.href = "/";
        }
        if (response.level === 'member') {
            setisLoading(false)
            setPosition('One Stop Platform')
            setMember(response.data)
        } else if (response.level === 'employee') {
            const shop = await getShop(response.data.employee_shop_id);
            if (shop.data.shop_status === false) {
                Swal.fire({
                    title: 'แจ้งเตือน!',
                    text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000,
                });
                return false;
            } else {
                setisLoading(false);
                setPosition('One Stop Service')
                setEmployee(response.data);
                setShop(shop.data);
            }
        }
    });

    const headleSearchTricket = async (e) => {
        e.preventDefault();
        if (originCode === '' ||
            destinationCode === ''
        ) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณาเลือกต้นทางและปลายทาง',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        if (departDate === '') {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณาเลือกวันที่จะเดินทาง',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        if (tripType === 'R') {
            if (returnDate === '') {
                Swal.fire({
                    title: 'แจ้งเตือน!',
                    text: 'กรุณาเลือกวันที่จะเดินทางกลับ',
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 3000,
                });
                return false;
            }
        }
        if (!promise) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณาอ่านและยอมรับเงื่อนไขและข้อตกลง',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        if (originCode === destinationCode) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'ต้นทางและปลายทางตรงกัน กรุณาตรวจสอบข้อมูล',
                icon: 'warning',
                showConfirmButton: false,
                timer: 3000,
            });
            return false;
        }
        let data;
        if (tripType === 'O') {
            data = {
                tripType: tripType,
                originCode: originCode,
                destinationCode: destinationCode,
                airline: "",
                svcClass: svcClass,
                directFlight: false,
                departDate: dayjs(departDate).format("YYYY-MM-DD"),
                adult: adult,
                child: child,
                infant: infant,
                languageCode: "th"
            };
        }
        if (tripType === 'R') {
            data = {
                tripType: tripType,
                originCode: originCode,
                destinationCode: destinationCode,
                airline: "",
                svcClass: svcClass,
                directFlight: false,
                departDate: dayjs(departDate).format("YYYY-MM-DD"),
                returnDate: dayjs(returnDate).format("YYYY-MM-DD"),
                adult: adult,
                child: child,
                infant: infant,
                languageCode: "th"
            };
        }
        setisLoading(true);
        const resp = await getTricket(data);
        if (resp.status) {
            setisLoading(false);
            setTricket(resp.data);
            setDialogTricketOne(true);
            setpgSearchOID(resp.pgSearchOID)
        }
    };

    const headleSelectFlightOne = async (e, value, item) => {
        e.preventDefault();
        let data;
        if (tripType === 'O') {
            data = {
                pgSearchOID: pgSearchOID,
                tripType: tripType,
                origin: value.depCity,
                destination: value.arrCity,
                adult: adult,
                child: child,
                infant: infant,
                svcClass: svcClass,
                S1: item.bookingCode,
                bMultiTicket_Fare: true,
                languageCode: "th",
            };
            setisLoading(true);
            setDialogTricketOne(false);
            const resp = await getTricketPrice(data);
            if (resp.status === true) {
                console.log(resp.data)
                setFlight(value);
                setFlightOne(item);
                setOrderTricket([...order_tricket, resp.data]);
                setisLoading(false);
                ClearData();
            } else {
                setisLoading(false);
                alert('ไม่มีเที่ยวบินในรอบนี้ ที่ให้บริการ');
                setDialogTricketOne(true);
            }
        } else if (tripType === 'R') {
            setFlight2(value.Flight_SegRef2);
            setFlight(value);
            setFlightOne(item);
            setDialogTricketTwo(true);
        }
    };

    const headleSelectFlightTwo = async (e, item) => {
        e.preventDefault();
        const data = {
            pgSearchOID: pgSearchOID,
            tripType: tripType,
            origin: Flight.depCity,
            destination: Flight.arrCity,
            adult: adult,
            child: child,
            infant: infant,
            svcClass: svcClass,
            S1: FlightOne.bookingCode,
            S2: item.bookingCode,
            bMultiTicket_Fare: true,
            languageCode: "th",
        };
        setisLoading(true);
        setDialogTricketOne(false);
        setDialogTricketTwo(false);
        const resp = await getTricketPrice(data);
        if (resp.status === true) {
            console.log(resp.data)
            setFlightTwo(item);
            setOrderTricket([...order_tricket, resp.data]);
            setisLoading(false);
            ClearData();
        } else {
            setisLoading(false);
            alert('ไม่มีเที่ยวบินในรอบนี้ ที่ให้บริการ');
            setDialogTricketOne(true);
            setDialogTricketTwo(true);
        }
    };

    // Check Out
    const calculateTotal = () => {
        return order_tricket.reduce((total, item) => total + item.grandTotal, 0);
    };

    const headleCheckReftel = async () => {
        if (reftel === "") {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'กรุณากรอกทศกัฐ์แฟมมิลี่',
                icon: 'warning',
                showConfirmButton: false,
                timer: 1500,
            });
            return false;
        } else {
            setisLoading(true);
            const response = await GetMember(reftel);
            if (response.status) {
                setisLoading(false);
                Swal.fire({
                    title: "ทศกัณฐ์แฟมมิลี่?",
                    icon: "success",
                    text: `เบอร์โทรศัพท์ ${response.data.tel} คุณ ${response.data.fristname} ${response.data.lastname}`,
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "ยืนยัน",
                    cancelButtonText: "ยกเลิก",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setReftelDisable(true);
                    }
                })
            } else {
                setisLoading(false);
                Swal.fire({
                    title: 'ทศกัณฐ์แฟมมิลี่!',
                    text: 'ไม่พบข้อมูลทศกัณฐ์แฟมมิลี่',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 3000,
                });
                return false;
            }
        }
    };

    const headlerClearReftel = async () => {
        Swal.fire({
            title: "เปลี่ยนทศกัณฐ์แฟมมิลี่?",
            icon: "warning",
            text: `คุณต้องการเปลี่ยนทศกัณฐ์แฟมมิลี่ ใช่หรือไม่?`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
        }).then((result) => {
            if (result.isConfirmed) {
                setReftelDisable(false);
                setReftel('');
            }
        })
    };


   
    const deleteProduct = (value) => e => {
        const position = order_tricket.indexOf(value);
        order_tricket.splice(position, 1);
        setOrderTricket([...order_tricket])
    };
    
        const handleNext = () => {
            if (page < detail.adtPaxs?.length - 1) {
                setPage(page + 1);
            }
        };
    
        const handlePrevious = () => {
            if (page > 0) {
                setPage(page - 1);
            }
        };
    


    const handleDialogDetail = (data) => e => {
       /*value.contactInfo.title = employee.employee_prefix;
        value.contactInfo.firstname = employee.employee_firstname;
        value.contactInfo.middlename = employee.employee_middlename;
        value.contactInfo.lastname = employee.employee_lastname;
        value.contactInfo.telNo = employee.employee_phone;
        value.contactInfo.email = employee.employee_email;*/
        //console.log(data)
        console.log('adtPaxs-1', data.adtPaxs)
        if (data.adtPaxs?.length) {
            data.adtPaxs.forEach(adt => {
                adt.firstname = ''
                adt.middlename = ''
                adt.lastname = ''
                adt.telNo = ''
                adt.email = ''
                adt.passportNumber = ''
                adt.passportNationality = ''
                adt.passportExpiryDate = ''
                adt.passportIssuingCountry = ''
                adt.passportIssuingDate = ''


            })
        }
        console.log('adtPaxs-2', data.adtPaxs)
        setDialogDetail(true);
        setDetail(data);
    };

   

    const heandleCheckOut = async () => {

    };

    function ClearData() {
        setTripType('O');
        setOriginCode('');
        setDestinationCode('');
        setDepartDate('');
        setRetrunDate('');
        setAdult(1);
        setChild(0);
        setInfant(0);
        setSvcClass(svc[0].code);
        setPromise(false);
    };

    const ImageTemplateOrder = (item) => {
        if (item === 'FD') {
            return <img src={require('../../assets/aoc/AirAsia.png')} alt="" width="70" />
        } else if (item === 'VZ') {
            return <img src={require('../../assets/aoc/VietjetAir.png')} alt="" width="70" />
        } else if (item === 'TG') {
            return <img src={require('../../assets/aoc/ThaiAriways.png')} alt="" width="70" />
        } else if (item === 'SL') {
            return <img src={require('../../assets/aoc/LionAir.png')} alt="" width="70" />
        } else if (item === 'DD') {
            return <img src={require('../../assets/aoc/NokAir.png')} alt="" width="70" />
        } else if (item === 'PG') {
            return <img src={require('../../assets/aoc/BangkokAirways.png')} alt="" width="70" />
        }
    };

    const ImageTemplate = (item) => {
        if (item.flightDetails[0].airline.code === 'FD') {
            return <img src={require('../../assets/aoc/AirAsia.png')} alt="" width="80" />
        } else if (item.flightDetails[0].airline.code === 'VZ') {
            return <img src={require('../../assets/aoc/VietjetAir.png')} alt="" width="80" />
        } else if (item.flightDetails[0].airline.code === 'TG') {
            return <img src={require('../../assets/aoc/ThaiAriways.png')} alt="" width="80" />
        } else if (item.flightDetails[0].airline.code === 'SL') {
            return <img src={require('../../assets/aoc/LionAir.png')} alt="" width="80" />
        } else if (item.flightDetails[0].airline.code === 'DD') {
            return <img src={require('../../assets/aoc/NokAir.png')} alt="" width="80" />
        } else if (item.flightDetails[0].airline.code === 'PG') {
            return <img src={require('../../assets/aoc/BangkokAirways.png')} alt="" width="80" />
        }
    };

    // set limits on childCount and adultCount >= 7
    const totalLimit = 7;
    const handleAdultChange = (e) => {
        const value = e.value;
        if (value >= 0 && value <= totalLimit - (child + infant)) {
            setAdult(value);
        }
    };
    
    const handleChildChange = (e) => {
        const value = e.value;
        if (value >= 0 && value <= totalLimit - (adult + infant)) {
            setChild(value);
        }
    };
    
    const handleInfantChange = (e) => {
        const value = e.value;
        if (value >= 0 && value <= totalLimit - (adult + child)) {
            setInfant(value);
        }
    };

    const totalOfcustomer = () => {
        return adult + child + infant;
    };

    //handles data 
    const handleAddData = async () => {
        const token = localStorage.getItem('token');
        console.log('Token:', token)
        try {
            const response = await fetch(`${process.env.REACT_APP_SHOP}/aoc/booking`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': `Bearer ${token}`,
                },
                body: JSON.stringify({ ...detail })
            });
            
            console.log('Response status:', response.status);
            const result = await response.json();
    
            if (response.ok && result.success) {
                alert('Customer data added successfully!');
                setDialogDetail(false);
                ClearData();
                alert('Failed to add customer data.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while adding customer data.');
        }
    };

    const handlePassportData = () => {
        setPassportData(prevState => !prevState);
        
      };
    

    return (
        <div>
            {position === 'One Stop Service' && (
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box>
                                <Grid container className={"container"}>
                                    <div className="title" ><h1>จองตั๋วเครื่องบิน (Tricket)</h1></div>
                                    <Box sx={{ width: '100%' }}>
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 3 }}>

                                            <Grid item xs={12} xl={6}>
                                                <Grid className="form">
                                                    <Item sx={{borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ค้นหาเที่ยวบิน</Item>
                                                    <Item2 sx={{ width: '100%', height: '100%', padding: '1rem', paddingTop: '1.5rem', borderTopRightRadius: '0', borderTopLeftRadius: '0' }}>
                                                        <Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                            {/* <Item3 sx={{ padding: 0 }}> */}
                                                            <FloatLabel className="custom-InputText">
                                                                <SelectButton invalid value={tripType} onChange={(e) => setTripType(e.value)} options={options}
                                                                    optionLabel="name" optionValue="code" className="custom-InputText" />
                                                            </FloatLabel>
                                                            {/* </Item3> */}
                                                        </Stack>
                                                        <Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                            <Item3 sx={{ padding: 0 }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <Dropdown value={originCode} onChange={(e) => setOriginCode(e.value)} filter options={airport} optionLabel="name"
                                                                        className="custom-InputText" optionValue="code" />
                                                                    <label htmlFor="province" >เลือกต้นทาง</label>
                                                                </FloatLabel>
                                                            </Item3>
                                                            <Item3 sx={{ padding: 0 }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <Dropdown value={destinationCode} onChange={(e) => setDestinationCode(e.value)} filter options={airport} optionLabel="name"
                                                                        className="custom-InputText" optionValue="code" />
                                                                    <label htmlFor="province">เลือกปลายทาง</label>
                                                                </FloatLabel>
                                                            </Item3>
                                                        </Stack>
                                                        <Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                            <Item3 sx={{ padding: 0 }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <Calendar value={departDate} className="custom-InputText"
                                                                        onChange={(e) => setDepartDate(e.value)} dateFormat="dd/mm/yy" showIcon />
                                                                    <label htmlFor="province">วันออกเดินทาง</label>
                                                                </FloatLabel>
                                                            </Item3>
                                                            {tripType === 'R' && (
                                                                <Item3 sx={{ padding: 0 }}>
                                                                    <FloatLabel className="custom-InputText">
                                                                        <Calendar value={returnDate} className="custom-InputText"
                                                                            onChange={(e) => setRetrunDate(e.value)} dateFormat="dd/mm/yy" showIcon />
                                                                        <label htmlFor="province">ไป-กลับ</label>
                                                                    </FloatLabel>
                                                                </Item3>
                                                            )}
                                                        </Stack>
                                                        <label htmlFor="adult" style={{marginLeft:'0.5rem', marginTop:'0.5rem', textAlign:'left'}}>ผู้ใหญ่</label>
                                                        <Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>                                                         
                                                            <Item3 sx={{ padding: 0 }}>
                                                                <FloatLabel className="custom-InputText" >
                                                                    <InputNumber className="custom-InputText xs:w-" id="adult" value={adult}  onChange={handleAdultChange}   min={0} max={totalLimit - (child + infant)}/>
                                                                </FloatLabel>
                                                            </Item3>
                                                        </Stack>
                                                        <label htmlFor="child" style={{marginLeft:'0.5rem', marginTop:'0.5rem',textAlign:'left'}}>เด็ก</label>
                                                        <Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                            <Item3 sx={{ padding: 0 }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <InputNumber className="custom-InputText" id="child" value={child}  onChange={handleChildChange}   min={0}  max={totalLimit - (adult + infant)} />
                                                                </FloatLabel>
                                                            </Item3>
                                                        </Stack>
                                                        <label htmlFor="infant" style={{marginLeft:'0.5rem', marginTop:'0.5rem',textAlign:'left'}}>เด็กทารก</label>
                                                        <Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                            <Item3 sx={{ padding: 0 }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <InputNumber className="custom-InputText" id="infant" value={infant}  onChange={handleInfantChange}   min={0} max={totalLimit - (adult + child)}/>
                                                                </FloatLabel>
                                                            </Item3>
                                                        </Stack>
                                                        <label htmlFor="province" style={{marginLeft:'0.5rem', marginTop:'0.5rem', textAlign:'left'}}>ชั้นโดยสาร</label>
                                                        <Stack spacing={{ xs: 2 }} sx={{ width: '100%', justifyContent: 'center' }} direction="row" useFlexGap flexWrap="wrap" className='Stack'>
                                                            <Item3 sx={{ padding: 0 }}>
                                                                <FloatLabel className="custom-InputText">
                                                                    <Dropdown value={svcClass} onChange={(e) => setSvcClass(e.value)} filter options={svc} optionLabel="name"
                                                                        className="custom-InputText" optionValue="code" />
                                                                    
                                                                </FloatLabel>
                                                            </Item3>
                                                        </Stack>
                                                    </Item2>
                                                </Grid>
                                                <Grid className="form">
                                                    <Grid item>
                                                        <FloatLabel>
                                                            <Checkbox onChange={e => setPromise(e.checked)} checked={promise}></Checkbox>
                                                            &nbsp;&nbsp;อ่านและยอมรับ <Link onClick={(e) => setDialogContract(true)}>เงื่อนไขและข้อตกลง</Link>
                                                        </FloatLabel>
                                                    </Grid>
                                                </Grid>
                                                <Grid className="form">
                                                    <div>
                                                        <Button className="button" onClick={headleSearchTricket}>ค้นหาเที่ยวบิน</Button>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} xl={6}>
                                            <Grid className="form">
                                                    <Grid sx={{ width: '100%', paddingRight: 0, paddingTop: 2 }}>
                                                        <DataTable value={order_tricket} stripedRows paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50]} style={{ fontFamily: 'Kanit' }}>
                                                            <Column header="เที่ยวบินไป" body={(rowData) => (
                                                                <div>
                                                                    {ImageTemplateOrder(rowData.depFlight[0].airline.code)}
                                                                    <br />
                                                                    {rowData.depFlight[0].airline.name}
                                                                </div>
                                                            )}></Column>
                                                            <Column header="" body={(rowData) => (
                                                                <div>
                                                                    {rowData.depFlight[0].depCity.code} <ConnectingAirportsRoundedIcon /> {rowData.depFlight[0].arrCity.code}
                                                                    <br />
                                                                    {datetimeFormat(rowData.depFlight[0].departureDateTime)} - {datetimeFormat(rowData.depFlight[0].arrivalDateTime)}
                                                                </div>
                                                            )}>
                                                            </Column>
                                                            <Column header="เที่ยวบินกลับ" body={(rowData) => (
                                                                <div>
                                                                    {rowData.retFlight === null ? (
                                                                        <div>
                                                                            <p>-</p>
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            {ImageTemplateOrder(rowData.retFlight[0].airline.code)}
                                                                            <br />
                                                                            {rowData.retFlight[0].airline.name}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}></Column>
                                                            <Column header="" body={(rowData) => (
                                                                <div>
                                                                    {rowData.retFlight === null ? (
                                                                        <div>
                                                                            <p>-</p>
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            {rowData.retFlight[0].depCity.code} <ConnectingAirportsRoundedIcon /> {rowData.retFlight[0].arrCity.code}
                                                                            <br />
                                                                            {datetimeFormat(rowData.retFlight[0].departureDateTime)} - {datetimeFormat(rowData.retFlight[0].arrivalDateTime)}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}>
                                                            </Column>
                                                            <Column field="noOfAdults" header="ผู้ใหญ่"></Column>
                                                            <Column field="noOfChildren" header="เด็ก"></Column>
                                                            <Column field="noOfInfants" header="ทารก"></Column>
                                                            <Column field="grandTotal" header="ราคาสุทธิ"></Column>
                                                            <Column style={{ width: '15%' }} body={(rowData) => (
                                                                <div>
                                                                    <Button icon="pi pi-align-justify" className="p-button-rounded p-button-outlined" onClick={handleDialogDetail(rowData)} />
                                                                    <Button icon="pi pi-trash" className="p-button-rounded p-button-outlined" style={{ color: 'red', marginLeft: '0.5rem' }} onClick={deleteProduct(rowData)} />
                                                                </div>
                                                            )}>
                                                            </Column>
                                                        </DataTable>
                                                    </Grid>
                                                </Grid>

                                                <Grid className="form">
                                                    <Grid container>
                                                        <Grid sx={{ width: '100%', paddingRight: 0 }}>
                                                            <Item sx={{ borderBottomRightRadius: '0', borderBottomLeftRadius: '0' }}>ยอดรวมสุทธิ</Item>
                                                            <Item2 sx={{ width: '100%', fontSize: '400%', color: '#ffff', borderTopRightRadius: '0', borderTopLeftRadius: '0', textShadow: '3px 2px 4px #000000' }}>{calculateTotal()}</Item2>
                                                        </Grid>
                                                        <Grid sx={{ width: '100%', paddingRight: 0 }}>
                                                            <div className='button-family-2'>
                                                                <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.7rem' }}>
                                                                    <Item3 sx={{ padding: 0, backgroundColor: 'transparent', boxShadow: 'none' }}>
                                                                        <FloatLabel>
                                                                            <InputText className="custom-InputText" id="reftel" value={reftel} onChange={(e) => setReftel(e.target.value)} disabled={refteldisable} />
                                                                            <label htmlFor="reftel">ทศกัณฐ์แฟมมิลี่</label>
                                                                        </FloatLabel>
                                                                    </Item3>
                                                                    <Item3 sx={{ padding: 0 }}>
                                                                        {!refteldisable && (
                                                                            <Button className="custom-button" label="ทศกัณฐ์แฟมมิลี่" onClick={headleCheckReftel} />
                                                                        )}
                                                                        {refteldisable && (
                                                                            <Button className="custom-button" label="เปลี่ยน" onClick={headlerClearReftel} />
                                                                        )}
                                                                    </Item3>
                                                                </Stack>
                                                                <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap" sx={{ paddingBottom: '0.5rem', width: '100%' }}>
                                                                    <Item3 sx={{ padding: 0 }}>
                                                                        <Button className="custom-button" label="หน้าจอสำหรับลูกค้า" icon="pi pi-user" />
                                                                    </Item3>
                                                                </Stack>
                                                                <Stack spacing={{ xs: 2 }} direction="row" useFlexGap flexWrap="wrap">
                                                                    <Item3 sx={{ padding: 0, width: '100%', height: '100%' }}>
                                                                        <Button className="custom-button-2" label="ชำระเงิน" icon="pi pi-check" onClick={heandleCheckOut} />
                                                                    </Item3>
                                                                </Stack>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {/* Dialog เช็คราคาเที่ยวบิน */}
            <Dialog header="เที่ยวบินไป" visible={dialogTricketOne} style={{ width: '60vw', fontFamily: 'Kanit',  }} onHide={() => { if (!dialogTricketOne) return; setDialogTricketOne(false); }}
                breakpoints={{ '1680px': '50vw', '641px': '100vw', '430px': '80vw' }}>
                {tricket.length === 0 ? (
                    <div>ไม่พบข้อมูลสายการบิน</div>
                ) : (
                    <Grid item xs={12}>
                        <Grid className="tricket-form">
                            <Box className="tricket-scroll">
                                {tricket.map((item, index) => (
                                    <Box sx={{ flexGrow: 1 }} key={index}>
                                        <Grid item xs={12}>
                                            {item.Flight_SegRef1.map((value, index) => (
                                                <Grid container key={index} className={'tricket-package'}>
                                                    <Grid item className={'tricket-package-logo'}>
                                                        {ImageTemplate(value)}
                                                    </Grid>
                                                    <Grid item className={'tricket-package-name-o'}>
                                                        <div>
                                                            {value.flightDetails[0].depDisplayDateTime.displayTime} น. <ConnectingAirportsRoundedIcon /> {value.flightDetails[0].arrDisplayDateTime.displayTime} น.
                                                            <br />
                                                            {value.flightDetails[0].depCity.code} <ConnectingAirportsRoundedIcon /> {value.flightDetails[0].arrCity.code}
                                                        </div>
                                                        <div>
                                                            {value.flightDetails[0].airline.name}
                                                        </div>
                                                    </Grid>
                                                    <Grid item className={'tricket-package-price'}>
                                                        {item.discountTag && (
                                                            <div>
                                                                <del>{item.fare.currencyCode} {numberFormat(item.fare.adtFare.strikethroughPrice)}/คน</del>
                                                            </div>
                                                        )}
                                                        <div>
                                                            {item.fare.currencyCode} {numberFormat(item.fare.adtFare.net)}/คน
                                                        </div>
                                                    </Grid>
                                                    <Grid item className={'tricket-package-button'}>
                                                        <Button label="จอง" onClick={(e) => headleSelectFlightOne(e, item, value)} className="button" />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Dialog>

            {/* Dialog เช็คราคาเที่ยวบิน */}
            <Dialog header="เที่ยวบินกลับ" visible={dialogTricketTwo} style={{ width: '60vw', fontFamily: 'Kanit' }} onHide={() => { if (!dialogTricketTwo) return; setDialogTricketTwo(false); }}
                breakpoints={{ '1680px': '50vw', '641px': '100vw', '430px': '80vw' }}>
                {Filght2.length === 0 ? (
                    <div>ไม่พบข้อมูลสายการบิน</div>
                ) : (
                    <Grid item xs={12}>
                        <Grid className="tricket-form">
                            <Box className="tricket-scroll">
                                {Filght2.map((item, index) => (
                                    <Box sx={{ flexGrow: 1 }} key={index}>
                                        <Grid container key={index} className={'tricket-package'}>
                                            <Grid item className={'tricket-package-logo'}>
                                                {ImageTemplate(item)}
                                            </Grid>
                                            <Grid item className={'tricket-package-name-o'}>
                                                <div>
                                                    {item.flightDetails[0].depDisplayDateTime.displayTime} น. <ConnectingAirportsRoundedIcon /> {item.flightDetails[0].arrDisplayDateTime.displayTime} น.
                                                    <br />
                                                    {item.flightDetails[0].depCity.code} <ConnectingAirportsRoundedIcon /> {item.flightDetails[0].arrCity.code}
                                                </div>
                                                <div>
                                                    {item.flightDetails[0].airline.name}
                                                </div>
                                            </Grid>
                                            <Grid item className={'tricket-package-price'}>
                                                {Flight.discountTag && (
                                                    <div>
                                                        <del>{Flight.fare.currencyCode} {numberFormat(Flight.fare.adtFare.strikethroughPrice)}/คน</del>
                                                    </div>
                                                )}
                                                <div>
                                                    {Flight.fare.currencyCode} {numberFormat(Flight.fare.adtFare.net)}/คน
                                                </div>
                                            </Grid>
                                            <Grid item className={'tricket-package-button'}>
                                                <Button label="จอง" onClick={(e) => headleSelectFlightTwo(e, item)} className="button" />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Dialog>

            {/* Dialog เงื่อนไขและสัญญา */}
            <Dialog header="เงื่อนไขและข้อตกลง" visible={dialogContract} style={{ width: '60vh', fontFamily: 'Kanit' }} onHide={() => { if (!dialogContract) return; setDialogContract(false); }}
                breakpoints={{ '2000px': '40vw', '641px': '70vw', '430px': '80vw' }}>
                <p>
                    1. ระบบนี้เป็นเพียงระบบคีย์พัสดุและเรียกขนส่งนั้นๆ เข้ารับพัสดุเท่านั้น ระยะเวลาในการจัดส่งพัสดุจะช้าหรือเร็ว ขึ้นอยู่กับแบรนด์ขนส่งนั้นๆ ไม่ได้เกี่ยวข้องโดยตรงกับบริษัทฯ
                </p>
                <p>
                    2. ระบบขนส่งดังกล่าวจะมีการเก็บข้อมูลผู้รับและผู้ส่ง ทางร้านค้าจะต้องแจ้งข้อมูลดังกล่าวแก่ลูกค้าเผื่อความยินยอมของลูกค้า
                </p>
                <p>
                    3. การคีย์ข้อมูลขนาดพัสดุ กว้างxยาวxสูง(เซนติเมตร) และน้ำหนักของพัสดุ(กรัม) จะต้องคีย์ตามความจริง <strong> หากคีย์ไม่ตรงกับพัสดุจริงทางบริษัทจะหักเงินในกระเป๋าร้านค้าตามจำนวนส่วนต่างทันที โดยไม่แจ้งให้ทราบล่วงหน้า</strong>
                </p>
                <p>
                    4. หากขนส่งมารับพัสดุหรือร้านค้ายินยอมให้ขนส่งรับพัสดุไปโดยไม่สแกนเข้ารับพัสดุหน้างาน หากพัสดุชิ้นนั้นเกิดการสูญหาย บริษัทจะไม่มีการรับผิดชอบทุกกรณี เนื่องจากทางตัวแทนไม่ปฏิบัติตามกฎและเงื่อนไขในการส่งพัสดุกับทางบริษัทฯ
                </p>
                <p>
                    5. กรณีมีการยกเลิกพัสดุ ทางบริษัทจะคืนเงินเพียงครึ่งหนึ่งของยอดทำรายการ ตามนโยบายของทางบริษัท
                </p>
            </Dialog>

            {/* Dialog Customer */}
    <Dialog 
    header="เพิ่มข้อมูลลูกค้า" 
    visible={dialogDetail} 
    style={{ width: '40vw', fontFamily: 'Kanit' }} 
    onHide={() => { if (!dialogDetail) return; setDialogDetail(false); }}
    breakpoints={{ '1680px': '40vw', '641px': '100vw', '430px': '80vw' }}
>           
    <p sx={{alignItems:'center'}}> Page{page+1}</p>
    <Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
        <Grid item xs={12}>
            {detail.adtPaxs && (

                <React.Fragment>
                    {detail.adtPaxs.map((el, index) => (
                        <Stack spacing={{ xs: 2 }} key={index} sx={{ width: '100%', alignItems: 'center' }} useFlexGap flexWrap="wrap" className={ index == page ? 'Stack' : 'hidden'}>
                            <Item3 sx={{ padding: 0, width: '100%' }}>
                                <FloatLabel className="custom-InputText">
                                    <Dropdown 
                                        value={detail.adtPaxs[index]?.prefix || null}
                                        onChange={(e) => handlePrefixChange(e.value, index)} 
                                        options={prefixes} 
                                        optionLabel="label"
                                        optionValue="value"
                                        className="custom-InputText" 
                                        placeholder="คำนำหน้า"
                                    />
                                    <label>คำนำหน้า</label>
                                </FloatLabel>
                            </Item3>
                            <Item3 sx={{ padding: 0, width: '100%' }}>
                                <FloatLabel className="custom-InputText">
                                    <InputText 
                                        className="custom-InputText" 
                                        value={el.firstname}
                                        onChange={(e) => handleFirstnameChange(e.target.value, index)} 
                                        id={`firstname-${index}`} 
                                    />
                                    <label htmlFor={`firstname-${index}`}>ชื่อ</label>
                                </FloatLabel>
                            </Item3>
                            <Item3 sx={{ padding: 0, width: '100%' }}>
                                <FloatLabel className="custom-InputText">
                                    <InputText 
                                        className="custom-InputText" 
                                        value={el.middlename}   
                                        onChange={(e) => handleMiddlenameChange(e.target.value, index)} 
                                        id={`middlename-${index}`} 
                                    />
                                    <label htmlFor={`middlename-${index}`}>ชื่อกลาง</label>
                                </FloatLabel>
                            </Item3>
                            <Item3 sx={{ padding: 0, width: '100%' }}>
                                <FloatLabel className="custom-InputText">
                                    <InputText 
                                        className="custom-InputText" 
                                        value={el.lastname}  
                                        onChange={(e) => handleLastnameChange(e.target.value, index)} 
                                        id={`lastname-${index}`} 
                                    />
                                    <label htmlFor={`lastname-${index}`}>นามสกุล</label>
                                </FloatLabel>
                            </Item3>
                            <Item3 sx={{ padding: 0, width: '100%' }}>
                                <FloatLabel className="custom-InputText">
                                    <InputText 
                                        type='number'
                                        pattern="\d{10}"
                                        className="custom-InputText" 
                                        value={el.telNo}   
                                        onChange={(e) => handleTelNoChange(e.target.value, index)} 
                                        id={`telNo-${index}`} 
                                    />
                                    <label htmlFor={`telNo-${index}`}>เบอร์โทรศัพท์</label>
                                </FloatLabel>
                            </Item3>
                            <Item3 sx={{ padding: 0, width: '100%' }}>
                                <FloatLabel className="custom-InputText">
                                    <InputText 
                                        className="custom-InputText" 
                                        value={el.email}  
                                        onChange={(e) => handleEmailChange(e.target.value, index)} 
                                        id={`email-${index}`} 
                                    />
                                    <label htmlFor={`email-${index}`}>อีเมล</label>
                                </FloatLabel>
                            </Item3>
                                    {/*<Button label="Passport" className='passbtn'  onClick={handlePassportData} />*/}

                                {/*{passportData &&(
                                    <>
                                    <Item3 sx={{ padding: 0, width: '100%' }}>
                                        <FloatLabel className="custom-InputText">
                                            <InputText
                                                className="custom-InputText" 
                                                value={el.passportNumber} 
                                                onChange={(e) => handlePassportNumber(e.target.value, index)}
                                                id= {`passportNumber-${index}`}
                                                />
                                                <label htmlFor={`passportNumber-${index}`}>หมายเลขพาสปอร์ต </label>
                                            </FloatLabel>
                                    </Item3>
                                    <Item3 sx={{ padding: 0, width: '100%' }}>
                                        <FloatLabel className="custom-InputText">
                                        <InputText
                                            className="custom-InputText" 
                                            value={el.passportNationality} 
                                            onChange={(e) => handlePassportNationality(e.target.value, index)} 
                                            id= {`passportNationality-${index}`}
                                            />
                                            <label htmlFor={`passportNationality-${index}`}>สัญชาติ</label>
                                        </FloatLabel>
                                    </Item3>
                                    <Item3 sx={{ padding: 0, width: '100%' }}>
                                    <label htmlFor={`passportExpiryDate-${index}`}>วันหมดอายุ Passport</label>
                                        <FloatLabel className="custom-InputText">
                                        <InputText
                                            type='date'
                                            className="custom-InputText" 
                                            value={el.passportExpiryDate} 
                                            onChange={(e) => handlePassportExpiryDate(e.target.value, index)} 
                                            id= {`passportExpiryDate-${index}`}
                                            />
                                           
                                        </FloatLabel>
                                    </Item3>
                                    <Item3 sx={{ padding: 0, width: '100%' }}>
                                        <FloatLabel className="custom-InputText">
                                        <InputText
                                            className="custom-InputText" 
                                            value={el.passportIssuingCountry} 
                                            onChange={(e) => handlePassportIssuingCountry(e.target.value, index)} 
                                            id= {`passportIssuingCountry-${index}`}
                                            />
                                            <label htmlFor={`passportIssuingCountry-${index}`}>ประเทศผู้ออก Passport</label>
                                        </FloatLabel>
                                    </Item3>
                                    <Item3 sx={{ padding: 0, width: '100%' }}>
                                    <label htmlFor={`passportIssuingDate-${index}`}>วันที่ออก Passport</label>
                                        <FloatLabel className="custom-InputText">
                                        <InputText
                                            type='date'
                                            className="custom-InputText" 
                                            value={el.passportIssuingDate} 
                                            onChange={(e) => handlePassportIssuingDate(e.target.value, index)} 
                                            id= {`passportIssuingDate-${index}`}
                                            />
                                        </FloatLabel>
                                    </Item3>
                                    </> 
                                )}*/}
                        </Stack>
                    ))}
                </React.Fragment>
            )}
        </Grid>
        <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }} className='grup-btn-media-dialog'>
                <ButtonGroup>
                {console.log(totalOfcustomer())}

                { totalOfcustomer() > 1  && (
                        <div className='flex items-center justify-between mb-5 mr-5'>

                            <Button
                                label="ย้อนกลับ"
                                icon="pi pi-chevron-left"
                                className='btn-previous'
                                onClick={handlePrevious}/>
                       
                    
                            <Button
                                label="ต่อไป"
                                icon="pi pi-chevron-right"
                                iconPos='right'
                                className='btn-previous'
                                onClick={handleNext}/>
                        </div>
                )}

                        
                    
                    <div className='flex items-center justify-between mr-5 '>
                            <Button 
                            label="เพิ่มข้อมูล" 
                            icon="pi pi-check" 
                             className='add' 
                            onClick={() => handleAddData()}
                        />
                        <Button 
                         label="ยกเลิก" 
                         icon="pi pi-times" 
                         className='b-canecl' 
                         onClick={() => setDialogDetail(false)}
                        /> 
                   
                    </div>
                       
                   
                    
                </ButtonGroup>
            </div>
        </Grid>
    </Grid>
</Dialog>


            <LoadingDialog isLoading={isLoading} />
        </div>
    )
}