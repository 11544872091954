import React, { useState } from "react";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from "primereact/floatlabel";
import { Divider } from "primereact/divider";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import LoadingDialog from "../../../views/LoadingView";
import axios from "axios";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getEmployee(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/employee/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getOrder(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/order/product/shopid/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getOrderById(packageID) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/order/product/${packageID}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function importProduct(packageID) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/order/product/import/${packageID}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getProductShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/product/shop/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

function datetimeFormat(date) {
	return dayjs(date).format("DD/MM/YYYY เวลา hh:mm:ss น.");
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

function getLastStatus(item) {
	return item[item.length - 1].name;
};

export default function ImportProduct() {
	const [isLoading, setisLoading] = useState(false);
	const [position, setPosition] = useState({});
	const [employee, setEmployee] = useState([]);
	const [shop, setShop] = useState({});

	const [order, setOrder] = useState([]);

	// Invoice
	const [barcode, setBarcode] = useState('');

	const [itemOrder, setItemOrder] = useState('');
	const [itemProduct, setItemProduct] = useState([]);

	useState(async () => {
		setisLoading(true);
		const response = await getMe();
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			Swal.fire({
				title: "แจ้งเตือน!",
				text: "ผู้ใช้งานดังกล่าว ไม่สามารถใช้งานระบบนี้ได้!",
				icon: "warning",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "ยืนยัน",
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = "/";
				}
			})
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				setisLoading(false);
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
				return false;
			} else {
				setisLoading(false);
				if (response.data.employee_position !== 'manager') {
					Swal.fire({
						title: 'แจ้งเตือน!',
						text: 'คุณไม่สามารถใช้งานระบบดังกล่าวได้',
						icon: 'warning',
						showConfirmButton: false,
						timer: 1500,
					});
					return false;
				}
				setPosition(shop.data.shop_type);
				setShop(shop.data);
				const resp_order = await getOrder(shop.data._id);
				setOrder(resp_order.data);
			}
		}
	});

	const onSeachBarcode = async (e) => {
		if (e.key === 'Enter') {
			if (barcode === '') {
				Swal.fire({
					title: 'แจ้งเตือน',
					icon: 'warning',
					text: 'กรอกเลขใบสั่งซื้อ',
					showConfirmButton: false,
					timer: 3000,
				});
				return false;
			}

			const data_order = order.find(
				(el) => el.barcode === barcode
			);

			if (!data_order) {
				Swal.fire({
					title: 'แจ้งเตือน',
					icon: 'warning',
					text: 'ไม่พบหมายเลขใบสั่งซื้อนี้',
					showConfirmButton: false,
					timer: 3000,
				});
				return false;
			}

			if (getLastStatus(data_order.status) === 'นำเข้าสต๊อก') {
				Swal.fire({
					title: 'แจ้งเตือน',
					icon: 'warning',
					text: 'สินค้านำเข้าสต๊อกเรียบร้อยแล้ว',
					showConfirmButton: false,
					timer: 3000,
				});
				return false;
			}


			if (getLastStatus(data_order.status) === 'ระหว่างจัดส่ง') {
				setItemOrder(data_order)
				setItemProduct(data_order.product_detail);
			} else {
				Swal.fire({
					title: 'แจ้งเตือน',
					icon: 'warning',
					text: 'ไม่สามารถนำเข้าได้ เนื่องจากสินค้าอยู่ระหว่างการจัดเตรียม',
					showConfirmButton: false,
					timer: 3000,
				});
				return false;
			}

		}
	};

	const importOrder = async (e) => {
		const resp = await getOrderById(itemOrder._id);
		// console.log(resp)
		if (getLastStatus(resp.data.status) === 'นำเข้าสต๊อก') {
			Swal.fire({
				title: 'แจ้งเตือน',
				icon: 'warning',
				text: 'ใบสั่งซื้อนี้ถูกนำเข้าสต๊อกเรียบร้อยแล้ว',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		}

		Swal.fire({
			title: "นำเข้าสินค้าในสต๊อก?",
			icon: "question",
			text: `ต้องการเพิ่มสินค้าเข้าในสต๊อกจากเลขใบสั่งซื้อเลขที่ ${resp.data.barcode}`,
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "ยืนยัน",
			cancelButtonText: "ยกเลิก",
		}).then(async (result) => {
			if (result.isConfirmed) {
				setisLoading(true);
				const resp = await importProduct(itemOrder._id);
				if (resp.status) {
					setisLoading(false);
					Swal.fire({
						title: 'สำเร็จ',
						icon: 'success',
						text: resp.message,
						showConfirmButton: false,
						timer: 3000,
					});
				} else {
					setisLoading(false);
					Swal.fire({
						title: 'ไม่สำเร็จ',
						icon: 'error',
						text: 'น้ำเข้าสินค้าไม่สำเร็จ',
						showConfirmButton: false,
						timer: 3000,
					});
				}
			}
		})
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"order-container"}>
								<div className="title" ><h1>นำเข้าสินค้าจากใบสั่งซื้อ</h1></div>
								<Box sx={{ width: '100%', marginBottom: '2rem' }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
											<Panel header="นำเข้าใบสั่งซื้อ" style={{ width: '50%', fontFamily: 'Kanit' }}>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<FloatLabel className="custom-InputText">
															<InputText className="custom-InputText" id="invoice" value={barcode} onChange={(e) => setBarcode(e.target.value)} onKeyUp={onSeachBarcode} />
															<label htmlFor="invoice">กรอกเลขใบสั่งซื้อ</label>
														</FloatLabel>
													</Grid>
												</Grid>
											</Panel>
										</Grid>
									</Grid>
								</Box>
								<Divider />
								{itemProduct.length !== 0 && (
									<React.Fragment>
										<div className="title" ><h1>เลขที่ใบสั่งซื้อ : {itemOrder.barcode}</h1></div>
										<Box sx={{ width: '100%' }}>
											<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
												<Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
													<div>
														{itemProduct.length !== 0 && (
															<Button label="นำสินค้าเข้าสต๊อก" onClick={importOrder} />
														)}
													</div>
												</Grid>
											</Grid>
											<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
												<Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
													<DataTable value={itemProduct} paginator rows={5} style={{ fontFamily: 'Kanit', width: '100%' }}>
														<Column field="productTG_name" header="รายการ"></Column>
														<Column header="ราคา/หน่วย" style={{ width: '20%' }} body={(item) => (
															<div>
																{numberFormat(item.productTG_cost.cost_net)}
															</div>
														)}>
														</Column>
														<Column header="จำนวน" style={{ width: '20%' }} body={(item) => (
															<div>
																{item.amount}
															</div>
														)}>
														</Column>
														<Column header="รวม" style={{ width: '20%' }} body={(item) => (
															<div>
																{item.total}
															</div>
														)}>
														</Column>
													</DataTable>
												</Grid>
											</Grid>
										</Box>
									</React.Fragment>
								)}
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<LoadingDialog isLoading={isLoading} />
		</div>
	)
}