import React from "react";
import "./commission.css";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { ScrollPanel } from 'primereact/scrollpanel';
import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';

async function getMe() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/me`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getCommission() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/commission/list`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getOrderService() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/order/service`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getOrderAoc() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/order/service/aoc`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

async function getOrderExpress() {
    const token = JSON.parse(localStorage.getItem('token'));
    return fetch(`${process.env.REACT_APP_SHOP}/order/service/express`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': `Bearer ${token}`,
        },
    }).then(data => data.json())
};

function datetimeFormat(date) {
    return dayjs(date).format("วันที่ DD/MM/YYYY เวลา HH:mm:ss น.");
};

function numberFormat(number) {
    return number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export default function CommisstionView() {

    const [member, setMember] = React.useState("");
    const [commissions, setCommission] = React.useState([]);
    const [order_aoc, setOrderAoc] = React.useState([]);
    const [order_service, setOrderService] = React.useState([]);
    const [order_exxpres, setOrderExpress] = React.useState([]);

    React.useState(async () => {
        const response = await getMe();
        if (response.logout === true) {
            localStorage.clear();
            window.location.href = "/";
        }
        if (response.level === 'member') {
            setMember(response.data);
            const commission = await getCommission();
            const order_aocs = await getOrderAoc();
            const order_services = await getOrderService();
            const order_expresss = await getOrderExpress();
            setCommission(commission.data.reverse());
            setOrderAoc(order_aocs.data);
            setOrderService(order_services.data);
            setOrderExpress(order_expresss.data);
        } else if (response.level === 'employee') {
            Swal.fire({
                title: "แจ้งเตือน!",
                icon: "warning",
                text: 'กรุณาเข้าสู่ระบบด้วยรหัสผู้ใช้งานของ Platform เพื่อตรวจสอบรายได้ของท่าน ขอบคุณค่ะ',
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ยืนยัน",
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = "/";
                }
            })
        }
    });

    const headleWithdraw = async (e) => {
        console.log(member)
        if (!member.bank.status || !member.iden.status) {
            Swal.fire({
                title: 'แจ้งเตือน!',
                text: 'ท่านไม่สมารถถอนรายได้ของท่านได้ เนื่องจากท่านยังไม่ได้ทำการยืนยันตัวตน กรุณายืนยันตัวตนก่อนทำรายการ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ยืนยันตัวตน",
                cancelButtonText: "ยกเลิก",
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.assign('/profile')
                }
            });
        }
    }

    function getReceipt(item) {
        if (item.code === 'Tricket') {
            const order = order_aoc.find(
                (el) => el._id === item.orderid
            );
            if (order) {
                return order.invoice
            } else {
                return 'ไม่พบรายการออเดอร์ดังกล่าวในระบบ'
            }
        } else if (item.code === 'Express') {
            const order = order_exxpres.find(
                (el) => el._id === item.orderid
            );
            if (order) {
                return order.invoice
            } else {
                return 'ไม่พบรายการออเดอร์ดังกล่าวในระบบ'
            }
        } else {
            const order = order_service.find(
                (el) => el._id === item.orderid
            );
            if (order) {
                return order.invoice
            } else {
                return 'ไม่พบรายการออเดอร์ดังกล่าวในระบบ'
            }
        }
    };

    const valueTemplate = (value) => {
        if (value < 30000) {
            return 30000
        } else if (value < 50000) {
            return 50000
        } else if (value < 100000) {
            return 100000
        } else if (value < 150000) {
            return 150000
        } else if (value < 200000) {
            return 200000
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box>
                        <Grid container className={"container"}>
                            <div className="title" ><h1>รายได้ (Commission)</h1></div>
                            <Box sx={{ width: '100%' }}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ justifyContent: 'center' }}>
                                    <Grid item className="com-draw-two">
                                        <Grid sx={{ width: '100%', paddingRight: 1 }}>
                                            <Card className="com-card" title='รายได้สะสม'>
                                                ฿ {numberFormat(member.commission == null ? '' : member.commission)}
                                                <br />
                                                <Button label="ถอนรายได้" severity="secondary" className="com-button" text onClick={headleWithdraw} />
                                            </Card>
                                            <br />
                                            <Card className="com-card" title='AllSale'>
                                                <div style={{ fontSize: '16px' }}>
                                                    {numberFormat(member.allsale == null ? '' : member.allsale)} / {valueTemplate(member.allsale == null ? '' : member.allsale)}
                                                </div>
                                                <progress value={member.allsale} max={valueTemplate(member.allsale)} style={{ height: '30px' }} />
                                                {/* <ProgressBar value={member.allsale} displayValueTemplate={valueTemplate} style={{ fontFamily: 'Kanit' }} /> */}
                                            </Card>
                                            <br />
                                            <Card className="com-card" title='Happy Point' style={{ backgroundColor: '#00CC00', color: 'white' }}>
                                                {member.happy_point}
                                                <br />
                                                <Button label="แลกคะแนน" severity="secondary" className="com-button" text style={{ color: 'white' }} />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                    <Grid item className="com-draw-one">
                                        <ScrollPanel className="com-scroll">
                                            {commissions.map((item, index) => (
                                                <Grid container key={index} className="com-model">
                                                    <Divider />
                                                    <Grid item>
                                                        {datetimeFormat(item.timestamp)}
                                                        <br />
                                                        บริการ : {item.code}
                                                        <br />
                                                        คุณได้รับค่า commission จำนวน &nbsp; <Tag value={numberFormat(item.data.remainding_commission)} severity="success"
                                                            style={{
                                                                fontFamily: 'Kanit', borderRadius: '1rem', fontSize: '14px',
                                                                paddingLeft: '0.5rem', paddingRight: '0.5rem'
                                                            }} /> &nbsp; บาท
                                                        <br />
                                                        จากใบเสร็จเลขที่ {getReceipt(item)}
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </ScrollPanel>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}