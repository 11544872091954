import React, { useState } from "react";
import './login.css';
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { ScrollPanel } from 'primereact/scrollpanel';
import { Divider } from 'primereact/divider';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';
import { Chip } from 'primereact/chip';

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getLoginHistory() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/login/history`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

function dateFormat(date) {
	return dayjs(date).format("วันที่ DD/MM/YYYY เวลา HH:mm:ss น.");
};

export default function LoginHistory() {

	const [me, setMe] = useState("");
	const [history, setHistory] = useState([]);

	useState(async () => {
		const response = await getMe();
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		setMe(response.data);
		const historys = await getLoginHistory();
		setHistory(historys.data)
	});

	return (
		<Box sx={{ flexGrow: 1 }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box>
						<Grid container className={"container"}>
							<div className="title" ><h1>ประวัติการเข้าใช้งาน</h1></div>
							<Box sx={{ width: '100%', paddingTop: '1rem' }}>
								<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
									<Grid item className="login-model-one">
									</Grid>
									<Grid item className="login-model-two">
										<ScrollPanel className="login-scroll">
											{history.map((item, index) => (
												<Grid container key={index}>
													<Divider />
													<Grid item style={{ textAlign: 'center', width: '100%', fontSize: '20px' }}>
														{dateFormat(item.timestamp)}
														<br />
														ผู้ใช้งาน : {item.name}
														<br />
														<Chip label={`IP : ${item.ip_address}`} style={{ fontFamily: 'Kanit', marginTop: '0.5rem', fontSize: '18px' }} />
													</Grid>
												</Grid>
											))}
										</ScrollPanel>
									</Grid>
								</Grid>
							</Box>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</Box>
	)
}