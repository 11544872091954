import React, { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "./order.css";
import axios from "axios";
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Panel } from 'primereact/panel';
import { Timeline } from 'primereact/timeline';
import { Image } from "primereact/image";
import LoadingDialog from "../../views/LoadingView";
import ReceiptArtwork from "../service/artwork/ReceiptArtwork";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getEmployee(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/employee/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

function datetimeFormat(date) {
	return dayjs(date).format("DD/MM/YYYY");
};

function datetimeFormatFull(date) {
	return dayjs(date).format("DD/MM/YYYY เวลา hh:mm:ss น.");
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

async function getOrderArtworkShop(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/order/service/shop/${packageId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getOrderArtworkMaker(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/order/service/maker/${packageId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getOrderByInvoice(invoice) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/order/service/orderref/${invoice}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function cancelOrderService(packageData) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/order/service/cancel`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json())
};

export default function OrderArtwork() {
	const [isLoading, setisLoading] = useState(false);
	const [member, setMember] = useState({});
	const [employee, setEmployee] = useState([]);
	const [visibleDetail, setVisibleDetail] = useState(false);
	const [order, setOrderArtwork] = useState([]);
	const [detail, setDetail] = useState("");
	const [detail_ref, setDetailRef] = useState("");
	const [package_detail, setPackageDetail] = useState([]);
	const [amount, setAmount] = useState(0);

	const [me, setMe] = useState("");
	const [emp, setEmp] = useState("");

	const [imagePreview1, setImagePreview1] = useState(''); //เล่มเขียว
	const [imagePreview2, setImagePreview2] = useState(''); //สำเนา ปชช
	const [image1, setImage1] = useState(''); //เล่มเขียว
	const [image2, setImage2] = useState(''); //สำเนา ปชช

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	useState(async () => {
		const response = await getMe();
		setisLoading(true);
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			const me = await getMe();
			setMember(me.data);
			const order = await getOrderArtworkMaker(response.data._id);
			setOrderArtwork(order.data.reverse())
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				setisLoading(false);
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				setisLoading(false);
				const emp = await getEmployee(shop.data._id);
				setEmployee(emp.data);
				const order = await getOrderArtworkShop(shop.data._id);
				setOrderArtwork(order.data.reverse());
				setMe(response.data)
				setEmp(response.data)
			}
		}
	});

	const ImageBodyTemplate = (item) => {
		return <Image src={`${process.env.REACT_APP_SHOP}/service/act/image/${item}`} alt='' width="100%" height="200" preview />
	};

	const totalTemplate = (item) => {
		return `${numberFormat(item.net)} บาท`;
	};

	const timeTemplate = (item) => {
		return datetimeFormat(item.updatedAt);
	};

	const employeeTemplate = (item) => {
		return <Chip label={getNameEmp(item)} style={{
			backgroundColor: '#FFD700',
			paddingLeft: '1rem',
			paddingRight: '1rem',
			fontFamily: 'Kanit',
			fontSize: '16px',
		}} />
	};

	const getLastStatus = (item) => {
		const status = item[item.length - 1].name;
		return status;
	};

	const statusColor = (item) => {
		switch (item) {
			case 'รอดำเนินการ':
				return 'bg-yellow-500';
			case 'กำลังดำเนินการ':
				return 'bg-blue-500';
			case 'ดำเนินการเสร็จสิ้น':
				return 'bg-green-500';
			case 'รอจัดส่ง':
				return 'bg-yellow-500';
			case 'จัดส่งสำเร็จ':
				return 'bg-green-500';
			default:
				return 'bg-red-500';
		}
	};

	const serviceName = (item) => {
		switch (item) {
			case 'Artwork': return 'บริการสื่อสิ่งพิมพ์';
			case 'Account': return 'บริการบัญชีและการเงิน';
			case 'Website': return 'บริการออกแบบและพัฒนาเว็บไซต์';
			case 'Marketing': return 'บริการส่งเสริมการตลาด';
			case 'Act': return 'บริการต่อ พ.ร.บ. รถ';
			default: return 'Service TG';
		}
	};

	const serviceColor = (item) => {
		switch (item) {
			case 'Artwork':
				return 'bg-yellow-500';
			case 'Account':
				return 'bg-sky-500';
			case 'Website':
				return 'bg-blue-500';
			case 'Marketing':
				return 'bg-green-500';
			case 'Act':
				return 'bg-purple-500';
			default:
				return 'danger';
		}
	};

	const getNameEmp = (item) => {
		if (item.shop_type === 'One Stop Platform') {
			return member.fristname
		} else if (item.shop_type === 'One Stop Service') {
			const emp = employee.find(
				(el) => el._id === item.employee
			);
			if (emp) {
				return emp.employee_firstname
			} else {
				return 'ไม่พบพนักงานดังกล่าว'
			}
		}
	};

	const headleDetail = (value) => async e => {
		if (value.servicename === 'Act') {
			const orderRef = await getOrderByInvoice(value.invoice);
			setDetailRef(orderRef.data);
		}
		setVisibleDetail(true)
		setDetail(value);
		setPackageDetail(value.product_detail);
		const amounts = value.product_detail.length;
		setAmount(amounts)
	};

	const headleUploadImage1 = async (e) => {
		const file = e.target.files[0];
		if (file) {
			const img_size = file.size;
			if (img_size > 500000) {
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'รูปภาพดังกล่าวมีขนาดใหญ่เกินไป',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
				return false
			}
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.addEventListener('load', (event) => {
				setImagePreview1(event.target.result);
			});
			setImage1(file)
		}
	};

	const headleUploadImage2 = async (e) => {
		const file = e.target.files[0];
		if (file) {
			const img_size = file.size;
			if (img_size > 500000) {
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'รูปภาพดังกล่าวมีขนาดใหญ่เกินไป',
					icon: 'warning',
					showConfirmButton: false,
					timer: 3000,
				});
				return false
			}
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);
			fileReader.addEventListener('load', (event) => {
				setImagePreview2(event.target.result);
			});
			setImage2(file);
		}
	};

	const headleUploadImage = async (e) => {
		if (image1 === '' && image2 === '') {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'กรุณาแนบรูปภาพให้ครบถ้วน',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false
		}
		const formData1 = new FormData();
		const formData2 = new FormData();

		formData1.append('image', image1);
		formData2.append('image', image2);

		let status = detail.status;
		status.push({
			name: 'รอดำเนินการ',
			timestamp: dayjs(Date.now()).format(""),
		})
		const data = {
			...detail,
			status: status
		};

		const token = JSON.parse(localStorage.getItem('token'));
		setisLoading(true);
		const resp1 = await axios.put(`${process.env.REACT_APP_SHOP}/order/service/orderref/book/${detail_ref._id}`, formData1, {
			headers: {
				'auth-token': `Bearer ${token}`
			}
		});

		const resp2 = await axios.put(`${process.env.REACT_APP_SHOP}/order/service/orderref/iden/${detail_ref._id}`, formData2, {
			headers: {
				'auth-token': `Bearer ${token}`
			}
		});

		const resp3 = await axios.put(`${process.env.REACT_APP_SHOP}/order/service/${detail._id}`, data, {
			headers: {
				'auth-token': `Bearer ${token}`
			}
		});

		if (resp1.status === 201 && resp2.status === 201 && resp3.status === 201) {
			setisLoading(false);
			clearImage();
			setVisibleDetail(false);
			Swal.fire({
				title: 'สำเร็จ!',
				text: 'ทำการแนบรูป สมุดเล่มเขียว สำเร็จ',
				icon: 'success',
				showConfirmButton: false,
				timer: 3000,
			});
		} else {
			Swal.fire({
				title: 'มีบางอย่างผิดพลาด!',
				text: 'เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ',
				icon: 'error',
				showConfirmButton: false,
				timer: 3000,
			});
		}
	};

	const heandleCancelExpress = (value) => async e => {
		if (emp.employee_position !== 'manager') {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ผู้ใช้งานดังกล่าวไม่สามารถทำรายการได้',
				icon: 'warning',
				showConfirmButton: false,
				timer: 3000,
			});
			return false;
		} else {
			Swal.fire({
				title: "ยกเลิกรายการ?",
				text: 'กรณียกเลิกรายการ ทางบริษัทจะคืนเงินเพียงเครื่องหนึ่งของยอดทำรายการ คุณต้องการยกเลิกรายการนี้ใช่หรือไม่?',
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "ยืนยัน",
				cancelButtonText: "ยกเลิก",
			}).then(async (result) => {
				if (result.isConfirmed) {
					const data = {
						maker_id: me._id,
						shop_id: me.employee_shop_id,
						id: value._id,
					};
					const resp = await cancelOrderService(data);
					if (resp.status === true) {
						Swal.fire({
							title: 'สำเร็จ',
							text: `คุณได้ทำการยกเลิกรายการขนส่ง ${value.invoice} สำเร็จ`,
							icon: 'success',
							showConfirmButton: false,
							timer: 3000,
						});
						setOrderArtwork([...order])
					}
				}
			})
		}
	};

	function clearImage() {
		setImage1('');
		setImagePreview1('');
		setImage2('');
		setImagePreview2('');
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"container"}>
								<div className="title" ><h1>รายการออเดอร์เซอร์วิส</h1></div>
								<Box sx={{ width: '100%' }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item className="w-full">
											<DataTable value={order} paginator rows={5} style={{ fontFamily: 'Kanit' }}>
												<Column header="เลขที่ใบเสร็จ" body={
													(el) => (
														<div>
															<Chip label={(el.invoice)} className={`px-2 py-1`} style={{ fontFamily: 'Kanit' }} />
														</div>
													)
												}></Column>
												<Column header="ประเภท" body={
													(el) => (
														<div>
															<Chip label={serviceName(el.servicename)} className={`${serviceColor(el.servicename)} text-white px-2 py-1`}
																style={{ fontFamily: 'Kanit' }} />
														</div>
													)
												}></Column>
												<Column header="ยอดรวม" body={totalTemplate}></Column>
												<Column header="การชำระเงิน" body={
													(el) => (
														<div>
															<Chip label={(el.paymenttype)} className={`px-2 py-1`} style={{ fontFamily: 'Kanit' }} />
														</div>
													)
												}></Column>
												<Column header="ผู้ทำรายการ" body={employeeTemplate}></Column>
												<Column header="สถานะ" body={
													(el) => (
														<div>
															<Chip label={getLastStatus(el.status)} className={`${statusColor(getLastStatus(el.status))} text-white px-2 py-1`} style={{ fontFamily: 'Kanit' }} />
														</div>
													)
												}></Column>
												<Column header="วันที่" body={timeTemplate}></Column>
												<Column header="ตัวเลือก" body={
													(el) => (
														<div className="p-d-flex p-jc-center">
															<Button outlined icon="pi pi-align-center" severity="help"
																label="รายละเอียด" style={{ fontFamily: 'Kanit' }} onClick={headleDetail(el)} />
															{emp.employee_position === 'manager' && (
																<React.Fragment>
																	{getLastStatus(el.status) !== 'ยกเลิก' && (
																		<Button outlined icon="pi pi-times" severity="danger"
																			label="ยกเลิก" style={{ fontFamily: 'Kanit', marginLeft: '0.5rem' }} onClick={heandleCancelExpress(el)} />
																	)}
																</React.Fragment>
															)}
														</div>
													)
												}></Column>
											</DataTable>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid >
			</Box >

			<Sidebar visible={visibleDetail} position="right" onHide={() => setVisibleDetail(false)} className="order-visible">
				<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
					<Grid item xs={12} style={{ fontFamily: 'Kanit' }}>
						<h2>เลขที่ใบเสร็จ : {detail.invoice}</h2>
						วันเวลาที่สั่ง : {datetimeFormatFull(detail.timestamp)}
						<div></div><br></br>
						<div style={{ textAlign: 'right' }}>
							<Button onClick={handlePrint} label="พิมพ์ใบเสร็จ" icon="pi pi-print" style={{ fontFamily: 'Kanit' }} />
						</div>
						<div></div><br></br>
						<Panel header="เกี่ยวกับลูกค้า & ที่อยู่จัดส่ง" style={{ textAlign: 'left', fontFamily: 'Kanit' }}>
							<p>ชื่อ : {detail.customer_name}</p>
							<p>เบอร์โทรศัพท์ : {detail.customer_tel}</p>
							<p>ที่อยู่จัดส่ง : {detail.customer_address}</p>
							<p>Lind ID : {detail.customer_line}</p>
						</Panel>
						<br />
						<Panel header={`ทั้งหมด : ${amount} รายการ`} style={{ textAlign: 'left', fontFamily: 'Kanit' }}>
							<DataTable value={detail.product_detail} style={{ fontFamily: 'Kanit' }}>
								<Column header="รายการ" body={(rowData) => (
									<div className="p-d-flex p-jc-center">
										{rowData.packagename}
										<br />
										{rowData.packagedetail}
									</div>
								)}></Column>
								<Column header="ราคา/หน่วย" body={(rowData) => (
									<div>
										{numberFormat(rowData.price / rowData.quantity)}
									</div>
								)}></Column>
								{detail.servicename === 'Artwork' && (
									<Column header="ค่าขนส่ง/หน่วย" body={(rowData) => (
										<div>
											{numberFormat(rowData.freight / rowData.quantity)}
										</div>
									)}></Column>
								)}
								{detail.servicename === 'Act' && (
									<Column header="ค่าขนส่ง/หน่วย" body={(rowData) => (
										<div>
											{numberFormat(rowData.freight / rowData.quantity)}
										</div>
									)}></Column>
								)}
								<Column header="จำนวน" body={(rowData) => (
									<div>
										{rowData.quantity}
									</div>
								)}></Column>
								<Column header="รวม" body={(rowData) => (
									<div>
										{numberFormat((rowData.price + rowData.freight))}
									</div>
								)}></Column>
							</DataTable>
						</Panel>
						<br />
						{detail.servicename === 'Act' && (
							<React.Fragment>
								<Panel header={'แนบรูปภาพเพื่อดำเนินการ'} style={{ textAlign: 'left', fontFamily: 'Kanit' }}>
									<Grid container spacing={2} sx={{ paddingTop: '1rem' }}>
										<Grid item xs={6}>
											<div>
												{detail_ref.book === "" && (
													<div className="image-package">
														{image1 === '' && (
															<label className="file-input-label1">
																<span>แนบรูปสมุดเล่มเขียว</span>
																<input type="file" className="input-image" onChange={headleUploadImage1} />
															</label>
														)}
														{image1 !== '' && (
															<div style={{ textAlign: 'center', width: '100%' }}>
																<Image src={imagePreview1} alt="img" width="100%" height="200" preview />
																<Button label="เปลี่ยนรูป" style={{ width: '50%' }} onClick={() => setImage1('')} />
															</div>
														)}
													</div>
												)}
												{detail_ref.book !== "" && (
													<div>
														{ImageBodyTemplate(detail_ref.book)}
													</div>
												)}
											</div>
										</Grid>
										<Grid item xs={6}>
											<div>
												{detail_ref.iden === "" && (
													<div className="image-package">
														{image2 === '' && (
															<label className="file-input-label1">
																<span>แนบรูปบัตรประชาชน</span>
																<input type="file" className="input-image" onChange={headleUploadImage2} />
															</label>
														)}
														{image2 !== '' && (
															<div style={{ textAlign: 'center', width: '100%' }}>
																<Image src={imagePreview2} alt="img" width="100%" height="200" preview />
																<Button label="เปลี่ยนรูป" style={{ width: '50%' }} onClick={() => setImage2('')} />
															</div>
														)}
													</div>
												)}
												{detail_ref.iden !== "" && (
													<div>
														{ImageBodyTemplate(detail_ref.iden)}
													</div>
												)}
											</div>
										</Grid>
										<Grid item xs={12}>
											<div style={{ textAlign: 'center', marginTop: '1rem' }}>
												<Button label="บันทึกข้อมูล" onClick={headleUploadImage} />
											</div>
										</Grid>
									</Grid>
								</Panel>
								<br />
							</React.Fragment>
						)}
						<Panel header='สถานะการทำงาน' style={{ textAlign: 'left', fontFamily: 'Kanit' }}>
							<Timeline value={detail.status} opposite={(item) => item.name} content={(item) => <small>{datetimeFormatFull(item.timestamp)}</small>} style={{ fontFamily: 'Kanit' }} />
						</Panel>
						<br />
						<Panel header="เอกสารแนบกลับ" style={{ textAlign: 'left', fontFamily: 'Kanit' }}>
							<p>เลขแทรคกิ้ง : {detail.tracking}</p>
						</Panel>
					</Grid>
				</Grid>

				<div style={{ display: 'none' }}>
					<div ref={componentRef} style={{ fontSize: '10px', textAlign: 'center', padding: '0rem', fontFamily: 'Kanit' }}>
						<ReceiptArtwork data={detail} />
					</div>
				</div>
			</Sidebar>

			<LoadingDialog isLoading={isLoading} />
		</div >
	)
}