import React, { useState } from "react";
import Swal from 'sweetalert2';
import dayjs from "dayjs";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

import LoadingDialog from "../../../views/LoadingView";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getEmployee(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/employee/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getOrderShop(packageId) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/preorder/shop/shop-id/${packageId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function closeShopPOS(packageData) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/invoice/shop`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
		body: JSON.stringify(packageData),
	}).then(data => data.json())
};

function datetimeFormatFull(date) {
	return dayjs(date).format("DD/MM/YYYY เวลา hh:mm:ss น.");
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

function getLastStatus(item) {
	return item[item.length - 1].name;
};

function setItemProduct(product) {
	const item = [];
	let value = null;
	product.forEach(el => {
		el.poshop_detail.forEach(i => {
			const check_item = item.find((it) => it.productTG_id === i.productShop_tossagun_id);
			if (check_item) {
				const position = item.findIndex((t) => t.productTG_id === i.productShop_tossagun_id);
				let summary_cost = check_item.summary_cost + (i.amount * i.product_ref.productTG_cost.cost_net);
				let summary_price = check_item.summary_price + (i.amount * i.product_ref.productTG_price.price);
				value = {
					...check_item,
					amount: check_item.amount + i.amount,
					summary_cost: summary_cost,
					summary_price: summary_price,
					profit: summary_price - summary_cost,
				};
				item.splice(position, 1, value);
			} else {
				let summary_cost = i.product_ref.productTG_cost.cost_net * i.amount;
				let summary_price = i.product_ref.productTG_price.price * i.amount;
				value = {
					productShop_barcode: i.productShop_barcode,
					productTG_id: i.productShop_tossagun_id,
					name: i.product_ref.productTG_name,
					amount: i.amount,
					cost: i.product_ref.productTG_cost.cost_net,
					price: i.product_ref.productTG_price.price,
					summary_cost: summary_cost,
					summary_price: summary_price,
					profit: summary_price - summary_cost,
				};
				item.push(value);
			}
		});
	});
	// console.log(item)
	// setItem(item);
	return item;
};

export default function ClosePOSShop() {
	const [isLoading, setisLoading] = useState(false);
	const [employee, setEmployee] = useState([]);

	const [shop, setShop] = useState("");
	const [me, setMe] = useState("");
	const [emp, setEmp] = useState("");

	const [order, setOrder] = useState([]);
	const [orders, setOrders] = useState([]);
	const [itemsProduct, setItem] = useState([]);

	useState(async () => {
		const response = await getMe();
		setisLoading(true);
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			Swal.fire({
				title: "แจ้งเตือน!",
				text: "ผู้ใช้งานดังกล่าว ไม่สามารถใช้งานระบบนี้ได้!",
				icon: "warning",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "ยืนยัน",
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = "/";
				}
			})
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				setisLoading(false);
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
			} else {
				setisLoading(false);
				setMe(response.data);
				setEmp(response.data);
				setShop(shop.data);
				const emp = await getEmployee(shop.data._id);
				setEmployee(emp.data);
				const resp = await getOrderShop(shop.data._id);
				const resp_order = resp.data.filter(
					(el) => el.poshop_cutoff === false && el.poshop_identity === true
				);
				setOrder(resp_order.reverse());
				setOrders(resp_order);
				const resp_item = await setItemProduct(resp_order);
				setItem(resp_item)
			}
		}
	});

	const calculateCost = () => {
		return itemsProduct.reduce((total, item) => total + item.summary_cost, 0);;
	};

	const calculateProfit = () => {
		return itemsProduct.reduce((total, item) => total + item.profit, 0);;
	};

	const closeShop = async e => {
		if (itemsProduct.length === 0) {
			Swal.fire({
				title: 'แจ้งเตือน!',
				text: 'ไม่พบรายการขายสินค้า',
				icon: 'warning',
				showConfirmButton: false,
				timer: 1500,
			});
			return false;
		}
		Swal.fire({
			title: "ปิดการขายประจำวัน!",
			text: "ยืนยันการปิดการขายและสร้างใบแจ้งหนี้เพื่อส่งยอดต้นทุนนี้?",
			icon: "question",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "ยืนยัน",
			cancelButtonText: "ยกเลิก",
		}).then(async (result) => {
			if (result.isConfirmed) {
				// window.location.href = "/";
				setisLoading(true);
				const data_invoce = {
					invoice_ref: shop.shop_number + dayjs(Date.now()).format('YYMMDDHHmmss'),
					invoice_shop_id: shop._id,
					invoice_detail: itemsProduct,
					invoice_poshop: orders,
					invoice_timestamp: dayjs(Date.now()).format(),
				};
				const res = await closeShopPOS(data_invoce);
				if (res.status) {
					setisLoading(false);

					Swal.fire({
						title: 'ปิดการขายสำเร็จ!',
						text: 'ปิดการขายเรียบร้อยแล้ว กรุณาไปที่ใบแจ้งหนี้เพื่อชำระยอดต้นทุนสินค้า',
						icon: 'success',
						showConfirmButton: true,
						confirmButtonColor: "#3085d6",
						confirmButtonText: "ยืนยัน",
					}).then((result) => {
						if (result.isConfirmed) {
							window.location.href = "/pos/invoice";
						}
					});
				} else {
					setisLoading(false);
					Swal.fire({
						title: 'แจ้งเตือน!',
						text: 'มีบางอย่างผิดพลาด',
						icon: 'error',
						showConfirmButton: false,
						timer: 3000,
					});
				}
			}
		})
	};

	const headerGroup = (
		<ColumnGroup>
			<Row>
				<Column header="ราคาต้นทุนที่ต้องส่ง : " colSpan={2} footerStyle={{ textAlign: 'left' }} />
				<Column header={`${numberFormat(calculateCost())} บาท`} colSpan={1} />
				<Column colSpan={5} />
			</Row>
			<Row>
				<Column header="กำไรก่อนหักค่าใช้จ่ายทางการตลาด : " colSpan={2} footerStyle={{ textAlign: 'left' }} />
				<Column header={`${numberFormat(calculateProfit())} บาท`} colSpan={1} />
				<Column colSpan={5} />
			</Row>
			<Row>
				<Column header="รายการสินค้าที่ถูกขาย : " colSpan={2} footerStyle={{ textAlign: 'left' }} />
				<Column header={`${itemsProduct.length} รายการ`} colSpan={1} />
				<Column colSpan={5} />
			</Row>
			<Row>
				<Column header="รายการขาย (ที่ไม่ถูกยกเลิกใบเสร็จ) : " colSpan={2} footerStyle={{ textAlign: 'left' }} />
				<Column header={`${orders.length} รายการ`} colSpan={1} />
				<Column colSpan={5} />
			</Row>
			<Row>
				<Column colSpan={1} header="รายการ" />
				<Column colSpan={1} header="สินค้า" />
				<Column colSpan={1} header="ต้นทุน/หน่วย" />
				<Column colSpan={1} header="ราคาขาย/หน่วย" />
				<Column colSpan={1} header="จำนวน" />
				<Column colSpan={1} header="ต้นทุนรวม" />
				<Column colSpan={1} header="ราคาขายรวม" />
				<Column colSpan={1} header="กำไร" />
			</Row>
		</ColumnGroup>
	);

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"order-container"}>
								<div className="title" >
									<h1>ปิดงานรายการขายประจำวัน</h1>
									<p><em>ปิดงานร้านค้าเพื่อส่งยอดและแจ้งชำระเงินต้นทุนภายในแต่ละวันหลังปิดร้าน</em></p>
								</div>

								<Box sx={{ width: '100%', height: 'auto' }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
											<Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
												<Button label="ปิดรายการขายประจำวัน" icon="pi pi-fw pi-upload" onClick={closeShop} />
											</Grid>
										</Grid>
									</Grid>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item className="order-model" style={{ marginTop: '2rem' }}>
											<DataTable value={itemsProduct} headerColumnGroup={headerGroup} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100]} style={{ fontFamily: 'Kanit' }}>
												<Column header="รายการ" style={{ width: '10%' }} body={(item) => (
													<Chip label={item.productShop_barcode} style={{
														fontFamily: 'Kanit',
														paddingLeft: '1rem', paddingRight: '1rem',
													}} />
												)}></Column>
												<Column header="สินค้า" style={{ width: '30%' }} body={(item) => (
													<div>
														{item.name}
													</div>
												)}></Column>
												<Column header="ต้นทุน/หน่วย" style={{ width: '10%' }} body={(item) => (
													<div>
														{numberFormat(item.cost)}
													</div>
												)}></Column>
												<Column header="ราคาขาย/หน่วย" style={{ width: '10%' }} body={(item) => (
													<div>
														{numberFormat(item.price)}
													</div>
												)}></Column>
												<Column header="จำนวน" style={{ width: '10%' }} body={(item) => (
													<div>
														{item.amount}
													</div>
												)}></Column>
												<Column header="ต้นทุนรวม" style={{ width: '10%' }} body={(item) => (
													<div>
														{numberFormat(item.summary_cost)}
													</div>
												)}></Column>
												<Column header="ราคาขายรวม" style={{ width: '10%' }} body={(item) => (
													<div>
														{numberFormat(item.summary_price)}
													</div>
												)}></Column>
												<Column header="กำไร" style={{ width: '10%' }} body={(item) => (
													<div>
														{numberFormat(item.profit)}
													</div>
												)}></Column>
											</DataTable>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>

			<LoadingDialog isLoading={isLoading} />
		</div>
	)
};