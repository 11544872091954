import React, { useState } from "react";
import dayjs from "dayjs";
import Swal from 'sweetalert2';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import LoadingDialog from "../../../views/LoadingView";

async function getMe() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/me`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getEmployee(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/employee/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

async function getPreorderShop(shop_id) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/preorder/tossagun/shop/${shop_id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

function datetimeFormat(date) {
	return dayjs(date).format("DD/MM/YYYY เวลา hh:mm:ss น.");
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export default function OrderPreorder() {
	const [isLoading, setisLoading] = useState(false);
	const [position, setPosition] = useState({});
	const [employee, setEmployee] = useState([]);
	const [shop, setShop] = useState({});

	// Order Preorder
	const [item_order, setItemOrder] = useState([]);

	// Detail
	const [dialogDetail, setDialogDetail] = useState(false);
	const [detail, setDetail] = useState({});
	const [orderDetail, setOrderDetail] = useState([]);

	useState(async () => {
		setisLoading(true);
		const response = await getMe();
		if (response.logout === true) {
			localStorage.clear();
			window.location.href = "/";
		}
		if (response.level === 'member') {
			setisLoading(false);
			Swal.fire({
				title: "แจ้งเตือน!",
				text: "ผู้ใช้งานดังกล่าว ไม่สามารถใช้งานระบบนี้ได้!",
				icon: "warning",
				showCancelButton: false,
				confirmButtonColor: "#3085d6",
				confirmButtonText: "ยืนยัน",
			}).then((result) => {
				if (result.isConfirmed) {
					window.location.href = "/";
				}
			})
		} else if (response.level === 'employee') {
			const shop = await getShop(response.data.employee_shop_id);
			if (shop.data.shop_status === false) {
				setisLoading(false);
				Swal.fire({
					title: 'แจ้งเตือน!',
					text: 'ร้านค้าของท่านถูกปิดการใช้งาน กรุณาติดต่อแอดมิน',
					icon: 'warning',
					showConfirmButton: false,
					timer: 1500,
				});
				return false;
			} else {
				setisLoading(false);
				if (response.data.employee_position !== 'manager') {
					Swal.fire({
						title: 'แจ้งเตือน!',
						text: 'คุณไม่สามารถใช้งานระบบดังกล่าวได้',
						icon: 'warning',
						showConfirmButton: false,
						timer: 1500,
					});
					return false;
				}
				setPosition(shop.data.shop_type);
				setShop(shop.data);
				// Product
				const emp = await getEmployee(shop.data._id);
				setEmployee(emp.data)
				const order = await getPreorderShop(shop.data._id);
				setItemOrder(order.data.reverse());
			}
		}
	});

	const headleDetail = (value) => e => {
		setDialogDetail(true);
		setDetail(value);
		setOrderDetail(value.ponba_detail);
	};

	return (
		<div>
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box>
							<Grid container className={"order-container"}>
								<div className="title" ><h1>รายการสั่งซื้อสินค้า</h1></div>
								<Box sx={{ width: '100%' }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item className="order-model">
											<DataTable value={item_order} paginator rows={5} style={{ fontFamily: 'Kanit' }}>
												<Column header="เลขที่ใบสั่งซื้อ" field="ponba_number"></Column>
												<Column header="สถานะ" body={(item) => (
													<div>
														<Chip label={item.ponba_status} style={{
															fontFamily: 'Kanit', paddingLeft: '1rem',
															paddingRight: '1rem',
														}} />
													</div>
												)}>
												</Column>
												<Column header="รายการ" body={(item) => (
													<div>
														{item.ponba_detail.length}
													</div>
												)}>
												</Column>
												<Column header="ยอดทำรายการ" body={(item) => (
													<div>
														{numberFormat(item.ponba_total)}
													</div>
												)}>
												</Column>
												<Column header="วันที่ทำรายการ" body={(item) => (
													<div>
														{datetimeFormat(item.ponba_date)}
													</div>
												)}>
												</Column>
												<Column header="ตัวเลือก" body={(item) => (
													<div>
														<Button outlined icon="pi pi-align-center" severity="help"
															label="รายละเอียด" style={{ fontFamily: 'Kanit' }} onClick={headleDetail(item)} />
													</div>
												)}>
												</Column>
											</DataTable>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid >
			</Box >

			<Dialog header={`เลขที่ใบสั่งซื้อ ${detail.ponba_number}`} visible={dialogDetail} onHide={() => { if (!dialogDetail) return; setDialogDetail(false); }}
				style={{ fontFamily: 'Kanit', width: '60vw' }} breakpoints={{ '960px': '75vw', '640px': '90vw' }} >
				<div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', width: '100%' }}>
					<p style={{ fontSize: '20px' }}>วันที่สั่งซื้อ</p>
					<p style={{ padding: '0.5rem' }}>{datetimeFormat(detail.ponba_date)}</p>
					{/* <div></div> */}
				</div>
				<Divider />
				<div style={{ width: '100%' }}>
					<table style={{ width: '100%' }}>
						<tbody>
							<tr style={{ backgroundColor: '#960e8f', color: 'white', fontWeight: 'bold' }}>
								<th style={{ textAlign: 'center' }}>ลำดับ</th>
								<th style={{ textAlign: 'center' }}>รหัสสินค้า</th>
								<th style={{ textAlign: 'left' }}>ชื่อสินค้า</th>
								<th style={{ textAlign: 'right' }}>ราคาต่อหน่วย</th>
								<th style={{ textAlign: 'right' }}>จำนวน</th>
								<th style={{ textAlign: 'right' }}>รวมเป็นเงิน</th>
							</tr>
						</tbody>
						<tbody>
							{orderDetail.map((item, index) => (
								<tr key={index}>
									<td style={{ textAlign: 'center' }}>{index + 1}</td>
									<td style={{ textAlign: 'center' }}>{item.productTG_barcode}</td>
									<td style={{ textAlign: 'left' }}>{item.productTG_name}</td>
									<td style={{ textAlign: 'right' }}>{numberFormat(item.productTG_cost.cost_net)}</td>
									<td style={{ textAlign: 'right' }}>{numberFormat(item.amount)}</td>
									<td style={{ textAlign: 'right' }}>{numberFormat(item.total)}</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td style={{ fontWeight: '700' }}>รวม :</td>
								<td style={{ fontWeight: '700' }}>{numberFormat(detail.ponba_total == null ? '' : detail.ponba_total)}</td>
							</tr>
						</tfoot>
					</table>
				</div>
				<Divider />
			</Dialog>

			<LoadingDialog isLoading={isLoading} />
		</div >
	)
}