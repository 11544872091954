import React, { useState } from "react";
import dayjs from "dayjs";
import LoadingDialog from "../../../views/LoadingView";

async function getMember(tel) {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/member/check/${tel}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

function datetimeFormat(date) {
	return dayjs(date).format("DD/MM/YYYY เวลา HH:mm:ss น.");
};

function numberFormat(number) {
	return number.toLocaleString("en-US", {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

async function getEmployee() {
	const token = JSON.parse(localStorage.getItem('token'));
	return fetch(`${process.env.REACT_APP_SHOP}/employee`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			'auth-token': `Bearer ${token}`,
		},
	}).then(data => data.json())
};

export default function ReceiptPOS({ data }) {
	const [isLoading, setisLoading] = useState(false);
	const [employee, setEmployee] = useState({});
	const [member, setMember] = useState({});
	const [order_detail, setOrderDetail] = useState([]);
	const [detail, setDetail] = useState({});

	useState(async () => {
		setisLoading(true);
		const mem = await getMember(data.poshop_platform);
		const emp = await getEmployee();
		if (mem.status === true && emp.status === true) {
			setisLoading(false);
			// setEmployee(emp.data);
			setDetail(data);
			setMember(mem.data);
			setOrderDetail(data.poshop_detail);
			const emp_data = emp.data.find(
				(el) => el._id === data.poshop_employee
			);
			if (emp_data) {
				setEmployee(emp_data);
			}
		}
	});

	return (
		<div>
			<div>
				<div>
					<img src={require('../../../assets/logoreceipt.png')} alt="img" style={{ width: '50%', paddingTop: '1rem' }} />
					<p>
						บริษัท ทศกัณฐ์ ดิจิตอลนิวเจนเนอเรชั่น จำกัด <br />(สำนักงานใหญ่)<br />
						103 หมู่ที่ 4 ถนนรอบเมืองเชียงใหม่ ตำบลสุเทพ อำเภอเมือง จังหวัดเชียงใหม่ 50200 <br />
						เลขประจำตัวผู้เสียภาษี 0505567007065 <br />
						โทรศัพท์ 058-083288 Line ID : @tossagun
					</p>
					<p>
						ต้นฉบับใบเสร็จ / ใบกำกับภาษี <br />
						Original Receipt / Tax Invoice
					</p>
					<p style={{ textAlign: 'right' }}>
						ใบเสร็จเลขที่ : {data.poshop_invoice}<br />
						วันที่ : {datetimeFormat(data.poshop_timestamp)}<br />
						ผู้รับเงิน : {employee.employee_firstname}
					</p>
				</div>
				<hr />
				<div style={{ textAlign: 'left' }}>
					ชื่อและที่อยู่ผู้ซื้อ/ใช้บริการ
					<br />
					คุณ {member.fristname} {member.lastname}
					<br />
					สมาชิก Number {member.card_number}
					<br />
					ที่อยู่ {member.address} {member.subdistrict} {member.district} {member.province} {member.postcode}
					<br />
					{/* เลขประจำตัวผู้เสียภาษี {member.iden.number} */}
					{/* <br /> */}
					เบอร์โทรศัพท์ : {member.tel}
				</div>
				<hr />
				<div>
					<table style={{ width: '100%' }}>
						<tbody>
							<tr>
								<th style={{ textAlign: 'left', width: '55%' }}>รายการ</th>
								<th style={{ textAlign: 'right', width: '15%' }}>ราคา</th>
								<th style={{ textAlign: 'center', width: '15%' }}></th>
								<th style={{ textAlign: 'right', width: '15%' }}>รวม</th>
							</tr>
						</tbody>
					</table>
					<table style={{ width: '100%' }}>
						<tbody>
							{order_detail.map((item, index) => (
								<React.Fragment key={index}>
									<tr>
										<td style={{ textAlign: 'left', width: '55%' }}>
											{item.product_ref.productTG_name}
										</td>
										<td style={{ textAlign: 'right', width: '15%' }}>{numberFormat(item.product_ref.productTG_price.price == null ? '' : item.product_ref.productTG_price.price)}</td>
										<td style={{ textAlign: 'right', width: '15%' }}>x{item.amount}</td>
										<td style={{ textAlign: 'right', width: '15%' }}>{numberFormat(item.total == null ? '' : item.total)}</td>
									</tr>
								</React.Fragment>
							))}
						</tbody>
					</table>
					<hr />
					<table style={{ width: '100%' }}>
						<tbody>
							<tr style={{ fontWeight: 'bold' }}>
								<td style={{ textAlign: 'left' }}>ยอดสุทธิ :</td>
								<td style={{ textAlign: 'right' }}>{numberFormat(data.poshop_total == null ? '' : data.poshop_total)}</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'left' }}>{data.poshop_paymenttype} :</td>
								<td style={{ textAlign: 'right' }}>{numberFormat(data.poshop_moneyreceive == null ? '' : data.poshop_moneyreceive)}</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'left' }}>เงินทอน :</td>
								<td style={{ textAlign: 'right' }}>{numberFormat(data.poshop_change == null ? '' : data.poshop_change)}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<hr />
				<div>
					<p style={{ textAlign: 'left' }}>
						หมายเหตุ : Remark
					</p>
					<p style={{ textAlign: 'left' }}>
					</p>
					<table style={{ width: '100%' }}>
						<tbody>
							<tr>
								<td style={{ textAlign: 'left' }}>
									สมาชิกสะสมคะแนน
									<br />
									Member Number : {member.card_number}
									<br />
									คะแนนสะสมคงเหลือ
								</td>
								<td style={{ textAlign: 'right' }}>{member.happy_point == null ? '' : member.happy_point}P</td>
							</tr>
						</tbody>
					</table>
					<p>
						***ขอขอบพระคุณที่ใช้บริการ***<br />
						ทุกการซื้อ/บริการ ... คือการแบ่งปัน<br />
						THANK TOU AND WELCOME
					</p>
					<p style={{ textAlign: 'left' }}>
						Attention : Contact help desk <br />
						หากมีข้อมูลสอบถาม/พบปัญหา สามารถสอบถามแนะนำ <br />
						Call center สอบถามการให้บริการได้ที่ <br />
						โทรศัพท์ 052-083288 <br />
						Line OA : @tossagun
					</p>
				</div>
			</div>

			<LoadingDialog isLoading={isLoading} />
		</div>
	)
};