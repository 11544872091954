// busLogos.js

import AirChaiyaphum from './BusLogo/AirChaiyaphum.jpg';
import BangkokBuslines from './BusLogo/BangkokBuslines.png';
import Bangsaphantour from './BusLogo/Bangsaphantour.jpg';
import Budsarakam from './BusLogo/Budsarakam.png';
import Chaiyaphum from './BusLogo/Chaiyaphum.jpeg';
import Chumphae from './BusLogo/Chumphae.png';
import DeeTravels from './BusLogo/DeeTravels.png';
import GOFamily from './BusLogo/GOFamily.png';
import Jirattakarn from './BusLogo/Jirattakarn.jpg';
import kantharalak from './BusLogo/kantharalak.jpg';
import Khemmarat from './BusLogo/Khemmarat.jpg';
import Krungsiam from './BusLogo/Krungsiam.jpg';
import LotusPhibun from './BusLogo/LotusPhibun.jpeg';
import Montanatip from './BusLogo/Montanatip.png';
import PhitsanulokYanyon from './BusLogo/PhitsanulokYanyon.jpg';
import PhuKradueng from './BusLogo/PhuKradueng.jpg';
import Piyachai from './BusLogo/Piyachai.jpeg';
import Piyaprasert from './BusLogo/Piyaprasert.png';
import PrayatTour from './BusLogo/PrayatTour.jpg';
import Rayong from './BusLogo/Rayong.jpg';
import Rungprasert from './BusLogo/Rungprasert.jpeg';
import Sasanan from './BusLogo/Sasanan.png';
import Sawasdee from './BusLogo/Sawasdee.jpg';
import SawasdeeSurin from './BusLogo/SawasdeeSurin.jpg';
import SombatTour from './BusLogo/SombatTour.jpg';
import SriTrang from './BusLogo/SriTrang.jpeg';
import Sunbus from './BusLogo/Sunbus.jpg';
import Suwannatee from './BusLogo/Suwannatee.jpg';
import Thaisanguan from './BusLogo/Thaisanguan.jpg';
import thaiSriram from './BusLogo/thaiSriram.png';
import van from './BusLogo/van.png';
import VanGroup from './BusLogo/VanGroup.png';
import Wattanasakorn from './BusLogo/Wattanasakorn.jpg';
import TaraTour from './BusLogo/TaraTour.jpg';
import phetprasert from './BusLogo/phetprasert.png';
import Orsuksa from './BusLogo/Orsuksa.jpeg';


const busLogos = {
  'Bangkok Buslines': BangkokBuslines,
  'Thai Sriram Transport Limited Partnership': thaiSriram,
  'Budsarakam Tour': Budsarakam,
  'Dee Travels Hatyai': DeeTravels,
  'Piyachai Pattana': Piyachai,
  'Sri Trang Tour': SriTrang,
  'Piyaprasert Tour': Piyaprasert,
  'Phitsanulok Yanyon': PhitsanulokYanyon,
  'Van Group 5': VanGroup,
  'Prayat Tour': PrayatTour,
  'Lotus Phiboon Tour': LotusPhibun,
  'Jirattakarn Khemarat': Jirattakarn,
  'Phu Kradueng Tour': PhuKradueng,
  'Khemmarat Rungreang Tour': Khemmarat,
  'Sombat Tour': SombatTour,
  'Kantharalak': kantharalak,
  'Wattanasakorn Tour': Wattanasakorn,
  'Montanatip': Montanatip,
  'Krungsiam Tour': Krungsiam,
  'Sawasdee Esan': Sawasdee,
  'Sawasdee Surin': SawasdeeSurin,
  'Rungprasert Tour': Rungprasert,
  'Sunbus': Sunbus,
  'Rayong Tour': Rayong,
  'TCL Van': van,
  'Thaisanguan Tour': Thaisanguan,
  'Bangsaphantour': Bangsaphantour,
  'Air Chaiyaphum': AirChaiyaphum,
  'Suwannatee Khon Song': Suwannatee,
  'Chumphae Tour': Chumphae,
  'Chaiyaphum Tour': Chaiyaphum,
  'GO Family': GOFamily,
  'Sasanan Tour': Sasanan,
  'Tara Tour & Travel': TaraTour,
  'Phetprasert': phetprasert,
  'Orsuksa Tour': Orsuksa

};

export const getBusLogo = (companyName) => busLogos[companyName] || van;