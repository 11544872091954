import React from "react";

export default function LoadingDialog({ isLoading }) {
	if (!isLoading) return null;

	return (
		<div className="overlay">
			<div className="spinner"></div>
		</div>
	);
};